import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import UploadFiles from "./UploadFiles";

function MediaUploadDialog(props) {
  const { uploadDialog, setUploadDialog, setUserImagesList, userImagesList } =
    props;

  const {} = useBuilder();

  //   const onSelectFile = (event) => {
  //     console.log(event.target.files, "joncdjdjdcnjdcjnd");

  //     const selectedFiles = event.target.files;
  //     const selectedFilesArray = Array.from(selectedFiles);
  //     const imagesArray = selectedFilesArray.map((file) => {
  //       return URL.createObjectURL(file);
  //     });

  //     setUserImagesList((p) => p.concat(imagesArray));
  //   };

  const handleClose = () => {
    setUploadDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={uploadDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
            },
          },
        }}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle>Upload Files</DialogTitle>
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <i class="fa fa-xmark"></i>
          </div>
        </div>
        <div
          style={{
            height: "75vh",
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
          }}
        >
          <UploadFiles
            userImagesList={userImagesList}
            setUserImagesList={setUserImagesList}
            setUploadDialog={setUploadDialog}
          />
        </div>
      </Dialog>
    </>
  );
}

export default MediaUploadDialog;
