export const get_local_preview = (file) => {
  if (!file) {
    return "";
  }
  return URL.createObjectURL(file);
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};
export const get_readable_storage_size = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(0) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(0) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(0) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

let timer;
export const  debounce=(func, timeout = 300)=> {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, timeout);
}
