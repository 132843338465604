import { Box, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  _get_user,
  _set_user,
} from "../../../../../DAL/localStorage/localStorage";
import { LoadingButton } from "@mui/lab";
import { _save_user_active_campaign } from "../../../../../DAL/projects/projects";
import { useSnackbar } from "notistack";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};
function ActiveCampaignURLandKeyModal({
  open,
  setOpen,
  setexpandActiveCampaign,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [activeCampaign, setActiveCampaign] = useState({
    activeCampaignURL: "",
    activeCampaignKey: "",
    isLoading: false,
  });

  const handleChangeCompanionLoader = (value) => {
    setActiveCampaign({ ...activeCampaign, isLoading: value });
  };

  const handleChangeActiveCampaign = (e) => {
    setActiveCampaign({ ...activeCampaign, [e.target.name]: e.target.value });
  };

  const handleSubmitActiveCompanion = async (e) => {
    e.preventDefault();
    // const page_id = params?.page_id;

    handleChangeCompanionLoader(true);
    const user = _get_user();

    let postData = {
      active_compaign_url: activeCampaign.activeCampaignURL,
      key: activeCampaign.activeCampaignKey,
    };
    const compain_url_resp = await _save_user_active_campaign(postData);
    if (compain_url_resp.code == 200) {
      _set_user({
        ...user,
        active_compaign_url: activeCampaign.activeCampaignURL,
        active_campaign_key: activeCampaign.activeCampaignKey,
      });
      enqueueSnackbar("Active campaign updated successfully", {
        variant: "success",
      });
      setexpandActiveCampaign(true);
    } else {
      enqueueSnackbar(compain_url_resp.message, { variant: "error" });
    }
    // _set_user({
    //     ...user,
    //     active_compaign_url: activeCampaign.activeCampaignURL,
    //     active_campaign_key: activeCampaign.activeCampaignKey,
    //   });

    handleChangeCompanionLoader(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setpageData = async () => {
    const user = _get_user();
    setActiveCampaign({
      ...activeCampaign,
      activeCampaignURL: user.active_compaign_url,
      activeCampaignKey: user.active_campaign_key,
    });
  };

  useEffect(() => {
    setpageData();
  }, [open]);

  return (
    <>
      <div>
        <Modal
          disableTypography="true"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmitActiveCompanion}>
            <Box sx={style} className="modal-theme">
              <div clo-12 className="text-end modalIcon">
                <CloseIcon onClick={handleClose} className="pointer" />
              </div>
              <Typography
                className="text-center mb-4"
                id="spring-modal-title"
                variant="h6"
                component="h2"
              >
                Active Campaign
              </Typography>

              <div className="col-12  mt-3">
                <TextField
                  fullWidth
                  label="Active Campaign URL"
                  required
                  name="activeCampaignURL"
                  value={activeCampaign.activeCampaignURL}
                  onChange={handleChangeActiveCampaign}
                />
              </div>

              <div className="col-12  mt-3">
                <TextField
                  fullWidth
                  label="Active Campaign Key"
                  required
                  name="activeCampaignKey"
                  value={activeCampaign.activeCampaignKey}
                  onChange={handleChangeActiveCampaign}
                />
              </div>

              <div className="col-12 mt-3">
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={activeCampaign.isLoading}
                >
                  Submit
                </LoadingButton>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default ActiveCampaignURLandKeyModal;
