import React from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { ElementList } from "../../../../ui_libs/Constants/constant";
import { Dialog, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ReplaceableElementModal = () => {
  const {
    openReplaceElementMenu,
    setOpenReplaceElementMenu,
    isReplaceableElement,
    setIsReplaceableElement,
    selectedElement,
    SelectedRow,
    SaveActionToStack,
    ReRenderHtml,
  } = useBuilder();

  const handleClose = () => {
    setOpenReplaceElementMenu(false);
  };
  const handleReplace = (el) => {
    let element = getNewElement(el);

    selectedElement.parentNode.innerHTML = element.outerHTML;
    handleClose();
    ReRenderHtml();
  };
  const handleAddElement = (el) => {
    let element = getNewElement(el);
    SelectedRow.append(element);
    ReRenderHtml();
    handleClose();
  };
  const getNewElement = (el) => {
    SaveActionToStack();
    let element = document.createElement("div");
    if (el.alternate_code) {
      element.classList.add("row");
      element.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/inpok4MKVLM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`;
    } else {
      element.innerHTML = el.code;

      element = element.children[0];
      element.children[0].removeAttribute("width");
      element.removeAttribute("id");
      element.className = "row";
    }

    return element;
  };
  const getHeading = () => {
    return isReplaceableElement
      ? "Click on any element to replace selected element"
      : "Click on element to add";
  };

  return (
    <Dialog
      keepMounted={true}
      open={openReplaceElementMenu}
      onClose={handleClose}
    >
      <Stack spacing={2} sx={{ padding: 2 }}>
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography variant="h6">{getHeading()}</Typography>
          <IconButton className="pointer" onClick={handleClose}>
            <CloseIcon   />
          </IconButton>
        </Stack>
        <div className="row p-4">
          {ElementList.map((el, i) => (
            <div
              key={i}
              onClick={() =>
                isReplaceableElement ? handleReplace(el) : handleAddElement(el)
              }
              className="col-3 p-1"
            >
              <div className="element-card">
                <i class={el.icon}></i>
                <div className="text-center custom-label pt-2">
                  <span className="">{el.title}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Stack>
    </Dialog>
  );
};

export default ReplaceableElementModal;
