import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Close, Done } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export const RowChildBlockClasses = ({
  // column block list
  Children,

  // coumn classes and handler
  Column_classes,
  column_classes_list,
  handleChangeColumnClass,

  // column block drag hndler
  handleDragEnd,

  // column icon function handlers
  handleMoveup,
  handleMovedown,
  handleDuplicate,
  handleRemoveColumn,
  handleDoneEditColumnName,
  handleEditBlockName,
  handleOpenStyling,

  // column block name chage
  EditColumnIndex,
  setEditColumnIndex,
  EditColumnName,
  setEditColumnName,
}) => {
  return (
    <div className="row ">
      <div className="col-12">
        <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
          <Droppable droppableId="doprable1">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {Children.map((child, index) => (
                  <Draggable
                    draggable={false}
                    key={index}
                    draggableId={`dragable-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`${
                          snapshot.isDragging ? "shadow" : null
                        } row border m-1 mt-2 pb-3 p-1 align-items-center column-block-list`}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          background: snapshot.isDragging ? "white" : null,
                        }}
                      >
                        <div className="col-12 mt-1">
                          <div className="d-flex row-reverse justify-content-end">
                            <span>
                              {/* <Tooltip
                                arrow
                                placement="top"
                                title="Block Styling"
                              >
                                <Icon
                                  icon="clarity:color-palette-solid"
                                  width="16"
                                  height="16"
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleOpenStyling(index)}
                                />
                              </Tooltip> */}
                              <Tooltip
                                arrow
                                placement="top"
                                title="Move block up"
                              >
                                <ArrowUpwardIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleMoveup(index)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Move block down"
                              >
                                <ArrowDownwardIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleMovedown(index)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Duplicate block"
                              >
                                <Icon
                                  icon="heroicons-outline:document-duplicate"
                                  width="16"
                                  height="16"
                                  className="edit-able-section-actions-delete"
                                  onClick={() => handleDuplicate(index)}
                                />
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title="Remove block"
                              >
                                <HighlightOffIcon
                                  sx={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                  className="edit-able-section-actions-delete"
                                  onClick={() => {
                                    handleRemoveColumn(index);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        <div className="mb-2 ">
                          {EditColumnIndex != index && (
                            <Stack
                              sx={{
                                alignitems: "center",
                              }}
                              direction="row"
                              spacing={1}
                            >
                              <Typography variant="subtitle2">
                                {child.getAttribute("block-name")}
                              </Typography>

                              {/* edit bolock name icon */}

                              <Tooltip
                                arrow
                                placement="top"
                                title="Edit block name"
                              >
                                <Icon
                                  icon="ant-design:edit-filled"
                                  width="15"
                                  height="15"
                                  className="column-block-list-edit-button"
                                  onClick={() =>
                                    handleEditBlockName(
                                      child.getAttribute("block-name"),
                                      index
                                    )
                                  }
                                />
                              </Tooltip>
                            </Stack>
                          )}

                          {EditColumnIndex == index && (
                            <div className="pt-2 pb-2 col-6 align-items-center">
                              <div
                                class="btn-group mr-2"
                                role="group"
                                aria-label="First group"
                              >
                                <div className="p-0 icon-container fit-content">
                                  <input
                                    onChange={(e) => {
                                      setEditColumnName(e.target.value);
                                    }}
                                    value={EditColumnName}
                                  ></input>
                                </div>

                                <div className="p-0 icon-container fit-content">
                                  <span className="mx-1 ">
                                    <Tooltip
                                      title="Update Name"
                                      placement="top"
                                      arrow
                                    >
                                      <Done
                                        sx={{ height: 18 }}
                                        htmlColor="green"
                                        onClick={() => {
                                          handleDoneEditColumnName(index);
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </div>
                                <div className="pr-1 icon-container fit-content">
                                  <span className="mx-1 ">
                                    <Tooltip
                                      title="Cancel"
                                      placement="top"
                                      arrow
                                    >
                                      <Close
                                        sx={{ height: 18 }}
                                        htmlColor="red"
                                        onClick={() => {
                                          setEditColumnIndex(null);
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-12">
                            <div className="row">
                              <label className="col custom-label">Mobile</label>
                              <label className="col custom-label">Tablet</label>
                              <label className="col custom-label">Laptop</label>
                            </div>
                            <div className="row">
                              {/* column class of small */}

                              <div className="col">
                                <select
                                  className="w-100 form-control custom-icon-select"
                                  value={Column_classes[index].sm}
                                  onChange={(e) =>
                                    handleChangeColumnClass(
                                      e.target.value,
                                      index,
                                      Column_classes[index].sm
                                    )
                                  }
                                >
                                  {column_classes_list.map((fs, i) => (
                                    <option key={i} value={"col-" + fs}>
                                      {fs}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* column class of medium */}

                              <div className="col">
                                <select
                                  className="w-100 form-control custom-icon-select"
                                  value={Column_classes[index].md}
                                  onChange={(e) =>
                                    handleChangeColumnClass(
                                      e.target.value,
                                      index,
                                      Column_classes[index].md
                                    )
                                  }
                                >
                                  {column_classes_list.map((fs, i) => (
                                    <option key={i} value={"col-md-" + fs}>
                                      {fs}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* column class of large */}
                              <div className="col">
                                <select
                                  className="w-100 form-control custom-icon-select"
                                  value={Column_classes[index].lg}
                                  onChange={(e) =>
                                    handleChangeColumnClass(
                                      e.target.value,
                                      index,
                                      Column_classes[index].lg
                                    )
                                  }
                                >
                                  {column_classes_list.map((fs, i) => (
                                    <option key={i} value={"col-lg-" + fs}>
                                      {fs}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
