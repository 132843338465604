import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function Layout({ styles, handleChangeLayout }) {
  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Layout</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">
          <div className="col-12 mt-2">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="container"
                checked={styles.layout === "container"}
                onChange={handleChangeLayout}
              />
              <label
                className="form-check-label pointer custom-label"
                htmlFor="container"
              >
                Containerize
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="container-fluid"
                checked={styles.layout === "container-fluid"}
                onChange={handleChangeLayout}
              />
              <label
                className="form-check-label pointer custom-label"
                htmlFor="container-fluid"
              >
                Full Width
              </label>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default Layout;
