import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { RowColumnStyleAccordion } from "../Row-Setting-Menu/components/Row-Column-Style/RowColumnStyleAccordion";
import GenerateGradient from "../../../../components/Generate-Gradient/GenerateGradient";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
import { Tooltip } from "@mui/material";
const SelectedBoxDivSettingMenu = () => {
  const { selectedBoxDiv, setSelectedBoxDiv, setOpenSelectedBoxDiv } =
    useBuilder();
  const [change, setChange] = useState(false);
  const [imgData, setImgData] = useState([]);
  const [showData, setShowData] = useState(false);
  const handleChangeColumnBlockStyle = (property, value, index) => {
    selectedBoxDiv.style.setProperty(property, value);
    setChange(!change);
  };
  const handleChangeColumnBackgroundColor = (property, value, index) => {
    selectedBoxDiv.style.setProperty(property, value);
    setChange(!change);
  };
  const getColumnproperty = (name, index) => {
    let property = selectedBoxDiv.style[name];
    return property ? property : "";
  };
  const ResetColumnproperty = (name, index) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  const ResetColumnBackgroundColor = (name, index) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  const handleChangeColumnBlockClass = (value, class_list, index) => {
    // let column_class_list = Children[index].classList;

    class_list.forEach((c) => {
      selectedBoxDiv.classList.remove(c.value);
    });
    if (value) {
      selectedBoxDiv.classList.add(value);
    }
    setChange(!change);
  };
  const handleCancel = () => {
    setOpenSelectedBoxDiv(false);
  };
  const handlegetColumnBlockClass = (class_list, index) => {
    let classname = "";

    class_list.forEach((c) => {
      if (selectedBoxDiv.classList.contains(c.value)) {
        classname = c.value;
      }
    });

    return classname;
  };
  const removeColumnproperty = (name) => {
    selectedBoxDiv.style.removeProperty(name);
    setChange(!change);
  };
  useEffect(() => {
    let data = [];
    const { offsetHeight, offsetWidth } = selectedBoxDiv;
    selectedBoxDiv.setAttribute("imgheight", offsetHeight);
    selectedBoxDiv.setAttribute("imgwidth", offsetWidth);
    data.push({
      height: selectedBoxDiv.getAttribute("imgheight"),
      width: selectedBoxDiv.getAttribute("imgwidth"),
    });
    // height=child.getAttribute('imgheight') width=child.getAttribute('imgwidth')
    setImgData([...data]);
    setShowData(true);
  }, [change]);

  return (
    <div className="row">
      <div
        className="col-12 pt-2 px-4 mb-2 pb-2 border-bottom"
        style={{
          position: "sticky",
          zIndex: "100",
          background: "white",
          top: "0%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 className="mb-0">Box Settings</h6>
        <div style={{ display: "flex" }}>
          <ElementSourceCodeEditorBar />
          <Tooltip title="Close" position="bottom" arrow>
            <div className="icon me-2" onClick={handleCancel}>
              <i class="fa fa-xmark"></i>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="col-12 pt-2 px-4">
        {showData && (
          <>
            <RowColumnStyleAccordion
              child={selectedBoxDiv}
              // OpenColumnStyle={0}
              ChangeColumnBlockStyle={handleChangeColumnBlockStyle}
              ChangeColumnBackgroundColor={handleChangeColumnBackgroundColor}
              getColumnproperty={getColumnproperty}
              ResetColumnproperty={ResetColumnproperty}
              ResetColumnBackgroundColor={ResetColumnBackgroundColor}
              ChangeColumnBlockClass={handleChangeColumnBlockClass}
              getColumnBlockClass={handlegetColumnBlockClass}
              // img properties
              ImgData={imgData[0]}
            />

            <GenerateGradient
              handleAddGradient={handleChangeColumnBlockStyle}
              getColumnproperty={getColumnproperty}
              removeColumnproperty={removeColumnproperty}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SelectedBoxDivSettingMenu;
