import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _update_user_design_page } from "../../../../DAL/projects-pages/projectspages";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function EditPageName({
  open,
  setOpen,
  project_id,
  Page,
  fetchAvailablePages,
}) {
  const navigate = useNavigate();

  const { rootValues, SelectedPage, setSelectedPage, setUseEditingProject } =
    useBuilder();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    PageName: Page.page_name,
    PageSlug: "",
  });

  const handlePageNameSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    let PageSlug = "";
    inputs.PageName = inputs.PageName.trim();
    PageSlug = inputs.PageName.replaceAll(" ", "-");
    PageSlug = PageSlug.toLowerCase();

    const page_resp = await UpdateThemeIndexPage(inputs.PageName, PageSlug);

    if (page_resp.code == 200) {
      setUseEditingProject(true);

      fetchAvailablePages();
      setIsLoading(false);
      setOpen(false);
    } else {
      enqueueSnackbar(page_resp.message, { variant: "error" });
      setIsLoading(false);
      // setOpen(false);
    }
  };
  const UpdateThemeIndexPage = async (PageName, PageSlug) => {
    const page = Page;
    page.page_name = PageName;
    // page.page_slug = PageSlug;
    const {
      page_name,
      page_slug,
      project,
      image,
      mode,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = page;
    const page_resp = await _update_user_design_page(Page._id, {
      page_name,
      page_slug,
      project,
      image,
      mode,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      meta_title,
      meta_description,

      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    });

    return page_resp;
  };
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs({ PageName: Page.page_name });
  }, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlePageNameSubmit}>
          <Box sx={style} className="modal-theme">
            <div clo-12 className="text-end modalIcon">
              <CloseIcon onClick={handleClose} className="pointer" />
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Edit Page Name
            </Typography>

            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                inputProps={{ pattern: "[0-9a-zA-Z-_ ]{1,100}" }}
                helperText="Special characters not allowed"
                label="Page Name"
                variant="outlined"
                fullWidth
                size="small"
                name="PageName"
                value={inputs.PageName}
                onChange={handleChange}
                required={true}
              />
            </div>

            {/* <div className="col-12 mt-3">
              <TextField
                inputProps={{ pattern: "[a-zA-Z-_]{1,100}" }}
                onInvalid={(e) => {
                  e.target.setCustomValidity("Use alhabets only");
                }}
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Page Slug"
                variant="outlined"
                fullWidth
                size="small"
                name="PageSlug"
                value={inputs.PageSlug}
                onChange={handleChange}
                required={true}
              />
            </div> */}
            <div className="col-12 mt-3">
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Page Name
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
