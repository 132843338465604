import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TwitterPicker } from 'react-color'
import { colors } from '../../../../../../ui_libs/Constants/constant'

function TextColor({ styles, handleChangeTextColor, handleResetTextColor }) {
  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Text Color</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">
          <div className="col-12">
            <TwitterPicker
              colors={colors}
              width="100%"
              onChange={handleChangeTextColor}
              color={styles.textColor}
            />
          </div>
          <div className="col-12 text-end mt-3">
            <span className="custom-link" onClick={handleResetTextColor}>
              Reset Text Color
            </span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default TextColor
