import { Chip, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { _templates_category_list } from "../../../DAL/template/template";

export default function TemplateSearch({
  templatesList,
  setFilteredTemplatesList,
}) {
  const [searchTemplates, setSearchTemplates] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);

  const [selectedCategoryList, setSelectedCategoryList] = useState(["all"]);

  const handleCLickAll = () => {
    setSearchTemplates("");

    if (CheckCategorySelected("all")) {
      setSelectedCategoryList(["all"]);
      setFilteredTemplatesList([...templatesList]);
      return;
    } else {
      setSelectedCategoryList(["all"]);
      setFilteredTemplatesList([...templatesList]);
    }
  };

  const handleClickCategoryChip = (name) => {
    let list = [];
    setSearchTemplates("");

    if (CheckCategorySelected(name)) {
      list = selectedCategoryList.filter((selectedName) => {
        return selectedName != name;
      });
    } else {
      list = selectedCategoryList;
      list.push(name);
    }

    list = CheckAllTagExist(list);

    setSelectedCategoryList([...list]);
    FilterTemplateList(list);
  };
  const FilterTemplateList = (selectedCategories) => {
    let filteredList = templatesList;
    if (selectedCategories.length > 0) {
      filteredList = [];
      for (let i = 0; i < templatesList.length; i++) {
        let template = templatesList[i];
        let result = false;
        for (let j = 0; j < template.category.length; j++) {
          let templateCategory = template.category[j];
          if (selectedCategories.includes(templateCategory.name)) {
            result = true;
            break;
          }
        }
        if (result) {
          filteredList.push(template);
        }
      }
    }
    setFilteredTemplatesList([...filteredList]);
  };
  const CheckCategorySelected = (name) => {
    const result = selectedCategoryList.filter((selectedName) => {
      return selectedName == name;
    });
    return result.length > 0 ? true : false;
  };

  const handleChangeSearchTemplate = (e) => {
    let value = e.target.value;
    let list = templatesList;
    setSearchTemplates(value);
    if (value.trim().length > 0) {
      setSelectedCategoryList(["all"]);
      list = [];
      templatesList.map((template) => {
        if (template.name.toLowerCase().includes(value.toLowerCase())) {
          list.push(template);
        }
      });
    }
    setFilteredTemplatesList([...list]);
  };
  const GetTemplateCategory = async () => {
    const resp = await _templates_category_list();
    if (resp.code == 200) {
      setCategoriesList(resp.category);
    } else {
      return;
    }
  };
  const CheckAllTagExist = (selectedList) => {
    let list = [];
    selectedList.map((cat) => {
      if (cat != "all") {
        list.push(cat);
      }
    });
    return list;
  };

  useEffect(() => {
    GetTemplateCategory();
  }, []);

  return (
    <div className="col-12 pb-4">
      <Stack
        direction={"row"}
        className="mx-4"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Stack direction={"row"} sx={{ flexWrap: "wrap" }} spacing={1}>
          <Chip
            label={"All"}
            size="small"
            className=""
            onClick={() => {
              handleCLickAll();
            }}
            style={{
              backgroundColor: CheckCategorySelected("all") ? "green" : "white",
              color: CheckCategorySelected("all") ? "white" : "black",
              cursor: "pointer",
              padding: "0.5rem 0.8rem",
            }}
          />
          {categoriesList.map((category) => (
            <Chip
              key={category.name}
              label={category.name}
              className=""
              size="small"
              onClick={() => {
                handleClickCategoryChip(category.name);
              }}
              style={{
                backgroundColor: CheckCategorySelected(category.name)
                  ? "green"
                  : "white",
                color: CheckCategorySelected(category.name) ? "white" : "black",
                cursor: "pointer",
                padding: "0.5rem 0.8rem",
              }}
            />
          ))}
        </Stack>
        <TextField
          InputProps={{
            style: {
              borderRadius: "30px",
              backgroundColor: "white",
            },
            startAdornment: (
              <InputAdornment position="start" sx={{ color: "#bebebe" }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          label={null}
          variant="standard"
          id="outlined-required"
          placeholder="Search..."
          value={searchTemplates}
          onChange={handleChangeSearchTemplate}
        />
      </Stack>
    </div>
  );
}
