import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Builder",
    path: "/builder-redirect-controller",
    icon: getIcon(barchartfill),
  },
  {
    title: "Programmes",
    path: "/builder",
    icon: getIcon(barchartfill),
  },
  {
    title: "the vault",
    path: "/vault",
    icon: getIcon(peopleFill),
  },
  // {
  //   title: 'profile',
  //   path: '/profile',
  //   icon: getIcon(fileTextFill)
  // },
  {
    title: "pods",
    path: "/pods",
    icon: getIcon(shoppingBagFill),
  },
  {
    title: "affirmations",
    path: "/affirmations",
    icon: getIcon(fileTextFill),
  },
  {
    title: "gratitude",
    path: "/gratitude",
    icon: getIcon(lockFill),
  },
  {
    title: "quotes",
    path: "/quotes",
    icon: getIcon(personAddFill),
  },
  {
    title: "memories",
    path: "/memories",
    icon: getIcon(alertTriangleFill),
  },
  {
    title: "daily activities",
    path: "/daily_activities",
    icon: getIcon(personAddFill),
  },
  {
    title: "calender",
    path: "/calender",
    icon: getIcon(alertTriangleFill),
  },
  {
    title: "support ticket",
    path: "/support_ticket",
    icon: getIcon(alertTriangleFill),
  },
];

export default sidebarConfig;
