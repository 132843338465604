import React, { useEffect } from "react";
import { website_placeholder_img } from "../../assets";
import { app_type, asserts_base_url } from "../../config/config";
import { useState } from "react";
import { _templates_list } from "../../DAL/template/template";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";
import {
  _create_user_design_project,
  _get_user_created_designs,
  _save_user_design,
} from "../../DAL/projects/projects";
import {
  _create_user_design_page,
  _update_user_design_page,
} from "../../DAL/projects-pages/projectspages";

import { useNavigate, useLocation } from "react-router-dom";
import CustomConfirmation from "../../components/CustomConfirmation";
import { _get_user } from "../../DAL/localStorage/localStorage";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import SelectTemplatePages from "./components/SelectTemplatePages";
import { IconButton } from "@mui/material";
import TemplateSearch from "./components/TemplateSearch";
function ChooseTemplate(props) {
  const {
    SelectedPage,
    setUseEditingProject,
    fetchFileFromURL,
    setSelectedSections,
  } = useBuilder();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [templates, setTemplates] = useState([]);

  const [openChangeTemplate, setOpenChangeTemplate] = useState(false);
  const [OpenChangeMultiplePageTemplate, setOpenChangeMultiplePageTemplate] =
    useState(false);
  const [filteredTemplatesList, setFilteredTemplatesList] = useState([]);

  const [storedNavigationState, setStoredNavigationState] = useState();
  const [TargetTemplate, setTargetTemplate] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [CreatingProject, setCreatingProject] = useState(false);
  const [TargetProject, setTargetProject] = useState();
  const handleOpenChangeTemplateConfirmation = () => {
    setOpenChangeTemplate(true);
  };

  const handleChangeTemplateConfirmation = async () => {
    const page_resp = await UpdateThemeIndexPage(
      TargetTemplate.Pages[0],
      TargetProject,
      TargetTemplate.image
    );
    if (page_resp.code === 200) {
      storedNavigationState.page = page_resp.page;
      setUseEditingProject(true);
      navigate(`/builder`, {
        state: storedNavigationState,
      });
    } else {
      enqueueSnackbar(page_resp.message, { variant: "error" });
    }
  };

  const handleProjectClick = (project) => {
    if (project.Pages && project.Pages.length > 0) {
      handleSelectTemplate(project);
    } else {
      enqueueSnackbar("No page available in the Selected Template", {
        variant: "error",
      });
      return;
    }
  };
  const UpdateThemeIndexPage = async (
    template_project,
    project_detail,
    project_thumbnail
  ) => {
    const pages = project_detail.pages;
    let selected_page_id;
    let selected_page_name;
    pages.map((page) => {
      if (page.page_slug == SelectedPage) {
        selected_page_name = page.page_name;
        selected_page_id = page._id;
      }
    });

    const {
      image,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = template_project;
    const page_resp = await _update_user_design_page(selected_page_id, {
      page_slug: SelectedPage,
      image: project_thumbnail ? project_thumbnail : image,
      project: project_detail._id,
      page_name: selected_page_name,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode: mode ? mode : "light",
      input_border_color: input_border_color ? input_border_color : "",
      input_placeholder_color: input_placeholder_color
        ? input_placeholder_color
        : "",
      input_text_color: input_text_color ? input_text_color : "",
      input_background_color: input_background_color
        ? input_background_color
        : "",
      menu_color: menu_color ? menu_color : theme_secondary_color,
      menu_hover_color: menu_hover_color
        ? menu_hover_color
        : theme_secondary_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    });

    return page_resp;
  };

  const CreateThemeIndexPage = async (
    template_project,
    project_id,
    slug,
    image
  ) => {
    // const page_slug = template_project.page_slug
    const page_name = slug;
    const {
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = template_project;

    const data = {
      page_slug: slug,
      image:
        TargetTemplate && TargetTemplate.image
          ? TargetTemplate.image
          : image
          ? image
          : "No Image",
      project: project_id,
      page_name: slug,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode: mode ? mode : "light",
      input_border_color: input_border_color ? input_border_color : "",
      input_placeholder_color: input_placeholder_color
        ? input_placeholder_color
        : "",
      input_text_color: input_text_color ? input_text_color : "",
      input_background_color: input_background_color
        ? input_background_color
        : "",
      menu_color: menu_color ? menu_color : theme_secondary_color,
      menu_hover_color: menu_hover_color
        ? menu_hover_color
        : theme_secondary_color,

      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };
    const page_resp = await _create_user_design_page(data);

    return page_resp;
  };
  const handleSelectTemplate = async (template_project) => {
    setTargetTemplate({ ...template_project });
    const resp = await _get_user_created_designs();

    if (resp.code === 200) {
      // if user creating theme 1st time
      if (resp.Project.length === 0) {
        setCreatingProject(true);
        handleCreateProject(template_project);
        return;
      }
      if (location && location.state && location.state.project_id) {
        const { project_id } = location.state;
        resp.Project.map((project) => {
          if (project._id == project_id) {
            if (project.pages.length > 0) {
              const selected_project = project;
              // let temp = [template_project, selected_project]
              setTargetProject({ ...selected_project });
              setTargetTemplate({ ...template_project });
              // setTargetTemplate([...temp])
              setStoredNavigationState({
                project: selected_project,
                is_template: true,
                template: template_project._id,
              });

              if (template_project.Pages && template_project.Pages.length > 1) {
                setOpenChangeMultiplePageTemplate(true);
              } else {
                setOpenChangeTemplate(true);
              }
              return;
            } else {
              enqueueSnackbar("No Index Page Available", { variant: "error" });
              return;
            }
          }
        });
      } else {
        enqueueSnackbar("No Project and Page Selected", { variant: "error" });
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleCreateProject = async (template_project) => {

    const user = JSON.parse(sessionStorage.getItem("user"));
    const business_type = user.business_type ? user.business_type._id : "";

    // change these values
    const postData = {
      project_name: user.business_name,
      project_category: business_type ? business_type : "",
      image: template_project.image,
      is_approved: false,
      is_completed: false,
      is_published: false,
      status: true,
    };
    const resp = await _create_user_design_project(postData);

    if (resp.code === 200) {
      let LandingPage;
      if (template_project && template_project.Pages) {
        template_project.Pages.map(async (Page, index) => {
          let slug = Page.page_slug ? Page.page_slug : "index";
          const page_resp = await CreateThemeIndexPage(
            Page,
            resp.project._id,
            slug,
            template_project.image
          );
          if (page_resp.code == 200) {
            if (index == 0) {
              LandingPage = page_resp;
            }

            // getPage HTML And append website id and page_id
            const Page_HTML = document.createElement("html");
            Page_HTML.innerHTML = await fetchFileFromURL(
              `${asserts_base_url}/${template_project._id}/${slug}.html`
            );
            if (Page_HTML && Page_HTML.getElementsByTagName("div")) {
              let Page_Div = Page_HTML.getElementsByTagName("div")[0];

              if (
                Page_Div &&
                Page_Div.dataset &&
                Page_Div.dataset.page_id &&
                Page_Div.dataset.website_id
              ) {
                Page_Div.dataset.page_id = page_resp.page._id;
                Page_Div.dataset.website_id = resp.project._id;
              } else {
                let Div = document.createElement("div");
                Div.setAttribute("data-page_id", page_resp.page._id);
                Div.setAttribute("data-website_id", resp.project._id);

                Page_HTML.getElementsByTagName("body")[0].prepend(Div);
              }
            }
            const Page_CSS = await fetchFileFromURL(
              `${asserts_base_url}/${template_project._id}/css/${
                slug == "index" ? "style" : slug
              }.css`
            );
            const Page_JS = await fetchFileFromURL(
              `${asserts_base_url}/${template_project._id}/js/${slug}.js`
            );

            const postData = {
              html: Page_HTML.outerHTML,
              css: Page_CSS ? Page_CSS : "/* No CSS */",
              javascript: Page_JS ? Page_JS : "/* No JS */",
              project_id: resp.project._id,
              slug_name: slug,
            };
            const Save_Page_resp = await _save_user_design(postData);
            if (Save_Page_resp.code == 200) {
              if (template_project.Pages.length == index + 1) {
                sessionStorage.setItem("project", JSON.stringify(resp.project));
                setUseEditingProject(true);
                navigate(
                  `/builder/${resp.project._id}/${LandingPage.page._id}`
                );
              }
            }
          } else {
            enqueueSnackbar(page_resp.message, { variant: "error" });
          }
        });
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const fetchAvailableTemplates = async () => {
    const user = _get_user();
    const postData = {
      app_type: `${user?.type}`,
    };
    const resp = await _templates_list(postData);
    if (resp.code === 200) {
      const data = [];
      const temp = resp.templates.filter((template) => {
        if (template.name == "Blank Theme") {
          data.push(template);
        } else {
          return template;
        }
      });
      data.push(...temp);
      setTemplates(data);
      setFilteredTemplatesList(data);
      setisLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const CheckAvailableSlug = (Name, extention, Number) => {
    let finalName = Name + extention;
    TargetProject.pages.map((ProjectPage, index) => {
      if (finalName == ProjectPage.page_slug) {
        finalName = CheckAvailableSlug(
          Name,
          `-copy(${Number + 1})`,
          Number + 1
        );
      }
    });

    return finalName;
  };
  const CreateNewPageFromTemplate = async (TemplatePage) => {
    let NewSlug = TemplatePage.TemplatePageName;
    if (TemplatePage.MakeCopy) {
      TargetProject.pages.map((ProjectPage, index) => {
        if (TemplatePage.TemplatePageName == ProjectPage.page_slug) {
          NewSlug = CheckAvailableSlug(TemplatePage.TemplatePageName, ``, 0);
        }
      });
      const page_resp = await CreateThemeIndexPage(
        TemplatePage.PageValues,
        TargetProject._id,
        NewSlug,
        TargetTemplate.image
      );
      let slug = TemplatePage.TemplatePageName;
      if (page_resp.code == 200) {
        // getPage HTML And append website id and page_id
        let resp = await UpdatePageonS3(page_resp.page, slug, NewSlug);
        if (resp.code == 200) {
          return resp;
        } else {
          enqueueSnackbar(resp.message, { variant: "error" });
          return resp;
        }
      }
    }
  };

  const UpdatePageonS3 = async (page, slug, NewSlug) => {
    const Page_HTML = document.createElement("html");
    Page_HTML.innerHTML = await fetchFileFromURL(
      `${asserts_base_url}/${TargetTemplate._id}/${slug}.html`
    );
    if (Page_HTML && Page_HTML.getElementsByTagName("div")) {
      let Page_Div = Page_HTML.getElementsByTagName("div")[0];

      if (
        Page_Div &&
        Page_Div.dataset &&
        Page_Div.dataset.page_id &&
        Page_Div.dataset.website_id
      ) {
        Page_Div.dataset.page_id = page._id;
        Page_Div.dataset.website_id = TargetProject._id;
      } else {
        let Div = document.createElement("div");
        Div.setAttribute("data-page_id", page._id);
        Div.setAttribute("data-website_id", TargetProject._id);

        Page_HTML.getElementsByTagName("body")[0].prepend(Div);
      }
    }
    const Page_CSS = await fetchFileFromURL(
      `${asserts_base_url}/${TargetTemplate._id}/css/${
        slug == "index" ? "style" : slug
      }.css`
    );
    const Page_JS = await fetchFileFromURL(
      `${asserts_base_url}/${TargetTemplate._id}/js/${slug}.js`
    );

    const postData = {
      html: Page_HTML.outerHTML,
      css: Page_CSS ? Page_CSS : "/* No CSS */",
      javascript: Page_JS ? Page_JS : "/* No JS */",
      project_id: TargetProject._id,
      slug_name: NewSlug,
    };
    const Save_Page_resp = await _save_user_design(postData);
    if (Save_Page_resp.code == 200) {
      return Save_Page_resp;
    } else {
      enqueueSnackbar(Save_Page_resp.message, { variant: "error" });
      return Save_Page_resp;
    }
  };
  const UpdateProjectPageFromTemplate = async (TemplatePage) => {
    let selected_page_name = TemplatePage.TemplatePageName;
    let selected_page_id = "";
    let selected_page_slug = "";
    TargetProject.pages.map((page) => {
      if (page.page_slug == TemplatePage.PageValues.page_slug) {
        selected_page_id = page._id;
        selected_page_slug = page.page_slug.toString();
        selected_page_name = page.page_name;
      }
    });
    const {
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      status,
      category,

      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = TemplatePage.PageValues;
    const page_resp = await _update_user_design_page(selected_page_id, {
      page_slug: selected_page_slug,
      image: TargetTemplate.image,
      project: TargetProject._id,
      page_name: selected_page_name,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      mode: mode ? mode : "light",
      input_border_color: input_border_color ? input_border_color : "",
      input_placeholder_color: input_placeholder_color
        ? input_placeholder_color
        : "",
      input_text_color: input_text_color ? input_text_color : "",
      input_background_color: input_background_color
        ? input_background_color
        : "",
      menu_color: menu_color ? menu_color : theme_secondary_color,
      menu_hover_color: menu_hover_color
        ? menu_hover_color
        : theme_secondary_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    });
    if (page_resp.code == 200) {
      let resp = await UpdatePageonS3(
        page_resp.page,
        TemplatePage.TemplatePageName,
        selected_page_slug
      );
      if (resp.code == 200) {
        return page_resp;
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
  };
  const handleUpdateExistingProject = async (Data, setOpen) => {
    let TaskComplete = [];
    Data.map((TemplatePage, index) => {
      if (TemplatePage.Selected) {
        if (TemplatePage.MakeCopy) {
          TaskComplete.push(CreateNewPageFromTemplate(TemplatePage));
        } else {
          TaskComplete.push(UpdateProjectPageFromTemplate(TemplatePage));
          // TaskComplete = await UpdateProjectPageFromTemplate(TemplatePage)
        }
      }
    });
    await Promise.all(TaskComplete).then((resp) => {
      setOpenChangeMultiplePageTemplate(false);

      if (location && location.state && location.state.project_id) {
        TargetProject.pages.map((page) => {
          if (page.page_slug == SelectedPage) {
            const data = [];
            setSelectedSections([...data]);
            navigate(`/builder/${location.state.project_id}/${page._id}`, {
              state: null,
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    fetchAvailableTemplates();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openChangeTemplate}
        setOpen={setOpenChangeTemplate}
        handleAgree={handleChangeTemplateConfirmation}
        title="Are sure you want to choose another template? It will discard your current page changes."
      />

      {TargetTemplate && TargetProject && OpenChangeMultiplePageTemplate && (
        <SelectTemplatePages
          open={OpenChangeMultiplePageTemplate}
          setOpen={setOpenChangeMultiplePageTemplate}
          TargetTemplate={TargetTemplate}
          TargetProject={TargetProject}
          handleDone={handleUpdateExistingProject}
        />
      )}
      {CreatingProject && (
        <div style={{ zIndex: 99 }}>
          <Loader labe_value="Please wait..!" />
        </div>
      )}
      <div
        style={CreatingProject ? { opacity: 0.4, pointerEvents: "none" } : {}}
        className="container"
      >
        <div className="col-12 mt-3">
          <IconButton
            onClick={() => {
              let data = [];
              setSelectedSections([...data]);
              navigate(-1);
            }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="row ">
          <div className="col-12 text-center">
            <h5>Designer-Made Website Templates</h5>
          </div>

          <div className="col-12 mt-4 mb-0">
            <TemplateSearch
              templatesList={templates}
              setFilteredTemplatesList={setFilteredTemplatesList}
            />
            <div className="row">
              {filteredTemplatesList.map((template) => (
                <div
                  key={template._id}
                  className="col-12 col-sm-6 col-lg-4 mt-2"
                >
                  <div className="border position-relative">
                    <div>
                      <img
                        className="main-dashboard-website-placeholder"
                        src={
                          template.image
                            ? asserts_base_url + "/" + template.image
                            : website_placeholder_img
                        }
                        // src={website_placeholder_img}
                        width="100%"
                        height="140%"
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />

                      <div className="main-dashboard-website-action rounded-card">
                        <button
                          onClick={() => handleProjectClick(template)}
                          className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                        >
                          Choose Page
                        </button>
                        <a
                          href={`${asserts_base_url}/${template._id}/index.html`}
                          target="_blank"
                        >
                          <button className="main-dashboard-website-action-btn visible-parent-hover">
                            Preview Template
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="main-dashboard-website-name text-capitalize d-flex  justify-content-between align-items-center">
                        {template.name}
                        <span className="float-end custom-label ">
                          Pages({template.Pages ? template.Pages.length : 1})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChooseTemplate;
