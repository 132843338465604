import React, { useState, useEffect } from "react";

const InputRequireList = ({
  Form,
  setFormRequire,
  GetInputList,
  handleChangeInputRequireAttribute,
  Change,
  setChange,
}) => {
  const handleChangeInputRequireStatus = (value, input) => {
    if (input.tagName == "INPUT" || input.tagName == "TEXTAREA") {
      let label = input.parentNode.getElementsByTagName("label")[0];
      // if label exist
      if (label) {
        if (value) {
          if (label[length - 1] != "*") {
            label.innerHTML = label.innerHTML + "*";
          }
        } else {
          let label_text = label.innerHTML;
          if (label_text[label_text.length - 1] == "*") {
            label.innerHTML = label_text.replace("*", "");
          }
        }
      } else {
        // if label not exist

        if (value) {
          if (input[input.length - 1] != "*") {
            input.placeholder = input.placeholder + "*";
          }
        } else {
          let placeholder = input.placeholder;
          if (placeholder[placeholder.length - 1] == "*") {
            input.placeholder = placeholder.replace("*", "");
          }
        }
      }
    } else if (input.tagName == "SELECT") {
      let _option = input.getElementsByTagName("option")[0];
      let label = input.parentNode.getElementsByTagName("label")[0];
      if (label) {
        if (value) {
          if (label[length - 1] != "*") {
            label.innerHTML = label.innerHTML + "*";
          }
        } else {
          let label_text = label.innerHTML;
          if (label_text[label_text.length - 1] == "*") {
            label.innerHTML = label_text.replace("*", "");
          }
        }
      } else {
        if (value) {
          _option.innerText = _option.innerText + "*";
        } else {
          let placeholder = input.getElementsByTagName("option")[0].innerText;
          if (placeholder[placeholder.length - 1] == "*") {
            _option.innerText = placeholder.replace("*", "");
          }
        }
      }
    }

    input.required = value;

    setChange(!Change);
  };
  const renderRequireInputs = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      if (input_list[index].type == "radio") {
        continue;
      }
      list.push(
        <div
          style={{
            userSelect: "none",
          }}
          key={index}
          className="mb-2 border p-2 mx-1 rounded form-checkbox-color d-flex align-items-center pointer"
          onClick={() => {
            handleChangeInputRequireStatus(
              !input_list[index].required,
              input_list[index]
            );
          }}
        >
          <input
            // onChange={(e) =>
            //   handleChangeInputRequireStatus(
            //     e.target.checked,
            //     input_list[index]
            //   )
            // }
            value={input_list[index].name}
            checked={input_list[index].required}
            type="checkbox"
            className="pointer"
          />
          <label
            // onClick={() => {
            //   handleChangeInputRequireStatus(
            //     !input_list[index].required,
            //     input_list[index]
            //   );
            // }}
            className="custom-label d-flex align-items-center pointer"
          >
            &nbsp;&nbsp;
            {input_list[index].dataset
              ? input_list[index].dataset.name
                ? input_list[index].dataset.name
                : input_list[index].name
                ? input_list[index].name
                : "N/A"
              : input_list[index].name
              ? input_list[index].name
              : "N/A"}
          </label>
        </div>
      );
    }
    return list;
  };

  const handleChangeFormInputAttributes = (attribute, value) => {
    let input_list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("textarea"),
      ...Form.getElementsByTagName("select"),
    ];
    for (let index = 0; index < input_list.length; index++) {
      input_list[index][attribute] = value;
      handleChangeInputRequireAttribute(input_list[index], value);
    }

    setChange(!Change);
  };

  const handleChangeRequireField = (value) => {
    handleChangeFormInputAttributes("required", value);
    //
    setFormRequire(value);
    Form.setAttribute("form_require", value);
  };

  useEffect(() => {}, [Change]);

  return (
    <div className=" px-2">
      <div className="col-12 p-2">
        <span className="custom-label mb-2">Select Required Fields</span>
      </div>

      <div className="row px-3">{renderRequireInputs()}</div>

      <div className="col-12 px-1">
        <div className="text-end mx-2">
          <span
            className="custom-link"
            onClick={() => handleChangeRequireField(true)}
          >
            Make all require
          </span>
        </div>
        <div className="text-end mx-2">
          <span
            className="custom-link"
            onClick={() => handleChangeRequireField(false)}
          >
            Make all optional
          </span>
        </div>
      </div>
      <div className="mx-2 mb-2 ">
        <span className=" custom-label">
          Prevent submission if selected fields are empty
        </span>
        {/*  */}
      </div>
    </div>
  );
};

export default InputRequireList;
