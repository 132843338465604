import tinymce from "tinymce";
import { get_formatted_font_size } from "../components/Custom-Editor/CustomEditor";
let editorsList = [];

export const InitializeTinyEditor = (setEditorMenuOpen, editorMenuOpen) => {
  try {
    RemoveContentEditable();
    tinymce.init({
      selector: ".wb-editor-block", // change this value according to the HTML
      inline: true,
      menubar: false,
      branding: false,
      max_width: 400,
      toolbar:
        "closeButton undo redo bold link italic fontsize | fontfamily forecolor alignleft aligncenter alignright alignjustify outdent indent  underline strikethrough subscript | superscript lineheight settingButton",
      // toolbar_mode: "wrap",

      setup: function (editor) {
        editor.ui.registry.addButton("closeButton", {
          icon: "close",
          tooltip: "Close",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
          },
        });
        editor.ui.registry.addButton("settingButton", {
          icon: "settings",
          tooltip: "Advance setting",
          disabled: true,
          onAction: function () {
            let targetEditor = CheckEditorAlreadyExist(editor.id);
            if (targetEditor) {
              targetEditor.hide();
            } else {
              editorsList.push(editor);
              editor.hide();
            }
            setTimeout(() => {
              if (!editorMenuOpen) {
                setEditorMenuOpen(true);
              }
            }, 100);
          },
        });
        // initialize function
        editor.on("init", function (e) {
          editorsList.push(editor);
        });
      },
      font_size_formats: get_formatted_font_size(),
    });
  } catch (error) {
    console.error(error, "Error in tiny editor");
  }
};
export const RemoveContentEditable = () => {
  editorsList = [];
  // tinymce.remove();
  [...document.getElementsByClassName("wb-editor-block")].map((block) => {
    block.classList.remove("mce-content-body");
    block.classList.remove("mce-edit-focus");
    block.style.removeProperty("position");
    block.removeAttribute("id");
    block.removeAttribute("contenteditable");
    block.removeAttribute("spellcheck");
  });
};

export const ShowEditor = (element) => {
  CloseAllEditor(element);
  let targetEditor = CheckEditorAlreadyExist(element.getAttribute("id"));
  if (targetEditor.hidden) {
    targetEditor.show();
    setTimeout(() => {
      targetEditor.focus();
    }, 1);
  }
};

export const CloseAllEditor = (element) => {
  if (editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (element && element.getAttribute("id") === editorsList[i].id) {
        continue;
      }
      editorsList[i].hide();
    }
  }
};
const CheckEditorAlreadyExist = (targetID) => {
  if (targetID && editorsList && editorsList.length > 0) {
    for (let i = 0; i < editorsList.length; i++) {
      if (editorsList[i].id === targetID) {
        return editorsList[i];
      }
    }
    return false;
  }
  return false;
};
