import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { s3baseUrl } from "../../config/config";
import { _get_active_helping_categories } from "../../DAL/helping-articles/helping_articles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

function HelpingCategories() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClickCategory = (category) => {
    navigate(`/helping-articles/${category._id}`);
  };

  const fetchAvailableTemplates = async () => {
    const resp = await _get_active_helping_categories();
    if (resp.code === 200) {
      setCategories(resp.helping_article_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchAvailableTemplates();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row pt-3">
          <div className="col-12 ">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 mb-5 text-center">
            <h5>Welcome To Wezily Builder Help Center</h5>
          </div>

          <div className="col-12 mt-2 mb-5">
            {categories.length === 0 && (
              <div className="text-center">
                <span>No Data Exist</span>
              </div>
            )}
            <div className="row">
              {categories.map((category) => (
                <div className="col-12 col-md-3 mb-3">
                  <div
                    onClick={() => handleClickCategory(category)}
                    className="card helping-category-card"
                  >
                    <img src={s3baseUrl + "/" + category.image} width="30%" />
                    <div className="mt-3 main-dashboard-website-name text-capitalize">
                      {category.name}
                    </div>

                    <div className="mt-2 w-100">
                      <p>{category.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpingCategories;
