import { base_uri } from "../../config/config";
import { invokeApi } from "../../bl_libs/invokeApi";

export const _login = (data) => {
  let requestObj = {
    path: "/api/app_api/login",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _register = (data) => {
  let requestObj = {
    path: "/api/customer/signup_customer",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _send_email_confirmation_code = (data) => {
  let requestObj = {
    path: "/api/app_api/email_verificatin",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _verify_confirmation_code = (data) => {
  let requestObj = {
    path: "/api/app_api/code_verificatin",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _update_forget_password = (data) => {
  let requestObj = {
    path: "/api/app_api/reset_password",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _change_password = (data) => {
  let requestObj = {
    path: "/api/app_api/change_password",
    method: "PUT",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };
  requestObj["postData"] = data;
  return invokeApi(requestObj);
};

export const _get_user_profile = () => {
  let requestObj = {
    path: "/api/customer/customer_dashobard",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    base_url: base_uri,
  };

  return invokeApi(requestObj);
};

export const _get_user_profile_by_custom_token = (token) => {
  let requestObj = {
    path: "/api/customer/customer_dashobard",
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
    base_url: base_uri,
  };

  return invokeApi(requestObj);
};
