import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import {
  _get_user_design_details,
  _save_user_active_campaign,
} from "../../../../DAL/projects/projects";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Loader from "../../../../components/Loader/Loader";
import {
  _get_user,
  _set_user,
} from "../../../../DAL/localStorage/localStorage";
function ActiveCampaign(props) {
  const { project } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [activeCampaign, setActiveCampaign] = useState({
    activeCampaignURL: "",
    activeCampaignKey: "",
    activeCampaignList: "",
    activeCampaignTag: "",
    success_message: "",
    failure_message: "",
    expanded: false,
    isLoading: false,
  });
  //

  const handleChangeActiveExpand = (value) => {
    setActiveCampaign({ ...activeCampaign, expanded: value });
  };

  const handleChangeCompanionLoader = (value) => {
    setActiveCampaign({ ...activeCampaign, isLoading: value });
  };

  const handleChangeActiveCampaign = (e) => {
    setActiveCampaign({ ...activeCampaign, [e.target.name]: e.target.value });
  };

  const handleSubmitActiveCompanion = async (e) => {
    e.preventDefault();
    // const project_id = params?.project_id;
    const postData = {
      // project_id: project_id,
      active_compaign_url: activeCampaign.activeCampaignURL,
      key: activeCampaign.activeCampaignKey,
      // list_id: activeCampaign.activeCampaignList,
      // tag_id: activeCampaign.activeCampaignTag,
      // success_message: activeCampaign.success_message,
      // failure_message: activeCampaign.failure_message,
    };

    handleChangeCompanionLoader(true);
    const resp = await _save_user_active_campaign(postData);

    if (resp.code === 200) {
      // handleChangeActiveExpand(false);
      const user = _get_user();

      _set_user({
        ...user,
        active_compaign_url: activeCampaign.activeCampaignURL,
        active_campaign_key: activeCampaign.activeCampaignKey,
      });
      enqueueSnackbar("Active campaign updated successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    handleChangeCompanionLoader(false);
  };

  const setProjectData = async () => {
    const user = _get_user();
    setActiveCampaign({
      ...activeCampaign,
      activeCampaignURL: user.active_compaign_url,
      activeCampaignKey: user.active_campaign_key,
      // activeCampaignList: project.active_campaign_list_id,
      // activeCampaignTag: project.active_campaign_tag_id,
      // success_message: project.success_message,
      // failure_message: project.failure_message,
    });
  };

  useEffect(() => {
    setProjectData();
  }, []);

  return (
    <>
      <>
        <div className="row mt-2">
          <div className="col-12">
            <Accordion expanded={activeCampaign.expanded} className="card">
              <AccordionSummary
                onClick={() =>
                  handleChangeActiveExpand(!activeCampaign.expanded)
                }
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Active Campaign Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmitActiveCompanion}>
                  <div className="row  justify-content-end">
                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign URL"
                        required
                        name="activeCampaignURL"
                        value={activeCampaign.activeCampaignURL}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign Key"
                        required
                        name="activeCampaignKey"
                        value={activeCampaign.activeCampaignKey}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div>

                    {/* <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign List ID"
                        name="activeCampaignList"
                        value={activeCampaign.activeCampaignList}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign Tag ID"
                        name="activeCampaignTag"
                        value={activeCampaign.activeCampaignTag}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign Success Message"
                        name="success_message"
                        value={activeCampaign.success_message}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div>

                    <div className="col-12 col-lg-6 mt-3">
                      <TextField
                        fullWidth
                        label="Active Campaign Failure Message"
                        name="failure_message"
                        value={activeCampaign.failure_message}
                        onChange={handleChangeActiveCampaign}
                      />
                    </div> */}

                    <div className="col-12 col-lg-3 text-end mt-3">
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={activeCampaign.isLoading}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    </>
  );
}

export default ActiveCampaign;
