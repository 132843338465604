import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { TwitterPicker } from "react-color";
import Select from "react-select";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputProperties } from "../../../Right-Menu/components";
import LabelAlignBar from "../LabelAlignBar";
import InlineInputField from "../../../Right-Menu/components/Inline-Input-Field/InlineInputField";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox,
  Divider,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import {
  justify_content_classes,
  label_alignment_classes,
} from "../../../../../../ui_libs/Constants/constant";
import InputRequireList from "./components/InputRequireList";
import InputPlaceholderValue from "./components/InputPlaceholderValue";
import SelectOptionList from "./components/SelectOptionList";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import ActiveCampaign from "../../../../../Website-Settings/components/Active-Campaign/Active_Compaign_Page";
import ActiveCampaignURLandKeyModal from "../../../../../Website-Settings/components/Active-Campaign/components/ActiveCampaignURLandKeyModal";
import { _get_user } from "../../../../../../DAL/localStorage/localStorage";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

function BasicFormSetting({
  Form,
  setForm,
  getForm,
  GetInputList,
  LabelRequire,
  setLabelRequire,
  HelperTextRequire,
  setHelperTextRequire,
  FormRequire,
  setFormRequire,
  CheckInputAvailable,

  // active compain
  Page,
  setPage,
  activeCompaignData,
  handleUpdateForm,
  GetActiveCompaignValues,
  cardToOpen,
}) {
  const { ReRenderHtml } = useBuilder();
  const [LabelAlignment, setLabelAlignment] = useState("top");
  const [activeCampaignModal, setActiveCampaignModal] = useState(false);
  const [activeCampaignURLandKey, setActiveCampaignURLandKey] = useState(true);
  const [LabelAlignValue, setLabelAlignValue] = useState("");

  const [Change, setChange] = useState(false);

  const handleChangeInClass = (property, val, class_list) => {
    let target = getForm();
    while (!target.parentNode.classList.contains("row")) {
      target = target.parentNode;
    }
    target = target.parentNode;

    class_list.map((cl) => {
      target.classList.remove(cl.value);
    });
    if (val) {
      target.classList.add(val);
    }
    setLabelAlignValue(val);
  };

  const renderLabelInputs = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      let label = input_list[index].parentNode.getElementsByTagName("label")[0];

      if (input_list[index].type != "radio" && label) {
        list.push(
          <div
            key={index}
            className="col-12 border mb-2 p-2 mx-1 rounded form-label-checkbox-color formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-4 d-flex align-items-center">
                <input
                  onChange={(e) => handleToggleInputLabel(e.target.value)}
                  value={index}
                  checked={getLabelVisibility(index)}
                  type="checkbox"
                />
                <div>
                  <label
                    onClick={() => {
                      handleToggleInputLabel(index);
                    }}
                    className="custom-label d-flex align-items-center"
                  >
                    &nbsp;&nbsp;
                    {input_list[index].dataset.name
                      ? input_list[index].dataset.name
                      : "N/A"}
                  </label>
                </div>
              </div>
              <div
                style={{
                  pointerEvents: getLabelVisibility(index) ? "auto" : "none",
                  opacity: getLabelVisibility(index) ? 1 : 0.5,
                }}
                className="col-8"
              >
                {/* <InlineInputField
                placeholder={label.innerHTML}
                value={label.innerHTML}
                handleUpdate={(val) => {
                  label.innerHTML = val;
                  setChange(!Change);
                }}
              /> */}
                <input
                  className="form-control form-control-sm"
                  value={label.innerHTML}
                  onChange={(e) => {
                    label.innerHTML = e.target.value;
                    setChange(!Change);
                  }}
                />
              </div>
            </div>
            {/* <div
             
            >
              <InlineInputField
                placeholder={label.innerHTML}
                value={label.innerHTML}
                handleUpdate={(val) => {
                  label.innerHTML = val;
                  setChange(!Change);
                }}
              />
            </div> */}
          </div>
        );
      }
    }
    return list;
  };
  const renderHelperTextInputs = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      if (input_list[index].type == "checkbox") {
        continue;
      }
      if (input_list[index].type == "radio") {
        continue;
      }
      let helper_text =
        input_list[index].parentNode.getElementsByTagName("p")[0];
      if (helper_text) {
        list.push(
          <div
            key={index}
            className="col-12 border mb-2 p-2 mx-1 rounded form-label-checkbox-color formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-4 d-flex align-items-center">
                <input
                  onChange={(e) => handleToggleInputHelperText(e.target.value)}
                  value={index}
                  checked={getHelperTextVisibility(index)}
                  type="checkbox"
                />
                <div>
                  <label
                    // onClick={() => {
                    //   handleToggleInputLabel(index);
                    // }}
                    className="custom-label d-flex align-items-center"
                  >
                    &nbsp;&nbsp;
                    {input_list[index].dataset.name
                      ? input_list[index].dataset.name
                      : "N/A"}
                  </label>
                </div>
              </div>
              <div
                style={{
                  pointerEvents: getLabelVisibility(index) ? "auto" : "none",
                  opacity: getLabelVisibility(index) ? 1 : 0.5,
                }}
                className="col-8"
              >
                <input
                  className="form-control form-control-sm"
                  value={helper_text.innerHTML}
                  onChange={(e) => {
                    helper_text.innerHTML = e.target.value;
                    setChange(!Change);
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    }
    return list;
    console.log(
      HelperTextList,
      "HelperTextListHelperTextListHelperTextListHelperTextListHelperTextList"
    );
  };
  const GetInputAttribute = (target, attribute) => {
    return target.getAttribute(attribute) == "true" ? true : false;
  };
  const getLabelVisibility = (index) => {
    let input_list = GetInputList();

    return input_list[index]?.parentNode?.getElementsByTagName("label")[0]
      ?.style?.visibility == "hidden"
      ? false
      : true;
  };
  const setLabelVisibility = (index, value) => {
    let input_list = GetInputList();

    if (input_list[index].parentNode.getElementsByTagName("label").length > 0) {
      return (input_list[index].parentNode.getElementsByTagName(
        "label"
      )[0].style.visibility = value);
    }

    if (input_list[index].type == "checkbox") {
      return;
    }
    let parent = input_list[index].parentNode;
    // crete label
    const input_label = document.createElement("label");
    input_label.classList.add("d-flex");
    input_label.classList.add("flex-start");
    input_label.innerHTML = input_list[index].dataset.name
      ? input_list[index].dataset.name
      : input_list[index].placeholder
      ? input_list[index].placeholder
      : input_list[index].name
      ? input_list[index].name
      : input_list[index].tagName;

    let labels = [...Form.getElementsByTagName("label")];
    if (labels.length > 0) {
      if (labels[0].style) {
        input_label.style.cssText = labels[0].style.cssText;
      }
      if (labels[0].classList) {
        [...labels[0].classList].map((cls) => {
          if (!input_label.classList.contains(cls)) {
            input_label.classList.add(cls);
          }
        });
      }
    }
    input_label.style.visibility = value ? "visible" : "hidden";

    // append
    parent.prepend(input_label);
  };
  const handleToggleInputLabel = (e) => {
    let result = getLabelVisibility(e);
    let value = result ? "hidden" : "visible";
    setLabelVisibility(e, value);
    setChange(!Change);
  };
  const getHelperTextVisibility = (index) => {
    let input_list = GetInputList();

    return input_list[index]?.parentNode?.getElementsByTagName("p")[0]?.style
      ?.visibility == "hidden"
      ? false
      : true;
  };
  const setHelperTextVisibility = (index, value) => {
    let input_list = GetInputList();

    if (input_list[index].parentNode.getElementsByTagName("p").length > 0) {
      return (input_list[index].parentNode.getElementsByTagName(
        "p"
      )[0].style.visibility = value);
    }

    if (input_list[index].type == "checkbox") {
      return;
    }
    let parent = input_list[index].parentNode;
    // crete label
    const input_label = document.createElement("label");
    input_label.classList.add("d-flex");
    input_label.classList.add("flex-start");
    input_label.innerHTML = input_list[index].dataset.name;

    let labels = [...Form.getElementsByTagName("p")];
    if (labels.length > 0) {
      if (labels[0].style) {
        input_label.style.cssText = labels[0].style.cssText;
      }
      if (labels[0].classList) {
        [...labels[0].classList].map((cls) => {
          if (!input_label.classList.contains(cls)) {
            input_label.classList.add(cls);
          }
        });
      }
    }
    input_label.style.visibility = value ? "visible" : "hidden";

    // append
    parent.prepend(input_label);
  };
  const handleToggleInputHelperText = (e) => {
    let result = getHelperTextVisibility(e);
    let value = result ? "hidden" : "visible";
    setHelperTextVisibility(e, value);
    setChange(!Change);
  };

  const handleLabelVisibility = (value) => {
    let input_list = GetInputList();

    for (let index = 0; index < GetInputList().length; index++) {
      if (input_list[index].type != "checkbox") {
        let val = value ? "visible" : "hidden";
        setLabelVisibility(index, val);
      }
    }
  };
  const removeLabel = (input) => {
    if (input.type == "radio") {
      return;
    }
    let _label = [...input.parentNode.getElementsByTagName("label")];
    if (_label.length > 0) {
      _label[0].remove();
    }
  };
  const handleRemoveLabel = () => {
    let input_list = GetInputList();

    for (let index = 0; index < GetInputList().length; index++) {
      if (input_list[index].type != "checkbox") {
        removeLabel(input_list[index]);
      }
    }
  };
  const handleChangeLabelOption = () => {
    Form.setAttribute("form_label", !LabelRequire);
    if (LabelRequire == false) {
      handleLabelVisibility(!LabelRequire);
    } else {
      handleRemoveLabel();
    }

    setLabelRequire(!LabelRequire);
    setChange(!Change);
  };
  const AddOrRemoveasteric = (text, value) => {
    if (value) {
      if (text[text.length - 1] != "*") {
        {
          return text + "*";
        }
      }
    } else {
      if (text[text.length - 1] == "*") {
        {
          let t = text.slice(0, -1);
          return t;
        }
      }
    }

    return text;
  };
  const handleChangeInputRequireAttribute = (input, value) => {
    if (input.tagName == "SELECT") {
      let _label = input.getElementsByTagName("option")[0];
      _label.innerText = AddOrRemoveasteric(_label.innerText, value);
    } else if (input.tagName == "INPUT" || input.tagName == "TEXTAREA") {
      input.placeholder = AddOrRemoveasteric(input.placeholder, value);
    }
    setChange(!Change);
  };

  const renderInputLabelText = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      let label = input_list[index].parentNode.getElementsByTagName("label")[0];
      if (label) {
        if (label.style.visibility == "visible") {
          list.push(
            <div className="mt-2">
              <InlineInputField
                placeholder={label.innerHTML}
                value={label.innerHTML}
                handleUpdate={(val) => {
                  label.innerHTML = val;
                  setChange(!Change);
                }}
              />
            </div>
          );
        }
      }
    }
    return list;
  };
  const renderSelectOptionList = () => {
    let _select_list = [...Form.getElementsByTagName("select")];
    let List = [];
    if (_select_list.length > 0) {
      _select_list.map((_select, _select_index) => {
        let _option_list = [..._select.getElementsByTagName("option")];
        _option_list.splice(0, 1);
        List.push(
          <div
            key={_select_index}
            className="rounded border mb-2 p-2 mx-1 formsetting-label-parent-div"
          >
            <div className=" col-4 d-flex align-items-center">
              <div>
                <label className="custom-label d-flex align-items-center fw-bold">
                  {_select.dataset.name}
                </label>
              </div>
            </div>
            {_option_list.length > 0 ? (
              _option_list.map((option, option_index) => (
                <div
                  key={option_index}
                  className="col-12  form-label-checkbox-color "
                >
                  {/* drag and drop */}
                  <div className=" col-12 d-flex align-items-center">
                    <div className=" col-3 d-flex align-items-center">
                      <div>
                        <label className="custom-label d-flex align-items-center">
                          Option {option_index + 1}
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        className="form-control form-control-sm"
                        value={option.innerText}
                        onChange={(e) => {
                          option.innerHTML = e.target.value;
                          option.setAttribute("value", e.target.value);
                          setChange(!Change);
                        }}
                      />
                    </div>
                    <div className="col-1">
                      <Tooltip arrow placement="top" title="Remove Option">
                        <IconButton component="label">
                          <Close
                            className="pointer"
                            htmlColor="red"
                            onClick={() => {
                              option.remove();
                              setChange(!Change);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <label className="custom-label">No Options Available</label>
                </div>
              </div>
            )}
            <div
              // style={
              //   _option_list.length > 0 &&
              //   _option_list[_option_list.length - 1].innerText.trim() == ""
              //     ? {
              //         pointerEvents: "none",
              //         opacity: 0.3,
              //       }
              //     : {
              //         pointerEvents: "auto",
              //       }
              // }
              className="d-flex justify-content-end"
            >
              {/* <Tooltip arrow placement="top" title="Add Option">
                <IconButton component="label">
                  <AddCircleOutlineIcon
                    className="pointer"
                    htmlColor="black"
                    onClick={() => {
                      let new_option = document.createElement("option");
                      _select.append(new_option);
                      setChange(!Change);
                    }}
                  />
                </IconButton>
              </Tooltip> */}
              <button
                style={
                  _option_list.length > 0 &&
                  _option_list[_option_list.length - 1].innerText.trim() == ""
                    ? {
                        pointerEvents: "none",
                        opacity: 0.3,
                      }
                    : {
                        pointerEvents: "auto",
                      }
                }
                type="button"
                className="btn btn-sm btn-secondary me-1"
                onClick={() => {
                  let new_option = document.createElement("option");
                  _select.append(new_option);
                  setChange(!Change);
                }}
              >
                Add New Option
              </button>
            </div>
          </div>
        );
      });
    }

    return List;
  };
  const handleDragEndOptionList = (
    { source, destination, ...rest },
    { list }
  ) => {
    if (source && destination && source.index != destination.index) {
      const optionList = list;
      // change input value
      let temp = optionList[destination.index].parentNode.outerHTML;
      optionList[destination.index].parentNode.outerHTML =
        optionList[source.index].parentNode.outerHTML;
      optionList[source.index].parentNode.outerHTML = temp;

      // change label value

      setChange(!Change);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  // const CheckInputAvailable = (tag) => {
  //   let _input_list = [...Form.getElementsByTagName(tag)];
  //   console.log(
  //     _input_list,
  //     "_input_list_input_list_input_list_input_list_input_list_input_list"
  //   );
  //   let result = false;
  //   if (_input_list.length > 0) {
  //     result = true;
  //   }
  //   return result;
  // };

  const renderRadioOptionList = () => {
    let Radio_Input_List = [...Form.querySelectorAll('input[type="radio"]')];
    let Radio_List_By_Name = [];
    Radio_Input_List.map((input) => {
      if (Radio_List_By_Name.length == 0) {
        Radio_List_By_Name.push({
          name: input.name,
          list: [input],
        });
      } else {
        let Input_Added = false;
        for (let index = 0; index < Radio_List_By_Name.length; index++) {
          if (input.name == Radio_List_By_Name[index].name) {
            Input_Added = true;
            Radio_List_By_Name[index].list.push(input);
            break;
          }
        }

        if (!Input_Added) {
          Radio_List_By_Name.push({
            name: input.name,
            list: [input],
          });
        }
      }
    });
    let List = [];
    if (Radio_List_By_Name.length > 0) {
      Radio_List_By_Name.map((Radio_Input_List, Radio_Input_List_Index) => {
        List.push(
          <div
            key={Radio_Input_List_Index}
            className=" row rounded border mb-2 p-2 mx-1 formsetting-label-parent-div"
          >
            <div className=" col-4 d-flex align-items-center">
              <div>
                <label className="custom-label d-flex align-items-center fw-bold">
                  {Radio_Input_List.name}
                </label>
              </div>
            </div>
            <div className="col-12">
              <DragDropContext
                onDragEnd={(props) =>
                  handleDragEndOptionList(props, Radio_Input_List)
                }
              >
                <Droppable droppableId="doprable1">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {Radio_Input_List.list.length > 0 ? (
                        Radio_Input_List.list.map(
                          (Radio_Input, Radio_Input_Index) => (
                            <Draggable
                              draggable={false}
                              key={Radio_Input_Index}
                              draggableId={`dragable-${Radio_Input_Index}`}
                              index={Radio_Input_Index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={`${
                                    snapshot.isDragging ? "shadow" : null
                                  } row border rounded formsetting-div-color-silver mb-1 align-items-center column-block-list`}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    background: snapshot.isDragging
                                      ? "white"
                                      : null,
                                  }}
                                >
                                  <div
                                    key={Radio_Input_Index}
                                    className="col-12  form-label-checkbox-color "
                                  >
                                    <div className=" col-12 d-flex align-items-center">
                                      <div className=" col-1 d-flex align-items-center">
                                        <div>
                                          {/* <label className="custom-label d-flex align-items-center">
                          Option {Radio_Input_Index + 1}
                        </label> */}
                                          <RadioButtonCheckedIcon fontSize="small" />
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <input
                                          className="form-control form-control-sm"
                                          value={
                                            Radio_Input.parentNode.getElementsByTagName(
                                              "label"
                                            )[0].innerText
                                          }
                                          onChange={(e) => {
                                            Radio_Input.parentNode.getElementsByTagName(
                                              "label"
                                            )[0].innerText = e.target.value;
                                            Radio_Input.parentNode
                                              .getElementsByTagName("label")[0]
                                              .setAttribute(
                                                "for",
                                                e.target.value
                                              );
                                            Radio_Input.setAttribute(
                                              "value",
                                              e.target.value
                                            );
                                            Radio_Input.setAttribute(
                                              "id",
                                              e.target.value
                                            );
                                            setChange(!Change);
                                          }}
                                        />
                                      </div>
                                      <div className="col-1">
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title="Remove Option"
                                        >
                                          <IconButton component="label">
                                            <Close
                                              className="pointer"
                                              htmlColor="red"
                                              onClick={() => {
                                                Radio_Input.parentNode.remove();
                                                setChange(!Change);
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <label className="custom-label">
                              No Options Available
                            </label>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="d-flex justify-content-end">
              <button
                // style={
                //   Radio_Input_List[
                //     Radio_Input_List_Index
                //   ].parentNode.getElementsByTagName("label").length > 0 &&
                //   Radio_Input_List[Radio_Input_List_Index].parentNode
                //     .getElementsByTagName("label")[0]
                //     .innerText.trim() == ""
                //     ? {
                //         pointerEvents: "none",
                //         opacity: 0.3,
                //       }
                //     : {
                //         pointerEvents: "auto",
                //       }
                // }
                type="button"
                className="btn btn-sm btn-secondary me-1"
                onClick={() => {
                  let parent = document.getElementsByName(
                    Radio_Input_List.name
                  )[0].parentNode.parentNode;

                  let _div = document.createElement("div");
                  _div.innerHTML = `<input type="radio" id="" data-name=" " name="${Radio_Input_List.name}" value="" placeholder="">&nbsp;
<label class="" for=""></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
                  _div.classList.add("d-flex");
                  parent.append(_div);
                  console.log(
                    parent,
                    "inputsinputsinputsinputsinputsinputsinputsinputs"
                  );
                  setChange(!Change);
                }}
              >
                Add New Option
              </button>
            </div>
          </div>
        );
      });
    }

    return List;
  };
  const CheckInputTypeAvailable = (type) => {
    let _input_list = [...Form.getElementsByTagName("input")];

    let result = false;
    if (_input_list.length > 0) {
      _input_list.map((input) => {
        if (input.type == type) {
          result = true;
        }
      });
    }
    return result;
  };

  const handleAddHelperText = (value) => {
    let List = GetInputList();
    List.map((item) => {
      if (item.type == "checkbox") {
        return;
      }
      if (item.type == "radio") {
        return;
      }
      let parent = item.parentNode;
      let _label = parent.getElementsByTagName("label")[0];
      let _input = parent.getElementsByTagName(item.tagName)[0];

      let _p = document.createElement("p");
      _p.classList.add("pb-0");
      _p.classList.add("mb-0");
      _p.classList.add("d-flex");
      _p.classList.add("justify-content-start");
      if (_label) {
        if (_label.style) {
          _p.style.cssText = _label.style.cssText;
        }
        let classes = _label.classList;
        if (classes) {
          classes.forEach((cl) => {
            let result = cl.indexOf("font-size-");
            console.log(result, "resultresultresultresultresultresultresult");
            if (result == -1) {
              _p.classList.add(cl);
            }
          });
        }
      }
      _p.style.fontSize = "9px";
      _p.innerText = `${
        item.dataset.name ? item.dataset.name : item.placeholder
      }`;
      parent.innerHTML = "";
      parent.prepend(_p);

      parent.prepend(_input);
      if (_label) {
        parent.prepend(_label);
      }
    });
  };
  const handleRemoveHelperText = () => {
    let List = GetInputList();
    List.map((item) => {
      let p = item.parentNode.getElementsByTagName("p")[0];
      if (p) {
        p.remove();
      }
    });
  };
  const handleChangeHelperTextOption = () => {
    if (HelperTextRequire == false) {
      handleAddHelperText(!HelperTextRequire);
    } else {
      handleRemoveHelperText();
    }
    Form.setAttribute("helper_text_require", !HelperTextRequire);
    setHelperTextRequire(!HelperTextRequire);
    setChange(!Change);
  };

  const setpageData = async () => {
    const user = _get_user();
    user.active_compaign_url && setActiveCampaignURLandKey(false);
  };
  useEffect(() => {
    setpageData();
  }, [activeCampaignModal]);

  // add or remove * on require feiled
  useEffect(() => {
    let list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("textarea"),
    ];
    list.map((input) => {
      handleChangeInputRequireAttribute(input, input.required);
    });
  }, []);

  useEffect(() => {
    ReRenderHtml();
    let target = getForm();
    setLabelRequire(GetInputAttribute(target, "form_label"));
  }, [Change]);

  return (
    <div className="col-12 pb-3">
      {/* <div className="col-12  p-3">
        <div className="col-12 custom-label">Form Alignment</div>
        <LabelAlignBar
          textAlign={LabelAlignValue}
          handleChangeInClass={handleChangeInClass}
          classesList={justify_content_classes}
        />
      </div> */}

      {/* required button */}

      <InputRequireList
        Form={Form}
        setFormRequire={setFormRequire}
        GetInputList={GetInputList}
        handleChangeInputRequireAttribute={handleChangeInputRequireAttribute}
        Change={Change}
        setChange={setChange}
      />

      <Divider />

      <InputPlaceholderValue
        Form={Form}
        GetInputList={GetInputList}
        Change={Change}
        setChange={setChange}
      />
      <Divider />
      {CheckInputTypeAvailable("radio") && (
        <>
          <div className="col-12 px-3 py-3">
            <div className="custom-label">Radio Options</div>
            {renderRadioOptionList()}
          </div>

          <Divider />
        </>
      )}
      {CheckInputAvailable("select") && (
        <>
          <div className="col-12 px-3 py-3">
            <div className="custom-label">Dropdown Options</div>
            {renderSelectOptionList()}
          </div>

          <Divider />
        </>
      )}

      <div className="col-12  pt-2 px-3">
        <span className="custom-label ">Show Label </span>
        <Switch
          size="small"
          style={{
            color: "",
          }}
          checked={LabelRequire}
          onClick={handleChangeLabelOption}
        />
      </div>
      {LabelRequire && (
        <div className=" px-2">
          <div className="col-12 p-2">
            <span className="custom-label mb-2">Select Field Labels</span>
          </div>

          <div className="row px-3">{renderLabelInputs()}</div>
        </div>
      )}

      {/* helper text */}

      <div className="col-12  py-2 px-3">
        <span className="custom-label ">Show Helper Text </span>
        <Switch
          size="small"
          style={{
            color: "",
          }}
          checked={HelperTextRequire}
          onClick={handleChangeHelperTextOption}
        />
      </div>
      {HelperTextRequire && (
        <div className=" px-2 mb-2">
          <div className="col-12 p-2">
            <span className="custom-label mb-2">Select Helper Text</span>
          </div>

          <div className="row px-3">{renderHelperTextInputs()}</div>
        </div>
      )}
      {/* <Divider />

      {activeCampaignURLandKey && (
        <div className="col-12  py-2 px-3 d-flex justify-content-between align-items-center">
          <span className="custom-label ">Active Campaign:</span>
          <Button onClick={() => setActiveCampaignModal(true)} size="small">
            Add Campaign
          </Button>
        </div>
      )}
      <ActiveCampaignURLandKeyModal
        open={activeCampaignModal}
        setOpen={setActiveCampaignModal}
      />
      {!activeCampaignURLandKey && (
        <div className="mx-3 my-2">
          <Accordion className="card mb-2 ">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Form Submittion
            </AccordionSummary>
            <AccordionDetails>
              <ActiveCampaign
                page={Page}
                setPage={setPage}
                activeCompaignData={activeCompaignData}
                handleUpdateForm={handleUpdateForm}
                GetActiveCompaignValues={GetActiveCompaignValues}
                cardToOpen={cardToOpen}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )} */}
    </div>
  );
}

export default BasicFormSetting;
