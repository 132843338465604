import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//
import account from "../../_mocks_/account";
import { _get_user } from "../../DAL/localStorage/localStorage";
import CustomConfirmation from "../../components/CustomConfirmation";
import ChangePassword from "./ChangePassword";
import ExitBuilderConfirmation from "../../components/ExitBuilderConfirmation";
import { s3baseUrl } from "../../config/config";
import ChooseTemplateModal from "../../pages/Choose-Template/ChooseTemplateModal";
export default function BuilderAccountPopover({
  handleLogout,
  project_id,
  handleSaveVersion,
}) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [chooseTemplateModal, setChooseTemplateModal] = useState(false);

  const [openChangeTemplate, setOpenChangeTemplate] = useState(false);
  const handleOpenChangeTemplateConfirmation = () => {
    setOpenChangeTemplate(true);
  };

  const profile = _get_user();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };

  const handleChangeTemplateConfirmation = () => {
    navigate(`/choose-template`, {
      state: {
        project_id: project_id,
      },
    });
    // setChooseTemplateModal(true);
  };

  const handleChangePasswordClick = () => {
    setOpenPasswordModal(true);
    setOpen(false);
  };

  const handleHelpCenterClick = () => {
    navigate(`/helping-articles-categories`);
  };

  const handleSupportClick = () => {
    navigate(`/support-tickets`);
  };

  // const handleLogout = async () => {
  //   // const formdata = new FormData();
  //   // formdata.append('token', sessionStorage.getItem('token'));
  //   // const result = await logout(formdata);
  //   // if (result.code === 200) {
  //   sessionStorage.clear();

  //   navigate("/login");
  //   // } else {
  //   //   enqueueSnackbar(result.message, { variant: 'error' });
  //   // }
  // };

  // ----------------------------------------------------------------------
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < `${string.length}`; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  const handleOpenSaveVersionDailogue = () => {
    handleSaveVersion();
    handleClose();
  };
  const MENU_OPTIONS = [
    {
      label: "Choose Template",
      icon: homeFill,
      handleClick: handleChangeTemplateConfirmation,
    },
    {
      label: "Save Page Version",
      icon: homeFill,
      handleClick: handleOpenSaveVersionDailogue,
    },
    // {
    //   label: "Change Password",
    //   icon: homeFill,
    //   handleClick: handleChangePasswordClick,
    // },
    // {
    //   label: "Contact Support",
    //   icon: homeFill,
    //   handleClick: handleSupportClick,
    // },
    // {
    //   label: "Help Center",
    //   icon: homeFill,
    //   handleClick: handleHelpCenterClick,
    // },
  ];

  // ----------------------------------------------------------------------
  return (
    <>
      <CustomConfirmation
        open={openChangeTemplate}
        setOpen={setOpenChangeTemplate}
        handleAgree={handleChangeTemplateConfirmation}
        title="Are sure you want to choose another template? It will discard your current changes."
      />

      <ChangePassword open={openPasswordModal} setOpen={setOpenPasswordModal} />
      <ChooseTemplateModal
        open={chooseTemplateModal}
        setOpen={setChooseTemplateModal}
      />

      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        {profile.profile_image ? (
          <Avatar
            src={
              profile.profile_image
                ? s3baseUrl + "/" + profile.profile_image
                : profile.first_name[0]
            }
            alt="photoURL"
          />
        ) : (
          <Avatar
            {...stringAvatar(profile.first_name)}
            height={40}
            width={40}
          />
        )}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile?.first_name + " " + profile?.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile?.email}
          </Typography>
        </Box>
        {console.log(profile, "profileprofileprofileprofile")}
        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.handleClick}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout(true)}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
