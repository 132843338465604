import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { TwitterPicker } from "react-color";
import Select from "react-select";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { IconButton, ListItem } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useBuilder } from "../../../../../../hooks//builder-context/BuilderContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputProperties } from "../../../Right-Menu/components";
import InlineInputField from "../../../Right-Menu/components/Inline-Input-Field/InlineInputField";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox,
  Stack,
  Divider,
  Tooltip,
  Icon,
} from "@mui/material";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  google_font_family_classes,
  label_alignment_classes,
} from "../../../../../../ui_libs/Constants/constant";
import { Close, Done } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CustomConfirmation from "../../../../../../components/CustomConfirmation";
import { get } from "lodash";
const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const container_layout_action_child = 1;
const background_image_action_child = 0;
const background_color_action_child = 0;
const text_color_action_child = 0;
const paddings_action_child = 0;
const margins_action_child = 0;

function FormCustomization({
  Form,
  setForm,
  getForm,
  GetInputList,
  LabelRequire,
  setLabelRequire,
  FormRequire,
  setFormRequire,
  CheckInputAvailable,
}) {
  const { ReRenderHtml } = useBuilder();
  const classes = useStyles();
  const [Change, setChange] = useState(false);
  const [Children, setChildren] = useState([]);
  const [RemovingDetail, setRemovingDetail] = useState("");
  const [RemoveInputConfirmation, setRemoveInputConfirmation] = useState(false);
  const [RemoveInputConfirmation_Index, setRemoveInputConfirmation_Index] =
    useState(false);

  const [InputList, setInputList] = useState([
    {
      name: "first_name",
      placeholder: "First Name",
      label_text: "First Name",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 1,
    },
    {
      name: "last_name",
      placeholder: "Last Name",
      label_text: "Last Name",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 2,
    },

    {
      name: "full_name",
      placeholder: "Full Name",
      label_text: "Full Name",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 3,
    },

    {
      name: "email",
      placeholder: "Email",
      label_text: "Email",
      input_require: false,
      type: "email",
      tag: "input",
      checked: false,
      priority: 4,
    },
    {
      name: "phone",
      placeholder: "Phone",
      label_text: "Phone",
      input_require: false,
      type: "phone",
      tag: "input",
      checked: false,
      priority: 5,
    },
    {
      name: "datetimepicer",
      placeholder: "Date Picker",
      label_text: "Date Picker",
      input_require: false,
      type: "date",
      tag: "input",
      checked: false,
      priority: 6,
    },

    // address fields
    {
      name: "address",
      placeholder: "Address",
      label_text: "Address",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 7,
    },
    {
      name: "street_address",
      placeholder: "Street Address",
      label_text: "Street Address",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 8,
    },
    {
      name: "city",
      placeholder: "City",
      label_text: "City",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 9,
    },
    {
      name: "state",
      placeholder: "State",
      label_text: "State",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 10,
    },
    {
      name: "postal_code",
      placeholder: "Postal Code",
      label_text: "Postal Code",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 11,
    },
    // address fields
    {
      name: "gender",
      placeholder: "Gender",
      label_text: "Select Gender",
      input_require: false,
      type: "",
      tag: "select",
      options: `<option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>`,
      checked: false,
      priority: 12,
    },

    {
      name: "country",
      placeholder: "Country List",
      label_text: "Select Country",
      input_require: false,
      type: "",
      tag: "select",
      checked: false,
      options: `
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
            `,
      priority: 13,
    },
    {
      name: "message",
      placeholder: "Message",
      label_text: "Message",
      input_require: false,
      type: "text",
      tag: "textarea",
      checked: false,
      priority: 14,
    },

    // {
    //   name: "custom",
    //   placeholder: "custom",
    //   label_text: "custom",
    //   input_require: false,
    //   type: "custom",
    //   tag: "custom",
    //   checked: false,
    //   priority: 15,
    // },
    {
      name: "checkbox",
      placeholder: "Check Box",
      label_text:
        "Don’t share your personal info with anyone. We will only send emails for our upcoming events",
      input_require: false,
      type: "checkbox",
      tag: "input",
      checked: false,
      priority: 15,
    },
  ]);
  const [Address_list, setAddress_list] = useState([
    {
      name: "street_address",
      placeholder: "Street Address",
      label_text: "Street Address",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 6,
    },
    {
      name: "city",
      placeholder: "City",
      label_text: "City",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 7,
    },
    {
      name: "state",
      placeholder: "State",
      label_text: "State",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 8,
    },
    {
      name: "postal_code",
      placeholder: "Postal Code",
      label_text: "Postal Code",
      input_require: false,
      type: "text",
      tag: "input",
      checked: false,
      priority: 9,
    },
  ]);
  const Column_Class_List = [
    "col-md-1",
    "col-md-2",
    "col-md-3",
    "col-md-4",
    "col-md-5",
    "col-md-6",
    "col-md-7",
    "col-md-8",
    "col-md-9",
    "col-md-10",
    "col-md-11",
    "col-md-12",
  ];
  const [OpenNewInput, setOpenNewInput] = useState(false);
  const [NewInputTypeList, setNewInputTypeList] = useState([
    {
      label: "Select Any Field",
      value: "",
      hiddren: true,
    },
    {
      label: "Text Input",
      value: "input",
    },
    {
      label: "Text Area",
      value: "textarea",
    },
    {
      label: "CheckBox",
      value: "checkbox",
    },
    {
      label: "Select Dropdown",
      value: "select",
    },

    {
      label: "Radio Button",
      value: "radio",
    },
  ]);
  const [NewInputType, setNewInputType] = useState("");

  // custom select button
  const [SelectInputRawOptionList, setSelectInputRawOptionList] = useState([
    "",
  ]);
  const [SelectInputLabel, setSelectInputLabel] = useState("");

  // custom radio button
  const [RadioInputLabel, setRadioInputLabel] = useState([""]);
  const [RadioInputRawOptionList, setRadioInputRawOptionList] = useState([""]);

  // custom input
  const [InputName, setInputName] = useState("");
  const [InputPlaceholder, setInputPlaceholder] = useState("");

  // custom textarea
  const [TextareaName, setTextareaName] = useState("");
  const [TextareaPlaceholder, setTextareaPlaceholder] = useState("");

  // custom checkbox
  const [CheckboxName, setCheckboxName] = useState("");
  const [CheckboxPlaceholder, setCheckboxPlaceholder] = useState("");

  // add and remove input on select

  // const Sort_Input_List = (newinput, inputData) => {
  //   let new_list = "";
  //   let input_added = false;
  //   let input_list = [...Form.children[0].children];
  //   if (input_list.length == 0) {
  //     new_list = newinput.outerHTML;
  //   } else {
  //     InputList.map((my_const_input) => {
  //       input_list.map((form_input_div) => {
  //         let form_input = [
  //           ...form_input_div.getElementsByTagName("input"),
  //           ...form_input_div.getElementsByTagName("select"),
  //           ...form_input_div.getElementsByTagName("textarea"),
  //         ];

  //         if (
  //           inputData.placeholder == my_const_input.placeholder &&
  //           !input_added
  //         ) {
  //           new_list += newinput.outerHTML;
  //           input_added = true;
  //           return;
  //         }
  //         if (my_const_input.placeholder == form_input[0].dataset.name) {
  //           new_list += form_input_div.outerHTML;
  //           return;
  //         }
  //       });
  //     });
  //   }

  //   return new_list;
  // };
  const CheckExistingInputStyles_CustomInput = (_div) => {
    let available_input_list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("textarea"),
    ];
    let available_label_list = [...Form.getElementsByTagName("label")];
    // if incoming input is checkbox
    if (_div.type == "checkbox") {
      let _input = _div.getElementsByTagName(NewInputType)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0] && available_input_list[0].style) {
        _input.style.cssText = available_input_list[0].style.cssText;
      }
      // _input.classList = available_input_list[0].classList;

      // copy label style
      if (_label) {
        if (available_label_list[0] && available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        _label.style.visibility = "visible";

        if (available_label_list[0] && available_label_list[0].classList) {
          _label.classList = available_label_list[0].classList;
        }
      }
      return;
    }

    // if incoming input is not checkbox but available input is checkbox
    if (
      available_input_list.length > 0 &&
      available_input_list[0].type == "checkbox"
    ) {
      let _input = _div.getElementsByTagName(NewInputType)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0].style) {
        _input.style.cssText = available_input_list[0].style.cssText;
      }
      google_font_family_classes.map((cl) => {
        [...available_label_list[0].classList].map((c) => {
          if (cl.value == c) {
            _input.classList.add(c);
          }
        });
      });

      // copy label style
      if (_label) {
        if (available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        _label.classList = available_label_list[0].classList;
        _label.style.visibility =
          Form.getAttribute("form_label") == "true" ? "visible" : "hidden";
      }
      return;
    }
    // if incoming input and incoming input is not checkbox

    if (
      available_input_list.length > 0 &&
      available_input_list[0].type != "checkbox"
    ) {
      let _input = _div.getElementsByTagName(NewInputType)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0].style) {
        _input.style.cssText = available_input_list[0].style.cssText;
      }
      _input.classList = available_input_list[0].classList;

      // copy label style
      if (_label) {
        if (available_label_list.length > 0 && available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        if (
          available_label_list.length > 0 &&
          available_label_list[0].classList
        ) {
          _label.classList = available_label_list[0].classList;
        }

        _label.style.visibility =
          Form.getAttribute("form_label") == "true" ? "visible" : "hidden";
      }
      return;
    }
  };
  const Sort_Input_List = (newinput, inputData) => {
    // Form.children[0].prepend(newinput);
    let Form_Div_List = [...Form.children[0].children];
    let sorted_input_list = [];
    let final_sorted_html = "";

    for (let index = 0; index <= Form_Div_List.length; index++) {
      sorted_input_list.push("");
    }
    // GET CUSTOM INPUT PLACE
    for (let index = 0; index < Form_Div_List.length; index++) {
      let Form_Input = [
        ...Form_Div_List[index].getElementsByTagName("input"),
        ...Form_Div_List[index].getElementsByTagName("textarea"),
        ...Form_Div_List[index].getElementsByTagName("select"),
      ];

      let found = false;
      for (let Input_Index = 0; Input_Index < InputList.length; Input_Index++) {
        if (
          Form_Input.length > 0 &&
          Form_Input[0].name == InputList[Input_Index].name
        ) {
          found = true;
          // break;
        }
      }
      if (!found) {
        sorted_input_list.splice(index, 1, Form_Div_List[index]);
        Form_Div_List.splice(index, 1);
        Form.children[0].children[index].remove();
        continue;
      }
    }

    // GET DEFAULT INPUT POSITION
    let Default_Input_List = [];
    let input_added = false;
    let input_list = [...Form.children[0].children];
    if (input_list.length == 0) {
      Default_Input_List.push(newinput);
    } else {
      InputList.map((my_const_input) => {
        input_list.map((form_input_div) => {
          let form_input = [
            ...form_input_div.getElementsByTagName("input"),
            ...form_input_div.getElementsByTagName("select"),
            ...form_input_div.getElementsByTagName("textarea"),
          ];
          if (inputData.name == my_const_input.name && !input_added) {
            Default_Input_List.push(newinput);
            input_added = true;
            return;
          }
          if (my_const_input.name == form_input[0].name) {
            Default_Input_List.push(form_input_div);
            return;
          }
        });
      });
    }

    // merge default and custom inputs
    for (let idx = 0; idx < Default_Input_List.length; idx++) {
      for (let index = 0; index < sorted_input_list.length; index++) {
        if (sorted_input_list[index] == "") {
          sorted_input_list.splice(index, 1, Default_Input_List[idx]);
          break;
        }
      }
    }

    // storing html of sorted list
    sorted_input_list.map((item) => {
      if (item != "") {
        final_sorted_html += item.outerHTML;
      }
    });

    return final_sorted_html;
  };
  const handleRemoveFormInput = (inputData) => {
    let form_input_list = GetInputList();
    if (inputData.name == "address") {
      let input_list = [...Form.getElementsByTagName("input")];
      input_list.map((input) => {
        Address_list.map((address_input) => {
          if (input.dataset.name == address_input.placeholder) {
            input.parentNode.remove();
          }
        });
      });
      setChange(!Change);
      return;
    }
    for (let index = 0; index < form_input_list.length; index++) {
      if (form_input_list[index].name == inputData.name) {
        let target = form_input_list[index];
        while (!target.parentNode.classList.contains("row")) {
          target = target.parentNode;
        }
        target.remove();
        inputData.checked = false;
      }
    }
    setInputList([...InputList]);
  };
  const GetLabelAlignmentStyleClass = (
    target_element,
    target_tag,
    class_list
  ) => {
    let list = [...Form.getElementsByTagName(target_tag)];
    let value = "justify-content-start";
    if (list.length > 0) {
      list.map((Element) => {
        class_list.map((cl) => {
          if (Element.classList.contains(cl.value)) {
            value = cl.value;
          }
        });
      });
    }

    if (target_element) {
      target_element.classList.add(value);
    }
    return value;
  };
  const CreateTextArea = (inputData) => {
    // textarea
    let _input = document.createElement("textarea");
    _input.classList.add("form-control");
    _input.setAttribute("name", inputData.name);
    _input.setAttribute("data-name", inputData.placeholder);
    _input.setAttribute("placeholder", inputData.placeholder);
    _input.setAttribute("cols", 50);
    _input.setAttribute("rows", 5);

    _input.setAttribute("type", inputData.type);
    _input.required = inputData.input_require;

    // label
    let _label = document.createElement("label");
    _label.classList.add("d-flex");
    _label.classList.add("flex-start");
    _label.innerHTML = inputData.label_text;
    _label.style.visibility =
      Form.getAttribute("form_label") == "true" ? "visible" : "hidden";

    // parent div
    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    _div.classList.add("col-md-12");

    if (Form.getAttribute("helper_text_require") == "true") {
      let _p = document.createElement("p");
      _p.classList.add("pb-0");
      _p.classList.add("mb-0");
      _p.classList.add("d-flex");
      GetLabelAlignmentStyleClass(_p, "p", label_alignment_classes);
      _p.style.cssText = "font-size: 9px; visibility: visible;";
      _p.innerText = `${inputData.placeholder}`;
      _div.prepend(_p);
    }
    _div.prepend(_input);

    Form.getAttribute("form_label") == "true" ? _div.prepend(_label) : "";

    return _div;
  };
  const CreateInputForm = (inputData) => {
    // textarea
    let _input = document.createElement("input");
    _input.classList.add("form-control");
    _input.setAttribute("name", inputData.name);
    _input.setAttribute("data-name", inputData.placeholder);
    _input.setAttribute("placeholder", inputData.placeholder);
    _input.setAttribute("type", inputData.type);
    _input.required = inputData.input_require;

    // label
    let _label = document.createElement("label");
    _label.classList.add("d-flex");
    _label.classList.add("flex-start");
    _label.innerHTML = inputData.label_text;
    _label.style.visibility =
      Form.getAttribute("form_label") === "true" ? "visible" : "hidden";

    // parent div
    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];
    let found = false;
    Column_Class_List.forEach((cl) => {
      if (inputs[0].parentNode.classList.contains(cl)) {
        _div.classList.add(cl);
        found = true;
      }
    });
    if (!found) {
      _div.classList.add("col-md-12");
    }
    // if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-6")
    // ) {
    //   _div.classList.add("col-md-6");
    // } else if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-12")
    // ) {
    //   _div.classList.add("col-md-12");
    // }

    // helper text
    if (Form.getAttribute("helper_text_require") == "true") {
      let _p = document.createElement("p");
      _p.classList.add("pb-0");
      _p.classList.add("mb-0");
      _p.classList.add("d-flex");
      GetLabelAlignmentStyleClass(_p, "p", label_alignment_classes);

      _p.style.cssText = "font-size: 9px; visibility: visible;";
      _p.innerText = `${inputData.placeholder}`;
      _div.prepend(_p);
    }
    _div.prepend(_input);

    Form.getAttribute("form_label") == "true" ? _div.prepend(_label) : "";

    return _div;
  };

  const CreateSelectInputForm = (inputData) => {
    // select
    let _select = document.createElement("select");
    _select.classList.add("form-control");
    _select.setAttribute("name", inputData.name);
    _select.setAttribute("data-name", inputData.placeholder);
    //  _select.setAttribute("placeholder", inputData.placeholder);
    _select.required = inputData.input_require;

    //  select options
    let _default_option = document.createElement("option");
    _default_option.innerText = `${inputData.label_text}`;
    _default_option.setAttribute("value", "");
    _default_option.setAttribute("hidden", "true");

    _select.innerHTML = _default_option.outerHTML + inputData.options;
    // label
    let _label = document.createElement("label");
    _label.classList.add("d-flex");
    _label.classList.add("flex-start");
    _label.innerHTML = inputData.label_text;
    _label.style.visibility =
      Form.getAttribute("form_label") == "true" ? "visible" : "hidden";

    // parent div
    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];
    let found = false;
    Column_Class_List.forEach((cl) => {
      if (inputs[0].parentNode.classList.contains(cl)) {
        _div.classList.add(cl);
        found = true;
      }
    });
    if (!found) {
      _div.classList.add("col-md-12");
    }
    // if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-6")
    // ) {
    //   _div.classList.add("col-md-6");
    // } else if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-12")
    // ) {
    //   _div.classList.add("col-md-12");
    // }

    if (Form.getAttribute("helper_text_require") == "true") {
      let _p = document.createElement("p");
      _p.classList.add("pb-0");
      _p.classList.add("mb-0");
      _p.classList.add("d-flex");
      GetLabelAlignmentStyleClass(_p, "p", label_alignment_classes);

      _p.style.cssText = "font-size: 9px; visibility: visible;";
      _p.innerText = `${inputData.placeholder}`;
      _div.prepend(_p);
    }
    _div.prepend(_select);

    Form.getAttribute("form_label") == "true" ? _div.prepend(_label) : "";

    return _div;
  };
  const CheckExistingInputStyles = (_div, inputData) => {
    let available_input_list = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("select"),
      // ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("textarea"),
    ];

    let available_label_list = [...Form.getElementsByTagName("label")];
    // if incoming input is checkbox
    if (inputData.type == "checkbox") {
      let _input = _div.getElementsByTagName(inputData.tag)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0] && available_input_list[0].style) {
        _input.style.cssText = available_input_list[0].style.cssText;
      }
      // _input.classList = available_input_list[0].classList;

      // copy label style
      if (_label) {
        if (available_label_list[0] && available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        _label.style.visibility = "visible";

        if (available_label_list[0] && available_label_list[0].classList) {
          _label.classList = available_label_list[0].classList;
        }
      }
      return;
    }

    // if incoming input is not checkbox but available input is checkbox
    if (
      available_input_list.length > 0 &&
      available_input_list[0].type == "checkbox"
    ) {
      let _input = _div.getElementsByTagName(inputData.tag)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0].style) {
        _input.style.cssText = available_input_list[0].style.cssText;
      }
      google_font_family_classes.map((cl) => {
        [...available_label_list[0].classList].map((c) => {
          if (cl.value == c) {
            _input.classList.add(c);
          }
        });
      });

      // copy label style
      if (_label) {
        if (available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        _label.classList = available_label_list[0].classList;
        _label.style.visibility =
          Form.getAttribute("form_label") == "true" ? "visible" : "hidden";
      }

      return;
    }
    // if incoming input and incoming input is not checkbox

    if (
      available_input_list.length > 0 &&
      available_input_list[0].type != "checkbox"
    ) {
      let _input = _div.getElementsByTagName(inputData.tag)[0];
      let _label = _div.getElementsByTagName("label")[0];

      // copy input style
      if (available_input_list[0].style) {
        if (available_input_list[0].style) {
          _input.style.cssText = available_input_list[0].style.cssText;
        }
      }
      _input.classList = available_input_list[0].classList;

      // copy label style
      if (_label) {
        if (available_label_list.length > 0 && available_label_list[0].style) {
          _label.style.cssText = available_label_list[0].style.cssText;
        }
        if (
          available_label_list.length > 0 &&
          available_label_list[0].classList
        ) {
          _label.classList = available_label_list[0].classList;
        }

        _label.style.visibility =
          Form.getAttribute("form_label") == "true" ? "visible" : "hidden";
      }

      _input.classList.add("form-control");
      _input.classList.remove("form-select");
      _input.classList.remove("form-select-lg");
      return;
    }
  };
  const CreateCheckBox = (inputData) => {
    // textarea
    let _input = document.createElement("input");
    _input.classList.add("checkbox-btn");
    _input.classList.add("me-2");
    _input.setAttribute("name", inputData.name);
    _input.setAttribute("data-name", inputData.placeholder);

    _input.setAttribute("id", "label-form_id_hlxmkgvflk");
    _input.setAttribute("placeholder", inputData.placeholder);
    _input.setAttribute("type", inputData.type);
    _input.required = inputData.input_require;

    // label
    let _label = document.createElement("label");
    _label.setAttribute("for", "label-form_id_hlxmkgvflk");
    _label.innerHTML = inputData.label_text;

    // parent div
    let _div = document.createElement("div");
    _div.innerHTML = `
                  <input type="checkbox" id="label-form_id_mcbiegfksv" name="checkbox" data-name="Check Box" class="me-2 checkbox-btn mt-1">
                  <label for="label-form_id_mcbiegfksv"> Don’t share your
                    personal
                    info with anyone.
                    We will only send emails for our upcoming events</label>
                `;
    _div.classList.add("wp-mt-20");
    _div.classList.add("d-flex");
    _div.classList.add("form-check");
    _div.classList.add("ps-0");

    // _div.prepend(_label);
    // _div.prepend(_input);

    return _div;
  };

  const handleCreateAddressInputList = () => {
    Address_list.map((item) => {
      let _div = document.createElement("div");

      _div = CreateInputForm(item);
      CheckExistingInputStyles(_div, item);

      // set priority number to arrange input

      let form_inner_div = Form.querySelector(".row");
      if (form_inner_div) {
        Form.children[0].innerHTML = Sort_Input_List(_div, item);
        setForm(Form);
        setChange(!Change);
      }
    });
    setChange(!Change);
  };
  const handleAddFormInput = (inputData) => {
    let _div = document.createElement("div");

    if (inputData.tag == "textarea") {
      _div = CreateTextArea(inputData);
    } else if (inputData.tag == "select") {
      _div = CreateSelectInputForm(inputData);
    } else if (inputData.tag == "input" && inputData.type == "checkbox") {
      _div = CreateCheckBox(inputData);
    } else {
      if (inputData.name == "address") {
        Address_list.map((item) => {
          _div = CreateInputForm(item);
          CheckExistingInputStyles(_div, item);

          // set priority number to arrange input

          let form_inner_div = Form.querySelector(".row");
          if (form_inner_div) {
            // form_inner_div.innerHTML = Sort_Input_List(_div, inputData);
            // form_inner_div.prepend(_div);
          }
          if (form_inner_div.children.length > 2) {
            form_inner_div.insertBefore(
              _div,
              form_inner_div.children[form_inner_div.children.length - 2]
            );
          } else {
            form_inner_div.append(_div);
          }
        });
        return;
      } else {
        _div = CreateInputForm(inputData);
      }
    }
    CheckExistingInputStyles(_div, inputData);
    let _input = [..._div.getElementsByTagName("select")];
    if (_input.length > 0) {
      _input[0].style.appearance = "auto";
    }
    // set priority number to arrange input

    let form_inner_div = Form.querySelector(".row");
    if (form_inner_div) {
      form_inner_div.innerHTML = Sort_Input_List(_div, inputData);
    }
  };
  const handleFormInputScope = (inputData) => {
    if (inputData.checked) {
      setRemoveInputConfirmation(true);
      setRemovingDetail(inputData);
    } else {
      handleAddFormInput(inputData);
    }
    setChange(!Change);
  };

  // drag to change input position

  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...getForm().children[0].children];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      getForm().children[0].innerHTML = newdata;
      setChange(!Change);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const handleDragEndOptionList = (
    { source, destination, ...rest },
    optionList
  ) => {
    if (source && destination && source.index != destination.index) {
      let temp = optionList[destination.index].outerHTML;
      optionList[destination.index].outerHTML =
        optionList[source.index].outerHTML;
      optionList[source.index].outerHTML = temp;

      setChange(!Change);
    } else {
      console.log("ERROR", source, destination);
    }
  };

  const handleRemoveColumn = (index) => {
    let column_list = [...getForm().children[0].children];
    column_list[index].remove();
    setChange(!Change);
  };

  const handleMoveup = (source) => {
    let destination = source - 1;
    if (source > 0) {
      let newList = [...getForm().children[0].children];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      getForm().children[0].innerHTML = newdata;
      setChange(!Change);
    }
  };
  const handleMovedown = (source) => {
    let destination = source + 1;
    if (source < Children.length) {
      let newList = [...getForm().children[0].children];

      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      getForm().children[0].innerHTML = newdata;
      setChange(!Change);
    }
  };
  const RenderInputName = (child) => {
    let input = [
      ...child.getElementsByTagName("input"),
      ...child.getElementsByTagName("textarea"),
      ...child.getElementsByTagName("button"),
      ...child.getElementsByTagName("select"),
    ];

    if (input.length > 0) {
      if (input[0].dataset && input[0].dataset.name) {
        return input[0].dataset.name;
      } else {
        if (input[0].placeholder) {
          return input[0].placeholder;
        } else if (input[0].name) {
          return input[0].name;
        } else {
          return input[0].innerHTML + input[0].tagName.toLowerCase();
        }
      }
    }

    return "N/A";
  };
  const CheckButton = (child) => {
    let result = true;
    if (child.getElementsByTagName("button").length > 0) {
      result = false;
    }
    return result;
  };
  const handleChangeInputDatasetName = (e, index) => {
    let value = InputList.filter((item) => {
      return item.placeholder == e.target.value;
    });
    let input = Children[index].getElementsByTagName("input")[0];
    let _label = Children[index].getElementsByTagName("label")[0];

    input.setAttribute("type", value[0].type);
    input.setAttribute("placeholder", value[0].placeholder);
    input.dataset.name = value[0].placeholder;

    _label.innerHTML = value[0].label_text;
    setChildren([...Children]);
  };
  const renderSelectBox = (input) => {
    return input.name == "checkbox" ? (
      ""
    ) : (
      <option value={input.placeholder}>{input.placeholder}</option>
    );
  };

  const handleChangeNewInputType = (e) => {
    setNewInputType(e.target.value);
  };
  const handleAddNewInput = () => {
    setOpenNewInput(true);
  };
  const handleCreateNewSelectInputwithOptionList = (list) => {
    // create select element
    let _select = document.createElement("select");
    _select.setAttribute("data-name", SelectInputLabel);
    _select.setAttribute("data-custom_input", "true");
    _select.classList.add("form-control");

    // options list
    // default option
    let _option = document.createElement("option");
    _option.setAttribute("value", "");
    _option.hidden = true;
    _option.innerHTML = "Select one";
    _select.append(_option);
    list.map((item) => {
      if (item.trim().length > 0) {
        let _options = document.createElement("option");
        _options.setAttribute("value", item.trim());
        _options.innerHTML = item.trim();
        _select.append(_options);
      }
    });
    // create label for select
    let _label = document.createElement("label");
    _label.classList.add("d-flex");
    _label.classList.add("justify-content-start");

    let helper_text = SelectInputLabel;
    _label.innerHTML = SelectInputLabel;
    _select.setAttribute(
      "name",
      SelectInputLabel.replaceAll(" ", "_").toLowerCase()
    );

    // create parent div and add select in the parent div

    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    _div.setAttribute("input_priority", Form.children[0].children.length);

    // append
    Form.getAttribute("form_label") == "true" ? _div.append(_label) : "";
    _div.append(_select);
    if (Form.getAttribute("helper_text_require") == "true") {
      let _p = document.createElement("p");
      _p.classList.add("pb-0");
      _p.classList.add("mb-0");
      _p.classList.add("d-flex");
      GetLabelAlignmentStyleClass(_p, "p", label_alignment_classes);
      _p.style.cssText = "font-size: 9px; visibility: visible;";
      _p.innerText = `${helper_text}`;
      _div.append(_p);
    }

    let form_inner_div = Form.querySelector(".row");

    CheckExistingInputStyles_CustomInput(_div);

    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];

    let found = false;
    Column_Class_List.forEach((cl) => {
      if (inputs[0].parentNode.classList.contains(cl)) {
        _div.classList.add(cl);
        found = true;
      }
    });
    if (!found) {
      _div.classList.add("col-md-12");
    }
    // if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-6")
    // ) {
    //   _div.classList.add("col-md-6");
    // } else if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-12")
    // ) {
    //   _div.classList.add("col-md-12");
    // }

    let _input = [..._div.getElementsByTagName("select")];
    if (_input) {
      _input[0].style.appearance = "auto";
    }

    if (form_inner_div.children.length > 2) {
      form_inner_div.insertBefore(
        _div,
        form_inner_div.children[form_inner_div.children.length - 2]
      );
    } else {
      form_inner_div.append(_div);
    }
  };
  const handleAddSelectInputDone = () => {
    if (SelectInputRawOptionList.length > 0) {
      handleCreateNewSelectInputwithOptionList(SelectInputRawOptionList);
      handleCloseNewInputCreation();
      setChange(!Change);
    }
  };
  const handleCreateNewRadioInputList = (list) => {
    // parent div
    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];
    if (
      inputs.length > 0 &&
      inputs[0].parentNode.classList.contains("col-md-6")
    ) {
      _div.classList.add("col-md-6");
    } else if (
      inputs.length > 0 &&
      inputs[0].parentNode.classList.contains("col-md-12")
    ) {
      _div.classList.add("col-md-12");
    }

    // input parent div
    let _input_div = document.createElement("div");
    _input_div.classList.add("d-flex");
    _input_div.style.flexWrap = "wrap";

    let name = RadioInputLabel;
    // radio inputs list
    list.map((item) => {
      if (item.trim().length > 0) {
        let _inner_div = document.createElement("div");
        _inner_div.classList.add("d-flex");

        _inner_div.innerHTML = `<input  type="radio" id="${item.trim()}" data-name="${name}" name="${RadioInputLabel.replaceAll(
          " ",
          "_"
        ).toLowerCase()}" value="${item.trim()}"/>&nbsp;
<label class="" for="${item.trim()}">${item.trim()}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;

        _input_div.append(_inner_div);
      }
    });

    // radio input label and its div
    let _label_div = document.createElement("div");
    _label_div.classList.add("d-flex");
    _label_div.classList.add("col-12");
    _label_div.innerHTML = `<label >${RadioInputLabel}</label>`;
    // let existing_radio_input = [...Form.querySelectorAll("input[type=radio]")];
    // if (existing_radio_input.length > 0) {
    //   if (existing_radio_input[0]?.parentNode?.parentNode?.classList) {
    //     _input_div.classList =
    //       existing_radio_input[0].parentNode.parentNode.classList;
    //     _label_div.classList =
    //       existing_radio_input[0].parentNode.parentNode.parentNode.children[0].classList;
    //   }
    // }
    if (Form.getAttribute("form_label") == "true") {
      let let_existing_labels = [...Form.querySelectorAll("label")];

      if (let_existing_labels.length > 0) {
        _label_div.classList = let_existing_labels[0].classList;
      }
    } else {
      _label_div.classList.add("justify-content-start");
    }

    _div.prepend(_label_div);
    _div.append(_input_div);
    let form_inner_div = Form.querySelector(".row");
    // CheckExistingInputStyles_CustomInput(_div);
    if (form_inner_div.children.length > 2) {
      form_inner_div.insertBefore(
        _div,
        form_inner_div.children[form_inner_div.children.length - 2]
      );
    } else {
      form_inner_div.append(_div);
    }
  };

  const handleAddRadioInputDone = () => {
    if (RadioInputRawOptionList.length > 0) {
      handleCreateNewRadioInputList(RadioInputRawOptionList);
      handleCloseNewInputCreation();
      setChange(!Change);
    }
  };
  const RenderDefaultInputList = () => {
    let list = [];
    InputList.map((input, index) => {
      let hide_input = false;
      Address_list.map((address) => {
        if (input.name == address.name) {
          hide_input = true;
        }
      });
      if (!hide_input) {
        list.push(
          <div
            key={index}
            style={{
              userSelect: "none",
            }}
            className="mb-2 border p-2 mx-1 rounded form-checkbox-color d-flex align-items-center pointer"
            onClick={() => handleFormInputScope(input)}
          >
            <input
              type="checkbox"
              value={input}
              checked={input.checked}
              className="pointer"
              // onChange={() => handleFormInputScope(input)}
            />
            <label
              // onClick={() => handleFormInputScope(input)}
              className="custom-label d-flex align-items-center pointer"
            >
              &nbsp;&nbsp;{input.placeholder}
            </label>
          </div>
        );
      }
    });

    return list;
  };

  const handleAddInputDone = () => {
    let _div = document.createElement("div");

    // create label for select
    let _label = document.createElement("label");
    let help_text =
      Form.getAttribute("helper_text_require") == "true" ? true : false;
    if (Form.getAttribute("form_label") == "true") {
      // if label is on

      _div.innerHTML = `
    <label class="d-flex justify-content-start"  >${InputPlaceholder}</label>
    
                <input type="text" class="form-control" name="${InputName.replaceAll(
                  " ",
                  "_"
                ).toLowerCase()}" placeholder="${InputPlaceholder}" data-name="${InputName}" />
              ${
                help_text
                  ? `<p
          class="pb-0 mb-0 d-flex ${GetLabelAlignmentStyleClass(
            null,
            "p",
            label_alignment_classes
          )}"
          style="font-size: 9px; visibility: visible;"
        >
          ${InputPlaceholder}
        </p>`
                  : ``
              }`;
    } else {
      // if label is off

      _div.innerHTML = `
      
                <input type="text" class="form-control" name="${InputName.replaceAll(
                  " ",
                  "_"
                ).toLowerCase()}" placeholder="${InputPlaceholder}" data-name="${InputName}" />
              ${
                help_text
                  ? `<p
          class="pb-0 mb-0 d-flex ${GetLabelAlignmentStyleClass(
            null,
            "p",
            label_alignment_classes
          )}"
          style="font-size: 9px; visibility: visible;"
        >
          ${InputPlaceholder}
        </p>`
                  : ``
              }
              
                `;
    }

    _div.classList.add("wp-mt-20");
    CheckExistingInputStyles(_div, InputList[0]);
    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];
    let found = false;
    Column_Class_List.forEach((cl) => {
      if (inputs[0].parentNode.classList.contains(cl)) {
        _div.classList.add(cl);
        found = true;
      }
    });
    if (!found) {
      _div.classList.add("col-md-12");
    }
    // if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-6")
    // ) {
    //   _div.classList.add("col-md-6");
    // } else if (
    //   inputs.length > 0 &&
    //   inputs[0].parentNode.classList.contains("col-md-12")
    // ) {
    //   _div.classList.add("col-md-12");
    // }
    let form_inner_div = Form.querySelector(".row");
    // CheckExistingInputStyles_CustomInput(_div);
    if (form_inner_div.children.length > 2) {
      form_inner_div.insertBefore(
        _div,
        form_inner_div.children[form_inner_div.children.length - 2]
      );
    } else {
      form_inner_div.append(_div);
    }
    handleCloseNewInputCreation();

    setChange(!Change);
  };

  const handleAddTextareaDone = () => {
    let _div = document.createElement("div");

    // create label for select
    let _label = document.createElement("label");
    let help_text =
      Form.getAttribute("helper_text_require") == "true" ? true : false;
    if (Form.getAttribute("form_label") == "true") {
      // if label is on
      _div.innerHTML = `
      <div class="form-group">
    <label class="d-flex justify-content-start"  >${TextareaName}</label>
   
    <textarea name="${TextareaName.replaceAll(
      " ",
      "_"
    ).toLowerCase()}" class="form-control" placeholder="${TextareaPlaceholder}"
     data-name="${TextareaName}" cols="50" rows="5" ></textarea>
      ${
        help_text
          ? `<p
      
          class="pb-0 mb-0 d-flex ${GetLabelAlignmentStyleClass(
            null,
            "p",
            label_alignment_classes
          )}"
          style="font-size: 9px; visibility: visible;"
        >
          ${TextareaPlaceholder}
        </p>`
          : ``
      }
                
              </div`;
    } else {
      // if label is off

      _div.innerHTML = `
                <div class="form-group">
               
<textarea name="${TextareaName.replaceAll(
        " ",
        "_"
      ).toLowerCase()}" class="form-control" placeholder="${TextareaPlaceholder}"
     data-name="${TextareaName}" cols="50" rows="5"></textarea>
      ${
        help_text
          ? `<p
          class="pb-0 mb-0 d-flex ${GetLabelAlignmentStyleClass(
            null,
            "p",
            label_alignment_classes
          )}"
          style="font-size: 9px; visibility: visible;"
        >
          ${TextareaPlaceholder}
        </p>`
          : ``
      }
                </div>
              `;
    }

    _div.classList.add("wp-mt-20");
    CheckExistingInputStyles(_div, InputList[InputList.length - 2]);
    let inputs = [
      ...Form.getElementsByTagName("select"),
      ...Form.getElementsByTagName("input"),
    ];
    let form_inner_div = Form.querySelector(".row");
    // CheckExistingInputStyles_CustomInput(_div);
    if (form_inner_div.children.length > 2) {
      form_inner_div.insertBefore(
        _div,
        form_inner_div.children[form_inner_div.children.length - 2]
      );
    } else {
      form_inner_div.append(_div);
    }
    handleCloseNewInputCreation();

    setChange(!Change);
  };

  const CheckInputsValid = (List, Label) => {
    let result = true;
    if (List.length > 0 && Label != "") {
      for (let index = 0; index < List.length; index++) {
        if (List[index] == "") {
          result = false;
          break;
        }
      }
    } else {
      result = false;
    }

    return !result;
  };
  const handleAddCheckboxDone = () => {
    let _div = document.createElement("div");
    _div.classList.add("wp-mt-20");
    _div.classList.add("col-md-12");
    _div.classList.add("d-flex");
    _div.classList.add("flex-start");
    _div.classList.add("align-items-center");

    // create label for select
    let _label = document.createElement("label");

    let _name = CheckboxName;

    _name = _name.trim().replaceAll(" ", "_").toLowerCase();
    _div.innerHTML = `
      <input type="checkbox" id="${_name}" name="${_name}" data-name="${CheckboxName}" class="me-2 checkbox-btn mt-1" placeholder="">
                  <label for="${_name}"> ${CheckboxPlaceholder}</label>`;

    _div.classList.add("wp-mt-20");
    CheckExistingInputStyles(_div, InputList[InputList.length - 1]);
    let inputs = [
      ...Form.getElementsByTagName("input"),
      ...Form.getElementsByTagName("select"),
    ];
    if (
      inputs.length > 0 &&
      inputs[0].parentNode.classList.contains("col-md-6")
    ) {
      _div.classList.add("col-md-6");
    } else if (
      inputs.length > 0 &&
      inputs[0].parentNode.classList.contains("col-md-12")
    ) {
      _div.classList.add("col-md-12");
    }
    let form_inner_div = Form.querySelector(".row");
    // CheckExistingInputStyles_CustomInput(_div);
    if (form_inner_div.children.length > 2) {
      form_inner_div.insertBefore(
        _div,
        form_inner_div.children[form_inner_div.children.length]
      );
    } else {
      form_inner_div.append(_div);
    }
    handleCloseNewInputCreation();

    setChange(!Change);
  };
  const handleCloseNewInputCreation = () => {
    setOpenNewInput(false);
    // custom select input
    setNewInputType("");
    setSelectInputRawOptionList([""]);

    setSelectInputLabel("");

    // custom radio
    setRadioInputLabel("");
    setRadioInputRawOptionList([""]);

    // custom input
    setInputName("");
    setInputPlaceholder("");

    // custom textarea
    setTextareaName("");
    setTextareaPlaceholder("");

    // custom checkbox
    setCheckboxName("");
    setCheckboxPlaceholder("");
  };
  const renderSelectOptionList = () => {
    let _select_list = [...Form.getElementsByTagName("select")];
    let List = [];
    if (_select_list.length > 0) {
      _select_list.map((_select, _select_index) => {
        let _option_list = [..._select.getElementsByTagName("option")];
        _option_list.splice(0, 1);
        List.push(
          <div
            key={_select_index}
            className="row border mb-2 rounded p-2 mx-1 formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div>
                <label className="custom-label d-flex align-items-center fw-bold">
                  {_select.dataset.name}
                </label>
              </div>
            </div>
            <div className="col-12">
              <DragDropContext
                onDragEnd={(props) =>
                  handleDragEndOptionList(props, _option_list)
                }
              >
                <Droppable droppableId="doprable1">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {_option_list.length > 0 ? (
                        _option_list.map((option, option_index) => (
                          <Draggable
                            draggable={false}
                            key={option_index}
                            draggableId={`dragable-${option_index}`}
                            index={option_index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={`${
                                  snapshot.isDragging ? "shadow" : null
                                } row border rounded formsetting-div-color-silver mb-1 align-items-center column-block-list`}
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? "white"
                                    : null,
                                }}
                              >
                                <div
                                  key={option_index}
                                  className="col-12  form-label-checkbox-color "
                                >
                                  <div className=" col-12 d-flex align-items-center">
                                    <div className=" col-3 d-flex align-items-center">
                                      <div>
                                        <label className="custom-label d-flex align-items-center">
                                          Option {option_index + 1}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-8">
                                      <input
                                        className="form-control form-control-sm"
                                        value={option.innerText}
                                        onChange={(e) => {
                                          option.innerHTML = e.target.value;
                                          option.setAttribute(
                                            "value",
                                            e.target.value
                                          );
                                          setChange(!Change);
                                        }}
                                      />
                                    </div>
                                    <div className="col-1">
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title="Remove Option"
                                      >
                                        <IconButton component="label">
                                          <Close
                                            className="pointer"
                                            htmlColor="red"
                                            onClick={() => {
                                              option.remove();
                                              setChange(!Change);
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <label className="custom-label">
                              No Options Available
                            </label>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div
              // style={
              //   _option_list.length > 0 &&
              //   _option_list[_option_list.length - 1].innerText.trim() == ""
              //     ? {
              //         pointerEvents: "none",
              //         opacity: 0.3,
              //       }
              //     : {
              //         pointerEvents: "auto",
              //       }
              // }
              className="d-flex justify-content-end"
            >
              {/* <Tooltip arrow placement="top" title="Add Option">
                <IconButton component="label">
                  <AddCircleOutlineIcon
                    className="pointer"
                    htmlColor="black"
                    onClick={() => {
                      let new_option = document.createElement("option");
                      _select.append(new_option);
                      setChange(!Change);
                    }}
                  />
                </IconButton>
              </Tooltip> */}
              <button
                style={
                  _option_list.length > 0 &&
                  _option_list[_option_list.length - 1].innerText.trim() == ""
                    ? {
                        pointerEvents: "none",
                        opacity: 0.3,
                      }
                    : {
                        pointerEvents: "auto",
                      }
                }
                type="button"
                className="btn btn-sm btn-secondary me-1"
                onClick={() => {
                  let new_option = document.createElement("option");
                  _select.append(new_option);
                  setChange(!Change);
                }}
              >
                Add New Option
              </button>
            </div>
          </div>
        );
      });
    }

    return List;
  };
  const GetData = () => {
    ReRenderHtml();

    let children = [...getForm().children[0].children];
    if (children) {
      setChildren(children);
    }

    let form_input_list = GetInputList();
    InputList.map((input) => {
      input.checked = false;
      for (let index = 0; index < form_input_list.length; index++) {
        if (input.name == "address") {
          Address_list.map((address_input) => {
            if (form_input_list[index].name == address_input.name) {
              input.checked = true;
            }
          });
        } else {
          if (form_input_list[index].name == input.name) {
            input.checked = true;
            break;
          } else {
            input.checked = false;
          }
        }
      }
    });
    setInputList([...InputList]);
  };
  useEffect(() => {
    GetData();
  }, [Change]);

  return (
    <div className="col-12">
      {/* remove default inputs confirmation */}
      <CustomConfirmation
        open={RemoveInputConfirmation}
        setOpen={setRemoveInputConfirmation}
        handleAgree={() => {
          handleRemoveFormInput(RemovingDetail);
          setRemoveInputConfirmation(false);
        }}
        title="Are you sure you want to remove input field?"
      />

      {/* remove inputs confirmation in drag and drop area */}
      <CustomConfirmation
        open={RemoveInputConfirmation_Index}
        setOpen={setRemoveInputConfirmation_Index}
        handleAgree={() => {
          handleRemoveColumn(RemovingDetail);
          setRemoveInputConfirmation_Index(false);
        }}
        title="Are you sure you want to remove input field?"
      />

      {/* sublabel list */}
      <div className="col-12 p-2">
        <div className="col-12 p-2">
          <span className="custom-label">Field Options</span>
        </div>
        {/* create 2 divs and each have col-6 even on 1 div od on other div */}
        <div className="row px-3">{RenderDefaultInputList()}</div>
        <Typography sx={{ fontSize: 10, paddingLeft: 1, marginBottom: 0 }}>
          Select Which Field to include in the Form
        </Typography>
        <div className="d-flex justify-content-end mx-2 ">
          {!OpenNewInput && (
            <Tooltip arrow placement="top" title="Add new Input">
              <button
                type="button"
                className="btn btn-sm btn-success me-1"
                onClick={handleAddNewInput}
              >
                Add New Input
              </button>
            </Tooltip>
          )}
        </div>
        {OpenNewInput && (
          <div className=" mt-3 rounded border mb-2 p-2  mx-1 card">
            <div className="d-flex justify-content-end">
              <Tooltip arrow placement="top" title="Close">
                <IconButton component="label">
                  <Close
                    className="pointer"
                    htmlColor="black"
                    onClick={handleCloseNewInputCreation}
                  />
                </IconButton>
              </Tooltip>
            </div>
            {OpenNewInput && (
              <div className="mx-2">
                <div className="col-12 mb-1">
                  <label className="custom-label d-flex ">
                    Selct Input Field
                  </label>
                </div>
                <select
                  className="w-100 form-control custom-label custom-icon-select"
                  value={NewInputType}
                  onChange={handleChangeNewInputType}
                >
                  {NewInputTypeList.map((fs, i) => (
                    <option hidden={fs.hiddren} key={i} value={fs.value}>
                      {fs.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {NewInputType == "input" && (
              <div className="m-2 mt-2 rounded border mb-2 p-2 mt-1 mx-1 formsetting-label-parent-div">
                <div className=" col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Name{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={InputName}
                      className="form-control form-control-sm"
                      onChange={(e) => setInputName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2 col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Placeholder{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={InputPlaceholder}
                      className="form-control form-control-sm"
                      onChange={(e) => setInputPlaceholder(e.target.value)}
                    />
                  </div>
                </div>

                <div className=" col-12 ">
                  <div className="col d-flex justify-content-end">
                    {/* <Tooltip arrow placement="top" title="Add">
                  <IconButton component="label">
                    <Done
                      htmlColor="green"
                      className="pointer"
                      onClick={handleAddInputDone}
                    />
                  </IconButton>
                </Tooltip> */}

                    <button
                      style={
                        InputName == "" || InputPlaceholder == ""
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-success me-1 mt-1"
                      onClick={handleAddInputDone}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}
            {NewInputType == "textarea" && (
              <div className="m-2 mt-2 rounded border mb-2 p-2 mt-1 mx-1 formsetting-label-parent-div">
                <div className=" col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Name{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={TextareaName}
                      className="form-control form-control-sm"
                      onChange={(e) => setTextareaName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2 col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Placeholder{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={TextareaPlaceholder}
                      className="form-control form-control-sm"
                      onChange={(e) => setTextareaPlaceholder(e.target.value)}
                    />
                  </div>
                </div>

                <div className=" col-12 ">
                  <div className="col d-flex justify-content-end">
                    <button
                      style={
                        TextareaName == "" || TextareaPlaceholder == ""
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-success me-1 mt-1"
                      onClick={handleAddTextareaDone}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}

            {NewInputType == "checkbox" && (
              <div className="m-2 mt-2 rounded border mb-2 p-2 mt-1 mx-1 formsetting-label-parent-div">
                <div className=" col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Name{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={CheckboxName}
                      className="form-control form-control-sm"
                      onChange={(e) => setCheckboxName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2 col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Placeholder{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={CheckboxPlaceholder}
                      className="form-control form-control-sm"
                      onChange={(e) => setCheckboxPlaceholder(e.target.value)}
                    />
                  </div>
                </div>

                <div className=" col-12 ">
                  <div className="col d-flex justify-content-end">
                    <button
                      style={
                        CheckboxName == "" || CheckboxPlaceholder == ""
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-success me-1 mt-1"
                      onClick={handleAddCheckboxDone}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}

            {NewInputType == "select" && (
              <div className="m-2 mt-2 rounded border mb-2 p-2 mt-1 mx-1 formsetting-label-parent-div">
                <div className=" col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Label Text
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={SelectInputLabel}
                      className="form-control form-control-sm"
                      onChange={(e) => setSelectInputLabel(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-1 col-12 d-flex align-items-center ">
                  {/* <div className="col-3">
                <label className="custom-label d-flex align-items-center">
                  Options
                </label>
              </div> */}
                  <div className="col-12 ">
                    {/* <textarea
                  value={SelectInputRawOptionList}
                  className="form-control form-control-sm"
                  onChange={(e) => setSelectInputRawOptionList(e.target.value)}
                /> */}
                    {SelectInputRawOptionList.length > 0 ? (
                      SelectInputRawOptionList.map((option, option_index) => (
                        <div
                          key={option_index}
                          className="col-12  form-label-checkbox-color "
                        >
                          <div className=" col-12 d-flex align-items-center">
                            <div className=" col-3 d-flex align-items-center">
                              <div>
                                <label className="custom-label d-flex align-items-center">
                                  Option {option_index + 1}
                                </label>
                              </div>
                            </div>
                            <div className="col-8">
                              <input
                                className="form-control form-control-sm"
                                value={SelectInputRawOptionList[option_index]}
                                id={option_index}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    SelectInputRawOptionList.splice(
                                      option_index + 1,
                                      0,
                                      ""
                                    );

                                    setTimeout(() => {
                                      let new_input = document.getElementById(
                                        option_index + 1
                                      );
                                      if (new_input) {
                                        new_input.focus();
                                      }
                                    }, 10);

                                    setChange(!Change);
                                  }
                                }}
                                onChange={(e) => {
                                  SelectInputRawOptionList[option_index] =
                                    e.target.value;

                                  setSelectInputRawOptionList([
                                    ...SelectInputRawOptionList,
                                  ]);
                                  // setChange(!Change);
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <Tooltip
                                arrow
                                placement="top"
                                title="Remove Option"
                              >
                                <IconButton component="label">
                                  <DeleteOutlineIcon
                                    className="pointer"
                                    htmlColor="red"
                                    onClick={() => {
                                      SelectInputRawOptionList.splice(
                                        option_index,
                                        1
                                      );
                                      // setSelectInputRawOptionList([
                                      //   ...SelectInputRawOptionList,
                                      // ]);
                                      setChange(!Change);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12">
                        <div className="d-flex justify-content-center">
                          <label className="custom-label">
                            No Options Available
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div
              style={
                SelectInputRawOptionList[SelectInputRawOptionList.length - 1] ==
                ""
                  ? {
                      pointerEvents: "none",
                      opacity: 0.3,
                    }
                  : {
                      pointerEvents: "auto",
                    }
              }
              className="d-flex justify-content-end"
            >
              <Tooltip arrow placement="top" title="Add Option">
                <IconButton component="label">
                  <AddCircleOutlineIcon
                    className="pointer"
                    htmlColor="black"
                    onClick={() => {
                      SelectInputRawOptionList.splice(
                        SelectInputRawOptionList.length,
                        0,
                        ""
                      );
                      setSelectInputRawOptionList([
                        ...SelectInputRawOptionList,
                      ]);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div> */}

                <div className=" col-12 ">
                  <div className="col d-flex justify-content-end">
                    {/* <Tooltip arrow placement="top" title="Add">
                  <IconButton component="label">
                    <Done
                      htmlColor="green"
                      className="pointer"
                      onClick={handleAddSelectInputDone}
                    />
                  </IconButton>
                </Tooltip> */}
                    <button
                      style={
                        SelectInputRawOptionList[
                          SelectInputRawOptionList.length - 1
                        ] == ""
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-secondary me-1"
                      onClick={() => {
                        SelectInputRawOptionList.splice(
                          SelectInputRawOptionList.length,
                          0,
                          ""
                        );
                        setChange(!Change);
                      }}
                    >
                      Add New Option
                    </button>
                    <button
                      style={
                        CheckInputsValid(
                          SelectInputRawOptionList,
                          SelectInputLabel
                        )
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-success me-1"
                      onClick={handleAddSelectInputDone}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}
            {NewInputType == "radio" && (
              <div className="m-2 mt-2 rounded border mb-2 p-2 mt-1 mx-1 formsetting-label-parent-div">
                <div className=" col-12 d-flex align-items-center">
                  <div className="col-3">
                    <label className="custom-label d-flex align-items-center">
                      Label{" "}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      value={RadioInputLabel}
                      className="form-control form-control-sm"
                      onChange={(e) => setRadioInputLabel(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-1 col-12 ">
                  {RadioInputRawOptionList.length > 0 ? (
                    RadioInputRawOptionList.map((option, option_index) => (
                      <div
                        key={option_index}
                        className="col-12  form-label-checkbox-color "
                      >
                        <div className=" col-12 d-flex align-items-center">
                          <div className=" col-3 d-flex align-items-center">
                            <div>
                              <label className="custom-label d-flex align-items-center">
                                Option {option_index + 1}
                              </label>
                            </div>
                          </div>
                          <div className="col-8">
                            <input
                              className="form-control form-control-sm"
                              value={RadioInputRawOptionList[option_index]}
                              id={option_index}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  RadioInputRawOptionList.splice(
                                    option_index + 1,
                                    0,
                                    ""
                                  );

                                  setTimeout(() => {
                                    let new_input = document.getElementById(
                                      option_index + 1
                                    );
                                    if (new_input) {
                                      new_input.focus();
                                    }
                                  }, 100);

                                  setChange(!Change);
                                }
                              }}
                              onChange={(e) => {
                                RadioInputRawOptionList[option_index] =
                                  e.target.value;

                                setRadioInputRawOptionList([
                                  ...RadioInputRawOptionList,
                                ]);
                                // setChange(!Change);
                              }}
                            />
                          </div>
                          <div className="col-1">
                            <Tooltip
                              arrow
                              placement="top"
                              title="Remove Option"
                            >
                              <IconButton component="label">
                                <DeleteOutlineIcon
                                  className="pointer"
                                  htmlColor="red"
                                  onClick={() => {
                                    RadioInputRawOptionList.splice(
                                      option_index,
                                      1
                                    );
                                    // setSelectInputRawOptionList([
                                    //   ...SelectInputRawOptionList,
                                    // ]);
                                    setChange(!Change);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <label className="custom-label">
                          No Options Available
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className=" col-12 ">
                  <div className="col d-flex justify-content-end">
                    <button
                      style={
                        RadioInputRawOptionList[
                          RadioInputRawOptionList.length - 1
                        ] == ""
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-secondary me-1"
                      onClick={() => {
                        RadioInputRawOptionList.splice(
                          RadioInputRawOptionList.length,
                          0,
                          ""
                        );
                        setChange(!Change);
                      }}
                    >
                      Add New Option
                    </button>
                    <button
                      style={
                        CheckInputsValid(
                          RadioInputRawOptionList,
                          RadioInputLabel
                        )
                          ? {
                              pointerEvents: "none",
                              opacity: 0.3,
                            }
                          : {
                              pointerEvents: "auto",
                            }
                      }
                      type="button"
                      className="btn btn-sm btn-success me-1"
                      onClick={handleAddRadioInputDone}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Divider />

      {CheckInputAvailable("select") && (
        <>
          <div className="col-12 px-3 py-3">
            <div className="custom-label">Dropdown Options</div>
            {renderSelectOptionList()}
          </div>

          <Divider />
        </>
      )}

      <div className="col-12 p-3">
        <span className="custom-label">Input Field Alignitems</span>
        {/* create 2 divs and each have col-6 even on 1 div od on other div */}
        <div className="row mb-3">
          <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
            <Droppable droppableId="doprable1">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {Children.map(
                    (child, index) =>
                      CheckButton(child) && (
                        <Draggable
                          draggable={false}
                          key={index}
                          draggableId={`dragable-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`${
                                snapshot.isDragging ? "shadow" : null
                              } row border m-1 mt-2  p-1 align-items-center column-block-list`}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? "white"
                                  : null,
                              }}
                            >
                              <div className="col-7 d-flex  justify-content-start">
                                <label>{RenderInputName(child)}</label>
                                {/* {child.getElementsByTagName("input")[0]?.dataset
                                  ?.name == "Checkbox" ? (
                                  <label className="custom-label mx-2">
                                    {RenderInputName(child)}
                                  </label>
                                ) : (
                                  <select
                                    className="w-100 form-control custom-label custom-icon-select"
                                    value={RenderInputName(child)}
                                    onChange={(e) =>
                                      handleChangeInputDatasetName(e, index)
                                    }
                                  >
                                    {InputList.map((input) =>
                                      renderSelectBox(input, child)
                                    )}
                                  </select>
                                )} */}
                              </div>
                              <div className="col d-flex justify-content-end">
                                <span>
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title="Move block up"
                                  >
                                    <ArrowUpwardIcon
                                      sx={{
                                        height: "18px",
                                        width: "18px",
                                      }}
                                      className="edit-able-section-actions-delete"
                                      onClick={() => handleMoveup(index)}
                                    />
                                  </Tooltip>

                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title="Move block down"
                                  >
                                    <ArrowDownwardIcon
                                      sx={{
                                        height: "18px",
                                        width: "18px",
                                      }}
                                      className="edit-able-section-actions-delete"
                                      onClick={() => handleMovedown(index)}
                                    />
                                  </Tooltip>

                                  {/* <Tooltip
                                  arrow
                                  placement="top"
                                  title="Duplicate block"
                                >
                                  <Icon
                                    icon="heroicons-outline:document-duplicate"
                                    width="16"
                                    height="16"
                                    className="edit-able-section-actions-delete"
                                    onClick={() => handleDuplicate(index)}
                                  />
                                </Tooltip> */}

                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title="Remove block"
                                  >
                                    <HighlightOffIcon
                                      sx={{
                                        height: "18px",
                                        width: "18px",
                                      }}
                                      className="edit-able-section-actions-delete"
                                      onClick={() => {
                                        setRemovingDetail(index);
                                        setRemoveInputConfirmation_Index(true);
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Typography sx={{ fontSize: 10 }}>
          Select Which Field to include in the Form
        </Typography>
      </div>
      <Divider />
    </div>
  );
}

export default FormCustomization;
