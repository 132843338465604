import React, { useEffect, useState, version } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import { makeStyles } from "@mui/styles";
import { Alert, LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { fontWeight } from "@mui/system";
import {
  _add_project_version,
  _get_project_version_list,
} from "../../../DAL/projects/projects";
import CustomConfirmation from "../../../components/CustomConfirmation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

// add version of project

export default function AddProjectVersion({
  reload,
  open,
  setOpen,
  Project_id,
}) {
  const navigate = useNavigate();
  const [SelectedVersion, setSelectedVersion] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    VersionName: "",
  });
  const [ProjectVersionList, setProjectVersionList] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const fetchAvailableProjectVersionList = async () => {
    const resp = await _get_project_version_list(Project_id);
    if (resp.code === 200) {
      setProjectVersionList(resp.Project);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }

    return;
  };
  const handleSubmitForm = async () => {
    setIsLoading(true);
    setShowAlert(false);

    const payload = {
      project_id: Project_id,

      version_name: inputs.VersionName,
    };

    const resp = await _add_project_version(payload);
    if (resp.code == 200) {
      setIsLoading(false);

      setOpen(false);
      enqueueSnackbar(resp.message, { variant: "success" });
      if (reload) {
        reload();
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const handleVerifyVersionCount = (e) => {
    e.preventDefault();
    if (ProjectVersionList.length >= 3) {
      setShowAlert(true);
    } else {
      handleSubmitForm();
    }
  };
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    fetchAvailableProjectVersionList();
    setInputs({ VersionName: "" });
  }, [open]);

  return (
    <div>
      <CustomConfirmation
        open={ShowAlert}
        setOpen={setShowAlert}
        handleAgree={handleSubmitForm}
        CancelAction={setOpen}
        title="You have reached maximum number of project. If you continue, it will remove your oldest project."
      />
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleVerifyVersionCount}>
          <Box sx={style} className="modal-theme">
            <div clo-12 className="text-end modalIcon">
              <CloseIcon onClick={handleClose} className="pointer" />
            </div>
            <Typography
              className="text-center mb-4"
              variant="h6"
              component="h2"
            >
              Add Project Version
            </Typography>
            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Version Name"
                variant="outlined"
                fullWidth
                size="small"
                name="VersionName"
                value={inputs.VersionName}
                onChange={handleChange}
                required={true}
              />
            </div>

            {/* <Divider /> */}
            <div className="col-12 mt-3">
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Version
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
