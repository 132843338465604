import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@iconify/react";
import { TwitterPicker } from "react-color";
import {
  colors,
  font_sizes_classes,
  font_weight_classes,
  google_font_family_classes,
  menu_bar_position_classes,
} from "../../../../../../ui_libs/Constants/constant";
import { Tooltip } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { FontWeightBar } from "../../../Right-Menu/components";
import MuiSelectClasses from "../../../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
function MenuStyles({}) {
  const {
    getTopMenuNavClasses,
    getSelectedSectionClass,
    updateTopMenuNavClasses,
    getTopMenuNavStyle,
    updateTopMenuNavStyle,
    updateSelectedSectionClass,
  } = useBuilder();

  const [menuStyles, setMenuStyles] = useState({
    color: "",
    fontSize: "",
    fontWeight: "",
    fontFamily: "",
    navbarPosition: "wb-navbar-scroll",
  });

  // const [menuPosition, setMenuPosition] = useState("wb-navbar-scroll");

  const handleChangeClass = (e, class_list) => {
    const name = e.target.name;
    const value = e.target.value;
    setMenuStyles({ ...menuStyles, [name]: value });
    updateTopMenuNavClasses({ value, class_list });
  };

  const handleChangeInClass = (name, value, class_list) => {
    setMenuStyles({ ...menuStyles, [name]: value });
    updateTopMenuNavClasses({ value, class_list });
  };

  const handleChangeStyle = (property, value) => {
    console.log({ [property]: value });
    setMenuStyles({ ...menuStyles, [property]: value });
    updateTopMenuNavStyle({ property, value });
  };
  const handleChangePosition = (key, value, class_list) => {
    setMenuStyles({ ...menuStyles, navbarPosition: value });
    // setMenuPosition(value);
    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
  };

  const handleResetTextColor = () => {};

  const handleResetFontSize = () => {};

  const getMenuStyles = () => {
    let data = {
      color: getTopMenuNavStyle({ property: "color" }),
      fontSize: getTopMenuNavClasses({ class_list: font_sizes_classes }),
      fontWeight: getTopMenuNavClasses({ class_list: font_weight_classes }),
      fontFamily: getTopMenuNavClasses({
        class_list: google_font_family_classes,
      }),
      navbarPosition: getSelectedSectionClass({
        class_list: menu_bar_position_classes,
        child: 0,
      }),
    };
    setMenuStyles(data);
  };

  const handleReset = (key, class_list) => {
    const value = "font-size-xs";
    setMenuStyles({ ...menuStyles, [key]: value });
    updateTopMenuNavClasses({ value, class_list });
  };

  useEffect(() => {
    getMenuStyles();
  }, []);

  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Menu Styles</Typography>
      </AccordionSummary>
      <AccordionDetails className="row">
        <div className="col-12">
          <div className="mb-3">
            <MuiSelectClasses
              label="family"
              handleChange={handleChangeInClass}
              data={google_font_family_classes}
              value={menuStyles.fontFamily}
            />
          </div>
        </div>
        <label className="form-label custom-label">Header Fixed on Top</label>
        <div className="col-12 mb-3">
          {menu_bar_position_classes.map((position, i) => (
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="menuPosition"
                id={i}
                checked={
                  menuStyles.navbarPosition
                    ? position.value === menuStyles.navbarPosition
                    : position.value === "wb-navbar-scroll"
                }
                onClick={() =>
                  handleChangePosition(
                    i,
                    position.value,
                    menu_bar_position_classes
                  )
                }
              />
              <label className="form-check-label pointer custom-label">
                {position.label}
              </label>
            </div>
          ))}
        </div>

        <div className="col-12 d-flex justify-content-between">
          <label className="form-label custom-label">Links Font Size</label>
          <Tooltip title="Reset menu font size" arrow placement="top">
            <span>
              <RotateLeftIcon
                className="pointer"
                onClick={() => handleReset("fontSize", font_sizes_classes)}
              />
            </span>
          </Tooltip>
        </div>
        <div className="col-12">
          <select
            className="w-100 form-control custom-icon-select"
            value={menuStyles.fontSize}
            name="fontSize"
            onChange={(e) => handleChangeClass(e, font_sizes_classes)}
          >
            <option value="">Reset</option>
            {font_sizes_classes.map((fs, i) => (
              <option key={i} value={fs.value}>
                {fs.label}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 mt-1">
          <FontWeightBar
            fontWeight={menuStyles.fontWeight}
            handleChangeInClass={handleChangeInClass}
            classesList={font_weight_classes}
          />
        </div>

        {/* Links color */}

        <div className="col-12 d-flex justify-content-between mt-2">
          <label className="form-label custom-label">Links Color</label>
          <Tooltip title="Reset menu text color" arrow placement="top">
            <span>
              <RotateLeftIcon
                className="pointer"
                onClick={(e) => {
                  updateTopMenuNavStyle({ property: "color", value: null });
                  getMenuStyles();
                }}
              />
            </span>
          </Tooltip>
        </div>
        <div className="col-12">
          <TwitterPicker
            colors={colors}
            width="100%"
            onChange={(e) => handleChangeStyle("color", e.hex)}
            color={menuStyles.color}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default MenuStyles;
