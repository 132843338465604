export const font_weight = [
  { value: "normal", label: "normal" },
  { value: "bold", label: "bold" },
  { value: "bolder", label: "bolder" },
  { value: "lighter", label: "lighter" },
  { value: "initial", label: "initial" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "300", label: "300" },
  { value: "400", label: "400" },
  { value: "500", label: "500" },
  { value: "600", label: "600" },
  { value: "700", label: "700" },
  { value: "800", label: "800" },
  { value: "900", label: "900" },
];
export const line_Height = [
  { value: "1", label: "1" },
  { value: "1.25", label: "1.25" },
  { value: "1.5", label: "1.5" },
  { value: "1.75", label: "1.75" },
  { value: "2", label: "2" },
  { value: "2.25", label: "2.25" },
  { value: "2.5", label: "2.5" },
  { value: "2.75", label: "2.75" },
  { value: "3", label: "3" },
  { value: "3.25", label: "3.25" },
  { value: "3.5", label: "3.5" },
  { value: "3.75", label: "3.75" },
  { value: "4", label: "4" },
];

export const color_array = [
  "#000000",
  "#FFFFFF",
  "#808080",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#EB9694",
];
export const sizeOptions = () => {
  const start = 1;
  const limit = 50;
  let option = [];
  for (let i = start; i <= limit; i++) {
    option.push({
      label: `${i}px`,
      value: `${(i / 16).toFixed(3)}rem`,
    });
  }
  return option;
};
