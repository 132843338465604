import { invokeApi } from "../../bl_libs/invokeApi";
import { source_base_uri } from "../../config/config";

export const _get_active_categories = (data) => {
  let requestObj = {
    path: "/api/category/list_category_active",
    base_url: source_base_uri,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
