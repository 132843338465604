import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {
  _save_user_design_html,
  _save_user_design,
} from "../../../../DAL/projects/projects";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};
// confirmation popup
export default function AddNewProjectPopup({ open, setOpen }) {
  const navigate = useNavigate();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    ProjectName: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    navigate("/add-project", {
      state: {
        Project_Name: inputs.ProjectName,
      },
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs({});
    setIsLoading(false);
  }, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style} className="modal-theme">
            <div clo-12 className="text-end modalIcon">
              <CloseIcon onClick={handleClose} className="pointer" />
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Add New Project
            </Typography>

            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Project Name"
                variant="outlined"
                fullWidth
                size="small"
                name="ProjectName"
                value={inputs.ProjectName}
                onChange={handleChange}
                required={true}
              />
            </div>

            <div className="col-12 mt-3">
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Project
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
