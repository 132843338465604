import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ColorLensSharpIcon from "@mui/icons-material/ColorLensSharp";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/lab/Skeleton";
import { s3builderSource } from "../../../../config/config";
import { createFilter } from "react-search-input";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { RightThemeMenu } from "..";
import { upperFirst } from "lodash";
import PageMenu from "../Page-Menu/PageMenu";
import Gallery from "../Gallery/Gallery";
import { ElementList } from "../../../../ui_libs/Constants/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    paddingTop: 0,
    paddingBottom: 100,
    transition: "0.5s ease-in-out",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: 700,
    color: "#555",
  },
  title: {
    paddingTop: 20,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 700,
    color: "#555",
  },
}));

function LeftMenu(props) {
  const {
    project_id,
    leftDrawerType,
    showSectionsInDrawer,
    showItemInDrawer,
    showSectionItemInDrawer,
    leaveHoverDrawer,
    handleTypeClick,
    leaveSectionsListDrawer,
    selectedlistItem,
    selectedMenu,
    getSectionsType,
    getSectionsList,
    isSectionListLoading,
    isMouseEnter,
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const { getAllSections, handleSectionOnSelect, leftMenu, RemoveRowBlock } =
    useBuilder();

  const [searchInput, setSearchInput] = React.useState("");
  const [searchFocus, setSearchFocus] = React.useState(false);

  // const [ElementList, setElementList] = React.useState([
  //   {
  //     title: "Text",
  //     icon: "fa-solid fa-paragraph",
  //     code: `<div id='drag'>
  //             <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
  //              or a typeface without relying on meaningful content</p>
  //           </div>`,
  //   },
  //   {
  //     title: "Heading",
  //     icon: "fa-sharp fa-solid fa-heading",
  //     code: `<div id='drag' >
  //             <h4>Lorem ipsum is a placeholder text commonly used</h4>
  //           </div>`,
  //   },
  //   {
  //     title: "Editor",
  //     icon: "fa-solid fa-file-contract",
  //     code: `<div id='drag' >
  //     <div class="wb-editor-block wp-pt-16 heading-style">
  //     <h4>Lorem ipsum</h4>
  //     <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
  //     or a typeface without relying on meaningful content
  //     </p>
  // </div>
  //     </div>
  //         `,
  //   },
  //   {
  //     title: "Button",
  //     icon: "fa-sharp fa-solid fa-toggle-off",
  //     code: `<div id='drag' >
  //     <button class="register-btn rounded border-0 w-100" type="submit" 
  //     style="background-color: var(--wb-website-theme-secondary-color) !important;">Button</button>       
  //     </div>`,
  //   },

  //   {
  //     title: "Image",
  //     icon: "fa fa-picture-o",
  //     code: `<div  id='drag' class="col-md-3 col-12" block-name="Block-1">
  //         <img width='70'   src="https://archive.org/download/placeholder-image/placeholder-image.jpg" 
  //         class="img-fluid" alt="" data-width="20" data-height="40">
  //         </div>
  //         `,
  //   },
  //   {
  //     title: "I-Frame",
  //     icon: "fa-solid fa-film",
  //     code: `<div id='drag' >
  //     <div class="wb-iframe">
  //     <img  width="560" height="315" src="https://cdn.icon-icons.com/icons2/2248/PNG/512/iframe_icon_136494.png" 
  //     class="img-fluid" alt="" data-width="20" data-height="40">
  //                   </div>  
  //     </div>
  //         `,
  //   },

  //   {
  //     title: "Layout",
  //     icon: "fa-sharp fa-solid fa-table-columns",
  //     code: 
  //   `<div id='drag' class="row wb-layout"  >
  //       <div class="col-6 d-flex justify-content-center align-items-center" style="min-height:10rem" >
  //         <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  //       </div>
  //       <div class="col-6 col-6 d-flex justify-content-center align-items-center" style="min-height:10rem">
  //         <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  //       </div>
  //     </div>
  //         `,
  //   },
  // ]);
  const [FilterList, setFilterList] = React.useState([...ElementList]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    // GetFilterElementList(e.target.value.toLowerCase());
  };
  const GetFilterElementList = (value) => {
    if (value.trim().length == 0) {
      setFilterList([...ElementList]);
      return;
    }
    setFilterList([
      ...ElementList.filter((item) => {
        if (item.title.toLowerCase().includes(value)) {
          return item;
        }
      }),
    ]);
  };

  const searchBlurControler = () => {
    if (searchInput.trim().length === 0) setSearchFocus(false);
  };

  const searchInputControler = () => {
    setSearchInput("");
    setSearchFocus(true);
  };
  const searchInputCloser = () => {
    setSearchInput("");
    setSearchFocus(false);
  };

  const handleSearchElemnt = (e) => {};

  const getFilteredList = getSectionsList().filter(
    createFilter(searchInput, ["name"])
  );

  const handleChooseSection = (section) => {
    handleSectionOnSelect(section, undefined, s3builderSource);
  };

  const handleClickHelpCenter = () => {
    navigate(`/helping-articles-categories`);
  };

  const handleClickContactUs = () => {
    navigate(`/support-tickets`);
  };

  useEffect(() => {
    setSearchInput("");
  }, [leftDrawerType]);

  if (leftMenu === "ALL") {
    return (
      <>
        {leftDrawerType === "closed" && (
          <div
            className={classes.root}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              key={"10212132asa8943"}
              onClick={() => {
                showItemInDrawer("Elements");
              }}
              className={
                selectedlistItem === "Elements"
                  ? "pointer border-bottom wb-menu-icon-selected"
                  : "pointer border-bottom wb-menu-icon"
              }
              style={{
                padding: "0 8px",
                fontSize: "12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                height: "42px",
              }}
            >
              <Tooltip title={"Elements"} placement="right" arrow>
                <AddCircleOutlineIcon style={{ size: 20 }} />
              </Tooltip>
              {isMouseEnter && <span className="ms-2  animation-box-LTR">Elements</span>}
            </div>
            <div
              key={"1021asasa21328943"}
              onClick={() => {
                showSectionItemInDrawer("Add Sections");
              }}
              className={
                selectedlistItem === "Add Sections"
                  ? "pointer border-bottom wb-menu-icon-selected"
                  : "pointer border-bottom wb-menu-icon"
              }
              style={{
                padding: "0 8px",
                fontSize: "12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                height: "42px",
              }}
            >
              <Tooltip title={"Add New Sections"} placement="right" arrow>
                <ViewDayIcon style={{ size: 20 }} />
              </Tooltip>
              {isMouseEnter && (
                <div
                  className="ms-2  animation-box-LTR"
                  style={{ transition: "ease-in-out 0.3s", width: "auto" }}
                >
                  Add New Sections
                </div>
              )}
            </div>
            <div
              key={"102121358943"}
              onClick={() => {
                showItemInDrawer("Pages and Sections");
              }}
              className={
                selectedlistItem === "Pages and Sections"
                  ? "pointer border-bottom wb-menu-icon-selected"
                  : "pointer border-bottom wb-menu-icon"
              }
              style={{
                padding: "0 8px",
                fontSize: "12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                height: "42px",
              }}
            >
              <Tooltip title={"Pages and Sections"} placement="right" arrow>
                <ViewCarouselIcon style={{ size: 20 }} />
              </Tooltip>
              {isMouseEnter && <span className="ms-2  animation-box-LTR">Pages and Sections</span>}
            </div>
            <div
              key={"102121358443"}
              onClick={() => {
                showItemInDrawer("Theme Setting");
              }}
              className={
                selectedlistItem === "Theme Setting"
                  ? "pointer border-bottom wb-menu-icon-selected"
                  : "pointer border-bottom wb-menu-icon"
              }
              style={{
                padding: "0 8px",
                fontSize: "12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                height: "42px",
              }}
            >
              <Tooltip title={"Theme Settings"} placement="right" arrow>
                <ColorLensSharpIcon style={{ size: 20 }} />
              </Tooltip>
              {isMouseEnter && <span className="ms-2  animation-box-LTR">Theme</span>}
            </div>
            <div
              key={"123121358443"}
              onClick={() => {
                showItemInDrawer("Gallery");
              }}
              className={
                selectedlistItem === "Gallery"
                  ? "pointer border-bottom wb-menu-icon-selected"
                  : "pointer border-bottom wb-menu-icon"
              }
              style={{
                padding: "0 8px",
                fontSize: "12px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                height: "42px",
              }}
            >
              <Tooltip title={"Gallery"} placement="right" arrow>
                <PermMediaIcon style={{ size: 20 }} />
              </Tooltip>
              {isMouseEnter && <span className="ms-2 animation-box-LTR">Gallery</span>}
            </div>
          </div>
        )}
        {/* ------------------------------------------- */}

        {/* Expand */}
        {leftDrawerType === "expand" && (
          <>
            <div
              className={`${classes.root}`}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50px",
                paddingRight: selectedlistItem === "Add Sections" ? "0" : "5px",
              }}
            >
              <div
                key={"102asa121328943"}
                onClick={() => {
                  showItemInDrawer("Elements");
                }}
                className={
                  selectedlistItem === "Elements"
                    ? "pointer border-bottom wb-menu-icon-selected"
                    : "pointer border-bottom wb-menu-icon"
                }
                style={{
                  padding: "0 8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  height: "42px",
                }}
              >
                <Tooltip title={"Elements"} placement="right" arrow>
                  <AddCircleOutlineIcon style={{ size: 20 }} />
                </Tooltip>
              </div>
              <div
                key={"1021213asas28943"}
                onClick={() => {
                  showSectionItemInDrawer("Add Sections");
                }}
                className={
                  selectedlistItem === "Add Sections"
                    ? "pointer border-bottom wb-menu-icon-selected"
                    : "pointer border-bottom wb-menu-icon"
                }
                style={{
                  padding: "0 8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  height: "42px",
                }}
              >
                <Tooltip title={"Add New Sections"} placement="right" arrow>
                  <ViewDayIcon style={{ size: 20 }} />
                </Tooltip>
              </div>
              <div
                key={"102121358943"}
                onClick={() => {
                  showItemInDrawer("Pages and Sections");
                }}
                className={
                  selectedlistItem === "Pages and Sections"
                    ? "pointer border-bottom wb-menu-icon-selected"
                    : "pointer border-bottom wb-menu-icon"
                }
                style={{
                  padding: "0 8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  height: "42px",
                }}
              >
                <Tooltip title={"Pages and Sections"} placement="right" arrow>
                  <ViewCarouselIcon style={{ size: 20 }} />
                </Tooltip>
              </div>
              <div
                key={"102121358443"}
                onClick={() => {
                  showItemInDrawer("Theme Setting");
                }}
                className={
                  selectedlistItem === "Theme Setting"
                    ? "pointer border-bottom wb-menu-icon-selected"
                    : "pointer border-bottom wb-menu-icon"
                }
                style={{
                  padding: "0 8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  height: "42px",
                }}
              >
                <Tooltip title={"Theme Settings"} placement="right" arrow>
                  <ColorLensSharpIcon style={{ size: 20 }} />
                </Tooltip>
              </div>
              <div
                key={"123121358443"}
                onClick={() => {
                  showItemInDrawer("Gallery");
                }}
                className={
                  selectedlistItem === "Gallery"
                    ? "pointer border-bottom wb-menu-icon-selected"
                    : "pointer border-bottom wb-menu-icon"
                }
                style={{
                  padding: "0 8px",
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  height: "42px",
                }}
              >
                <Tooltip title={"Gallery"} placement="right" arrow>
                  <PermMediaIcon style={{ size: 20 }} />
                </Tooltip>
              </div>
            </div>

            {selectedlistItem === "Elements" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
                className="transition"
              >
                <div
                  className="border-bottom"
                  style={{
                    height: "42px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 8px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  <div>{selectedlistItem}</div>
                  <div className="icon" onClick={leaveHoverDrawer}>
                    <i class="fa fa-xmark"></i>
                  </div>
                </div>

                <div
                  className="transition"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    padding: "10px 0px 60px",
                    justifyItems: "center",
                    overflowX: "hidden",
                    overflowY: "auto",
                    rowGap: "10px",
                  }}
                >
                  {FilterList.map((el, i) => (
                    <div
                      draggable
                      className="element-card section-item"
                      onDragStart={(e) => {
                        RemoveRowBlock(document);
                        let data = [
                          ...document.getElementsByClassName("Drag-Element"),
                        ];
                        for (let a = 0; a < data.length; a++) {
                          data[a].classList.remove("Drag-Element");
                        }
                        e.currentTarget.firstChild.classList.add(
                          "Drag-Element"
                        );
                      }}
                      key={i}
                    >
                      <div
                        style={{ visibility: "hidden", display: "none" }}
                        id={el.code}
                        dangerouslySetInnerHTML={{ __html: el.code }}
                      ></div>
                      <i class={el.icon}></i>
                      <div className="text-center custom-label pt-2">
                        <span className="">{el.title}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedlistItem === "Add Sections" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
                className="transition"
              >
                {!selectedMenu && (
                  <div
                    className="border-bottom"
                    style={{
                      height: "2.625rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0px 8px",
                      fontSize: "14px",
                      marginLeft: "5px",
                      fontWeight: "500",
                    }}
                  >
                    <div>{selectedlistItem}</div>
                    <div className="icon" onClick={leaveHoverDrawer}>
                      <i class="fa fa-xmark"></i>
                    </div>
                  </div>
                )}
                {selectedMenu && (
                  <div
                    className="border-bottom"
                    style={{
                      lineHeight: "41px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0px 8px 0px 0px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        width: "90%",
                      }}
                    >
                      <Tooltip title={"Sections List"} placement="top" arrow>
                        <div
                          className="icon"
                          onClick={leaveSectionsListDrawer}
                          style={{ margin: "0px 10px" }}
                        >
                          <i class="fa-solid fa-arrow-left"></i>
                        </div>
                      </Tooltip>
                      <div className="text-capitalize ms-2">
                        {selectedMenu.name}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        width: "20%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="search-box" style={{ zIndex: "10" }}>
                        {searchFocus && (
                          <input
                            className="input search-box-width searchInput"
                            type="text"
                            autoFocus
                            onBlur={searchBlurControler}
                            placeholder="Search..."
                            value={searchInput}
                            onChange={handleSearchChange}
                            style={{ position: "relative" }}
                          />
                        )}
                        {!searchFocus && (
                          <div className="icon" onClick={searchInputControler}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </div>
                        )}
                        {searchFocus && (
                          <div className="icon" onClick={searchInputCloser}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </div>
                        )}
                      </div>
                      <div className="icon" onClick={leaveHoverDrawer}>
                        <i class="fa fa-xmark"></i>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="transition"
                  style={{
                    padding: "0px 0px 60px",
                    display: "flex",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      style: "flex",
                      flexDirection: "column",
                      padding: "0px 5px 0px 0px",
                      width: selectedMenu ? "50px" : "100%",
                    }}
                  >
                    {getSectionsType().map((menu, i) => (
                      <div
                        key={menu._id}
                        onClick={() => showSectionsInDrawer(menu)}
                        className={
                          selectedMenu === menu
                            ? "pointer border-bottom d-flex wb-menu-icon-selected"
                            : "pointer border-bottom d-flex wb-menu-icon"
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: selectedMenu ? "50px" : "",
                          height: "35px",
                          padding: selectedMenu ? "0px 14px 0px 6px" : "0",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title={menu.name} placement="right" arrow>
                          <img
                            width="22"
                            height="22"
                            className="ms-2"
                            src={s3builderSource + "/" + menu.image}
                          ></img>
                        </Tooltip>
                        {!selectedMenu && (
                          <span className="ms-2 sidebar-title text-capitalize">
                            {menu.name}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                  {selectedMenu && (
                    <Droppable droppableId="list-sections">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            overflow: "auto",
                          }}
                        >
                          <div
                            style={{
                              paddingBottom: "2rem",
                              overflow: "auto",
                              padding: "0px 5px",
                            }}
                          >
                            {isSectionListLoading ? (
                              <div className="wb-left-menu-wrapper">
                                {[1, 2, 3, 4].map((val) => (
                                  <div
                                    key={val}
                                    className="mb-1 pointer"
                                    style={{ padding: 5, width: "100%" }}
                                  >
                                    <Skeleton
                                      variant="rect"
                                      width={200}
                                      height={118}
                                    />

                                    <div className="text-center">
                                      <span>
                                        {" "}
                                        <Skeleton
                                          variant="text"
                                          width={200}
                                          height={25}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="overflow-auto">
                                {getFilteredList.map((section, index) => {
                                  return (
                                    <Draggable
                                      key={section._id}
                                      draggableId={JSON.stringify(section)}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="mt-2 pointer border-bottom section-item"
                                          style={{ width: "100%" }}
                                          onClick={() =>
                                            handleChooseSection(section)
                                          }
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {section.image ? (
                                            <img
                                              height={105}
                                              width={300}
                                              src={
                                                s3builderSource +
                                                "/" +
                                                section.image
                                              }
                                              alt=""
                                            />
                                          ) : (
                                            <Skeleton
                                              variant="rect"
                                              width={"100%"}
                                              height={118}
                                            />
                                          )}
                                          <div className="text-center custom-label pb-1 pt-1">
                                            <span className="">
                                              {section.name}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  )}
                </div>
              </div>
            )}

            {selectedlistItem === "Pages and Sections" && (
              <PageMenu
                selectedlistItem={selectedlistItem}
                leaveHoverDrawer={leaveHoverDrawer}
              />
            )}

            {selectedlistItem === "Theme Setting" && (
              <RightThemeMenu
                selectedlistItem={selectedlistItem}
                leaveHoverDrawer={leaveHoverDrawer}
              />
            )}
            {selectedlistItem === "Gallery" && (
              <Gallery
                selectedlistItem={selectedlistItem}
                leaveHoverDrawer={leaveHoverDrawer}
              />
            )}
          </>
        )}
        {/* ------------------------------------------- */}
        {/* {leftDrawerType === "sections-list" &&
          getAllSections().map((section) => (
            <div
              className="selection-box ml-4"
              key={section.id}
              onClick={() => handleSectionOnSelect(section)}
            >
              {" "}
              <span className="selection-box-heading">
                {section.section_name}
              </span>
            </div>
          ))} */}
        {/* it will show when sections list are visible */}
        {/* {leftDrawerType === "expand" && (
          <div className="bottom-buttons pb-2 pe-2">
            <div className="pb-1 ms-1 pointer" onClick={handleClickHelpCenter}>
              <Tooltip title="Help Center" placement="right" arrow>
                <Icon fontSize="30" icon="bx:help-circle" />
              </Tooltip>
            </div>
            <div className="pb-1 ms-1 pointer" onClick={handleClickContactUs}>
              <Tooltip title="Contact Support" placement="right" arrow>
                <Icon fontSize="30" icon="bx:support" />
              </Tooltip>
            </div>
          </div>
        )} */}
        {/* it will show when when mouse not in the drawer but sections are not visible */}
        {/* {leftDrawerType === "closed" && !isMouseEnter && (
          <div className="bottom-buttons pb-2 pe-2">
            <div
              className="w-100 pb-1 ms-1 pointer"
              onClick={handleClickHelpCenter}
            >
              <Icon fontSize="30" icon="bx:help-circle" />
            </div>
            <div
              className="w-100 pb-1 ms-1 pointer"
              onClick={handleClickContactUs}
            >
              <Icon fontSize="30" icon="bx:support" />
            </div>
          </div>
        )} */}
        {/* it will show when when mouse in the drawer but sections are not visible */}
        {/* {leftDrawerType === "closed" && isMouseEnter && (
          <div className="bottom-buttons pb-2 pe-2">
            <div className="pb-1 ms-1 pointer" onClick={handleClickHelpCenter}>
              <Icon fontSize="30" icon="bx:help-circle" />{" "}
              <Label className="pointer" variant="ghost" color="info">
                Help Center
              </Label>
            </div>
            <div className="pb-1 ms-1 pointer" onClick={handleClickContactUs}>
              <Icon fontSize="30" icon="bx:support" />
              <Label className="pointer ms-1" variant="ghost" color="info">
                Contact Support
              </Label>
            </div>
          </div>
        )} */}
        {/* {leftDrawerType === "closed" && isMouseEnter && (
          <div className="bottom-buttons w-100 pe-2">
            <div className="w-100 pb-1">
              <Label
                onClick={handleClickHelpCenter}
                className="w-100 pointer"
                variant="ghost"
                color="info"
              >
                Help Center
              </Label>
            </div>
            <div className="w-100">
              <Label
                onClick={handleClickContactUs}
                className="w-100 pointer"
                variant="ghost"
                color="info"
              >
                Contact Us
              </Label>
            </div>
          </div>
        )} */}
      </>
    );
  }

  return <div className={classes.root}>Invalid leftMenu value</div>;
}

export default LeftMenu;
