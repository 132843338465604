import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { _change_password } from "../../DAL/login/login";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function ChangePassword({ open, setOpen }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    password: "",
    repeatPassword: "",
    oldPassword: "",
  });

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (inputs.password !== inputs.repeatPassword) {
      enqueueSnackbar("Password and confirm password do not match", {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);
    const postData = {
      // old_password: inputs.oldPassword,
      new_password: inputs.password,
      confirm_password: inputs.repeatPassword,
    };

    const result = await _change_password(postData);
    console.log(result, "result");
    if (result.code === 200) {
      setInputs({});
      setIsLoading(false);
      handleClose();
      enqueueSnackbar("Password Changed Successfully", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs({});
  }, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlePasswordSubmit}>
          <Box sx={style} className="modal-theme">
            <div clo-12 className="text-end modalIcon">
              <CloseIcon onClick={handleClose} className="pointer" />
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Change Password
            </Typography>
            {/* <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Old Password"
                variant="outlined"
                fullWidth
                size="small"
                autoFocus
                name="oldPassword"
                value={inputs.oldPassword}
                onChange={handleChange}
                required={true}
              />
            </div> */}
            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="New Password"
                // type="password"
                variant="outlined"
                fullWidth
                size="small"
                name="password"
                value={inputs.password}
                onChange={handleChange}
                required={true}
              />
            </div>

            <div className="col-12 mt-3">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Repeat New Password"
                variant="outlined"
                fullWidth
                // type="password"
                size="small"
                name="repeatPassword"
                value={inputs.repeatPassword}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-12 mt-3">
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
