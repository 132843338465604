import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { margin_classes } from "../../../../../../ui_libs/Constants/constant";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Tooltip } from "@mui/material";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";

function MarginSpacing() {
  const {
    updateSelectedElementClass,
    getSelectedElementClassByType,
    updateSelectedElementSpacing,
    getSelectedElementStyleByProperty } =
    useBuilder();
  // predefined classes
  const [Reset, setReset] = useState(false)

  const [margins, setMargins] = useState({
    top: "",
    bottom: "",
    left: "",
    right: "",
  });

  const [custom_margins, setcustomMargins] = useState({
    top: "",
    bottom: "",
    left: "",
    right: "",
  });

  const handleResetSpacing = () => {
    updateSelectedElementClass({
      class_list: margin_classes("top"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: margin_classes("bottom"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: margin_classes("left"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: margin_classes("right"),
      value: "",
    });

    // RESET CUSTOM MARGINS
    updateSelectedElementSpacing("margin", ["top"], '');
    updateSelectedElementSpacing("margin", ["bottom"], '');
    updateSelectedElementSpacing("margin", ["left"], '');
    updateSelectedElementSpacing("margin", ["right"], '');

    setReset(!Reset)

  };

  useEffect(() => {

  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <label className="form-label custom-label">Margin</label>
        </div>
        <div className="col d-flex justify-content-end">
          <Tooltip title="Reset Margins" arrow placement="top">
            <span>
              <RotateLeftIcon
                className="pointer"
                onClick={() => handleResetSpacing()}
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="row">
        {/* top */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type='margin' dimention='margin-top' label='top' />

        {/* bottom */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type='margin' dimention='margin-bottom' label='bottom' />



        {/* left */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type='margin' dimention='margin-left' label='left' />


        {/* right */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={margin_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type='margin' dimention='margin-right' label='right' />

      </div>
    </>
  );
}

export default MarginSpacing;
