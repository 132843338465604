import { invokeApi } from "../../bl_libs/invokeApi";

export const _upload_picture_to_user_gallery = (files) => {
  let requestObj = {
    path: "/api/customer/upload_picture_to_user_gallery",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  requestObj["postData"] = files;
  return invokeApi(requestObj);
};

export const get_user_gallery = () => {
  let requestObj = {
    path: "/api/customer/get_user_gallery",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_picture = (data) => {
  let requestObj = {
    path: "/api/customer/delete_picture",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
