import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {
  _save_user_design_html,
  _save_user_design,
} from "../../../../DAL/projects/projects";
import { makeStyles } from "@mui/styles";
import { Alert, LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import {
  _create_user_design_page,
  _use_page_version,
  _get_version_count,
  _save_page_version,
} from "../../../../DAL/projects-pages/projectspages";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import LimitationModal from "../../../../components/LimitationModal/LimitationModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function AddNewVersion({
  reload,
  open,
  setOpen,
  Page_id,
  SaveHTML,
  version,
  label = "Add Page Version",
}) {
  const { handleSaveTemplateToServer, customerSubscriptionDetail } =
    useBuilder();
  const navigate = useNavigate();
  const [SelectedVersion, setSelectedVersion] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    VersionName: "",
  });

  const [limitModal, setLimitModal] = useState(false);

  const handleCheckVersion = async (e) => {
    e.preventDefault();
    if (
      PageVersionList.length <
        customerSubscriptionDetail.plan_id.pages_version_count ||
      SelectedVersion !== ""
    ) {
      handleSubmitForm(e);
    } else {
      setLimitModal(true);
      handleClose();
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    // if (PageVersionList.length >= 3 && SelectedVersion == "") {
    //   enqueueSnackbar("Already Have 3 Version Please Delete 1", {
    //     variant: "error",
    //   });
    //   return;
    // }
    setIsLoading(true);
    if (SaveHTML) {
      const save_page_resp = await handleSaveTemplateToServer({
        preview: false,
      });
      if (!save_page_resp) {
        setIsLoading(false);
        return;
      }
    }

    const payload = {
      page_id: Page_id,

      version_name: inputs.VersionName,
    };

    if (SelectedVersion) {
      payload.version_id = SelectedVersion._id;
      payload.order = SelectedVersion.order;
    }

    const resp = await _save_page_version(payload);
    if (resp.code == 200) {
      setIsLoading(false);
      setOpen(false);
      enqueueSnackbar(resp.message, { variant: "success" });
      if (reload) {
        reload();
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const [PageVersionList, setPageVersionList] = useState([]);
  const GetPageVersionList = async () => {
    const resp = await _get_version_count(Page_id);
    console.log(resp, "GetPageVersionList");
    if (resp.code == 200) {
      resp.Page.Pages_Version.map((page_version) => {
        if (page_version._id == version) {
          setInputs({ ...inputs, ["VersionName"]: page_version.version_name });
          setSelectedVersion({ ...page_version });
        }
      });
      setPageVersionList([...resp.Page.Pages_Version]);
    }
  };
  useEffect(() => {
    setInputs({ VersionName: "" });
    setSelectedVersion("");
    if (open) {
      GetPageVersionList();
    }
  }, [open]);

  return (
    <>
      <div>
        <Modal
          disableTypography="true"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleCheckVersion}>
            <Box sx={style} className="modal-theme">
              <div clo-12 className="text-end modalIcon">
                <CloseIcon onClick={handleClose} className="pointer" />
              </div>
              <Typography
                className="text-center mb-4"
                variant="h6"
                component="h2"
              >
                {label}
              </Typography>
              <div className="col-12 mt-3">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  // helperText={
                  //   PageVersionList &&
                  //   PageVersionList.length > 0 &&
                  //   !SelectedVersion &&
                  //   "Reached maximum number of versions. delete any version or chose form version list "
                  // }
                  id="outlined-basic"
                  label="Version Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="VersionName"
                  value={inputs.VersionName}
                  onChange={handleChange}
                  required={true}
                />
                {/* {PageVersionList &&
                PageVersionList.length >= 3 &&
                !SelectedVersion && (
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 10,
                      color: `red`,
                    }}
                  >
                    Reached maximum number of versions. delete any version or
                    chose form version list
                  </Typography>
                )} */}
              </div>
              {PageVersionList && PageVersionList.length > 0 && (
                <Typography
                  style={{ marginTop: 8, fontWeight: "bold", fontSize: 12 }}
                >
                  Select from Available version List to replace with new version
                </Typography>
              )}
              {PageVersionList && (
                <div className="row   px-2">
                  {PageVersionList.map((PageVersion) => (
                    <div className="col-6 p-1  ">
                      <div
                        className="pointer card p-2 border d-flex justify-content-center"
                        onClick={() => {
                          if (SelectedVersion._id == PageVersion._id) {
                            setSelectedVersion("");
                            setInputs({
                              ...inputs,
                              ["VersionName"]: "",
                            });
                          } else {
                            setSelectedVersion(PageVersion);
                            setInputs({
                              ...inputs,
                              ["VersionName"]: PageVersion.version_name,
                            });
                          }
                        }}
                        style={
                          SelectedVersion._id == PageVersion._id
                            ? {
                                backgroundColor: "silver",
                              }
                            : {
                                backgroundColor: "white",
                              }
                        }
                      >
                        <div class="main-dashboard-website-name text-capitalize display-3 ">
                          {PageVersion.version_name}
                          <span class="float-end"></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {PageVersionList && PageVersionList.length > 0 && <Divider />}
              <div className="col-12 mt-3">
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Add Version
                </LoadingButton>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
      <LimitationModal
        open={limitModal}
        setOpen={setLimitModal}
        limitationModalTitle={"version"}
      />
    </>
  );
}
