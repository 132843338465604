import React, { useEffect, useRef } from 'react'
import { website_placeholder_img } from '../../../assets'
import { app_type, s3baseUrl, s3builderSource } from '../../../config/config'
import { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSnackbar } from 'notistack'
import { IconButton, Typography } from '@mui/material'

import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { set } from 'lodash'
import { stubFalse } from 'lodash'
import {
  _get_project_version_list,
  _remove_project_version,
  _update_project_version_name,
  _use_project_version,
} from '../../../DAL/projects/projects'
import CustomConfirmation from '../../../components/CustomConfirmation'
import EditVersionName from '../../Version-List/component/EditVersionName'
import CustomPopover from '../../../components/CustomPopover'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment/moment'

function ProjectVersionList(props) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [ProjectVersion, setProjectVersion] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isUseVesion, setisUseVesion] = useState(false)
  const [
    RemoveProjectVersionDailogue,
    setRemoveProjectVersionDailogue,
  ] = useState(false)
  const [TargetVersionID, setTargetVersionID] = useState('')
  const [OpenEditVersionName, setOpenEditVersionName] = useState(false)
  const [TargetVersion, setTargetVersion] = useState('')
  const fetchAvailableProjectVersionList = async () => {
    setisUseVesion(false)
    const resp = await _get_project_version_list(params.project_id)
    console.log(resp, 'resprespresprespresprespresprespresp')
    if (resp.code === 200) {
      setProjectVersion(resp.Project)
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
    setisLoading(false)
    return
  }

  const handlePreviewVersion = (Version) => {
    window.open(
      `${s3baseUrl}/${Version._id}/${Version.pages[0].page_slug}.html`,
    )
  }

  const handleUseVersion = async (Version) => {
    setisUseVesion(true)

    const formData = new FormData()
    formData.append('project_id', params.project_id)
    formData.append('version_id', Version._id)
    formData.append('version_name', Version.version_name)

    const user_version_res = await _use_project_version(formData)

    if (user_version_res.code === 200) {
      if (location.state.project) {
        if (location.state.project.pages.length > 0) {
          setisUseVesion(false)
          fetchAvailableProjectVersionList()
          enqueueSnackbar('Project Version Roll back Successfully', {
            variant: 'success',
          })
          navigate(
            `/builder/${location.state.project.pages[0].project}/${location.state.project.pages[0]._id}`,
          )
        }
      }
    } else {
      setisLoading(false)
      setisUseVesion(false)
      enqueueSnackbar(user_version_res.message, { variant: 'error' })
    }
    return
  }

  const handleremoveVersion = (ProjectVersion) => {
    setTargetVersionID(ProjectVersion._id)
    setRemoveProjectVersionDailogue(true)
  }

  const handleRemoveVersion = async (_multiple_hit) => {
    if (_multiple_hit) {
      setRemoveProjectVersionDailogue(false)
      setisUseVesion(true)

      const del_res = await _remove_project_version(TargetVersionID)
      console.log(del_res, 'response from delete version')

      if (del_res.code === 200) {
        setisUseVesion(false)

        fetchAvailableProjectVersionList()
        enqueueSnackbar(del_res.message, {
          variant: 'success',
        })
      } else {
        setisUseVesion(false)
        enqueueSnackbar(del_res.message, { variant: 'error' })
      }
      return
    }
  }
  const handleEditVersionName = async (version_name, setloader) => {
    const payload = {
      project_id: params.project_id,
      version_id: TargetVersion._id,
      version_name: version_name,
    }

    const resp = await _update_project_version_name(payload)
    if (resp.code == 200) {
      setloader(false)
      setOpenEditVersionName(false)
      fetchAvailableProjectVersionList()
      enqueueSnackbar(resp.message, { variant: 'success' })
    } else {
      setloader(false)

      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }
  const handleGetVersionName = (version) => {
    setTargetVersion(version)
    setOpenEditVersionName(true)
  }
  const MENU_OPTIONS = (row, index) => {
    return [
      {
        label: 'Preview Version',
        icon: 'carbon:view',
        handleClick: handlePreviewVersion,
      },
      {
        label: 'Edit Version Name',
        icon: 'ant-design:edit-twotone',
        handleClick: handleGetVersionName,
      },
      {
        label: 'Use Version',
        icon: 'material-symbols:history',
        handleClick: handleUseVersion,
      },
      {
        label: 'Delete Version',
        icon: 'ant-design:delete-twotone',
        handleClick: handleremoveVersion,
      },
    ]
  }

  useEffect(() => {
    setisLoading(true)
    fetchAvailableProjectVersionList()
  }, [])

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      {isUseVesion && (
        <div className="Loader">
          <Loader />
        </div>
      )}
      {OpenEditVersionName && (
        <EditVersionName
          open={OpenEditVersionName}
          setOpen={setOpenEditVersionName}
          onSubmit={handleEditVersionName}
          Version={TargetVersion}
        />
      )}
      <CustomConfirmation
        open={RemoveProjectVersionDailogue}
        setOpen={setRemoveProjectVersionDailogue}
        handleAgree={handleRemoveVersion}
        title="Are sure you want to remove this version?"
      />
      <div
        style={isUseVesion ? { opacity: 0.3, pointerEvents: 'none' } : {}}
        className="container "
      >
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1)
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className=" d-flex  align-items-center justify-content-center ">
            <div>
              <h5 className="pt-2">Available Versions of Project</h5>
            </div>
          </div>

          <div className="col-12 mt-2 mb-5">
            <div className="row">
              {ProjectVersion.length == 0 && (
                <div className="d-flex justify-content-center ">
                  <h6 style={{ marginTop: 100 }}>No version saved</h6>
                </div>
              )}
              {ProjectVersion.map((Version, index) => (
                <div
                  id="p1"
                  key={Version._id}
                  className="col-12 col-sm-6 col-lg-4 mt-4 page_card p-2 version_parent_div p2"
                >
                  <div className="page_menu_option_for_version">
                    <CustomPopover
                      color={'#ffff'}
                      data={Version}
                      menu={MENU_OPTIONS(Version, index)}
                    />{' '}
                  </div>
                  <iframe
                    scrolling="no"
                    style={{ pointerEvents: 'none' }}
                    width="100%"
                    height="100%"
                    className="version_card"
                    src={`${s3baseUrl}/${Version._id}/${Version.pages[0].page_slug}.html`}
                    title="description"
                  ></iframe>
                  <div class="px-2">
                    <div class="main-dashboard-website-name text-capitalize">
                      {/* {Version.version_name} */}
                      {moment(Version.createdAt).format('LLLL')}
                      <span class="float-end"></span>
                    </div>
                  </div>{' '}
                  {/* <div className="border position-relative">
                    <div className="page_menu_option">
                      <CustomPopover
                        color={'#ffff'}
                        data={Page}
                        menu={MENU_OPTIONS(Page, index)}
                      />{' '}
                    </div>
                    <div>
                      <img
                        className="main-dashboard-website-placeholder"
                        onError={(e) => {
                          e.target.src = website_placeholder_img
                        }}
                        src={
                          Page.image
                            ? s3baseUrl + '/' + Page.pages[0].image
                            : website_placeholder_img
                        }
                        // src={website_placeholder_img}
                        width="100%"
                        height="140%"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                        }}
                        alt=""
                      />
                      <div className="main-dashboard-website-action rounded-card">
                        <button
                          onClick={() => handleUseVersion(Page)}
                          className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                        >
                          Use Version
                        </button>

                        <button
                          onClick={() => handlePreviewPage(Page)}
                          className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                        >
                          Preview Version
                        </button>
                      </div>
                      <div class="p-2">
                        <div class="main-dashboard-website-name text-capitalize">
                          {Page.version_name}
                          <span class="float-end"></span>
                        </div>
                      </div>{' '}
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectVersionList
