import { filter, forEach } from 'lodash'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import plusFill from '@iconify/icons-eva/plus-fill'
import Chip from '@mui/material/Chip'
import Label from '../../../components/Label'
import {
  Link,
  Link as RouterLink,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import closeFill from '@iconify/icons-eva/close-fill'

// material

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TextField,
  Typography,
  Tooltip,
  TableContainer,
  Divider,
  TablePagination,
  IconButton,
} from '@mui/material'

import Page from '../../../components/Page'
import Scrollbar from '../../../components/Scrollbar'
import SearchNotFound from '../../../components/SearchNotFound'
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from '../../../components/_dashboard/user'
//
import USERLIST from '../../../_mocks_/user'
import { useSnackbar } from 'notistack'
import CustomPopover from '../../../components/CustomPopover'
import CustomConfirmation from '../../../components/CustomConfirmation'
import { _delete_project_subscriber_by_email } from '../../../DAL/projects/projects'
import moment from 'moment'
import { get_short_string } from '../../../utils/utils'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '', label: '#', alignRight: false },
  { id: 'first_name', label: 'First Name', alignRight: false },
  { id: 'last_name', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'createdAt', label: 'Subscribed At', alignRight: false },
  { id: 'Email Status', label: 'Email Status', alignRight: false },
  { id: '', label: '', alignRight: true },
]

const TABLE_HEAD_WITH_MESSAGE = [
  { id: '', label: '#', alignRight: false },
  { id: 'first_name', label: 'First Name', alignRight: false },
  { id: 'last_name', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'message', label: 'Message', alignRight: false },
  { id: 'createdAt', label: 'Subscribed At', alignRight: false },
  { id: 'Email Status', label: 'Email Status', alignRight: false },
  { id: '', label: '', alignRight: true },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
    })
  }
  return stabilizedThis.map((el) => el[0])
}

export default function CustomerListing({ data, fetchProjectSubscribers }) {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [showMessageColum, setShowMessageColum] = useState(false)
  const [SubscriberViewModalOpen, setSubscriberViewModalOpen] = useState(false)
  const [ViewSubscriberData, setViewSubscriberData] = useState([])

  const getTableHead = () => {
    if (showMessageColum) {
      return TABLE_HEAD_WITH_MESSAGE
    } else {
      return TABLE_HEAD
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const deleteSubscriberByEmail = async (id) => {
    const project_id = params?.project_id

    const postData = {
      project_id,
      subscriber_id:id,
    }

    const resp = await _delete_project_subscriber_by_email(postData)
    if (resp.code === 200) {
      setOpenDelete(false)
      fetchProjectSubscribers()
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName,
  )

  const isUserNotFound = filteredUsers.length === 0

  const handleDeleteSubscriber = () => {
    deleteSubscriberByEmail(selectedRow._id)
  }
  const handleDeleteConfirmation = (row) => {
    setSelectedRow(row)
    setOpenDelete(true)
  }
  const handleViewSubscriberDetail = (row) => {
    setViewSubscriberData(row)
    setSubscriberViewModalOpen(true)
  }
  const MENU_OPTIONS = [
    {
      label: 'View Details',
      icon: 'carbon:view',
      handleClick: handleViewSubscriberDetail,
    },
    {
      label: 'Delete',
      icon: 'ant-design:delete-twotone',
      handleClick: handleDeleteConfirmation,
    },
  ]

  // if (isLoading) {
  //   return <Loader />;
  // }

  useEffect(() => {
    let show_message = false

    data.forEach((row) => {
      if (row.message) {
        show_message = true
      }
    })

    setShowMessageColum(show_message)
  }, [])

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        open={SubscriberViewModalOpen}
        onClose={() => setSubscriberViewModalOpen(false)}
        PaperProps={{
          sx: { width: 350, border: 'none', overflow: 'hidden' },
        }}
      >
        <Scrollbar>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <Typography variant="h5" sx={{ ml: 1 }}>
              Subscriber Detail
            </Typography>
            <IconButton>
              <Icon
                icon={closeFill}
                onClick={() => setSubscriberViewModalOpen(false)}
                width={25}
                height={25}
              />
            </IconButton>
          </Stack>
          <Divider />

          {ViewSubscriberData &&
          ViewSubscriberData.custom_fields &&
          ViewSubscriberData.custom_fields.length > 0 ? (
            ViewSubscriberData.custom_fields.map((item, index) => (
              <div
                key={index}
                className="col-12 border-bottom mb-10 p-2 mx-1 rounded form-label-checkbox-color"
              >
                <div className=" col-12 d-flex align-items-center">
                  <div className=" col-4 d-flex align-items-center">
                    <div>
                      <label className="px-2 custom-label d-flex align-items-center fw-bold">
                        {item.label ? item.label : item.key ? item.key : 'N/A'}
                      </label>
                    </div>
                  </div>
                  <div className="col-8">
                    <label className="custom-label d-flex align-items-center">
                      {item.value == true && 'True'}
                      {item.value == false && 'False'}
                      {item.value != true && item.value != false && item.value}
                    </label>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <Stack sx={{ ml: 3, mt: 1 }} direction="row">
                <Typography sx={{ mr: 1 }} variant="h6">
                  Name :
                </Typography>
                <h6 className="pt-1 text-capitalize">
                  {ViewSubscriberData.first_name +
                    ' ' +
                    ViewSubscriberData.last_name}
                </h6>
              </Stack>

              <Stack sx={{ ml: 3, mt: 1 }} direction="row">
                <Typography sx={{ mr: 1 }} variant="h6">
                  Email :
                </Typography>
                <h6 className="pt-1 text-capitalize">
                  {ViewSubscriberData.email}
                </h6>
              </Stack>

              <Stack sx={{ ml: 3, pr: 5, mt: 1 }} direction="row">
                <Typography sx={{ mr: 1 }} variant="h6">
                  Phone :
                </Typography>
                <h6 className="pt-1 text-capitalize">
                  {ViewSubscriberData.phone}
                </h6>
              </Stack>

              <Stack sx={{ ml: 3, pr: 5, mt: 3 }}>
                <Typography sx={{ mr: 1 }} variant="h6">
                  Message :
                </Typography>
                {ViewSubscriberData.message ? (
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    {ViewSubscriberData.message}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    N/A
                  </Typography>
                )}
              </Stack>

              <Stack sx={{ ml: 3, pr: 5, mt: 5 }}>
                <Typography variant="h6">Created At :</Typography>
                <Typography variant="subtitle1">
                  {moment(ViewSubscriberData.createdAt).format('LLL')}
                </Typography>
              </Stack>
            </>
          )}
        </Scrollbar>
      </Drawer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDeleteSubscriber}
        title={`Are sure you want to remove that subscriber? "${selectedRow.email}"`}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h4" gutterBottom>
          Subscribers &nbsp;:&nbsp;&nbsp;
          {location.state.Project.project_name.toUpperCase()}
        </Typography>
        {/* <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
            Add User
          </Button> */}
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          data={data}
        />

        <>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={getTableHead()}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      email,
                      first_name,
                      last_name,
                      phone,
                      message,
                      createdAt,
                      email_status,
                    } = row

                    return (
                      <TableRow hover key={i} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{first_name}</TableCell>
                        <TableCell>{last_name}</TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{phone ? phone : '-'}</TableCell>

                        {showMessageColum && (
                          <TableCell>
                            {message ? (
                              <Tooltip title={message}>
                                <span>{get_short_string(message)}</span>
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )}

                        <TableCell>{moment(createdAt).format('LLL')}</TableCell>
                        <TableCell>
                          {
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color={email_status ? 'info' : 'error'}
                            >
                              {email_status ? 'Yes' : 'No'}
                            </Label>
                          }
                        </TableCell>
                        <TableCell>
                          <CustomPopover data={row} menu={MENU_OPTIONS} />{' '}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>

        {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </Card>
    </>
  )
}
