import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {
  _set_is_dev,
  _set_token,
  _set_user,
} from "../../DAL/localStorage/localStorage";
import {
  _get_user_profile,
  _get_user_profile_by_custom_token,
} from "../../DAL/login/login";
import {
  _get_user_created_designs,
  _get_user_design_details,
} from "../../DAL/projects/projects";

function ClientPortalController(props) {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const pushToBuilder = async () => {
    navigate(`/builder/${params.project_id}/${params.page_id}`);
  };

  const check_builder_url = () => {
    sessionStorage.clear();
    sessionStorage.clear();
    const token = params?.token;

    if (token) {
      fetchProfile(token);
      return;
    }
  };

  const fetchProfile = async (token) => {
    const resp = await _get_user_profile_by_custom_token(token);

    const user = { ...resp.Dashboard.user, ...resp.Dashboard.user_detail };

    if (resp.code === 200) {
      _set_token(token);
      _set_user(user);
      sessionStorage.setItem("client_base_url", document.referrer);
      //   sessionStorage.setItem("client_end_point", JSON.parse(params.url));

      pushToBuilder();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    check_builder_url();
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}

export default ClientPortalController;
