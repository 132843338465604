import { invokeApi } from "../../bl_libs/invokeApi";

export const _s3upload = (folder, file) => {
  let requestObj = {
    path: "/api/app_api/upload_image_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  let formData = new FormData();
  formData.append("project_name", folder);
  formData.append("upload_file", file);
  requestObj["postData"] = formData;
  return invokeApi(requestObj);
};

export const _upload_file_on_s3 = async (data) => {
  let requestObj = {
    path: `/api/app_api/upload_image_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _s3upload_with_name = (folder, file, file_name) => {
  let requestObj = {
    path: "/api/app_api/upload_image_file_on_s3",
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  let formData = new FormData();
  formData.append("project_name", folder);
  formData.append("upload_file", file);
  formData.append("file_name", file_name);
  requestObj["postData"] = formData;
  return invokeApi(requestObj);
};
