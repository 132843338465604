import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { makeStyles } from '@mui/styles'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Tooltip from '@mui/material/Tooltip'
const useStyles = makeStyles({
  btn: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
const InputTransformBar = ({
  ButtonTransform,
  handleChangeInClass,
  classesList,
  getFormInputStyle,
  updateFormInputBorderRadiusStyleByProperty,
  GetInputList,
  Form,
}) => {
  const styleClasses = useStyles()

  const [borderRadius, setBorderRadius] = useState('0')

  const getValue = () => {
    let border_radius = getFormInputStyle({
      property: 'border-radius',
    })

    if (border_radius) {
      border_radius = border_radius.replace('px', '')
    } else {
      border_radius = 0
    }

    setBorderRadius(border_radius)
  }

  const handleChangeBorderRadius = (_value) => {
    let value = _value ? _value + 'px' : 0
    let list = [...GetInputList(), ...Form.getElementsByTagName('button')]
    list.map((element) => {
      element.style.setProperty('border-radius', value, 'important')
    })

    setBorderRadius(_value)
  }

  useEffect(() => {
    getValue()
  }, [ButtonTransform])
  return (
    <>
      <div class="mb-2">
        <div className="font-size-xsm mt-2 d-flex">
          <Tooltip title={'Reset'} placement="top" arrow>
            <span
              className={
                ButtonTransform ? 'wb-icons-box' : 'wb-icons-box-active'
              }
              onClick={() => {
                handleChangeInClass('imageTransform', null, classesList)
                updateFormInputBorderRadiusStyleByProperty({
                  property: 'border-radius',
                  value: null,
                })
              }}
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls, i) => (
            <Tooltip key={i} title={cls.label} placement="top" arrow>
              <span
                className={
                  ButtonTransform === cls.value
                    ? 'wb-icons-box-active'
                    : 'wb-icons-box'
                }
                onClick={() => {
                  console.log()

                  if (classesList[i].value == 'img-custom-round-size') {
                    console.log('Hello')
                  } else {
                    updateFormInputBorderRadiusStyleByProperty({
                      property: 'border-radius',
                      value: null,
                    })
                  }
                  handleChangeInClass('imageTransform', cls.value, classesList)
                }}
              >
                <Icon icon={cls.icon_name} />
              </span>
            </Tooltip>
          ))}
          {ButtonTransform === 'img-custom-round-size' && (
            <>
              <input
                className="form-control form-control-sm"
                id="edit-able-width-input"
                size="small"
                label="Width"
                type="number"
                value={borderRadius}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleChangeBorderRadius(e.target.value)
                  }
                }}
                placeholder="custom"
                autoFocus
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InputTransformBar
