import Editor from "@monaco-editor/react";
import { Stack } from "@mui/material";
import React from "react";

const VSEditor = ({ defaultValue, onChange, defaultLanguage }) => {
  return (
    <Stack sx={{ padding: 0, margin: 0, zIndex: 1000 }}>
      <Editor
        height="35vh"
        defaultValue={defaultValue}
        options={{
          wordWrap: "on",
          autoClosingBrackets: "always",
          autoClosingQuotes: "always",
        }}
        defaultLanguage={defaultLanguage}
        // theme={"vs-dark"}
        onChange={onChange}
      />
    </Stack>
  );
};

export default VSEditor;
