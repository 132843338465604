import React, { useEffect, useState } from 'react'

import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Tooltip } from '@mui/material'
import CustomSpacing from '../../Custom-Spacing/CustomSpacing'
import { form_spacing_classes } from '../../../../../ui_libs/Constants/constant'

function FormMarginSpacing({
  updateSelectedElementClass,
  getSelectedElementClassByType,
  updateSelectedElementSpacing,
  getSelectedElementStyleByProperty,
}) {
  // predefined classes
  const [Reset, setReset] = useState(false)

  const [paddings, setPaddings] = useState({
    top: '',
    bottom: '',
    left: '',
    right: '',
  })

  const [custom_paddings, setcustomPaddings] = useState({
    top: '',
    bottom: '',
    left: '',
    right: '',
  })

  const handleResetSpacing = () => {
    updateSelectedElementClass({
      class_list: form_spacing_classes('top'),
      value: '',
    })
    updateSelectedElementClass({
      class_list: form_spacing_classes('bottom'),
      value: 'wp-mb-3',
    })
    updateSelectedElementClass({
      class_list: form_spacing_classes('left'),
      value: '',
    })
    updateSelectedElementClass({
      class_list: form_spacing_classes('right'),
      value: '',
    })

    // RESET CUSTOM PADDINGS
    updateSelectedElementSpacing('padding', ['top'], '')
    updateSelectedElementSpacing('padding', ['bottom'], '')
    updateSelectedElementSpacing('padding', ['left'], '')
    updateSelectedElementSpacing('padding', ['right'], '')

    setReset(!Reset)
  }

  useEffect(() => {}, [])

  return (
    <>
      <div className="row">
        <div className="col">
          <label className="custom-label">Form Spacing</label>
        </div>
        <div className="col d-flex justify-content-end">
          <Tooltip title="Reset Spacing" arrow placement="top">
            <span>
              <RotateLeftIcon
                className="pointer"
                onClick={() => handleResetSpacing()}
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="row">
        {/* top */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={form_spacing_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="margin"
          dimention="margin-top"
          label="top"
          label_="vertical"
        />

        {/* left */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={form_spacing_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="padding"
          dimention="padding-left"
          label="left"
          label_="horizontal"
        />
      </div>
    </>
  )
}

export default FormMarginSpacing
