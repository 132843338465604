import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'

function ExitBuilderConfirmation({
  open,
  setOpen,
  handleAgree,
  handleSavePageAndExit,
  title,
  option1 = null,
  option2 = null,
  ShowOption1 = true,
}) {
  const [isLoading, setisLoading] = useState(!open)
  const handleCloseDialog = () => {
    setOpen(false)
  }
  const SaveAndExit = () => {
    setisLoading(true)
    handleSavePageAndExit()
  }
  useEffect(() => {
    setisLoading(false)
  }, [open])

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {title ? title : 'Are you sure you want to take this action?'}
        </DialogTitle>
        <DialogActions
          style={isLoading ? { pointerEvents: 'none', opacity: 0.4 } : {}}
        >
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {ShowOption1 && (
            <Button onClick={handleAgree}>{option1 ? option1 : 'Exit'}</Button>
          )}
          <LoadingButton loading={isLoading} onClick={SaveAndExit}>
            {option2 ? option2 : 'Save & Exit'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExitBuilderConfirmation
