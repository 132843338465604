import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";
import Label from "../../Label";
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  data,
}) {
  const getFormattedData = () => {
    const row = [["First Name", "Last Name", "Email", "Phone"]];
    data.map((d) => {
      const csv_row = [d.first_name, d.last_name, d.email, d.phone];

      row.push(csv_row);
    });

    return row;
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search Support Ticket"
          startAdornment={
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: "text.disabled" }}
              />
            </InputAdornment>
          }
        />
      )}

      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-start"
        mb={3}
      >
        <div className="me-2 pointer">
          <Label variant="filled" color="info">
            ALL
          </Label>
        </div>
        <div className="me-2">
          <Label variant="outlined" color="info">
            OPEN
          </Label>
        </div>

        <div className="me-2">
          <Label variant="outlined" color="success">
            SOLVED
          </Label>
        </div>

        <div className="me-2">
          <Label variant="outlined" color="warning">
            IN PROCESS
          </Label>
        </div>
      </Stack> */}
    </RootStyle>
  );
}
