import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import {
  padding_classes,
  margin_classes,
} from "../../../../ui_libs/Constants/constant";
import CodeIcon from "@mui/icons-material/Code";

import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";
import { s3baseUrl } from "../../../../config/config";
import CustomSpacing from "../Custom-Spacing/CustomSpacing";

import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  font_family_classes,
  google_font_family_classes,
  font_sizes_classes,
  font_weight_classes,
  italic_classes,
  line_height_classes,
  text_alignment_classes,
  text_decoration_classes,
  text_transform_classes,
  image_alignment_classes,
  image_transform_classes,
  object_fit_classes,
  image_size_classes,
  colors,
} from "../../../../ui_libs/Constants/constant";
import { fontAwesomeIcons } from "../../../../ui_libs/Constants/fontAwesomeIcons";
import { Icon } from "@iconify/react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  FontWeightBar,
  TextAlignBar,
  TextDecorationBar,
  TextItalicBar,
  TextTransformBar,
  ImageAlignBar,
  ImageTransformBar,
  ObjectFitBar,
  IconSelect,
  ImageSizeBar,
  IconLink,
} from "../Right-Menu/components";
import { TwitterPicker } from "react-color";
import MuiSelectClasses from "../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
import CKEditor from "../../../../components/editor/CKEditor";
import CustomEditor from "../../../../components/Custom-Editor/CustomEditor";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
const useStyles = makeStyles({
  previewIcon: {
    height: "180px",
    backgroundColor: "#eeebebf",
  },
  iTag: {
    alignSelf: "center",
  },
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
function EditorDrawer() {
  const styleClasses = useStyles();
  const {
    getIsDevState,
    setEditorMenuOpen,
    getEditAbleInputs,
    handleChangeInEditableInputs,
    handleUpdateElement,
    handleRemoveElement,
    getSelectedElementTag,
    rootValues,
    updateSelectedElementClass,
    getSelectedElementClassByType,
    getSelectedElement,
    checkIsEditorElement,
    getSelectedElementAttributes,
    selectedElementAttributes,
    setSelectedElementAttributes,
    getSelectedElementStyleByProperty,
    updateSelectedElementSpacing,
    updateSelectedIconLink,
    getSelectedIconLink,
    editorRef,
    getSelectedSections,
    handleOpenElementSourceCode,
  } = useBuilder();
  const [textColor, setTextColor] = useState(rootValues.primary_text_color);
  const [ResetMargin, setResetMargin] = useState(false);
  const [ResetPadding, setResetPadding] = useState(false);
  const [margins, setMargins] = useState({
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  });
  const [paddings, setPaddings] = useState({
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isValidSize, setIsValidSize] = useState(true);
  const [classes, setClasses] = useState({
    fontFamily: "",
    fontSize: "",
    fontWeight: "",
    textTransform: "",
    textDecoration: "",
    lineHeight: "",
    textAlign: "",
    italic: "",
    imageAlign: "",
    imageTransform: "",
    objectFit: "",
    imageSize: "",
  });
  const [elementHTML, setElementHTML] = useState("");
  const [sectionDatasets, setSectionDatasets] = useState([]);
  const [selectValue, setSelectValue] = useState("Custom");
  const [sizeLimit, setSizeLimit] = useState(20);
  const previewIconRef = useRef();

  const handleCancel = () => {
    setEditorMenuOpen(false);
  };

  const handleChangeHTML = (value) => {
    setElementHTML(value);
    handleChangeInEditableInputs("text", value);
  };

  const handleSave = () => {
    let action = "remove";
    let exists = findLinks(selectedElementAttributes.href);
    if (!exists) {
      if (!selectedElementAttributes.target) {
        action = "add";
      }
    }

    handleUpdateElement(action);
  };

  const handleRemove = () => {
    handleRemoveElement();
  };

  const handleImageSelection = async (e) => {
    setIsLoading(true);

    const resp = await _s3upload(
      `${rootValues.project_name}`,
      e.target.files[0]
    );

    if (resp.code === 200) {
      const img = s3baseUrl + "/" + resp.file_name;
      handleChangeInEditableInputs("src", img);
      setIsLoading(false);
    }
  };

  const isEditText = () => {
    const tag = getSelectedElementTag();
    const stopping_tags = ["IMG", "I", "INPUT", "TEXTAREA"];

    let status = true;

    stopping_tags.forEach((element) => {
      if (element === tag) {
        status = false;
      }
    });

    return status;
  };
  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    setSectionDatasets(datasets);
  };

  const findLinks = (link) => {
    let is_match = false;
    sectionDatasets.forEach((section) => {
      if (section.section_id === link) {
        is_match = true;
      }
    });
    return is_match;
  };
  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };

  const handleGetClassByType = () => {
    const classes_data = {
      fontSize: getSelectedElementClassByType({
        class_list: font_sizes_classes,
      }),
      fontWeight: getSelectedElementClassByType({
        class_list: font_weight_classes,
      }),
      textTransform: getSelectedElementClassByType({
        class_list: text_transform_classes,
      }),
      textDecoration: getSelectedElementClassByType({
        class_list: text_decoration_classes,
      }),
      lineHeight: getSelectedElementClassByType({
        class_list: line_height_classes,
      }),
      textAlign: getSelectedElementClassByType({
        class_list: text_alignment_classes,
      }),
      fontFamily: getSelectedElementClassByType({
        class_list: google_font_family_classes,
      }),
      // fontFamily: getSelectedElementClassByType({
      //   class_list: font_family_classes,
      // }),
      italic: getSelectedElementClassByType({
        class_list: italic_classes,
      }),
      imageAlign: getSelectedElementClassByType({
        class_list: image_alignment_classes,
      }),
      imageTransform: getSelectedElementClassByType({
        class_list: image_transform_classes,
      }),
      imageSize: getSelectedElementClassByType({
        class_list: image_size_classes,
      }),
    };

    setClasses(classes_data);
  };

  const handleChangeIcon = (ref, className) => {
    ref.current.setAttribute("class", className);
    //
  };
  // if parent tag is A
  const isParentAnchorTag = () => {
    let isParentAnchor = false;
    let element = getSelectedElement();

    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "A") {
          isParentAnchor = true;
        }
      }
    }

    return isParentAnchor;
  };
  //Margins & Paddings
  const resetSelectedElementSpacing = (type) => {
    let properties = ["top", "bottom", "left", "right"];
    let element = getSelectedElement();
    for (let property of properties) {
      element.style.removeProperty(`${type + "-" + property}`);
    }
  };

  const resetSelectedElementClass = (selected_class) => {
    const value = "";
    let properties = ["top", "bottom", "left", "right"];

    for (let property of properties) {
      const payload = {
        class_list: selected_class(property),
        value,
      };
      updateSelectedElementClass(payload);
    }
  };
  const handleResetSpacing = (type) => {
    resetSelectedElementSpacing(type);
    if (type == "padding") {
      setResetPadding(!ResetPadding);
      resetSelectedElementClass(padding_classes);
    } else if (type == "margin") {
      resetSelectedElementClass(margin_classes);
      setResetMargin(!ResetMargin);
    }
  };
  const handlePaddingsChange = (e) => {
    setPaddings({ ...paddings, [e.target.name]: e.target.value });
    updateSelectedElementSpacing("padding", e.target.name, e.target.value);
  };
  const handleMarginsChange = (e) => {
    setMargins({ ...margins, [e.target.name]: e.target.value });
    updateSelectedElementSpacing("margin", e.target.name, e.target.value);
  };
  const getIconClass = () => {
    let element = getSelectedElement();
    let className = element.getAttribute("class");
    return className;
  };
  //text color handling
  const isTagText = () => {
    let isValid = true;
    let invalidTextTags = ["IMG", "I"];
    let element = getSelectedElement();
    let tagName = element.nodeName;
    for (let tag of invalidTextTags) {
      if (tag === tagName) {
        isValid = false;
      }
    }
    return isValid;
  };
  const handleChangeTextColor = (value) => {
    let element = getSelectedElement();
    element.style.setProperty("color", value, "important");
    setTextColor(value);
  };
  const ResetTextColor = () => {
    let element = getSelectedElement();
    element.style.removeProperty("color");
    setTextColor("#000000");
  };
  const getTextColor = () => {
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue("color");
    setTextColor(color);
  };
  //Handle state on basis of selected element
  const setAttributeStates = (data) => {
    let obj = {};
    let attributes = [];
    obj["type"] = data.type;
    data.attributes.forEach((attr) => {
      attributes.push(attr);
    });
    attributes.forEach((atr) => {
      obj[atr.name] = atr.value;
    });
    setSelectedElementAttributes(obj);
    setSelectValue(obj.href);
  };
  const handleHref = (action) => {
    if (action == "add") {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["target"]: "_blank",
      });
    }
    if (action == "remove") {
      delete selectedElementAttributes["target"];
      setSelectedElementAttributes({ ...selectedElementAttributes });
    }
  };
  const handleChangeInSelect = (e) => {
    setSelectValue(e.target.value);
    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
    });
  };
  const handleInputChange = (e) => {
    setSelectValue(e.target.value);
    setSelectedElementAttributes({
      ...selectedElementAttributes,
      ["href"]: e.target.value,
    });
  };
  const validate = () => {
    if (selectedElementAttributes.type === "IMG") {
      let isValid = true;
      let height = getEditAbleInputs("height");
      let width = getEditAbleInputs("width");
      setIsValidSize(true);
      if (height < sizeLimit || width < sizeLimit) {
        isValid = false;
        setIsValidSize(false);
      }
      if (isLoading) {
        setIsValidSize(false);
      }
      return isValid;
    }
  };
  const handleFontWeightChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);

    let parent = getSelectedElement();
    let children = [...parent.children];
    if (children.length > 0) {
      children.map((child) => {
        class_list.map((cl) => {
          child.classList.remove(cl.value);
        });
        child.classList.add(value);
      });
    }
  };
  useEffect(() => {
    get_preview_div_sections();
    handleGetClassByType();
    let attributesData = getSelectedElementAttributes();
    setAttributeStates(attributesData);
    getTextColor();
  }, []);
  //Avoid too small image size
  useEffect(() => {
    validate();
  }, [getEditAbleInputs("height"), getEditAbleInputs("width")]);

  return (
    <div className="p-3 pt-0" role="presentation">
      {isEditText() && (
        <div className="row">
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mb-0">Text Editor</h6>
            <div style={{ display: "flex" }}>
              <Tooltip title="Remove Block" placement="bottom" arrow>
                <div className="icon icon-delete me-2" onClick={handleRemove}>
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </Tooltip>
             <ElementSourceCodeEditorBar/>
              <Tooltip title="Close" placement="bottom" arrow>
                <div className="icon me-2" onClick={handleCancel}>
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="col-12">
            <CustomEditor
              editorRef={editorRef}
              content={getEditAbleInputs("text")}
              setContent={(value) => {
                handleChangeInEditableInputs("text", value);
              }}
              mode={false}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {getSelectedElementTag() !== "I" && (
              <Tooltip title="Update Text" placement="top" arrow>
                <button
                  type="button"
                  className="btn btn-success my-2"
                  onClick={handleSave}
                  disabled={
                    getSelectedElementTag() == "IMG" ? !isValidSize : false
                  }
                  style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                >
                  Update
                </button>
              </Tooltip>
            )}
          </div>

          {/* <div className="col-12">
            <div>
              <CKEditor
                content={getEditAbleInputs("text")}
                setContent={(value) => {
                  handleChangeInEditableInputs("text", value);
                }}
                mode={false}
              />
            </div>
          </div> */}

          {/* font-size */}
          <div className="col-12 mt-1">
            <div className="mb-2">
              <MuiSelectClasses
                label="family"
                handleChange={handleChangeInClass}
                data={google_font_family_classes}
                value={classes.fontFamily}
              />
            </div>
          </div>

          {/* font-weight */}
          <div className="col-12">
            <label className="custom-label">Font Weight</label>
          </div>
          <div className="col-12">
            <FontWeightBar
              fontWeight={classes.fontWeight}
              handleChangeInClass={handleFontWeightChangeInClass}
              classesList={font_weight_classes}
            />
          </div>
        </div>
      )}

      {/* selected text color */}

      {/* Paddings */}
      {getIsDevState() && (
        <div className="col-12">
          <div className="row">
            <div className="col">
              <label className="form-label custom-label">Padding:</label>
            </div>
            <div className="col d-flex justify-content-end">
              <Tooltip title="Reset Paddings" arrow placement="top">
                <span>
                  <RotateLeftIcon
                    className={styleClasses.btn}
                    onClick={() => handleResetSpacing("padding")}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="row">
            <CustomSpacing
              ResetValue={ResetPadding}
              myclass={padding_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="padding"
              dimention="padding-top"
              label="top"
            />

            {/* bottom */}
            <CustomSpacing
              ResetValue={ResetPadding}
              myclass={padding_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="padding"
              dimention="padding-bottom"
              label="bottom"
            />

            {/* left */}
            <CustomSpacing
              ResetValue={ResetPadding}
              myclass={padding_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="padding"
              dimention="padding-left"
              label="left"
            />

            {/* right */}
            <CustomSpacing
              ResetValue={ResetPadding}
              myclass={padding_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="padding"
              dimention="padding-right"
              label="right"
            />
          </div>
        </div>
      )}
      {/* Margins */}
      {getIsDevState() && (
        <div className="col-12">
          <div className="row">
            <div className="col">
              <label className="form-label custom-label">Margin:</label>
            </div>
            <div className="col d-flex justify-content-end">
              <Tooltip title="Reset Margins" arrow placement="top">
                <span>
                  <RotateLeftIcon
                    className={styleClasses.btn}
                    onClick={() => handleResetSpacing("margin")}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="row">
            <CustomSpacing
              ResetValue={ResetMargin}
              myclass={margin_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="margin"
              dimention="margin-top"
              label="top"
            />

            {/* bottom */}
            <CustomSpacing
              ResetValue={ResetMargin}
              myclass={margin_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="margin"
              dimention="margin-bottom"
              label="bottom"
            />

            {/* left */}
            <CustomSpacing
              ResetValue={ResetMargin}
              myclass={margin_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="margin"
              dimention="margin-left"
              label="left"
            />

            {/* right */}
            <CustomSpacing
              ResetValue={ResetMargin}
              myclass={margin_classes}
              updateSelectedElementSpacing={updateSelectedElementSpacing}
              updateSelectedElementClass={updateSelectedElementClass}
              getSelectedElementClassByType={getSelectedElementClassByType}
              getSelectedElementStyleByProperty={
                getSelectedElementStyleByProperty
              }
              spacing_type="margin"
              dimention="margin-right"
              label="right"
            />
          </div>
        </div>
      )}
      <div className="col-12 mb-3">
        {!isValidSize && getSelectedElementTag() == "IMG" ? (
          <p className="text-danger">{`Minimum Height and Width is ${sizeLimit} !`}</p>
        ) : null}
      </div>
      {/* <div
        className="col-12 pb-3 pt-2"
        style={{
          position: "fixed",
          background: "white",
          bottom: "0%",
          zIndex: 100,
        }}
      >
        {getSelectedElementTag() !== "I" && (
          <button
            type="button"
            className="btn btn-success me-1"
            onClick={handleSave}
            disabled={getSelectedElementTag() == "IMG" ? !isValidSize : false}
          >
            Update
          </button>
        )}
        <button
          type="button"
          className="btn btn-secondary me-1"
          onClick={handleCancel}
        >
          {getSelectedElementTag() === "I" ? "Close" : "Cancel"}
        </button>
        {getSelectedElementTag() !== "I" && (
          <button
            type="button"
            className="btn btn-danger float-right"
            onClick={handleRemove}
          >
            Remove
          </button>
        )}
      </div> */}
      <div className="col-12 text-right mt-5"></div>
    </div>
  );
}

export default EditorDrawer;
