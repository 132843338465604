import React, { useEffect } from "react";

const InputPlaceholderValue = ({ Form, GetInputList, Change, setChange }) => {
  const GetInputPlaceHolderValue = (input) => {
    if (input.tagName == "SELECT") {
      let option_list = [...input.getElementsByTagName("option")];
      if (option_list.length > 0) {
        return option_list[0].innerHTML;
      }
    } else if (input.tagName == "INPUT" && input.type == "radio") {
      if (input.parentNode.getElementsByTagName("label").length > 0) {
        return input.parentNode.getElementsByTagName("label")[0].innerHTML;
      }
    } else {
      return input.placeholder;
    }
    return "Error";
  };
  const handleChangeInputPlaceholderValue = (input, e) => {
    if (input.tagName == "SELECT") {
      let option_list = [...input.getElementsByTagName("option")];
      option_list[0].innerHTML = e.target.value;
    } else if (input.tagName == "INPUT" && input.type == "radio") {
      input.parentNode.getElementsByTagName("label")[0].innerHTML =
        e.target.value;
    } else {
      input.placeholder = e.target.value;
    }

    setChange(!Change);
  };
  const renderInputsPlaceholders = () => {
    let input_list = GetInputList();
    let list = [];
    for (let index = 0; index < input_list.length; index++) {
      if (
        input_list[index].type != "checkbox" &&
        input_list[index].type != "radio"
      ) {
        list.push(
          <div
            key={index}
            className="col-12 border mb-2 p-2 mx-1 rounded form-label-checkbox-color formsetting-label-parent-div"
          >
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-4 d-flex align-items-center">
                <div>
                  <label className="custom-label d-flex align-items-center">
                    &nbsp;&nbsp;
                    {input_list[index].dataset
                      ? input_list[index].dataset.name
                        ? input_list[index].dataset.name
                        : input_list[index].name
                        ? input_list[index].name
                        : "N/A"
                      : input_list[index].name
                      ? input_list[index].name
                      : "N/A"}
                  </label>
                </div>
              </div>
              <div className="col-8">
                {/* <InlineInputField
                placeholder={label.innerHTML}
                value={label.innerHTML}
                handleUpdate={(val) => {
                  label.innerHTML = val;
                  setChange(!Change);
                }}
              /> */}
                <input
                  className="form-control form-control-sm"
                  value={GetInputPlaceHolderValue(input_list[index])}
                  onChange={(e) =>
                    handleChangeInputPlaceholderValue(input_list[index], e)
                  }
                />
              </div>
            </div>
          </div>
          // <div className="mt-2">
          //   <InlineInputField
          //     placeholder={input_list[index].placeholder}
          //     value={input_list[index].placeholder}
          //     handleUpdate={(val) => {
          //       input_list[index].placeholder = val;
          //       setChange(!Change);
          //     }}
          //   />
          //   {/* <input
          //     className="custom-label form-label"
          //     value={input_list[index].placeholder}
          //     handleUpdate={(e) => {
          //       input_list[index].placeholder = e.target.value;
          //       setChange(!Change);
          //     }}
          //   /> */}
          // </div>
        );
      }
    }
    return list;
  };

  useEffect(() => {}, [Change]);

  return (
    <div className="col-12 px-3 py-3">
      <div className="custom-label">Input Placeholders</div>
      {renderInputsPlaceholders()}
    </div>
  );
};

export default InputPlaceholderValue;
