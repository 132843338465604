import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  _delete_project_subscriber_by_email,
  _project_subscribers_list,
} from "../../DAL/projects/projects";
import Loader from "../../components/Loader/Loader";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SubscribersListing from "./components/SubscribersListing";

function Subscribers() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [subscribers, setSubscribers] = useState([]);

  const fetchProjectSubscribers = async () => {
    const project_id = params?.project_id;

    const postData = {
      // project_id: "620b7c7393a24c6e85365630",
      project_id,
    };

    const resp = await _project_subscribers_list(postData);
    if (resp.code === 200) {
      const list = resp.active_compaign_user_list;
      setSubscribers(list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchProjectSubscribers();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12">
            <SubscribersListing
              data={subscribers}
              fetchProjectSubscribers={fetchProjectSubscribers}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscribers;
