const _dispatch_find_element_by_id_and_child = (id, child = 0) => {
  const element = document.getElementById(id);
  const section = element.getElementsByTagName("SECTION")[0];

  if (child === 0) {
    return section;
  }
  if (child === 1) {
    return section.firstElementChild;
  }
  if (child === 2) {
    return section.firstElementChild.firstElementChild;
  }
};

const array_list_elements = (targetElement) => {
  const list = [];

  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];
    // if (element.children.length === 0) {
    //   data.push(element.innerText);
    // }

    const format = {
      main_element: element,
      sub_elements: array_list_elements(element.children),
      element_text: element.children.length === 0 ? element.innerText : "",
      class_name: element.className,
    };

    list.push(format);
  }
  return list;
};

const collect_list_data = (targetElement, data) => {
  for (let index = 0; index < targetElement.length; index++) {
    const element = targetElement[index];
    if (element) {
      if (element.children.length === 0) {
        data.push({
          text: element.innerText,
          link: element.href
            ? element.href
                .replace(window.location.pathname, "")
                .replace(window.location.origin, "")
            : "",
          target: element.target,
        });
      }
    }
    collect_list_data(element.children, data);
  }
  return data;
};

const _dispatch_find_lists_by_id_and_child = (id) => {
  const element = document.getElementById(id);
  let list = [];
  let data = [];

  let show_menu = true;

  const ul_list = element.getElementsByTagName("UL");

  if (element.getElementsByTagName("nav").length === 0) {
    show_menu = false;
    return { show_menu };
  }

  if (ul_list) {
    list = array_list_elements(ul_list);
  }

  data = collect_list_data(ul_list, data);

  return { list, data, show_menu };
};

const _dispatch_find_i_frames_by_id = (id) => {
  const element = document.getElementById(id);

  let list = [];

  let show_menu = true;
  let frame = element.getElementsByClassName("wb-iframe");

  if (frame.length === 0) {
    show_menu = false;
    return { show_menu };
  }

  for (let index = 0; index < frame.length; index++) {
    const element = frame[index];
    const value = frame[index].innerHTML;
    list.push({ element, value: value ? value.trim() : "" });
  }

  return { list, frame, show_menu };
};

const _dispatch_find_form_by_id = (id) => {
  const element = document.getElementById(id);
  if (element) {
    let list = [];

    let show_menu = true;
    let form = element.getElementsByTagName("form");

    if (form.length === 0) return { form: form, status: false };

    const first = form[0][0];

    return { form: form, status: true };
  } else {
    return { form: null, status: false };
  }
};

const _dispatch_get_dataset_by_settingId = (id) => {
  let sectionContainer = document.getElementById(id);
  let section = sectionContainer.getElementsByTagName("section");

  return section.item(0).dataset;
};
const _dispatch_handle_href_target = (element, action) => {
  console.log(action, "ACTION");
  if (!element) {
    console.error("No element!");
    return;
  }
  if (action == "add") {
    element.setAttribute("target", "_blank");
  }
  if (action == "remove") {
    element.removeAttribute("target");
  }
};

const _find_parent_row_of_element = (element) => {
  let currentElement = element;

  while (currentElement && currentElement.parentNode) {
    if (currentElement.classList && currentElement.classList.contains("row")) {
      return currentElement;
    } else {
      currentElement = currentElement.parentNode;
    }
  }

  return false;
};
const _find_is_editor_element = (element) => {
  let currentElement = element;
  while (currentElement && currentElement.parentNode) {
    if (
      currentElement.classList &&
      currentElement.classList.contains("wb-editor-block")
    ) {
      return currentElement;
    }
    currentElement = currentElement.parentNode;
  }

  return false;
};
const _find_section_of_element = (element) => {
  let currentElement = element;

  if (element.classList) {
    if (element.classList.contains("edit-able-section")) {
      return element.children[0];
    } else if (element.classList.contains("break-able-section")) {
      return element.children[0];
    }
  }

  while (currentElement && currentElement.parentNode) {
    if (currentElement && currentElement.tagName == "SECTION") {
      return currentElement.parentNode.parentNode;
    } else {
      currentElement = currentElement.parentNode;
    }
  }
  return false;
};

export {
  _dispatch_get_dataset_by_settingId,
  _dispatch_find_element_by_id_and_child,
  _dispatch_find_lists_by_id_and_child,
  _dispatch_find_i_frames_by_id,
  _dispatch_find_form_by_id,
  _dispatch_handle_href_target,
  _find_parent_row_of_element,
  _find_section_of_element,
  _find_is_editor_element,
};
