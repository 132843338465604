import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  _delete_project_subscriber_by_email,
  _project_subscribers_list,
} from "../../DAL/projects/projects";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupportTicketListing from "./components/SupportTicketListing";

function Subscribers() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12">
            <SupportTicketListing />
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscribers;
