import React from "react";

export const RowDivManipulation = ({
  label,
  classlist,
  value,
  handleChangeRowClass,
}) => {
  return (
    <div className="col-12 mb-2">
      <div className="col-12 mb-1">
        <span className="custom-label">{label}</span>
      </div>
      <div className="col-12">
        {classlist.map((val, index) => (
          <div key={index} class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id={val.value}
              checked={value === val.value || value === val?.extra_class}
              onClick={() => handleChangeRowClass(val.value, classlist)}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor={val.value}
            >
              {val.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
