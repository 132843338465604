import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'

import { _get_user_design_details_page } from '../../DAL/projects-pages/projectspages'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Loader from '../../components/Loader/Loader'
import { _get_user, _set_user } from '../../DAL/localStorage/localStorage'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { PageSetting } from './components'
import { useBuilder } from '../../hooks/builder-context/BuilderContext'
import Active_Compaign_Page from '../Website-Settings/components/Active-Campaign/Active_Compaign_Page'

function PageSettings() {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [Page, setPage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fetchPageInfo = async () => {
    const page_resp = await _get_user_design_details_page(params?.page_id)
    if (page_resp.code === 200) {
      console.log(page_resp.Page)
      const page = page_resp.Page
      setPage(page)
      setIsLoading(false)
    } else {
      enqueueSnackbar(page_resp.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchPageInfo()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1)
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 d-flex  mb-1">
            <Typography variant="subtitle1">Project Name : </Typography>
            &nbsp;&nbsp;
            <Typography>{location.state.Project.project_name}</Typography>
          </div>

          <div className="col-12  d-flex  mb-1">
            <Typography variant="subtitle1">
              Page Name &nbsp;&nbsp; &nbsp;:{' '}
            </Typography>
            &nbsp;&nbsp;
            <Typography>{Page.page_name}</Typography>
          </div>

          <div className="col-12">
            <Active_Compaign_Page page={Page} />
          </div>

          <div className="col-12">
            <PageSetting page={Page} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PageSettings
