import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";
import TabletIcon from "@mui/icons-material/Tablet";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Alert, Backdrop, Tooltip } from "@mui/material";
import { mobilePreviewImage, tabletPreviewImage } from "../../../../assets";
function DevicesPreview() {
  const {
    tabletPreview,
    mobilePreview,
    setTabletPreview,
    setMobilePreview,
    previewURL,
    changeInHTML,
    setChangeInHTML,
    CheckChangeExsistInHTML,
  } = useBuilder();

  onclose = () => {
    setMobilePreview(false);
    setTabletPreview(false);
    setChangeInHTML(false);
  };
  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        onclose();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
  }, []);

  return (
    <>
      {(mobilePreview || tabletPreview) && (
        <Backdrop
          open="open"
          sx={{
            backgroundColor: "#ffffffb0",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {changeInHTML && (
            <div
              style={{
                position: "absolute",
                top: "1%",
                left: "0",
              }}
            >
              <Alert sx={{fontSize:12}} severity="info">
                You have unsaved changes in your website. Go, Save first and then open device
                preview.
              </Alert>
            </div>
          )}
          <span
            className=" centered text-dark pe-3"
            style={{ top: "4%", left: "99%", cursor: "pointer" }}
            onClick={() => onclose()}
          >
            <CloseIcon sx={{ fontSize: 30, color: "white" }} />
          </span>

          <div style={{ display: "flex", top: "4%" }} className="centered">
            <Tooltip title="Tablet Preview" arrow placement="top">
              <span
                className="wb-header-preview border-start text-dark pe-3"
                onClick={() => {
                  setTabletPreview(true);
                  setMobilePreview(false);
                }}
              >
                <TabletIcon
                  sx={{
                    fontSize: 25,
                    color: tabletPreview ? "green" : "white",
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Mobile Preview" arrow placement="top">
              <span
                className="wb-header-preview  border-end  text-dark pe-3"
                onClick={() => {
                  setMobilePreview(true);
                  setTabletPreview(false);
                }}
              >
                <SmartphoneIcon
                  sx={{
                    fontSize: 25,
                    color: mobilePreview ? "green" : "white",
                  }}
                />
              </span>
            </Tooltip>
          </div>
          <div>
            <iframe
              src={previewURL}
              className="style-3"
              // id= "style-3"
              style={{
                height: tabletPreview ? "87vh" : "89.5vh",
                width: tabletPreview ? "768px" : "380px",
                display: "block",
                overflow: "hidden",
                position: "absolute",
                margin: tabletPreview ? "10vh 1rem auto" : "8.7vh 3px auto",
                borderRadius: tabletPreview ? "1rem" : "0.5rem",
              }}
            ></iframe>
            <img
              src={tabletPreview ? tabletPreviewImage : mobilePreviewImage}
              style={{
                height: "91vh",
                margin: "8vh auto auto",
                width: tabletPreview ? "801px" : "390px",
                zIndex: 100,
              }}
            />
          </div>
        </Backdrop>
      )}
    </>
  );
}

export default DevicesPreview;
