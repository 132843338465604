import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { padding_classes } from "../../../../../../ui_libs/Constants/constant";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";

function Paddings({ handleGetPaddingClass, handleupdatePaddingClass, handleGetPaddingCustom, handleUpdatePaddingCustom }) {





  //done custom
  const handleGetCustomSpacing = (property) => {
    const value = handleGetPaddingCustom(property.property)
    return value ? value / 16 + "rem" : '';

  }

  const handleUpdateCustomSpacing = (...property) => {
    handleUpdatePaddingCustom(property[0], property[2] * 16)
  }



  const handleGetClassSpacing = (myproperty) => {
    const value = handleGetPaddingClass(myproperty)
    return value ? value : 0

  }

  const handleUpdateClassSpacing = (property) => {
    handleupdatePaddingClass(property)
  }


  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Padding</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">


          <CustomSpacing
            myclass={padding_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='paddingTop' dimention='paddingTop' label='top' />


          <CustomSpacing
            myclass={padding_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='paddingBottom' dimention='paddingBottom' label='bottom' />

          <CustomSpacing
            myclass={padding_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='paddingLeft' dimention='paddingLeft' label='left' />

          <CustomSpacing
            myclass={padding_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='paddingRight' dimention='paddingRight' label='right' />


        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default Paddings;
