import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";
import { s3baseUrl } from "../../../../config/config";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollectionsIcon from "@mui/icons-material/Collections";
import BorderWidth from "./components/Border-Width/BorderWidth";
import {
  button_width_classes,
  border_classes,
  padding_classes,
  button_variant_classes,
  button_radius_classes,
} from "../../../../ui_libs/Constants/constant";
import CustomSpacing from "../Custom-Spacing/CustomSpacing";

import {
  font_family_classes,
  google_font_family_classes,
  font_sizes_classes,
  font_weight_classes,
  italic_classes,
  line_height_classes,
  text_alignment_classes,
  text_decoration_classes,
  text_transform_classes,
  image_alignment_classes,
  button_alignment_classes,
  image_transform_classes,
  object_fit_classes,
  image_size_classes,
  colors,
  margin_left_classes,
} from "../../../../ui_libs/Constants/constant";
import ButtonTransformBar from "./components/ButtonRadius-Transform-Bar/ButtonTransformBar";
import { fontAwesomeIcons } from "../../../../ui_libs/Constants/fontAwesomeIcons";
import { Icon } from "@iconify/react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Alert from "@mui/material/Alert";

import {
  FontWeightBar,
  TextAlignBar,
  TextDecorationBar,
  TextItalicBar,
  TextTransformBar,
  ImageAlignBar,
  ImageTransformBar,
  ObjectFitBar,
  IconSelect,
  ImageSizeBar,
  IconLink,
  InputProperties,
  MarginSpacing,
  PaddingSpacing,
} from "./components";
import ButtonProperty from "./components/Buton-Property/ButtonProperty";
import { TwitterPicker } from "react-color";
import { Close, Done } from "@mui/icons-material";
import MuiSelectClasses from "../Right-Theme-Menu/components/MuiSelect/MuiSelectClasses";
const useStyles = makeStyles({
  previewIcon: {
    height: "180px",
    backgroundColor: "#eeebebf",
  },
  iTag: {
    alignSelf: "center",
  },
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
// react image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CircularProgress from "@mui/material/CircularProgress";
import { CanvasPreview } from "./components/Crop-Image/CanvasPreview";
import { useDebounceEffect } from "./components/Crop-Image/useDebounceEffect";
import Croper from "./components/Crop-Image/Croper";
import { Bolt } from "@mui/icons-material";
import { width } from "@mui/system";
import ButtonVariant from "./components/Button-Variant/ButtonVariant";
import { _upload_picture_to_user_gallery } from "../../../../DAL/User-Gallery/user_gallery";
import GalleryDialog from "../Gallery/GalleryDialog";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";

function RightMenu() {
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState();
  const [ImageDimention, setImageDimention] = useState();
  const [Customcrop, setCustomcrop] = useState(false);
  const [Error, setError] = useState(false);
  const [PreviewImage, setPreviewImage] = useState({
    src: "",
    show: true,
  });
  const [ImgData, setImgData] = useState({
    height: "",
    width: "",
  });
  const [Croperheightwidth, setCroperheightwidth] = useState({
    height: "",
    width: "",
    status: false,
  });
  const [uploadedimgheightwidth, setuploadedimgheightwidth] = useState({
    height: "",
    width: "",
  });
  const [UplodImgType, setUplodImgType] = useState("");
  const [Change, setChange] = useState(false);

  function onImageLoad(e) {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    const height = naturalHeight;
    const width = naturalWidth;
    uploadedimgheightwidth.height = height;
    uploadedimgheightwidth.width = width;
    setImageDimention({ x: 0, y: 0, height, width, unit: "px" });

    Croperheightwidth.status = true;
    handlechangecropaspect("fix");
  }
  const [ColorList, setColorList] = useState(colors);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.

        CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        ChangeCropHeightWidth();
      }
    },
    100,
    [completedCrop]
  );

  const handlecrop = () => {
    const canvas = previewCanvasRef.current;
    // const { height, width } = canvas;
    // canvas.height = 90;
    // canvas.width = 127;
    const final = canvas.toDataURL(`${UplodImgType}`, 1);

    const dataURI = final;

    // old code with no extention

    // // convversion back to image
    // var byteString = atob(dataURI.split(",")[1]);

    // // write the bytes of the string to an ArrayBuffer
    // var ab = new ArrayBuffer(byteString.length);
    // var ia = new Uint8Array(ab);
    // for (var i = 0; i < byteString.length; i++) {
    //   ia[i] = byteString.charCodeAt(i);
    // }
    // const myblob = new File([ab], { type: "image/jpeg" });

    // new code with extention added
    var byteCharacters = atob(dataURI.split(",")[1]);
    var byteArrays = [];
    const contentType = "png";
    const sliceSize = 512;
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var myblob = new Blob(byteArrays, { type: contentType });
    handleImageSelection(myblob);
  };

  const handleFullSelect = () => {
    setChecked("full");
    setCrop({
      unit: "%", // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    setAspect(undefined);
    setCompletedCrop({
      unit: "px", // Can be 'px' or '%'
      x: ImageDimention.x,
      y: ImageDimention.y,
      height:
        ImageDimention.height /
        (uploadedimgheightwidth.height / imgRef.current.height),
      width:
        ImageDimention.width /
        (uploadedimgheightwidth.width / imgRef.current.width),
    });
    ChangeCropHeightWidth();
  };
  const handleremoveIMG = () => {
    PreviewImage.show = true;
    setError(false);
    setImgSrc("");
  };

  const styleClasses = useStyles();
  const {
    getIsDevState,
    setRightMenuOpen,
    getEditAbleInputs,
    handleChangeInEditableInputs,
    handleUpdateElement,
    handleupdateIMG,
    handleRemoveElement,
    getSelectedElementTag,
    rootValues,
    // handleIMGHeightWidth,
    updateSelectedElementClass,
    getSelectedElementClassByType,
    getSelectedElement,
    checkIsEditorElement,
    getSelectedElementAttributes,
    selectedElementAttributes,
    setSelectedElementAttributes,
    updateSelectedElementSpacing,
    updateParentLink,
    updateParentLinkTarget,
    getParentLink,
    updateSelectedElementAttributes,
    getSelectedElementStyleByProperty,
    RedoAction,
    SaveActionToStack,
    userImagesList,
    setUserImagesList,
    handleOpenElementSourceCode,
    handleOpenCustomClassMenu,
  } = useBuilder();

  const [textColor, setTextColor] = useState(rootValues.primary_text_color);
  const [margins, setMargins] = useState({
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditor, setIsEditor] = useState();
  const [isValidSize, setIsValidSize] = useState(true);

  const [classes, setClasses] = useState({
    fontFamily: "",
    fontSize: "",
    fontWeight: "",
    textTransform: "",
    textDecoration: "",
    lineHeight: "",
    textAlign: "",
    italic: "",
    imageAlign: "",
    imageTransform: "",
    objectFit: "",
    imageSize: "",
  });
  const [galleryDialog, setGalleryDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [targetImage, setTargetImage] = useState(false);
  const [openDeleteImageDiloge, setOpenDeleteImageDiloge] = useState(false);
  const [sectionDatasets, setSectionDatasets] = useState([]);
  const [selectValue, setSelectValue] = useState("Custom");
  const [sizeLimit, setSizeLimit] = useState(20);
  const [ResetBorder, setResetBorder] = useState(false);
  const previewIconRef = useRef();
  const handleCancel = () => {
    setRightMenuOpen(false);
  };

  const handleSave = () => {
    let action = "remove";
    let exists = findLinks(selectedElementAttributes.href);
    if (!exists) {
     
      if (!selectedElementAttributes.target) {
        action = "add";
      }

    }
    handleUpdateElement();
  };
  const handleRemove = () => {
    handleRemoveElement();
  };
  const [Checked, setChecked] = useState("custom");
  const handlechangecropaspect = (click) => {
    if (click == "custom") {
      setChecked("custom");
      setAspect(undefined);
    }
    if (click == "fix") {
      setAspect(ImgData.width / ImgData.height);
      setChecked("fix");

      // image height width from img tag
      let recomended_height = parseInt(ImgData.height);
      let recomended_width = parseInt(ImgData.width);

      // new uploaded image height width
      let image_height = uploadedimgheightwidth.height;
      let image_width = uploadedimgheightwidth.width;
      // check if new image is greater or smaller than the recomended size
      if (
        image_height <= recomended_height ||
        image_width <= recomended_width
      ) {
        if (
          image_width / image_height ==
          recomended_width / recomended_height
        ) {
          // aspect ratio same
          const { height, width } = imgRef.current;
          if (width < image_width) {
            image_width = image_width / (uploadedimgheightwidth.width / width);
          }
          if (height < image_height) {
            image_height =
              image_height / (uploadedimgheightwidth.height / height);
          }
          recomended_height = image_height;
          recomended_width = image_width;
        } else {
          let asp = recomended_width / recomended_height;
          if (image_width / image_height > 1) {
            recomended_width =
              recomended_width * image_height * (1 / recomended_height);
            recomended_height = image_height;
          } else {
            recomended_height =
              image_width * recomended_height * (1 / recomended_width);
            recomended_width = image_width;
          }
          recomended_width =
            recomended_width /
            (uploadedimgheightwidth.width / imgRef.current.width);
          recomended_height =
            recomended_height /
            (uploadedimgheightwidth.height / imgRef.current.height);
        }
      } else {
        const { height, width } = imgRef.current;
        recomended_height = recomended_height * (width / image_width);
        recomended_width = recomended_width * (height / image_height);
      }
      setCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: "px",
      });
      setCompletedCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: "px",
      });
    }

    setCustomcrop(!Customcrop);
  };

  const handleImageSelection = async (file) => {
    setIsLoading(true);

    // const resp = await _s3upload(`${rootValues._id}`, file);
    let formData = new FormData();
    formData.append("upload_file", file);
    const resp = await _upload_picture_to_user_gallery(formData);
    if (resp.code === 200) {
      let images_list = [...resp.gallery.images].reverse();
      setUserImagesList(images_list);
      const img =
        s3baseUrl +
        "/" +
        resp.gallery.images[resp.gallery.images.length - 1].file_path;

      handleupdateIMG(img);
      // handleIMGHeightWidth(height, width);
      PreviewImage.src = img;
      PreviewImage.show = true;
      setIsLoading(false);
      setError(false);
    }
  };

  const isEditText = () => {
    const tag = getSelectedElementTag();
    const stopping_tags = ["IMG", "I", "INPUT", "TEXTAREA", "SELECT"];

    let status = true;

    stopping_tags.forEach((element) => {
      if (element === tag) {
        status = false;
      }
    });

    return status;
  };
  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    setSectionDatasets(datasets);
  };
  const findLinks = (link) => {
    let is_match = false;
    sectionDatasets.forEach((section) => {
      if (section.section_id === link) {
        is_match = true;
      }
    });
    return is_match;
  };

  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };
  const Remove_wp_Row_Div = (element) => {
    const wp_row_block_list = element.querySelector(".wb-preview-div");

    if (wp_row_block_list) {
      wp_row_block_list.remove();
    }
  };
  const handleChangeButtonAlignment = (key, value, class_list) => {
    let element = getSelectedElement();
    let Max_Count = 1;
    if (element.tagName == "BUTTON" && element.parentNode.tagName == "A") {
      Max_Count = 2;
    }
    Remove_wp_Row_Div(element);
    while (element.parentNode.children.length == 1) {
      element = element.parentNode;
      Remove_wp_Row_Div(element);
    }
    let multiple_button = [
      ...element.parentNode.getElementsByTagName("a"),
      ...element.parentNode.getElementsByTagName("button"),
    ];

    if (multiple_button.length > Max_Count) {
      setClasses({ ...classes, [key]: value });
      return "";
    }

    class_list.map((cl) => {
      if (element && element.classList) {
        element.classList.remove(cl.value);
      }
    });
    if (value) {
      element.classList.add("d-flex");
      element.classList.add(value);
    } else {
      element.classList.remove("d-flex");
    }
    setClasses({ ...classes, [key]: value });
  };
  const handleGetButtonAlignment = (class_list) => {
    let value = "";
    let element = getSelectedElement();
    Remove_wp_Row_Div(element);
    while (element.parentNode.children.length == 1) {
      element = element.parentNode;
      Remove_wp_Row_Div(element);
    }
    class_list.map((cl) => {
      if (
        element &&
        element.classList &&
        element.classList.contains(cl.value)
      ) {
        value = cl.value;
      }
    });
    return value;
  };
  const handleGetClassByType = () => {
    let classes_data = {
      fontSize: getSelectedElementClassByType({
        class_list: font_sizes_classes,
      }),
      fontWeight: getSelectedElementClassByType({
        class_list: font_weight_classes,
      }),
      textTransform: getSelectedElementClassByType({
        class_list: text_transform_classes,
      }),
      textDecoration: getSelectedElementClassByType({
        class_list: text_decoration_classes,
      }),
      lineHeight: getSelectedElementClassByType({
        class_list: line_height_classes,
      }),
      textAlign: getSelectedElementClassByType({
        class_list: text_alignment_classes,
      }),
      fontFamily: getSelectedElementClassByType({
        class_list: google_font_family_classes,
      }),
      // fontFamily: getSelectedElementClassByType({
      //   class_list: font_family_classes,
      // }),
      italic: getSelectedElementClassByType({
        class_list: italic_classes,
      }),
      imageAlign: getSelectedElementClassByType({
        class_list: image_alignment_classes,
      }),
      imageTransform: getSelectedElementClassByType({
        class_list: image_transform_classes,
      }),
      imageSize: getSelectedElementClassByType({
        class_list: image_size_classes,
      }),
    };
    classes_data = { ...GetButtonAlignProperty(classes_data) };

    setClasses(classes_data);
  };
  const GetButtonAlignProperty = (classes_data) => {
    let element = getSelectedElement();
    if (element.tagName == "A" || element.tagName == "BUTTON") {
      classes_data = {
        ...classes_data,
        imageAlign: handleGetButtonAlignment(button_alignment_classes),
      };
    }
    return classes_data;
  };

  const handleChangeIcon = (ref, className) => {
    ref.current.setAttribute("class", className);
    //
  };
  // if parent tag is A
  const isParentAnchorTag = () => {
    let isParentAnchor = false;
    let element = getSelectedElement();

    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "A") {
          isParentAnchor = true;
        }
      }
    }

    return isParentAnchor;
  };
  const handleResetBorder = () => {
    let element = getSelectedElement();
    element.style.removeProperty("border-width");
    updateSelectedElementClass({
      class_list: button_width_classes(),
      value: "",
    });
    if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
      element.style.removeProperty("border-style");
    }
    setResetBorder(!ResetBorder);
  };
  // border-width
  const getSelectedElementBorderWidth = (property) => {
    const value = getSelectedElementStyleByProperty({
      property: `${property.property}`,
    });
    let val = value.replace("rem", "");
    val = val * 16;
    return val;
  };
  const updateSelectedElementBorderWidth = (property, name, value) => {
    let element = getSelectedElement();
    if (value == 0) {
      element.style.removeProperty(property);
      if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
        element.style.removeProperty("border-style");
      }
    } else {
      value = value + "rem";
      element.style.setProperty(property, value, "important");
      if (selectedElementAttributes.type === "A" || isParentAnchorTag()) {
        property = property.replace("width", "style");
        element.style.setProperty(property, "solid", "important");
      }
    }
  };
  const updateSelectedElementBorderWidthClass = (property) => {
    const payload = {
      class_list: property.class_list,
      value: property.value,
    };
    let element = getSelectedElement();
    if (property.value == "") {
      element.classList.add("border-0");
    } else {
      element.classList.remove("border-0");
    }
    updateSelectedElementClass(payload);
  };
  const getSelectedElementBorderWidthClass = (property) => {
    return getSelectedElementClassByType({
      class_list: property.class_list,
    });
  };

  //Margins & Paddings
  const resetSelectedElementSpacing = (type) => {
    let properties = ["top", "bottom", "left", "right"];
    let element = getSelectedElement();
    for (let property of properties) {
      element.style.removeProperty(`${type + "-" + property}`);
    }
  };
  const handleResetSpacing = (type) => {
    resetSelectedElementSpacing(type);
    setPaddings({
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
    });
    setMargins({
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
    });
  };
  const getIconClass = () => {
    let element = getSelectedElement();
    let className = element.getAttribute("class");
    return className;
  };
  //text color handling
  const isTagText = () => {
    let isValid = true;
    let invalidTextTags = ["IMG", "I"];
    let element = getSelectedElement();
    let tagName = element.nodeName;
    for (let tag of invalidTextTags) {
      if (tag === tagName) {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleChangeTextColor = (value) => {
    let element = getSelectedElement();
    // element.style.setProperty("color", value, "important");
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        "color",
        `var(--wb-website-theme-secondary-color)`
      );
    } else {
      element.style.setProperty("color", value);
    }
    setTextColor(value);
  };

  const handleChangeProperty = (property, value) => {
    SaveActionToStack();
    let element = getSelectedElement();
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        property,
        `var(--wb-website-theme-secondary-color)`,
        "important"
      );

      return;
    }
    element.style.setProperty(property, value, "important");
  };

  const ResetTextColor = () => {
    let element = getSelectedElement();
    element.style.removeProperty("color");

    setTextColor("#000000");
  };

  const ResetProperty = (property) => {
    let element = getSelectedElement();
    element.style.removeProperty(property);
    return getproperty(property);
  };

  const getproperty = (property) => {
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };

  //Handle state on basis of selected element
  const setAttributeStates = (data) => {
    let obj = {};
    let attributes = [];
    obj["type"] = data.type;
    data.attributes.forEach((attr) => {
      attributes.push(attr);
    });
    attributes.forEach((atr) => {
      obj[atr.name] = atr.value;
    });
    PreviewImage.src = obj["src"];
    ImgData.height = obj["imgheight"];
    ImgData.width = obj["imgwidth"];

    // get parent value if parent is anchor tag
    if (isParentAnchorTag()) {
      const link = getParentLink();
      obj.href = link.href;
      obj.target = link.target;
    }
    setLinkType("custom");
    if (obj.href) {
      // check pages link
      rootValues.page_list.map((page) => {
        if (page.page_slug == obj.href.split(".")[0]) {
          setLinkType("pages");
        }
      });

      // check section link
      if (findLinks(obj.href)) {
        setLinkType("pages");
      }
      // custom link

      // abcdef
    }
    setSelectedElementAttributes(obj);
    setSelectValue(obj.href);
  };
  const handleHref = (action) => {
    if (action == "add") {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["target"]: "_blank",
      });

      // update parent too if available
      if (isParentAnchorTag()) {
        updateParentLinkTarget("_blank");
      }
    }
    if (action == "remove") {
      delete selectedElementAttributes["target"];
      setSelectedElementAttributes({ ...selectedElementAttributes });
      // update parent too if available
      if (isParentAnchorTag()) {
        updateParentLinkTarget("");
      }
    }
  };
  const [LinkType, setLinkType] = useState("");
  const handleChangeLinkType = (e) => {
    setLinkType(e.target.value);
    if (isParentAnchorTag()) {
      updateParentLink("");
      updateParentLinkTarget("");
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: "",
        ["target"]: "",
      });
    } else {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: "",
        ["target"]: "",
      });
    }
  };
  const handleChangeInSelect = (e) => {
    setSelectValue(e.target.value);
    if (isParentAnchorTag()) {
      updateParentLink(e.target.value);
      updateParentLinkTarget("");
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: e.target.value,
        ["target"]: "",
      });
    } else {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: e.target.value,
        ["target"]: "",
      });
    }
  };
  const handleInputChange = (e) => {
    setSelectValue(e.target.value);

    if (isParentAnchorTag()) {
      updateParentLink(e.target.value);
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: e.target.value,
      });
    } else {
      setSelectedElementAttributes({
        ...selectedElementAttributes,
        ["href"]: e.target.value,
      });
    }
  };
  const handleChooseFromGallery = () => {
    setGalleryDialog(true);
  };
  const DeleteImageConfirmation = (image) => {
    setTargetImage(image);
    setOpenDeleteImageDiloge(true);
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      const img = e.target.files[0];
      setUplodImgType(img.type);

      PreviewImage.show = false;
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  useEffect(() => {}, [Change]);
  useEffect(() => {
    AppendThemeValues();
  }, []);
  const handleGetIconColor = () => {
    return textColor;
  };

  const AppendThemeValues = () => {
    if (rootValues && rootValues.secondary_color) {
      let myset = new Set();
      let color = [
        // rootValues.primary_color,
        rootValues.secondary_color,
        ...colors,
      ];
      color.forEach((val) => {
        myset.add(val);
      });

      setColorList([...myset]);
    }
  };
  const ChangeCropHeightWidth = () => {
    Croperheightwidth.height = parseInt(previewCanvasRef.current.height);
    Croperheightwidth.width = parseInt(previewCanvasRef.current.width);

    setChange(!Change);
  };
  useEffect(() => {
    get_preview_div_sections();
    handleGetClassByType();

    let attributesData = getSelectedElementAttributes();
    setAttributeStates(attributesData);
    setTextColor(getproperty("color"));
    setIsEditor(checkIsEditorElement(getSelectedElement()));
  }, []);
  //Avoid too small image size
  useEffect(() => {}, [
    getEditAbleInputs("height"),
    getEditAbleInputs("width"),
  ]);

  return (
    <div className="p-3 pt-0" role="presentation">
      <GalleryDialog
        galleryDialog={galleryDialog}
        setGalleryDialog={setGalleryDialog}
        uploadDialog={uploadDialog}
        setUploadDialog={setUploadDialog}
        DeleteImageConfirmation={DeleteImageConfirmation}
        UpdateImageSrc={true}
      />
      {isEditText() && (
        <div className="row">
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {(selectedElementAttributes.type === "A" ||
              isParentAnchorTag()) && <h6 className="mb-0">Button Settings</h6>}
            {!(
              selectedElementAttributes.type === "A" || isParentAnchorTag()
            ) && <h6 className="mb-0">Text Editor</h6>}
            <div style={{ display: "flex" }}>
              <Tooltip title="Remove" placement="bottom" arrow>
                <div className="icon icon-delete me-2" onClick={handleRemove}>
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </Tooltip>
              <ElementSourceCodeEditorBar />
              <Tooltip title="Close" placement="bottom" arrow>
                <div className="icon me-2" onClick={handleCancel}>
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="col-12">
            <div>
              <label
                htmlFor="edit-able-text-input"
                className="form-label custom-label"
              >
                Text
              </label>

              <textarea
                className="w-100 form-control"
                id="edit-able-text-input"
                label="Text"
                multiline
                rows={4}
                value={getEditAbleInputs("text")}
                onChange={(e) =>
                  handleChangeInEditableInputs("text", e.target.value)
                }
                autoFocus
              ></textarea>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {getSelectedElementTag() !== "I" && (
                  <Tooltip title="Update Text" placement="top" arrow>
                    <button
                      type="button"
                      className="btn btn-success my-2"
                      onClick={handleSave}
                      disabled={
                        getSelectedElementTag() == "IMG" ? !isValidSize : false
                      }
                      style={{ padding: "0.1rem 0.75rem", fontSize: "0.8rem" }}
                    >
                      Update
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            {/* <TwitterPicker
            colors={colors}
            width="100%"
            // onChange={(e) =>
            //   handleUpdateStyleProperty(
            //     "backgroundColor",
            //     e.hex,
            //     background_color_action_child
            //   )
            // }
            // color={styles.backgroundColor}
          /> */}
          </div>
          {/* font-fmily */}
          <div className="col-12">
            <div className="mb-3">
              <MuiSelectClasses
                label="family"
                handleChange={handleChangeInClass}
                data={google_font_family_classes}
                value={classes.fontFamily}
              />
            </div>
          </div>
          <div className="col-6">
            {/* font-size */}
            <div className="mb-3">
              <label
                for="edit-able-text-input"
                className="form-label custom-label"
              >
                Font Size
              </label>

              <select
                className="w-100 form-control custom-icon-select"
                value={classes.fontSize}
                onChange={(e) =>
                  handleChangeInClass(
                    "fontSize",
                    e.target.value,
                    font_sizes_classes
                  )
                }
              >
                <option value="">Reset</option>
                {font_sizes_classes.map((fs, i) => (
                  <option key={i} value={fs.value}>
                    {fs.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Line height */}
          <div className="col-6">
            <div className="mb-3">
              <label
                for="edit-able-text-input"
                className="form-label custom-label"
              >
                Line Height
              </label>

              <select
                className="w-100 form-control custom-icon-select"
                value={classes.lineHeight}
                onChange={(e) =>
                  handleChangeInClass(
                    "lineHeight",
                    e.target.value,
                    line_height_classes
                  )
                }
              >
                <option value="">Reset</option>
                {line_height_classes.map((fs, i) => (
                  <option key={i} value={fs.value}>
                    {fs.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Text Align */}
          <div className="col-12">
            <TextAlignBar
              textAlign={classes.textAlign}
              handleChangeInClass={handleChangeInClass}
              classesList={text_alignment_classes}
            />
          </div>
          {/* float alignment */}
          <div className="col-12">
            <ImageAlignBar
              imageAlign={classes.imageAlign}
              handleChangeInClass={handleChangeButtonAlignment}
              classesList={button_alignment_classes}
              // classesList={image_alignment_classes}
            />
          </div>
          {/* font-weight */}
          <div className="col-12">
            <FontWeightBar
              fontWeight={classes.fontWeight}
              handleChangeInClass={handleChangeInClass}
              classesList={font_weight_classes}
            />
          </div>
          {/* text_transform */}
          <div className="col-12">
            <TextTransformBar
              textTransform={classes.textTransform}
              handleChangeInClass={handleChangeInClass}
              classesList={text_transform_classes}
            />
          </div>
          {/* text_decoration */}
          <div className="col-12">
            <TextDecorationBar
              textDecoration={classes.textDecoration}
              handleChangeInClass={handleChangeInClass}
              classesList={text_decoration_classes}
            />
          </div>
          {/* text_italic */}
          <div className="col-12">
            <TextItalicBar
              textItalic={classes.italic}
              handleChangeInClass={handleChangeInClass}
              classesList={italic_classes}
            />
          </div>
        </div>
      )}
      {getSelectedElementTag() === "IMG" && (
        <div className="col-12">
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mb-0">Image Settings</h6>
            <div style={{ display: "flex" }}>
              <Tooltip title="Remove Image" placement="bottom" arrow>
                <div className="icon icon-delete me-2" onClick={handleRemove}>
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </Tooltip>
              
              <ElementSourceCodeEditorBar />

              <Tooltip title="Close" placement="bottom" arrow>
                <div className="icon me-2" onClick={handleCancel}>
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="col-12 mb-2">
            <Accordion defaultExpanded className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Image Properties</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row ">
                  {/* <Croper
                    // handlechangecropaspect={handlechangecropaspect}
                    uploadedimgheightwidth={uploadedimgheightwidth}
                    handleFullSelect={handleFullSelect}
                    onImageLoad={onImageLoad}
                    handlecrop={handlecrop}
                    handleremoveIMG={handleremoveIMG}
                    Customcrop={Customcrop}
                    // states
                    Croperheightwidth={Croperheightwidth}
                    ImgData={ImgData}
                    PreviewImage={PreviewImage}
                    imgSrc={imgSrc}
                    Checked={Checked}
                    crop={crop}
                    aspect={aspect}
                    imgRef={imgRef}
                    completedCrop={completedCrop}
                    previewCanvasRef={previewCanvasRef}
                    isLoading={isLoading}
                    // set states
                    setImgSrc={setImgSrc}
                    setUplodImgType={setUplodImgType}
                    setChecked={setChecked}
                    setAspect={setAspect}
                    setCrop={setCrop}
                    setCompletedCrop={setCompletedCrop}
                    setCustomcrop={setCustomcrop}
                  /> */}
                  <div className="col-12 mb-1">
                    <span className="custom-label">Upload Image:</span>
                    {/* react crop image  */}
                    <div className="row d-flex  align-items-center">
                      <div className="col-6 mt-2">
                        <Button
                          variant="outlined"
                          component="label"
                          sx={{
                            borderColor: "#3899ec",
                            color: "#3899ec",
                            borderRadius: "30px",
                            padding: "3px 20px",
                            boxShadow: "none",
                            "&:hover": {
                              borderColor: "#4eb7f5",
                            },
                          }}
                        >
                          <input
                            hidden
                            type="file"
                            accept="image/*"
                            onChange={onSelectFile}
                          />
                          <i
                            class="fa-solid fa-plus"
                            style={{ fontSize: "12px" }}
                          ></i>
                          <span
                            style={{
                              marginLeft: "0.5rem",
                              fontSize: "16px",
                              fontWeight: "lighter",
                            }}
                          >
                            Upload
                          </span>
                        </Button>
                      </div>
                      <div className="col-6 mt-2 d-flex justify-content-end">
                        <Tooltip
                          title="Choose From Gallery"
                          arrow
                          placement="top"
                        >
                          <IconButton onClick={handleChooseFromGallery}>
                            <CollectionsIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  {ImgData && ImgData.height && ImgData.width && (
                    <div className="col-12 mb-1">
                      <span className="custom-label">
                        Recomended Height : {ImgData.height + "px"} Width :{" "}
                        {ImgData.width + "px"}
                      </span>
                    </div>
                  )}

                  {!PreviewImage.show && (
                    <div>
                      {Boolean(imgSrc) && (
                        <div className="col-12">
                          <div>
                            <div className="row ">
                              <div className="col-12">
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="container"
                                    checked={Checked == "fix" && "true"}
                                    onClick={() =>
                                      handlechangecropaspect("fix")
                                    }
                                  />
                                  <label
                                    className="form-check-label pointer custom-label"
                                    htmlFor="container"
                                  >
                                    Recomended Size
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="container-fluid"
                                    checked={Checked == "custom" && "true"}
                                    onClick={() =>
                                      handlechangecropaspect("custom")
                                    }
                                  />
                                  <label
                                    className="form-check-label pointer custom-label"
                                    htmlFor="container-fluid"
                                  >
                                    Custom Size
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="full-size"
                                    checked={Checked == "full" && "true"}
                                    onClick={handleFullSelect}
                                  />
                                  <label
                                    className="form-check-label pointer custom-label"
                                    htmlFor="full-size"
                                  >
                                    Full Image
                                  </label>
                                </div>
                              </div>
                            </div>

                            {Croperheightwidth.status && (
                              <div className="row mb-2">
                                <div className="col-12">
                                  <span className="custom-label">
                                    Image Height:
                                    {Croperheightwidth.height +
                                      "px" +
                                      " Width:" +
                                      Croperheightwidth.width +
                                      "px"}
                                  </span>
                                </div>
                              </div>
                            )}

                            <ReactCrop
                              crop={crop}
                              onChange={(c) => setCrop(c)}
                              onComplete={setCompletedCrop}
                              aspect={aspect}
                            >
                              <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                onLoad={onImageLoad}
                              />
                            </ReactCrop>
                          </div>
                        </div>
                      )}
                      {Boolean(imgSrc) && (
                        <div>
                          {Boolean(completedCrop) && (
                            <div className="col-12">
                              <div>
                                <label className="form-label custom-label">
                                  Preview
                                </label>
                                <div>
                                  <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                      border: "1px solid black",
                                      objectFit: "cover",
                                      width:
                                        completedCrop.width <= 333
                                          ? completedCrop.width
                                          : 333,
                                      height: completedCrop.height,
                                    }}
                                  />
                                </div>
                                {Checked == "fix" &&
                                  (ImgData.height > Croperheightwidth.height ||
                                    ImgData.width >
                                      Croperheightwidth.width) && (
                                    <Alert severity="warning">
                                      Recomended Height Width is (
                                      {ImgData.height +
                                        "px" +
                                        "," +
                                        ImgData.width +
                                        "px"}
                                      )
                                    </Alert>
                                  )}
                                {Boolean(completedCrop) && (
                                  <div className="pt-2 pb-2 col-6 align-items-center">
                                    <div
                                      class="btn-group mr-2"
                                      role="group"
                                      aria-label="First group"
                                    >
                                      <div className="p-0 icon-container fit-content">
                                        <span className="mx-1 ">
                                          <Tooltip
                                            title="update Image"
                                            placement="top"
                                            arrow
                                          >
                                            {isLoading ? (
                                              <CircularProgress size="1.2rem" />
                                            ) : (
                                              <Done
                                                htmlColor="green"
                                                onClick={handlecrop}
                                              />
                                            )}
                                          </Tooltip>
                                        </span>
                                      </div>
                                      <div className="pr-1 icon-container fit-content">
                                        <span className="mx-1 ">
                                          <Tooltip
                                            title="Cancel Image"
                                            placement="top"
                                            arrow
                                          >
                                            <Close
                                              htmlColor="red"
                                              onClick={handleremoveIMG}
                                            />
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {/* image display */}

                  {PreviewImage.show && (
                    <div>
                      <img alt="Image" src={PreviewImage.src} />
                    </div>
                  )}

                  {/* <div className="col-12">
            <div className="row pt-3">
              <div className="col-5">
                <div className="mb-3">
                  <label for="edit-able-height-input" className="form-label">
                    Height
                  </label>
                  <input
                    className="form-control "
                    id="edit-able-height-input"
                    size="small"
                    label="Height"
                    type="number"
                    value={getEditAbleInputs("height")}
                    onChange={(e) => handleChangeInEditableInputs("height", e.target.value)}
                  />
                </div>
              </div>

              <div className="col-5">
                <div className="mb-3">
                  <label for="edit-able-width-input" className="form-label">
                    Width
                  </label>
                  <input
                    className="form-control "
                    id="edit-able-width-input"
                    size="small"
                    label="Width"
                    type="number"
                    value={getEditAbleInputs("width")}
                    onChange={(e) => handleChangeInEditableInputs("width", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div> */}
                  {/* image alignment */}
                  <div className="col-12 mt-2">
                    <div>
                      <label className="form-label custom-label">Align</label>
                    </div>
                    <ImageAlignBar
                      imageAlign={classes.imageAlign}
                      handleChangeInClass={handleChangeInClass}
                      classesList={image_alignment_classes}
                    />
                  </div>
                  <div className="col-12">
                    <div>
                      <label className="form-label custom-label">
                        Border Radius
                      </label>
                    </div>
                    <ImageTransformBar
                      imageTransform={classes.imageTransform}
                      handleChangeInClass={handleChangeInClass}
                      classesList={image_transform_classes}
                    />
                  </div>
                  <div className="col-12">
                    <div>
                      <label className="form-label custom-label">
                        Image Size
                      </label>
                    </div>
                    <ImageSizeBar
                      getEditAbleInputs={getEditAbleInputs}
                      handleChangeInEditableInputs={
                        handleChangeInEditableInputs
                      }
                      imageSize={classes.imageSize}
                      // customImageSize={classes.customImageSize}
                      handleChangeInClass={handleChangeInClass}
                      classesList={image_size_classes}
                    />
                  </div>

                  {/* <div className="col-12">
            <div>
              <label className="form-label">Image Size</label>
            </div>
            <ObjectFitBar
              objectFit={classes.objectFit}
              handleChangeInClass={handleChangeInClass}
              classesList={object_fit_classes}
            />
          </div> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      {getSelectedElementTag() == "I" && (
        <div className="col-12 ">
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mb-0">Icon Settings</h6>
            <div style={{ display: "flex" }}>
              <Tooltip title="Remove Icon" placement="bottom" arrow>
                <div className="icon icon-delete me-2" onClick={handleRemove}>
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </Tooltip>

              <ElementSourceCodeEditorBar />

              <Tooltip title="Close" placement="bottom" arrow>
                <div className="icon me-2" onClick={handleCancel}>
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="col-12">
            <label className="form-label custom-label">Icon Preview:</label>
          </div>
          <div className="col-12 mb-3">
            <i ref={previewIconRef} className={getIconClass()}></i>
          </div>
          <div className="col-12 mb-3">
            <label className="form-label custom-label">Choose Icon:</label>
            <IconSelect
              label="Icon"
              previewRef={previewIconRef}
              iconClass={getIconClass()}
              data={fontAwesomeIcons}
              handleChange={handleChangeIcon}
            />
          </div>
          <div className="col-12">
            <div className="mb-3">
              <label
                for="edit-able-text-input"
                className="form-label custom-label"
              >
                Icon Size
              </label>

              <select
                className="w-100 form-control custom-icon-select"
                value={classes.fontSize}
                onChange={(e) =>
                  handleChangeInClass(
                    "fontSize",
                    e.target.value,
                    font_sizes_classes
                  )
                }
              >
                <option value="">Reset</option>
                {font_sizes_classes.map((fs, i) => (
                  <option key={i} value={fs.value}>
                    {fs.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 mb-3">
            <ButtonProperty
              GetProperty={getproperty}
              label={"Icon Color:"}
              ResetProperty={ResetTextColor}
              property={"color"}
              handleChangeProperty={(property, value) =>
                handleChangeTextColor(value)
              }
              colors={colors}
            />
          </div>
        </div>
      )}
      {/* {isParentAnchorTag() && (
        <div className="col-12 mb-3">
          <Tooltip title="Link" arrow placement="top">
            <span>
              <InsertLinkIcon />
            </span>
          </Tooltip>
          <IconLink
            iconLink={getSelectedIconLink}
            handleUpdate={updateSelectedIconLink}
          />
        </div>
      )} */}
      {(getSelectedElementTag() === "INPUT" ||
        getSelectedElementTag() === "TEXTAREA") && (
        <div className="col-12">
          <InputProperties
            handleCancel={handleCancel}
            handleRemove={handleRemove}
          />
        </div>
      )}
      {/* selected text color */}
      {isTagText() && (
        <ButtonProperty
          GetProperty={getproperty}
          label={"Text Color"}
          ResetProperty={ResetProperty}
          property={"color"}
          handleChangeProperty={handleChangeProperty}
          colors={colors}
          rootValues={rootValues}
        />
      )}
      {(selectedElementAttributes.type === "A" || isParentAnchorTag()) && (
        <div className="row">
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Link</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="col-12">
                  <div className="row align-items-center">
                    <div className="col-10 ">
                      <div>
                        <span>
                          <label
                            for="edit-able-href-input"
                            className="col d-flex  form-label custom-label"
                          >
                            Link to
                          </label>
                        </span>
                        <div className="custom">
                          <select
                            value={LinkType}
                            onChange={(e) => handleChangeLinkType(e)}
                            name="linkSelect"
                            id="linkSelect"
                            className="form-control custom-icon-select form-control-sm pt-1 mb-3"
                          >
                            <option value="custom">Custom Link</option>
                            <option value="pages">Pages</option>
                            <option value="section">Section</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {LinkType == "section" && (
                    <div className="row align-items-center">
                      <div className="col-10 ">
                        <div>
                          <span>
                            <Tooltip arrow placement="top" title="Link">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-link"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                              </svg>
                            </Tooltip>
                          </span>
                          <div className="custom">
                            <select
                              value={selectedElementAttributes?.href?.replace(
                                "/",
                                ""
                              )}
                              onChange={(e) => handleChangeInSelect(e)}
                              name="linkSelect"
                              id="linkSelect"
                              className="form-control custom-icon-select form-control-sm"
                            >
                              {!sectionDatasets.length < 1 &&
                                sectionDatasets.map((data, i) => {
                                  return (
                                    <option key={i} value={data.section_id}>
                                      {data.section_title}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {LinkType == "pages" && (
                    <div className="row align-items-center">
                      <div className="col-6 ">
                        <div>
                          <span>
                            <Tooltip arrow placement="top" title="Link">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-link"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                              </svg>
                            </Tooltip>
                          </span>
                          <div className="custom">
                            <select
                              value={selectedElementAttributes?.href?.replace(
                                "/",
                                ""
                              )}
                              onChange={(e) => handleChangeInSelect(e)}
                              name="linkSelect"
                              id="linkSelect"
                              className=" form-control custom-icon-select form-control-sm"
                            >
                              <option value={"#"}>Select any page </option>
                              {rootValues.page_list.length > 0 &&
                                rootValues.page_list.map((page, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={page.page_slug + ".html"}
                                    >
                                      {page.page_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 mt-1 pt-3 ">
                        <span>
                          <Tooltip title={"Reset"} placement="top" arrow>
                            <span
                              className={`wb-icons-box ${
                                !selectedElementAttributes.target &&
                                "wb-icons-box-active"
                              }`}
                              onClick={(e) => handleHref("remove")}
                            >
                              <Icon icon="ant-design:stop-outlined" />
                            </span>
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            title={"Open in new tab"}
                            placement="top"
                            arrow
                          >
                            <span
                              onClick={(e) => handleHref("add")}
                              className={
                                selectedElementAttributes.target === "_blank"
                                  ? "wb-icons-box-active"
                                  : "wb-icons-box"
                              }
                            >
                              <Icon icon="ic:baseline-open-in-new" />
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  )}

                  {LinkType == "custom" && (
                    <>
                      <div className="row align-items-center">
                        <div className="col-6 ">
                          <div>
                            <span>
                              <Tooltip arrow placement="top" title="Link">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-link"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                </svg>
                              </Tooltip>
                            </span>
                            <div className="custom">
                              <input
                                value={selectedElementAttributes.href}
                                onChange={(e) => handleInputChange(e)}
                                name="link"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mt-1 pt-3 ">
                          <span>
                            <Tooltip title={"Reset"} placement="top" arrow>
                              <span
                                className={`wb-icons-box ${
                                  !selectedElementAttributes.target &&
                                  "wb-icons-box-active"
                                }`}
                                onClick={(e) => handleHref("remove")}
                              >
                                <Icon icon="ant-design:stop-outlined" />
                              </span>
                            </Tooltip>
                          </span>
                          <span>
                            <Tooltip
                              title={"Open in new tab"}
                              placement="top"
                              arrow
                            >
                              <span
                                onClick={(e) => handleHref("add")}
                                className={
                                  selectedElementAttributes.target === "_blank"
                                    ? "wb-icons-box-active"
                                    : "wb-icons-box"
                                }
                              >
                                <Icon icon="ic:baseline-open-in-new" />
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <input
                className="w-100 form-control"
                id="edit-able-href-input"
                label="Link"
                value={getEditAbleInputs("href")}
                onChange={(e) => handleChangeInEditableInputs("href", e.target.value)}
              /> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      {/* -----------------------buttons properties */}
      {(selectedElementAttributes.type === "A" ||
        isParentAnchorTag() ||
        getSelectedElementTag() === "BUTTON") && (
        <>
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Button Properties</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="col-12 mb-3">
                  <div>
                    <label className="form-label custom-label">
                      Border Radius
                    </label>
                  </div>
                  <ButtonTransformBar
                    ButtonTransform={classes.imageTransform}
                    handleChangeInClass={handleChangeInClass}
                    classesList={button_radius_classes}
                  />
                </div>
                <div className="col-12 mb-3">
                  <div>
                    <label className="form-label custom-label">
                      Button Variant
                    </label>
                  </div>
                  <ButtonVariant
                    handleChangeInClass={handleChangeInClass}
                    classesList={button_variant_classes}
                  />
                </div>

                {/* background color */}
                <ButtonProperty
                  GetProperty={getproperty}
                  label={"Background Color"}
                  ResetProperty={ResetProperty}
                  property={"background-color"}
                  handleChangeProperty={handleChangeProperty}
                  colors={colors}
                />

                {/* border color */}
                <ButtonProperty
                  GetProperty={getproperty}
                  label={"Boder Color"}
                  ResetProperty={ResetProperty}
                  property={"border-color"}
                  handleChangeProperty={handleChangeProperty}
                  colors={colors}
                />

                <BorderWidth
                  handleResetBorder={handleResetBorder}
                  ResetBorder={ResetBorder}
                  border_classes={button_width_classes}
                  updateSelectedElementBorderWidth={
                    updateSelectedElementBorderWidth
                  }
                  updateSelectedElementBorderWidthClass={
                    updateSelectedElementBorderWidthClass
                  }
                  getSelectedElementBorderWidthClass={
                    getSelectedElementBorderWidthClass
                  }
                  getSelectedElementBorderWidth={getSelectedElementBorderWidth}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}
      {/* Paddings */}
      {getIsDevState() && (
        <div className="col-12">
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Padding</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PaddingSpacing />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      {/* Margins */}
      {getIsDevState() && (
        <div className="col-12">
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Margin</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MarginSpacing />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
      <div className="col-12 mb-3">
        {!isValidSize && getSelectedElementTag() == "IMG" ? (
          <p className="text-danger">{`Minimum Height and Width is ${sizeLimit} !`}</p>
        ) : null}
      </div>
      {/* <div className="col-12 mt-4 ">
        {getSelectedElementTag() !== "I" && (
          <button
            type="button"
            className="btn btn-success me-1"
            onClick={handleSave}
            disabled={getSelectedElementTag() == "IMG" ? !isValidSize : false}
          >
            Update
          </button>
        )}
        <button
          type="button"
          className="btn btn-secondary me-1"
          onClick={handleCancel}
        >
          {getSelectedElementTag() === "I" ? "Close" : "Cancel"}
        </button>

        <button
          type="button"
          className="btn btn-danger float-right"
          onClick={handleRemove}
        >
          Remove
        </button>
      </div> */}
      <div className="col-12 text-right mt-5"></div>
    </div>
  );
}

export default RightMenu;
