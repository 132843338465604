import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { template } from "lodash";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState, useEffect } from "react";
function SelectTemplatePages({
  open,
  setOpen,
  TargetTemplate,
  TargetProject,
  handleDone,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [TemplatePageData, setTemplatePageData] = useState([]);
  const [ProjectPageSlug, setProjectPageSlug] = useState([]);
  const [Loading, setLoading] = useState(true);
  const handleCheck = async () => {
    setLoading(true);
    let procede = false;
    TemplatePageData.map((page) => {
      if (page.Selected) {
        procede = true;
      }
    });
    if (procede) {
      let result = await handleDone(TemplatePageData, setOpen);
    } else {
      setLoading(false);
      enqueueSnackbar(
        "Please select at least 1 page from template pages list",
        { variant: "error" }
      );
    }
  };
  useEffect(() => {
    setLoading(false);
    let data = [];
    let slugs = [];
    TargetTemplate.Pages.map((Page) => {
      data.push({
        Selected: false,
        TemplatePageName: Page.page_slug,
        MakeCopy: true,
        PageValues: { ...Page },
        UpdateCurrentPage: {
          value: false,
          index: -1,
          slug: "",
        },
      });
    });
    TargetProject.pages.map((Page, index) => {
      slugs.push(Page.page_slug);
    });
    setTemplatePageData([...data]);
    setProjectPageSlug([...slugs]);
  }, [TargetTemplate, TargetProject]);

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {/* <div> */}
          <label style={{ fontSize: 16 }} className=" col-12 custom-label">
            Please select pages from template page list and choose action you
            want to perform
          </label>
          <div className="row ">
            <div
              className="p-2 col card"
              style={{
                height: 250,
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="">
                <label className="custom-label">Template Pages List</label>
              </div>
              {TemplatePageData.map((Page, index) => (
                <div
                  className=" form-checkbox-color pointer  rounded"
                  style={{
                    width: "100%",
                    height: 40,
                    marginBottom: 10,
                    paddingTop:
                      ProjectPageSlug.length > 0 &&
                      ProjectPageSlug.includes(Page.TemplatePageName)
                        ? 3
                        : 9,
                    paddingBottom:
                      ProjectPageSlug.length > 0 &&
                      ProjectPageSlug.includes(Page.TemplatePageName)
                        ? 0
                        : 9,
                  }}
                >
                  <div
                    style={{
                      userSelect: "none",
                    }}
                    onClick={() => {
                      TemplatePageData[index].Selected =
                        !TemplatePageData[index].Selected;
                      setTemplatePageData([...TemplatePageData]);
                    }}
                    key={index}
                    className="border px-2 mx-1 rounded form-checkbox-color d-flex align-items-center pointer"
                  >
                    <input
                      type="checkbox"
                      checked={Page.Selected}
                      className="pointer"
                    />
                    <label className="px-2 custom-label d-flex align-items-center pointer text-capitalize">
                      {Page.TemplatePageName}
                    </label>
                  </div>

                  {/* options to add page */}
                  {ProjectPageSlug.length > 0 &&
                    ProjectPageSlug.includes(Page.TemplatePageName) && (
                      <div
                        style={{
                          userSelect: "none",
                          pointerEvents: Page.Selected ? "auto" : "none",
                          opacity: Page.Selected ? "1" : "0.3",
                        }}
                        className="mb-2 px-4 pb-1 mx-1 d-flex align-items-center pointer"
                      >
                        <div style={{ paddingRight: 10 }} className="d-flex">
                          <input
                            type="radio"
                            id={`copy${index}`}
                            name={`copy${index}`}
                            checked={Page.Selected && Page.MakeCopy}
                            onChange={() => {
                              TemplatePageData[index].MakeCopy = true;

                              setTemplatePageData([...TemplatePageData]);
                            }}
                            className="pointer"
                          />
                          <label
                            htmlFor={`copy${index}`}
                            style={{ fontSize: 10 }}
                            className="px-1 d-flex align-items-center pointer"
                          >
                            Make a copy
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id={`overwrite${index}`}
                            name={`overwrite${index}`}
                            onChange={() => {
                              TemplatePageData[index].MakeCopy = false;

                              setTemplatePageData([...TemplatePageData]);
                            }}
                            checked={Page.Selected && !Page.MakeCopy}
                            className="pointer"
                          />
                          <label
                            htmlFor={`overwrite${index}`}
                            style={{ fontSize: 10 }}
                            className="px-1 d-flex align-items-center pointer"
                          >
                            Update existing file
                          </label>
                        </div>
                        {/* <div className="d-flex">
                          <input
                            type="radio"
                            id={`current${index}`}
                            name={`current${index}`}
                            onChange={() => {
                              TemplatePageData[index].MakeCopy = false;

                              setTemplatePageData([...TemplatePageData]);
                            }}
                            checked={Page.Selected && !Page.MakeCopy}
                            className="pointer"
                          />
                          <label
                            htmlFor={`current${index}`}
                            style={{ fontSize: 10 }}
                            className="d-flex align-items-center pointer"
                          >
                            Update in selected page
                          </label>
                        </div> */}
                      </div>
                    )}
                </div>
              ))}
            </div>
            <div
              className="p-2 col card"
              style={{
                height: 250,
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="">
                <label className="custom-label">
                  {" "}
                  Pages exist with same name in project
                </label>
              </div>
              {TargetProject.pages.map((Page, index) =>
                TemplatePageData.map(
                  (TemplatePage) =>
                    TemplatePage.TemplatePageName == Page.page_slug && (
                      <div
                        key={index}
                        className=" form-checkbox-color d-flex align-items-center rounded"
                        style={{
                          width: "100%",
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginBottom: 10,
                        }}
                      >
                        <label className="px-3 custom-label text-capitalize">
                          {Page.page_slug}
                        </label>
                      </div>
                    )
                )
              )}
            </div>
          </div>
          {/* </div> */}
        </DialogTitle>
        <DialogActions>
          <Button disabled={Loading} onClick={handleCloseDialog}>
            Cancel
          </Button>
          <LoadingButton loading={Loading} onClick={handleCheck}>
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectTemplatePages;
