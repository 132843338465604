import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {
  _save_user_design_html,
  _save_user_design,
} from "../../../../DAL/projects/projects";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import { PhotoCamera, SettingsPowerRounded } from "@mui/icons-material";
import { s3baseUrl } from "../../../../config/config";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function EditProjectThumbnail({
  open,
  setOpen,
  EditProjectThumbnail,
  TargetProject,
}) {
  const navigate = useNavigate();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [apiCallOnReset, setApiCallOnReset] = useState(false);
  const [buttonVisibility, setButtonVisibility] = useState(false);
  const [inputImage, setInputImage] = useState();
  const [previousImagelink, setPreviousInputImagelink] = useState("");
  const [selectedimage, setSelectedImage] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setIsLoading(true);
    if (buttonVisibility == false) {
      if (selectedimage) {
        const resp = await _s3upload(`${TargetProject._id}`, inputImage);

        EditProjectThumbnail(resp.file_name);
      } else {
        // EditProjectThumbnail(TargetProject.image);
        handleClose();
      }
    } else {
      if (apiCallOnReset== true) {
        const imagepath = TargetProject._id + "/asserts/index";

        EditProjectThumbnail(imagepath);
      } else {
        handleClose();
      }
    }
  };

  const handleResetImage = () => {
    setButtonVisibility(true);
    setApiCallOnReset(true)
    setSelectedImage("");
    const image = TargetProject._id + "/asserts/index";

    setPreviousInputImagelink(image);
  };

  const handleImage = (e) => {
    e.preventDefault();
    setButtonVisibility(false);
    setInputImage(e.target.files[0]);

    let selectedFile = e.target.files[0];

    const imagesArray = URL.createObjectURL(selectedFile);

    setSelectedImage(imagesArray);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
    setDisabled(false);
  };
  useEffect(() => {
    setPreviousInputImagelink(TargetProject.image);
    if (TargetProject.image === TargetProject._id + "/asserts/index") {
      setButtonVisibility(true);
    } else {
      setButtonVisibility(false);
    }
    setIsLoading(false);
  }, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ ...style, width: "35%" }} className="modal-theme">
            <div clo-12 className="text-end modalIcon">
              <CloseIcon onClick={handleClose} className="pointer" />
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Edit Project Thumbnail
            </Typography>

            <div className="col-12 mt-3">
              <img
                src={
                  selectedimage
                    ? selectedimage
                    : s3baseUrl + "/" + previousImagelink
                }
                width="100%"
                height="140%"
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                }}
              />

              <FormControl
                className="edit-thumbnail-action rounded-card"
                style={{ ...style, pl: 0 }}
              >
                <Button
                  className="main-dashboard-website-action-btn visible-parent-hover"
                  component="label"
                  disabled={disabled}
                >
                  Change Image
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
                <Button
                  type="button"
                  hidden={buttonVisibility}
                  className="main-dashboard-website-action-btn visible-parent-hover mt-2"
                  disabled={disabled}
                  onClick={handleResetImage}
                >
                  Reset Default
                </Button>
              </FormControl>
            </div>

            <div className="col-12 mt-3">
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Thumbnail
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
