import { _dispatch_find_element_by_id_and_child } from "../../utils";

const _dispatch_update_styles_in_dom = ({ id, child, property, value }) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  if (!section) {
    console.error("No section found", section);
    return;
  }
  if (value == "") {
    section.style[property] = "";
  } else {
    section.style[property] = value;
  }
};

const _dispatch_get_styles_from_dom = ({ id, child, property }) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  if (!section) {
    console.error("No section found", section);
    return;
  }
  const { offsetHeight, offsetWidth } = section;
  section.setAttribute("imgheight", offsetHeight);
  section.setAttribute("imgwidth", offsetWidth);
  const value = section.style[property];
  return value;
};
const _dispatch_get_heightwidth_from_dom = (id, child) => {
  if (!id) {
    console.error("Id is undefined", id);
    return;
  }

  let section = _dispatch_find_element_by_id_and_child(id, child);

  const { offsetHeight, offsetWidth } = section;
  return { offsetHeight, offsetWidth };
};

const _dispatch_update_styles_by_element_in_dom = ({
  element,
  property,
  value,
}) => {
  if (!element) {
    console.error("No element found", element);
    return;
  }

  element.style[property] = value;
};

const _dispatch_get_styles_by_element_from_dom = ({ element, property }) => {
  if (!element) {
    console.error("No element found", element);
    return;
  }

  const value = element.style[property];
  return value;
};

export {
  _dispatch_update_styles_in_dom,
  _dispatch_get_styles_from_dom,
  _dispatch_get_heightwidth_from_dom,
  _dispatch_update_styles_by_element_in_dom,
  _dispatch_get_styles_by_element_from_dom,
};
