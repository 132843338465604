import { asserts_base_url } from "../config/config";
import { _making_zip_of_project } from "../DAL/download/download";
import {
  _get_user_design_details,
  _save_user_design,
} from "../DAL/projects/projects";
import { GetSectionCss } from "./sectionData";
// important variables
let elementCountList = {};
let sectionCss = "";
var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");
var myInit = {
  method: "GET",
  headers: myHeaders,
};

// functions
const fetchFileFromURL = (url) => {
  return new Promise(async (resolve, reject) => {
    const resp = await fetch(url, myInit);
    if (resp.status === 200) {
      const data = await resp.text();
      resolve(data);
    } else {
      console.error(resp);
      resolve("");
    }
  });
};

export const getNestedElementStyles = async (element, sectionName) => {
  if (elementCountList[element.tagName]) {
    elementCountList[element.tagName] += 1;
  } else {
    elementCountList[element.tagName] = 1;
  }

  if (
    element &&
    element.style &&
    element.style.cssText &&
    element.style.cssText.length > 0
  ) {
    let className = `section-${sectionName}-${element.tagName}-${
      elementCountList[element.tagName]
    }`;
    let CustomClassString = `.${className}{
    ${element.style.cssText}
          }`;

    sectionCss = `
        ${sectionCss}
          ${CustomClassString}`;

    element.removeAttribute("style");
    element.classList.add(className);
  }

  //   if have multiple childrens
  if (element.children && element.children.length > 0) {
    let children = element.children;
    for (let i = 0; i < children.length; i++) {
      await getNestedElementStyles(children[i], sectionName);
    }
  }
  return true;
};

export const getPageSections = async (element, css) => {
  let sectionList = [...element.getElementsByTagName("section")];
  elementCountList = {};
  let new_css = "";
  let new_html = "";
  for (let i = 0; i < sectionList.length; i++) {
    let sectionName = sectionList[i].getAttribute("data-section_name");
    sectionCss = GetSectionCss(css, sectionName);
    sectionCss = `${sectionCss ? sectionCss.trim() : ""}
    
      /* ==========================================================================
  section Custom Classes
  ========================================================================== */
  `;
    await getNestedElementStyles(
      sectionList[i],
      sectionName ? sectionName : `Section-${i}`
    );
    new_css += `
  /*----[[${sectionName}]]---*/

  ${sectionCss.trim()}

  /*----${sectionName}----*/
  `;

    new_html += sectionList[i].outerHTML;
  }

  return { new_html, new_css };
};
export const getSubstring = (str, start, end) => {
  let char1 = str.indexOf(start) + 1;
  let char2 = str.lastIndexOf(end);
  return str.substring(char1, char2);
};
export const fetchPageData = async (baseURL, projectID, pageSlug) => {
  let HTML_URL = `${baseURL}/${projectID}/${pageSlug}.html`;
  let CSS_URL = `${baseURL}/${projectID}/css/${
    pageSlug == "index" ? "style" : pageSlug
  }.css`;
  let JS_URL = `${baseURL}/${projectID}/js/${pageSlug}.js`;

  const resp = await Promise.all([
    fetchFileFromURL(HTML_URL),
    fetchFileFromURL(CSS_URL),
    fetchFileFromURL(JS_URL),
  ]);
  return {
    old_html: resp[0],
    old_css: resp[1],
    old_js: resp[2],
  };
};

const convertStyleToClass = async (page, baseURL, projectID) => {
  let pageSlug = page.page_slug;

  let { old_html, old_css, old_js } = await fetchPageData(
    baseURL,
    projectID,
    pageSlug
  );
  let element = document.createElement("div");

  element.innerHTML = old_html;

  //   active compain div
  let active_compain = element.getElementsByTagName("div")[0];
  let page_head = await getSubstring(old_html, "<head>", "</head>");

  let { new_html, new_css } = await getPageSections(element, old_css);
  let new_js = old_js;
  new_html = `
  <!DOCTYPE html>
  <html lang="en">
  <${page_head}

  </head>

  <body>
     ${active_compain.outerHTML}

        ${new_html}

        <!-- Body Script Start-->
        ${page.body_script ? page.body_script : ""}
        <!-- Body Script End -->

        <script src="https://code.jquery.com/jquery-3.6.0.js"
      integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
        <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
      
      <!-- font-awesome 6 -->
      <script
        src="https://kit.fontawesome.com/e30259c958.js"
        crossorigin="anonymous"
      ></script>
      
      <!-- Carousel -->
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      ></script>

      <script src="${asserts_base_url}/asserts-db/js/index.js" crossorigin="anonymous"></script>
      <script src="js/${pageSlug}.js"></script>
      
    </body>
  </html>
  `;
  const postData = {
    html: new_html,
    css: new_css,
    javascript: new_js,
    project_id: projectID,
    slug_name: pageSlug,
  };

  await _save_user_design(postData);
  return {
    html: old_html,
    css: old_css,
    javascript: old_js,
    project_id: projectID,
    slug_name: pageSlug,
  };
};
const handleDownload = async (baseURL, projectID) => {
  const postData = {
    project_name: `${projectID}`,
  };
  const resp = await _making_zip_of_project(postData);

  const zip_path = `${baseURL}/${projectID}/${projectID}.zip`;
  // let blob = await fetch(zip_path, myInit).then((res) => res.blob());

  // saveAs(blob, `${name}.zip`);
  return zip_path;
};
const revert_Old_HTML_CSS_JS = async (data) => {
  const resp = await _save_user_design(data);
  return resp;
};
export const getPageHTML_CSS_JS = async (baseURL, projectID) => {
  const resp = await _get_user_design_details(projectID);
  if (resp.code != 200) {
    return false;
  }
  let pagesDataList = [];

  let pages = [];
  for (let i = 0; i < 1; i++) {
    pages.push(convertStyleToClass(resp.Pages[i], baseURL, projectID));
  }
  pagesDataList = await Promise.all([...pages]);
  let zip_path = await handleDownload(baseURL, projectID);


// revert
  for (let i = 0; i < resp.Pages.length; i++) {
    pages.push(revert_Old_HTML_CSS_JS(pagesDataList[i]));
  }
  await Promise.all([...pages]);

  let download_button = document.createElement("a");
  download_button.href = zip_path;
  download_button.click();
  return true
};
