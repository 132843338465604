import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Chip, TextField, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import {
  _add_domain_against_project,
  _add_ssl_against_domain,
  _get_user_design_details,
  _save_user_active_campaign,
} from '../../../../DAL/projects/projects'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Loader from '../../../../components/Loader/Loader'
import { _get_user, _set_user } from '../../../../DAL/localStorage/localStorage'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CopyToClipboard from 'react-copy-to-clipboard'
function CustomDomain(props) {
  const { project, instanceIP } = props
  const params = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState({
    domain: '',
    enable_ssl: false,
    expanded: true,
  })

  const [sslLoading, setSSLLoading] = useState(false)
  //

  const handleChangeActiveExpand = (value) => {
    setData({ ...data, expanded: value })
  }

  const handleChangeCompanionLoader = (loading, enable_ssl) => {
    setData({ ...data, isLoading: loading, enable_ssl: enable_ssl })
  }

  const handleChangeActiveCampaign = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleEnableSSL = async () => {
    setSSLLoading(true)

    const project_id = params?.project_id
    const postData = {
      project_id: project_id,
      domain: data.domain,
    }

    const resp = await _add_ssl_against_domain(postData)
    setSSLLoading(false)
    if (resp.code === 200) {
      enqueueSnackbar(
        'SSL enabled successfully. It will take some time to complete.',
        {
          variant: 'success',
        },
      )
      fetchProjectData()
      handleChangeCompanionLoader(false, true)
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
      handleChangeCompanionLoader(false, true)
    }
  }
  const CheckDomain = (domain) => {
    if (domain.length == 0) {
      return true
    } else if (/([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/.test(domain)) {
      return true
    } else {
      return false
    }
  }
  const handleSubmitActiveCompanion = async (e) => {
    e.preventDefault()

    const project_id = params?.project_id
    const postData = {
      project_id: project_id,
      domain: data.domain,
    }

    if (data.domain.search('http') !== -1) {
      enqueueSnackbar('Please add domain without http:// or https://.', {
        variant: 'error',
      })
      return
    }
    if (CheckDomain(data.domain)) {
      handleChangeCompanionLoader(true, false)
      const resp = await _add_domain_against_project(postData)

      if (resp.code === 200) {
        enqueueSnackbar('Domain added successfully', {
          variant: 'success',
        })
        handleChangeCompanionLoader(false, true)
      } else {
        enqueueSnackbar(resp.message, { variant: 'error' })
        handleChangeCompanionLoader(false, false)
      }
    } else {
      setSSLLoading(false)
      enqueueSnackbar('Invalid Domain Name', { variant: 'error' })
    }
  }

  const handleClickIP = () => {}
  const handleCopied = () => {
    enqueueSnackbar('Copied to clipboard!', {
      variant: 'success',
    })
  }

  const fetchProjectData = async () => {
    const user = _get_user()

    setData({
      ...data,
      domain: project.domain,
      enable_ssl: project.domain ? true : false,
    })
  }

  const handleEditDomain = () => {
    setData({ ...data, enable_ssl: false })
  }

  useEffect(() => {
    fetchProjectData()
  }, [])

  return (
    <>
      <>
        <div className="row mt-2">
          <div className="col-12">
            <Accordion expanded={data.expanded} className="card">
              <AccordionSummary
                onClick={() => handleChangeActiveExpand(!data.expanded)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Custom Domain Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmitActiveCompanion}>
                  <div className="row  justify-content-end">
                    <div className="col-12">
                      <h6>Instructions:</h6>
                      <ol>
                        <li>
                          Sign in to your <strong>Domain Name Provider</strong>,
                          Inc.
                        </li>
                        <li>
                          Go to the&nbsp;<strong>Manage</strong> option
                          for&nbsp;the domain.
                        </li>
                        <li>
                          Go to the <strong>DNS settings</strong> for the
                          domain, Inc.
                        </li>

                        <li>
                          Now, add this <strong>IP</strong> as{' '}
                          <strong>ARecord</strong>:
                          <CopyToClipboard
                            onCopy={handleCopied}
                            text={instanceIP}
                          >
                            <Chip
                              className="ms-2"
                              label={
                                <>
                                  {instanceIP}{' '}
                                  <ContentCopyIcon fontSize="small" />
                                </>
                              }
                              onClick={handleClickIP}
                              deleteIcon={<ContentCopyIcon />}
                            />
                          </CopyToClipboard>
                        </li>
                      </ol>
                    </div>
                    {data.enable_ssl && (
                      <>
                        <div className="col-12 mt-3">
                          <TextField
                            fullWidth
                            label="Custom Domain URL"
                            required
                            name="domain"
                            value={data.domain}
                            disabled={data.enable_ssl}
                            onChange={handleChangeActiveCampaign}
                          />
                        </div>

                        {!project.ssl_status && (
                          <div className="col-12 col-lg-2 text-end mt-3">
                            <LoadingButton
                              fullWidth
                              size="large"
                              variant="outlined"
                              onClick={handleEnableSSL}
                              loading={sslLoading}
                            >
                              Enable SSL
                            </LoadingButton>
                          </div>
                        )}

                        <div className="col-12 col-lg-2 text-end mt-3">
                          <LoadingButton
                            fullWidth
                            size="large"
                            // variant="outlined"
                            onClick={handleEditDomain}
                          >
                            Edit Domain
                          </LoadingButton>
                        </div>
                      </>
                    )}

                    {!data.enable_ssl && (
                      <>
                        <div className="col-12 mt-3">
                          <TextField
                            fullWidth
                            label="Custom Domain URL"
                            required
                            name="domain"
                            value={data.domain}
                            onChange={handleChangeActiveCampaign}
                          />
                        </div>

                        <div className="col-12 col-lg-3 text-end mt-3">
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={data.isLoading}
                          >
                            Update
                          </LoadingButton>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    </>
  )
}

export default CustomDomain
