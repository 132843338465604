import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
function BackgroundSize({
  value,
  handleChangeInClass,
  classesList,
  OpenColumnStyle,
}) {
  if (!OpenColumnStyle) {
    OpenColumnStyle = -1
  }
  return (
    <div className="row">
      <div className="col-12 mt-2">
        <span className="custom-label ">Background Size</span>
      </div>
      <div className="col-12">
        {classesList.map((bg_size) => (
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="backgroundSize"
              id={`${bg_size.value}${OpenColumnStyle}`}
              checked={value === bg_size.value}
              onChange={() =>
                handleChangeInClass(
                  'backgroundSize',
                  bg_size.value,
                  classesList,
                )
              }
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor={`${bg_size.value}${OpenColumnStyle}`}
            >
              {bg_size.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BackgroundSize
