import React, { useEffect, useState } from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Typography from "@mui/material/Typography";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";
import { Tooltip } from "@mui/material";
import { padding_classes } from "../../../../../../ui_libs/Constants/constant";
function RowColumnPadding({
  // padding_classes,
  updateSelectedElementSpacing,
  updateSelectedElementClass,
  getSelectedElementClassByType,
  getSelectedElementStyleByProperty,
}) {
  // pre-defined classes values
  const [Reset, setReset] = useState(false);

  const handleResetSpacing = () => {
    updateSelectedElementClass({
      class_list: padding_classes("top"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("bottom"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("left"),
      value: "",
    });
    updateSelectedElementClass({
      class_list: padding_classes("right"),
      value: "",
    });

    // RESEST CUSTOM PADDING
    updateSelectedElementSpacing("padding", ["top"], "");
    updateSelectedElementSpacing("padding", ["bottom"], "");
    updateSelectedElementSpacing("padding", ["left"], "");
    updateSelectedElementSpacing("padding", ["right"], "");
    setReset(!Reset);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Typography variant="body2">Padding</Typography>
        </div>
        <div className="col d-flex justify-content-end">
          <Tooltip title="Reset Paddings" arrow placement="top">
            <span>
              <RotateLeftIcon
                className="pointer"
                onClick={() => handleResetSpacing()}
              />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="row">
        {/* top */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={padding_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="padding"
          dimention="padding-top"
          label="top"
        />

        {/* bottom */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={padding_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="padding"
          dimention="padding-bottom"
          label="bottom"
        />

        {/* left */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={padding_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="padding"
          dimention="padding-left"
          label="left"
        />

        {/* right */}
        <CustomSpacing
          ResetValue={Reset}
          myclass={padding_classes}
          updateSelectedElementSpacing={updateSelectedElementSpacing}
          updateSelectedElementClass={updateSelectedElementClass}
          getSelectedElementClassByType={getSelectedElementClassByType}
          getSelectedElementStyleByProperty={getSelectedElementStyleByProperty}
          spacing_type="padding"
          dimention="padding-right"
          label="right"
        />
      </div>
    </>
  );
}

export default RowColumnPadding;
