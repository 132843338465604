import React, { useState, useEffect } from "react";

function BackgorundPosition({
  backgroundPosition,
  handleChangeInClass,
  classesList,
}) {

  return (
    <div className="row mt-2">
      <div className="col-12">
        <span className="custom-label">Background Position</span>
      </div>
      <div className="col-12 mt-2">
        <select
          className="w-100 form-control custom-icon-select"
          value={
            backgroundPosition != null ? backgroundPosition : "reset"
          }
          onChange={(e) =>
            handleChangeInClass(
              "BackgroudPosition",
              e.target.value,
              classesList
            )
          }
        >
          <option value=''>Reset</option>

          {classesList.map((fs, i) => (
            <option key={i} value={fs.value}>
              {fs.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default BackgorundPosition;
