import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomConfirmation from "../../../../../../components/CustomConfirmation";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
function VideoIframe({ getSelectedSectionFrames, handleChangeLayout }) {
  const { SaveActionToStack } = useBuilder();
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [targetIndex, setTargetIndex] = useState(-1);
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeInFrame = (e, i) => {
    let list = data.list.map((l) => l);
    list[i][e.target.name] = e.target.value;
    setData({ ...data, list });
  };

  const handleSave = () => {
    data.list.forEach((single_frame) => {
      console.log(single_frame, "single frame");
      const frame_string = single_frame.value.trim();
      const is_have_iframe = frame_string.search("<iframe");
      const is_have_iframe_close = frame_string.search("</iframe>");

      if (is_have_iframe === -1 || is_have_iframe_close === -1) {
        enqueueSnackbar("Please enter a valid embedded code", {
          variant: "error",
        });
        return;
      }

      single_frame.element.innerHTML = single_frame.value;
      setData(getSelectedSectionFrames());
      // single_frame.element.src = single_frame.src;
      // single_frame.element.width = single_frame.width;
      // single_frame.element.height = single_frame.height;
    });
  };
  const handleRemoveIFrame = (index) => {
    setTargetIndex(index);
    setOpenConfirmationPopup(true);
  };
  const handleConfirmRemoveIFrame = () => {
    SaveActionToStack();
    data.list[targetIndex].element.remove();
    data.list.splice(targetIndex, 1);
    setData({ ...data });
    setOpenConfirmationPopup(false);
  };

  useEffect(() => {
    setData(getSelectedSectionFrames());
  }, []);

  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Video I-Frame</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomConfirmation
          open={openConfirmationPopup}
          setOpen={setOpenConfirmationPopup}
          handleAgree={handleConfirmRemoveIFrame}
          title="Are sure you want to remove this I-Frame?"
        />
        <div>
          {data?.list.map((single_frame, i) => (
            <div className="row border m-1 mt-2 p-1">
              <div className="col-12">
                <label
                  for="edit-able-href-input"
                  class="form-label w-100 d-flex justify-content-between align-items-center"
                >
                  Embedded Code
                  <Tooltip title="Remove I-Frame" anchor placement="top">
                    <IconButton
                      onClick={() => {
                        handleRemoveIFrame(i);
                      }}
                    >
                      <CancelIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  {/* <span className="float-end">{i + 1}</span> */}
                </label>

                <textarea
                  className="w-100 form-control"
                  label="Link"
                  rows={4}
                  placeholder="<iframe..."
                  value={single_frame.value}
                  name="value"
                  onChange={(e) => handleChangeInFrame(e, i)}
                />
              </div>
              {/* <div className="col-12 align-self-center">
                <label for="edit-able-href-input" class="form-label mt-2">
                  Dimensions:
                </label>
              </div>

              <div className="col-6">
                <input
                  className="w-100 form-control"
                  type="number"
                  label="Link"
                  name="width"
                  placeholder="Width"
                  value={single_frame.width}
                  onChange={(e) => handleChangeInFrame(e, i)}
                />
              </div>
              <div className="col-6">
                <input
                  className="w-100 form-control"
                  type="number"
                  name="height"
                  label="Link"
                  placeholder="Height"
                  value={single_frame.height}
                  onChange={(e) => handleChangeInFrame(e, i)}
                />
              </div> */}
            </div>
          ))}
          <div className="row">
            <div className="col-12 mt-2 text-center">
              <button
                type="button"
                class="btn btn-success me-1"
                onClick={handleSave}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default VideoIframe;
