import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import InlineInputField from "../Inline-Input-Field/InlineInputField";
import CodeIcon from "@mui/icons-material/Code";
import ElementSourceCodeEditorBar from "../../../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";

function InputProperties({ handleCancel, handleRemove }) {
  const [Change, setChange] = useState(true);
  const { getSelectedElementAttributes, updateSelectedElementAttributes } =
    useBuilder();

  const [attributes, setAttributes] = useState({
    placeholder: "",
    name: "",
    required: "",
  });

  const handleChangeAttribute = (key, value) => {
    if (key == "required") {
      if (value) {
        setAttributes({
          ...attributes,
          ["placeholder"]: attributes.placeholder + "*",
          [key]: value,
        });

        handleChangeAttribute("placeholder", attributes.placeholder + "*");
      } else {
        const placeholder = attributes.placeholder.split("*")[0];
        setAttributes({
          ...attributes,
          ["placeholder"]: placeholder,
          [key]: value,
        });
        handleChangeAttribute("placeholder", placeholder);
      }
    }
    setAttributes({ ...attributes, [key]: value });
    updateSelectedElementAttributes(key, value);
    setChange(!Change);
  };

  const getElementAttributeProperty = (property_name) => {
    const element_properties = getSelectedElementAttributes();

    const attribute = element_properties.attributes.find(
      (attr) => attr.name === property_name
    );
    if (property_name == "required") {
      return attribute?.name ? true : false;
    } else {
      return attribute?.value;
    }
  };

  useEffect(() => {
    setAttributes({
      ...attributes,
      placeholder: getElementAttributeProperty("placeholder"),
      name: getElementAttributeProperty("name"),
      required: getElementAttributeProperty("required"),
    });
  }, [Change]);

  return (
    <div className="mb-2">
      <div
        className="col-12 pt-2 mb-2 pb-2 border-bottom"
        style={{
          position: "sticky",
          zIndex: "100",
          background: "white",
          top: "0%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 className="mb-0">Input Field Settings</h6>
        <div style={{ display: "flex" }}>
          <Tooltip title="Remove Input field" placement="bottom" arrow>
            <div className="icon icon-delete me-2" onClick={handleRemove}>
              <i class="fa-solid fa-trash-can"></i>
            </div>
          </Tooltip>
          <ElementSourceCodeEditorBar />
          <Tooltip title="Close" placement="bottom" arrow>
            <div className="icon me-2" onClick={handleCancel}>
              <i class="fa fa-xmark"></i>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="col-12 mt-3 mb-3">
        <label className="mb-1 custom-label" htmlFor="">
          Placeholder:
        </label>

        <InlineInputField
          placeholder="Field Placeholder"
          value={attributes.placeholder}
          handleUpdate={(val) => handleChangeAttribute("placeholder", val)}
        />
      </div>

      <div className="col-12">
        {attributes.name != "checkbox" && (
          <div className="col-12 ">
            <label className="mb-1 custom-label" htmlFor="">
              Input For:
            </label>
            <select
              className=" form-control custom-icon-select"
              name="name"
              id="name"
              value={attributes.name}
              onChange={(e) => handleChangeAttribute("name", e.target.value)}
            >
              <option value="">Choose Name</option>
              <option value="first_name">First Name</option>
              <option value="last_name">Last Name</option>
              <option value="full_name">Full Name</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
              <option value="message">Message</option>
            </select>
          </div>
        )}

        {/* requred input or not */}
        <div className="col-12 mt-2 d-flex custom-label align-items-center">
          <div>
            <label>Requrie Input : </label>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div
            class="form-check form-check-inline"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              class="form-check-input"
              type="radio"
              name="inputrequired"
              id="Yes"
              checked={attributes.required ? true : false}
              onChange={(e) => {
                handleChangeAttribute("required", true);
              }}
              style={{ marginTop: "0", marginRight: "0.5rem" }}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor="Yes"
            >
              Yes
            </label>
          </div>
          <div
            class="form-check form-check-inline"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              class="form-check-input"
              type="radio"
              name="inputrequired"
              id="No"
              checked={attributes.required ? false : true}
              onChange={(e) => handleChangeAttribute("required", false)}
              style={{ marginTop: "0", marginRight: "0.5rem" }}
            />
            <label
              className="form-check-label pointer custom-label"
              htmlFor="No"
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputProperties;
