import { _dispatch_find_element_by_id_and_child } from "../../utils";

const _dispatch_update_classes_by_element_in_dom = ({
  element,
  value,
  class_list,
}) => {
  if (!element) {
    console.error(
      "Id is undefined in _dispatch_update_classes_in_dom",
      element
    );
    return;
  }

  const classes = element.classList;
  class_list.forEach((fs) => {
    if (fs.value != "") {
      classes.remove(fs.value);
    }
  });
  if (value) {
    classes.add(value);
  }
};



const _dispatch_get_class_by_element_in_dom = ({ element, class_list }) => {
  if (!element) {
    console.error(
      "Id is undefined in _dispatch_update_classes_in_dom",
      element
    );
    return;
  }

  const classes = element.classList;
  let class_name = "";
  class_list.forEach((cl) => {
    classes.forEach((c) => {
      if (c === cl.value) {
        class_name = c;
      }
    });
  });

  return class_name;
};

const _dispatch_update_classes_in_dom = ({ id, child, value, class_list }) => {
  if (!id) {
    console.error("Id is undefined in _dispatch_update_classes_in_dom", id);
    return;
  }

  let element = _dispatch_find_element_by_id_and_child(id, child);

  if (!element) {
    console.error(
      "No element found in _dispatch_update_classes_in_dom",
      element
    );
    return;
  }

  const classes = element.classList;

  class_list.forEach((fs) => classes.remove(fs.value));
  if (value) {
    classes.add(value);
  }
};

const _dispatch_get_classes_from_dom = ({ id, child, class_list }) => {
  if (!id) {
    console.error("Id is undefined in _dispatch_update_classes_in_dom", id);
    return;
  }

  let element = _dispatch_find_element_by_id_and_child(id, child);

  if (!element) {
    console.error(
      "No element found in _dispatch_update_classes_in_dom",
      element
    );
    return;
  }

  const classes = element.classList;
  let class_name = "";
  class_list.forEach((cl) => {
    classes.forEach((c) => {
      if (c === cl.value) {
        class_name = c;
      }
    });
  });

  return class_name;
};

export {
  _dispatch_update_classes_in_dom,
  _dispatch_get_classes_from_dom,
  _dispatch_update_classes_by_element_in_dom,
  _dispatch_get_class_by_element_in_dom,
};
