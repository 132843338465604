import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";
import QuickSelection from "./components/Quick-Selection/QuickSelection";
import AdvanceColorSelection from "./components/Advance-Color-Selection/AdvanceColorSelection";
import ThemeSelection from "./components/Advance-Color-Selection/components/ThemeSelection";
import ThemeText from "./components/Advance-Color-Selection/components/TextSetting";

function RightMenu(props) {
  const { leaveHoverDrawer, selectedlistItem } = props;
  const {
    setRightThemeMenuOpen,
    handleUpdateElement,
    handleRemoveElement,
    rootValues,
    handleChangeRootValue,
  } = useBuilder();

  const [isLoading, setIsLoading] = useState(false);
  const [settingTab, setSettingTab] = useState("0");

  const handleCancel = () => {
    setRightThemeMenuOpen(false);
  };

  const handleSave = () => {
    handleUpdateElement();
  };
  const handleRemove = () => {
    handleRemoveElement();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          overflow: "hidden",
        }}
        className="transition"
      >
        <div
          className="border-bottom"
          style={{
            lineHeight: "41px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 8px 0 0",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "41px",
              width: "80%",
              justifyContent: "space-between",
            }}
          >
            <div
              className={
                settingTab === "0"
                  ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                  : "wb-style-selection-tab wb-style-selection-tab text-center"
              }
              style={{
                height: "42px",
                lineHeight: "42px",
                padding: "0px 0px",
                width: "100%",
              }}
              onClick={() => setSettingTab("0")}
            >
              <span className="pointer">Quick</span>
            </div>
            <div
              className={
                settingTab === "1"
                  ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                  : "wb-style-selection-tab wb-style-selection-tab text-center"
              }
              onClick={() => setSettingTab("1")}
              style={{
                height: "42px",
                lineHeight: "42px",
                padding: "0px 0px",
                width: "100%",
              }}
            >
              <span className="pointer">Color</span>
            </div>
            <div
              className={
                settingTab === "2"
                  ? "wb-style-selection-tab wb-style-selection-tab-active text-center"
                  : "wb-style-selection-tab wb-style-selection-tab text-center"
              }
              onClick={() => setSettingTab("2")}
              style={{
                height: "42px",
                lineHeight: "42px",
                padding: "0px 0px",
                width: "100%",
              }}
            >
              <span className="pointer">Typography</span>
            </div>
          </div>
          <div className="icon" onClick={leaveHoverDrawer}>
            <i class="fa fa-xmark"></i>
          </div>
        </div>
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            paddingBottom: "70px",
          }}
        >
          {settingTab === "0" && <QuickSelection />}
          {/* {settingTab === "1" && <AdvanceColorSelection />} */}
          {settingTab === "1" && <ThemeSelection />}
          {settingTab === "2" && <ThemeText />}
        </div>
      </div>
      {/* <div className="row m-0">
        <div
          className={
            settingTab === "0"
              ? "col-6 wb-style-selection-tab wb-style-selection-tab-active text-center"
              : "col-6 wb-style-selection-tab wb-style-selection-tab text-center"
          }
          onClick={() => setSettingTab("0")}
        >
          <span className="pointer">Quick Selection</span>
        </div>{" "}
        <div
          className={
            settingTab === "1"
              ? "col-6 wb-style-selection-tab wb-style-selection-tab-active text-center"
              : "col-6 wb-style-selection-tab wb-style-selection-tab text-center"
          }
          onClick={() => setSettingTab("1")}
        >
          <span className="pointer">Advance Selection</span>
        </div>
      </div> */}
      {/* {settingTab === "0" && <QuickSelection />}
      {settingTab === "1" && <AdvanceColorSelection />} */}
    </>
  );
}

export default RightMenu;
