import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import {  _sections_list } from "../../DAL/template/template";

import {
  base_uri,
  s3baseUrl,
  s3builderSource,
  asserts_base_url,
  client_side_url,
} from "../../config/config";
import {
  _add_domain_against_project,
  _make_unzip_file_of_project,
  _publish_project_on_domain,
  _save_user_design,
  _update_user_design_project,
} from "../../DAL/projects/projects";
import { GetSectionCss, GetSectionJS } from "../../utils/sectionData";
import { _update_user_design_page } from "../../DAL/projects-pages/projectspages";
import { _making_zip_of_project } from "../../DAL/download/download";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import {
  _dispatch_get_classes_from_dom,
  _dispatch_get_styles_from_dom,
  _dispatch_get_heightwidth_from_dom,
  _dispatch_update_classes_in_dom,
  _dispatch_update_styles_in_dom,
  _dispatch_update_classes_by_element_in_dom,
  _dispatch_get_class_by_element_in_dom,
  _dispatch_update_styles_by_element_in_dom,
  _dispatch_get_styles_by_element_from_dom,
  _dispatch_find_lists_by_id_and_child,
  _dispatch_find_i_frames_by_id,
  _dispatch_get_dataset_by_settingId,
  _dispatch_update_title_by_settingId,
  _dispatch_handle_href_target,
  _dispatch_find_form_by_id,
  _find_parent_row_of_element,
  _find_section_of_element,
  _find_is_editor_element,
} from "./utils";
import { _generate_random_string } from "./utils/Basic-Functions/Basic-Functions";
import { _get_user, _is_dev } from "../../DAL/localStorage/localStorage";
import { height } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { _s3upload_with_name } from "../../DAL/s3upload/s3upload";
import { element } from "prop-types";
import {
  InitializeTinyEditor,
  RemoveContentEditable,
  ShowEditor,
  CloseAllEditor,
} from "../../utils/inlineeTinyEditor";
import { get_user_gallery } from "../../DAL/User-Gallery/user_gallery";
import { detail_customer_by_token } from "../../DAL/customer/Customer";

var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");

var myInit = {
  method: "GET",
  headers: myHeaders,
};

const BuilderContext = React.createContext();

export const useBuilder = () => useContext(BuilderContext);
let FirstAttemp_Drop = true;
let current_target_element = "";
let current_hovering_over_element = "";

let previous_target_element = "";
let previous_hovering_over_element = "";

export function ContextBuilder({ children }) {
  /* ------------------------------------------------------
  ------------------| Hooks Functions |--------------------
  ------------------------------------------------------- */

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  -------------------| Drawers States |--------------------
  ------------------------------------------------------- */

  const [leftMenu, setLeftMenu] = useState("ALL");
  const [leftMenuOpen, setLeftMenuOpen] = useState(true);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const [editorMenuOpen, setEditorMenuOpen] = useState(false);
  const [OpenRowSetting, setOpenRowSetting] = useState(false);
  const [SelectedRow, setSelectedRow] = useState("");
  const [targetSectionId, setTargetSectionId] = useState("");
  const [SelectedRowClasses, setSelectedRowClasses] = useState([]);
  const [rightThemeMenuOpen, setRightThemeMenuOpen] = useState(false);
  const [mobilePreview, setMobilePreview] = useState(false);
  const [tabletPreview, setTabletPreview] = useState(false);
  const [changeInHTML, setChangeInHTML] = useState(false);
  const [previewURL, setPreviewURL] = useState("");
  const [sectionSettingsMenuOpen, setSectionSettingsMenuOpen] = useState(false);
  const [FormSettingsMenuOpen, setFormSettingsMenuOpen] = useState(false);

  const [developerOptionMenuType, setDeveloperOptionMenuType] =
    useState("source-code");
  const [openDeveloperOptionMenu, setOpenDeveloperOptionMenu] = useState(false);

  const navigate = useNavigate();
  /* ------------------------------------------------------
  -------------------/ Drawers States /--------------------
  ------------------------------------------------------- */

  //================= customer detail ================

  const [customerSubscriptionDetail, setCustomerSubscriptionDetail] =
    useState(null);

  // -------------------/ Modal States (start) /--------------------//

  const [headerContentModal, setHeaderContentModal] = useState(false);

  // -------------------/ Modal States (end) /--------------------//

  // -------------------/ Dialog States (start) /--------------------//
  const [isHeaderDialogOpen, setIsHeaderDialogOpen] = useState(false);
  // -------------------/ Dialog States (end) /--------------------//

  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSectionsIndex, setSelectedSectionsIndex] = useState(null);
  const [selectedSectionSettingId, setSelectedSectionSettingId] = useState("");

  const [editAbleInputs, setEditAbleInputs] = useState({
    text: "",
    href: "",
    src: "",
    width: "",
    height: "",
  });

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [selectedElement, setSelectedElement] = useState(null);
  const [openReplaceElementMenu, setOpenReplaceElementMenu] = useState(false);
  const [isReplaceableElement, setIsReplaceableElement] = useState(true);

  // SELECTED ELEMENT ATTRIBUTES STATES
  const [selectedElementAttributes, setSelectedElementAttributes] = useState(
    {}
  );

  const [rootValues, setRootValues] = useState({
    primary_color: "#FFFFFF",
    secondary_color: "#EB144C",
    theme_heading_color: "#222222",
    primary_text_color: "#000000",
    secondary_text_color: "#FFFFFF",
    hover_text_color: "#FFFFFF",
    hover_background_color: "#EB144C",
    font_family: "'Roboto', sans-serif",
    theme_box_shadow: "#ABB8C3",
    // further status
    theme_mode: "light",
    theme_h1_font_size: "2.500rem",
    theme_h2_font_size: "2.000rem",
    theme_h3_font_size: "1.750rem",
    theme_h4_font_size: "1.500rem",
    theme_p_font_size: "1.000rem",
    theme_h1_font_weight: "500",
    theme_h2_font_weight: "500",
    theme_h3_font_weight: "500",
    theme_h4_font_weight: "500",
    theme_p_font_weight: "500",
    theme_line_height: "1.5",
  });

  //<<<==========Edit mode========>>>
  const [editMode, setEditMode] = useState(true);
  const [isDev, setIsDev] = useState(false);
  //<<<==========Meta states========>>>
  const [metaStates, setMetaStates] = useState({
    pageTitle: "",
    pageDescription: "",
    keywords: "",
    favicon: "",
    socialTitle: "",
    socialPageUrl: "",
    socialDescription: "",
    socialImageUrl: "",
    robots: "",
    themeColor: "",
  });
  //<<<==========page handling ========>>>
  const [SelectedPage, setSelectedPage] = useState("index");
  const [UseEditingProject, setUseEditingProject] = useState(false);

  const [LastSaveData, setLastSaveData] = useState("");
  const editorRef = useRef(null);

  const [openSelectedBoxDiv, setOpenSelectedBoxDiv] = useState(false);
  const [selectedBoxDiv, setSelectedBoxDiv] = useState("");

  // user gallery
  const [userImagesList, setUserImagesList] = useState([]);
  const [searchImageList, setSearchImageList] = useState([]);

  // left menu
  const [leftDrawerType, setLeftDrawerType] = useState("closed");
  const [selectedlistItem, setSelectedlistItem] = useState("");
  const [drawerWidth, setDrawerWidth] = useState(50);

  // image Editor
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  // icon Editor
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const [iconEditorPosition, setIconEditorPosition] = useState("");
  // Right Menu
  const [rightMenuAnchorEl, setRightMenuAnchorEl] = useState(null);
  const [rightMenuList, setRightMenuList] = useState(null);

  // -================ customerSubscriptionDetail =================

  const _get_user_subscription = async () => {
    if (
      sessionStorage.getItem("token") &&
      customerSubscriptionDetail === null
    ) {
      const resp = await detail_customer_by_token();

      console.log(resp, "nccjdnjcnjdncjdjcjnd");
      if (resp.code == 200) {
        const sub = await _set_user_subscription(resp.customer);
        return sub;
      } else {
        return false;
      }
    } else {
      return customerSubscriptionDetail;
    }
  };
  const _set_user_subscription = async (data) => {
    setCustomerSubscriptionDetail({ ...data });
    return data;
  };

  useEffect(() => {
    _get_user_subscription();
  }, [useNavigate()]);

  /* ------------------------------------------------------
  *************DOM Manipulation General Functions**********
  ------------------------------------------------------- */
  /* ------------------------------------------------------
  -------------DOM Manipulation General Functions----------
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  *************DOM Manipulation Specific Functions*********
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  *************DOM Manipulation Specific Functions*******
  ------------------------------------------------------- */

  // =================-Gallery Work-===========

  const [userAssertDetails, setUserAssertDetails] = useState("");

  const GetImagesList = async () => {
    const resp = await get_user_gallery();
    if (resp.code == 200) {
      if (
        resp &&
        resp.customer &&
        resp.customer.gallery &&
        resp.customer.gallery.images &&
        resp.customer.gallery.images.length > 0
      ) {
        let images_list = [...resp.customer.gallery.images].reverse();
        setUserImagesList(images_list);
        setSearchImageList(images_list);
        updateUserAsserts(
          resp.customer.gallery.total_asserts_file_count,
          resp.customer.gallery.total_asserts_file_size
        );
      } else {
        setUserImagesList([]);
        setSearchImageList([]);
        updateUserAsserts(0, 0);
      }
    }
  };

  const updateUserAsserts = (file_count, file_size) => {
    let temp = { file_count: file_count, file_size: file_size };
    setUserAssertDetails({ ...temp });
  };

  const Get_Tag_and_Class = (element, name, search) => {
    let tagname = element.tagName;
    if (tagname == name) {
      let classname = element.className ? element.className : null;
      if (classname != null) {
        let result = classname.search(search);
        if (result >= 0) {
          return true;
        }
      }
    }
    return false;
  };

  const CheckElementIsRow = (element) => {
    let result = false;
    while (element.parentNode) {
      result = Get_Tag_and_Class(element, "DIV", "row");

      if (result) {
        let first_child = element.firstChild ? element.firstChild : null;
        let myresult = Get_Tag_and_Class(first_child, "DIV", "wp-row-block");
        if (!myresult) {
          // icon
          const icon = document.createElement("i");
          icon.classList.add("wp-row-click");
          icon.classList.add("fa-solid");
          icon.classList.add("fa-gear");
          icon.innerText = " Row";

          // outer div
          const outerdiv = document.createElement("div");
          // outerdiv.prepend(text);
          outerdiv.prepend(icon);
          outerdiv.classList.add("wp-row-block");

          element.prepend(outerdiv);
        }
        break;
      }

      element = element.parentNode;
    }
    element.addEventListener("mousedown", (e) => {
      
      e.stopImmediatePropagation();
      e.stopImmediatePropagation();
      let clicked_element = e.target;

      let parent = clicked_element.parentNode;

      if (Get_Tag_and_Class(clicked_element, "I", "wp-row-click")) {
        setSelectedRow(parent.parentNode);
        setSelectedElement(parent.parentNode);
        setSelectedRowClasses(parent.parentNode.classList);
        setTimeout(() => {
          setOpenRowSetting(true);
        }, 100);
        return;
      }
    });
  };

  const CheckElementIsContainBoxClas = (element) => {
    let result = false;
    while (element.parentNode) {
      result = Get_Tag_and_Class(element, "DIV", "wb-box");

      if (result) {
        let first_child = element.firstChild ? element.firstChild : null;
        let myresult = Get_Tag_and_Class(first_child, "DIV", "wp-box-block");
        if (!myresult) {
          // icon
          const icon = document.createElement("span");
          icon.classList.add("wp-box-click");
          // icon.classList.add("fa-solid");
          // icon.classList.add("fa-gear");
          icon.innerText = " Box";

          // outer div
          const outerdiv = document.createElement("div");
          // outerdiv.prepend(text);
          outerdiv.prepend(icon);
          outerdiv.classList.add("wp-box-block");

          element.prepend(outerdiv);
        }
        break;
      }

      element = element.parentNode;
    }
    element.addEventListener("mousedown", (e) => {
      e.stopImmediatePropagation();
      e.stopImmediatePropagation();
      let clicked_element = e.target;

      let parent = clicked_element.parentNode;

      if (Get_Tag_and_Class(clicked_element, "SPAN", "wp-box-click")) {
        // box logic
        setSelectedBoxDiv(parent.parentNode);
        setSelectedElement(parent.parentNode)
        setTimeout(() => {
          setOpenSelectedBoxDiv(true);
        }, 100);
        return;
      }
    });
  };

  const targetElementForAddListener = (element, section) => {
    if (!element) {
      console.error("Invalid Element");
      return;
    }
    CheckElementIsRow(element);
    getIsDevState() && CheckElementIsContainBoxClas(element);
    if (element && element.tagName == "DIV" && element.classList) {
      if (element.classList.contains("wp-row-block")) {
        // element.remove();
        return;
      } else if (element.classList.contains("wp-box-block")) {
        // element.remove();
        return;
      }
    }

    if (element.children.length > 0) {
      for (let index = 0; index < element.children.length; index++) {
        const targetNode = element.children[index];
        if (targetNode.tagName == "SELECT") {
          addListener(targetNode, section);
        }
        if (targetNode.tagName === "A") {
          console.log(targetNode.tagName, "Target Node");
          // addListener(targetNode)
          targetNode.addEventListener("click", (e) => e.preventDefault());

          // element.addEventListener("click", (e) => {
          //   e.preventDefault();

          //   // console.log(this, "THIS");
          //   // if (e.target === this) {
          //   //   alert("target");
          //   // } else {
          //   //   alert("not");
          //   // }
          //   return false;
          // });
        }

        let is_editor = checkIsEditorElement(targetNode);
        let is_disable = checkIsDisableElement(targetNode);

        // making editor div for editor
        if (is_editor) {
          addListener(targetNode, section);
          updateLinks(targetNode, section);
        } else {
          if (targetNode.children.length === 0) {
            // if element has wb-disable class
            // disable events against that element
            if (is_disable) {
              updateLinks(targetNode, section);
            } else {
              addListener(targetNode, section);
              updateLinks(targetNode, section);
            }
          } else {
            targetElementForAddListener(targetNode, section);
          }
        }
      }
    } else {
      alert("targetElementForAddListener else case invoked...");
    }
  };

  // <---------------Do undo start----------------->

  const [HistoyStack, setHistoyStack] = useState([]);
  const [RedoStack, setRedoStack] = useState([]);
  const [UndoRootValues, setUndoRootValues] = useState([]);
  const [RedoRootValues, setRedoRootValues] = useState([]);

  useEffect(() => {
    setHistoyStack([]);
    setRedoStack([]);
    setSelectedSections([]);
    setUndoRootValues([]);
    setRedoRootValues([]);
  }, [SelectedPage]);

  const handleGetHtmlBeforeAction = (updatedList) => {
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = findElementById(sec.section_id).outerHTML;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    return [...updatedHtml];
  };
  const handleRollBackLastAction = (updatedList) => {
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = sec.section_html;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    // console.log(updatedHtml, "updatedHtml");
    setSelectedSections(updatedHtml);
    return updatedHtml;
  };
  const SaveActionToStack = async () => {
    setRedoStack([]);
    setRedoRootValues([]);
    // let x = await handleUpdateStateFromDOM(selectedSections)
    let HTMLBeforAction = handleGetHtmlBeforeAction(selectedSections);
    let Current_Root_Values = { ...rootValues };
    UndoRootValues.splice(0, 0, Current_Root_Values);
    HistoyStack.splice(0, 0, HTMLBeforAction);

    if (HistoyStack.length > 15) {
      HistoyStack.splice(15, 1);
      UndoRootValues.splice(15, 1);
    }
    setUndoRootValues([...UndoRootValues]);
    setHistoyStack([...HistoyStack]);

    return true;
  };

  const UpdateDOMHTML = (SectionList) => {
    if (SectionList.length > 0) {
      SectionList.map((sec, index) => {
        const section = document.getElementById(sec.section_id);

        if (section) {
          section.outerHTML = sec.section_html;
        }
      });
    }
  };
  const UndoAction = async () => {
    if (HistoyStack.length > 0) {
      RedoRootValues.splice(0, 0, { ...rootValues });
      let Last_Change_Root_Values = UndoRootValues.splice(0, 1);
      setRootValues({ ...Last_Change_Root_Values[0] });
      setUndoRootValues([...UndoRootValues]);
      setRedoRootValues([...RedoRootValues]);

      let CurrentHTML = handleGetHtmlBeforeAction(selectedSections);

      let HTMLBeforeLastAction = [...HistoyStack.splice(0, 1)];
      // if (RedoStack.length == 0) {
      RedoStack.splice(0, 0, CurrentHTML);
      // } else {
      // RedoStack.splice(0, 0, HTMLBeforeLastAction[0])
      // }
      setHistoyStack([...HistoyStack]);

      setRedoStack([...RedoStack]);

      let HTML_List = await handleRollBackLastAction(HTMLBeforeLastAction[0]);
      if (HTML_List) {
        UpdateDOMHTML(HTMLBeforeLastAction[0]);
      }

      ReRenderHtml();
    }
  };
  const RedoAction = async () => {
    if (RedoStack.length > 0) {
      let Last_Change_Root_Values = RedoRootValues.splice(0, 1);
      UndoRootValues.splice(0, 0, { ...rootValues });
      setRootValues({ ...Last_Change_Root_Values[0] });
      setUndoRootValues([...UndoRootValues]);
      setRedoRootValues([...RedoRootValues]);

      let HTMLBeforeLastAction = [...RedoStack.splice(0, 1)];
      setRedoStack([...RedoStack]);
      let CurrentHTML = handleGetHtmlBeforeAction(selectedSections);

      HistoyStack.splice(0, 0, CurrentHTML);
      setHistoyStack([...HistoyStack]);
      let HTML_List = await handleRollBackLastAction(HTMLBeforeLastAction[0]);
      if (HTML_List) {
        UpdateDOMHTML(HTMLBeforeLastAction[0]);
      }
      ReRenderHtml();
    }
  };

  // <---------------Do undo end----------------->

  // <---------------Drag And Drop Start----------------->

  // drag and drop listners start
  const handleAddDragDropListener = (element) => {
    if (!element) {
      return;
    }
    addEventListener("dragstart", (e) => {
      CloseAllEditor();
      FirstAttemp_Drop = true;
      current_target_element = "";
      current_hovering_over_element = "";

      previous_target_element = "";
      previous_hovering_over_element = "";
    });
    element.addEventListener("dragover", (e) => {
      if (e.target.tagName === "IMG") {
        CheckIncomingElementIsIMG(e);
      }
      if (CheckIncomingElementIsReplaceable(e)) {
        e.preventDefault();
        return;
      }
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      if (e.target.tagName == "SECTION") {
        return;
      } else if (
        e.target.parentNode &&
        e.target.parentNode.tagName == "SECTION"
      ) {
        return;
      }
      if (
        current_target_element &&
        current_hovering_over_element &&
        current_target_element.outerHTML &&
        current_target_element.outerHTML.includes(Drag_Element.outerHTML) &&
        current_target_element.outerHTML.includes(e.target.outerHTML)
      ) {
        SwapElementPositionAccordingTOMouse(e, Drag_Element);
      } else {
        // enqueueSnackbar("DragOver Else Case Invoke", { variant: "warning" });
        // console.log("DragOver Else Case Invoke",current_target_element,current_hovering_over_element);
      }
      e.preventDefault();

      // e.preventDefault();
    });

    element.addEventListener("dragenter", (e) => {
      let Drag_Element = GetDragElement();
      if (CheckIncomingElementIsReplaceable(e)) {
        e.preventDefault();
        return;
      }
      if (!Drag_Element) return;

      // check hover element is drag element
      if (handleEnterDragID(e)) {
        e.preventDefault();
        return;
      }

      // remove element if enter in new element
      RemoveOldEleemntIfEnterInNewElement();

      // check element parent is editor

      if (handleEnterEditor(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element parent is form
      else if (handleEnterFORM(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element is IMG
      else if (handleEnterIMG(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }

      // check element is BUTTON or Anchor
      else if (handleEnterBUTTON_or_ANCHOR(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }

      // check element is I
      else if (handleEnterI(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element is Row
      else if (handleEnterROW(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      e.preventDefault();
    });

    element.addEventListener("drop", (e) => {
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      let iframe_code = `
      <iframe width="560" height="315" src="https://www.youtube.com/embed/inpok4MKVLM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      `;
      // if (CheckIncomingElementIsReplaceable(e)) {
      //   if (e.target && e.target.parentNode) {
      //     // reset property
      //     current_target_element.style.removeProperty("opacity");
      //     current_target_element.style.removeProperty("outline");
      //     current_target_element.style.removeProperty("outline-style");
      //     SaveActionToStack();
      //     e.target.parentNode.innerHTML = Drag_Element.innerHTML;
      //     return;
      //   }
      // }
      if (FirstAttemp_Drop) {
        FirstAttemp_Drop = false;
        if (current_hovering_over_element && current_target_element) {
          // preprocessing of element
          let col = CheckColumnSpacing();
          let new_element = current_target_element.querySelector("#drag");
          if (new_element) {
            // new_element.removeAttribute("id");
            if (new_element.children && new_element.children.length > 0) {
              if (new_element.children[0].tagName === "IMG") {
                new_element.children[0].removeAttribute("width");
              } else if (
                new_element.children[0].tagName === "DIV" &&
                new_element.children[0].classList &&
                new_element.children[0].classList.contains("wb-iframe")
              ) {
                new_element.children[0].innerHTML = iframe_code;
              }
            }
          }

          // reset property
          current_target_element.style.removeProperty("opacity");
          current_target_element.style.removeProperty("outline");
          current_target_element.style.removeProperty("outline-style");

          // get element location
          let location = [...current_target_element.children].indexOf(
            new_element,
            0
          );
          // saveactiontostack
          let copy = new_element.cloneNode(true);
          new_element.remove();
          SaveActionToStack();
          if (CheckIncomingElementIsReplaceable(e)) {
            console.log(new_element, "lsdksjlksdjflkdsfjkdsflkdsfj");
            if (
              new_element &&
              new_element.classList &&
              new_element.classList.contains("wb-layout")
            ) {
              // noting
            } else {
              if (e.target && e.target.parentNode) {
                e.target.parentNode.innerHTML = new_element.innerHTML;
                return;
              }
            }
          }
          current_target_element.insertBefore(
            copy,
            current_target_element.children[location]
          );
          new_element = current_target_element.querySelector("#drag");
          new_element.removeAttribute("id");

          if (col >= 3) {
            let row = document.createElement("div");
            row.classList.add("row");
            let clone = new_element.cloneNode(true);
            if (clone.classList && clone.classList.contains("wb-layout")) {
              row.innerHTML = clone.innerHTML;
            } else {
              row.append(clone);
            }
            current_target_element.insertBefore(row, new_element);
            new_element.remove();
          }

          current_target_element.style.removeProperty("opacity");
          current_target_element.style.removeProperty("outline");
          current_target_element.style.removeProperty("outline-style");
          current_target_element = "";
          current_hovering_over_element = "";
          previous_target_element = "";
          previous_hovering_over_element = "";

          ReRenderHtml();
        }
      }
    });
  };
  document.addEventListener("dragend", (e) => {
    setTimeout(() => {
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      if (current_hovering_over_element && previous_target_element) {
        if (current_target_element.querySelector("#drag")) {
          current_target_element.querySelector("#drag").remove();
        }
        current_target_element.style.removeProperty("opacity");
        current_target_element.style.removeProperty("outline");
        current_target_element.style.removeProperty("outline-style");
        current_target_element = "";
        current_hovering_over_element = "";
        previous_target_element = "";
        previous_hovering_over_element = "";
        ReRenderHtml();
      }
    }, 100);
  });
  document.addEventListener("drop", (e) => {
    setTimeout(() => {
      handleDropUserIMG(e);
    }, 100);
  });

  // drag and drop listners end
  const AddElementInTargetPlace = (e) => {
    if (
      previous_target_element === current_target_element &&
      previous_hovering_over_element === current_hovering_over_element &&
      current_target_element != ""
    ) {
      return;
    }
    PushElementAccordingToMousePosition(e);
  };
  const PushElementAccordingToMousePosition = (e) => {
    // get grag element clone
    let Drag_Element = GetDragElement();

    let elementPos = current_hovering_over_element.getBoundingClientRect();

    if (e.clientY > (elementPos.bottom + elementPos.top) / 2) {
      // append at bottom of element
      if (current_hovering_over_element == current_target_element) {
        current_target_element.append(Drag_Element);
      } else {
        current_target_element.insertBefore(
          Drag_Element,
          current_hovering_over_element.nextSibling
        );
      }
    } else {
      // append at top of element
      if (current_hovering_over_element == current_target_element) {
        current_target_element.prepend(Drag_Element);
      } else {
        current_target_element.insertBefore(
          Drag_Element,
          current_hovering_over_element
        );
      }
    }
  };
  const GetDragElement = () => {
    let Drag_Element = [...document.getElementsByClassName("Drag-Element")];
    if (Drag_Element && Drag_Element.length > 0) {
      Drag_Element = Drag_Element[0].firstChild.cloneNode(true);
      return Drag_Element;
    }
    return false;
  };
  const RemovingStyleFromPreviousElement = () => {
    if (current_target_element !== previous_target_element) {
      if (previous_target_element && previous_target_element.style) {
        previous_target_element.style.removeProperty("opacity");
        previous_target_element.style.removeProperty("outline");
        current_target_element.style.removeProperty("outline-style");
      }

      // add style to new element
      if (current_target_element && current_target_element.style) {
        current_target_element.style["opacity"] = "0.6";
        current_target_element.style["outline"] = "black solid 2px";
        current_target_element.style["outline-style"] = "dashed";
      }
    }
  };
  const RemoveOldEleemntIfEnterInNewElement = () => {
    if (previous_hovering_over_element != current_hovering_over_element) {
      if (
        previous_hovering_over_element &&
        previous_hovering_over_element.nextSibling &&
        previous_hovering_over_element.nextSibling.id &&
        previous_hovering_over_element.nextSibling.id == "drag"
      ) {
        previous_hovering_over_element.nextSibling.remove();
      }
      if (
        previous_hovering_over_element &&
        previous_hovering_over_element.previousSibling &&
        previous_hovering_over_element.previousSibling.id &&
        previous_hovering_over_element.previousSibling.id == "drag"
      ) {
        previous_hovering_over_element.previousSibling.remove();
      }
    }
  };
  const CheckColumnSpacing = () => {
    if (current_hovering_over_element && current_target_element) {
      let element = current_target_element;
      while (element && element.parentNode) {
        if (element.parentNode.tagName === "SECTION") {
          break;
        }
        element = element.parentNode;
      }

      let elementPos = element.getBoundingClientRect();
      let current_target_elementPos =
        current_target_element.getBoundingClientRect();

      console.log(
        "Total Coloumns Contain ",
        GetPercentageWidth(
          current_target_elementPos.right - current_target_elementPos.left,
          elementPos.right - elementPos.left
        )
      );
      return GetPercentageWidth(
        current_target_elementPos.right - current_target_elementPos.left,
        elementPos.right - elementPos.left
      );
    }
  };
  const GetPercentageWidth = (element, container) => {
    if (element <= container) {
      return parseInt(((element / container) * 100) / 8);
    } else {
      return 12;
    }
  };
  const SwapElementPositionAccordingTOMouse = (e, Drag_Element) => {
    let exist = current_target_element.querySelector("#drag");
    let elementPos = current_hovering_over_element.getBoundingClientRect();

    if (e.clientY > (elementPos.bottom + elementPos.top) / 2) {
      // append at bottom of element
      if (current_hovering_over_element == current_target_element) {
        if (
          !(
            current_target_element.children &&
            current_target_element.children.length > 0 &&
            current_target_element.children[
              current_target_element.children.length - 1
            ].id &&
            current_target_element.children[
              current_target_element.children.length - 1
            ].id == "drag"
          )
        ) {
          current_target_element.append(Drag_Element);
          exist && exist.remove();
        }
      } else {
        if (
          !(
            current_hovering_over_element &&
            current_hovering_over_element.nextSibling &&
            current_hovering_over_element.nextSibling.id &&
            current_hovering_over_element.nextSibling.id == "drag"
          )
        ) {
          current_target_element.insertBefore(
            Drag_Element,
            current_hovering_over_element.nextSibling
          );
          exist && exist.remove();
        }
      }
    } else {
      // append at top of element
      if (current_hovering_over_element == current_target_element) {
        if (
          !(
            current_target_element.children &&
            current_target_element.children.length > 0 &&
            current_target_element.children[0].id &&
            current_target_element.children[0].id == "drag"
          )
        ) {
          current_target_element.prepend(Drag_Element);
          exist && exist.remove();
        }
      } else {
        if (
          !(
            current_hovering_over_element &&
            current_hovering_over_element.previousSibling &&
            current_hovering_over_element.previousSibling.id &&
            current_hovering_over_element.previousSibling.id == "drag"
          )
        ) {
          current_target_element.insertBefore(
            Drag_Element,
            current_hovering_over_element
          );
          exist && exist.remove();
        }
      }
    }
  };
  const handleAddElement = (e) => {
    if (current_target_element && current_hovering_over_element) {
      RemovingStyleFromPreviousElement();
      AddElementInTargetPlace(e);
    }
  };

  // element handler
  const handleEnterDragID = (e) => {
    let check = e.target;
    let result = false;
    // hover over drag element then return as default
    while (check && check.parentNode) {
      if (check.id && check.id == "drag") {
        console.log("Found DragID");
        result = true;
      }
      if (check.tagName == "SECTION") {
        break;
      }
      check = check.parentNode;
    }

    return result;
  };
  const handleEnterFORM = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element && element.tagName === "FORM") {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };
  const handleEnterEditor = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element.classList && element.classList.contains("wb-editor-block")) {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };
  const handleEnterIMG = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && element.tagName === "IMG") {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterBUTTON_or_ANCHOR = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && (element.tagName === "BUTTON" || element.tagName === "A")) {
      if (element.parentNode.tagName == "A") {
        element = element.parentNode;
      }
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterI = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && element.tagName === "I") {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterROW = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element.classList && element.classList.contains("row")) {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };

  // <---------------Drag And Drop Image From User Gallery----------------->

  const CheckIncomingElementIsIMG = (e) => {
    let Element = GetUserGalleryIMG();
    if (!Element) return;
    e.preventDefault();
  };
  const CheckIncomingElementIsReplaceable = (e) => {
    let result = false;
    if (
      e.target &&
      e.target.classList &&
      e.target.classList.contains("wb-replaceable-element")
    ) {
      result = true;
    }
    return result;
  };
  const handleDropUserIMG = (e) => {
    if (e.target.tagName === "IMG") {
      let Element = GetUserGalleryIMG();
      if (!Element) return;
      let element_exist_in_editable_section = false;
      let element = e.target;
      while (element && element.parentNode) {
        if (element.tagName === "SECTION") {
          element_exist_in_editable_section = true;
        }
        element = element.parentNode;
      }

      if (element_exist_in_editable_section) {
        e.target.src = Element.src;
        e.target.alt && e.target.alt.length > 0 ? "" : (e.target.alt = "Image");
        RemoveUserGalleryClass();
      }
    }
  };
  const GetUserGalleryIMG = () => {
    let Element = [...document.getElementsByClassName("user-gallery-img")];
    if (Element && Element.length > 0) {
      Element = Element[0].cloneNode(true);
      return Element;
    }
    return false;
  };
  const RemoveUserGalleryClass = () => {
    let data = [...document.getElementsByClassName("user-gallery-img")];
    for (let a = 0; a < data.length; a++) {
      data[a].classList.remove("user-gallery-img");
    }
  };

  // <---------------Drag And Drop End----------------->

  const addListener = (element) => {
    const tag = element.tagName;
    const name = element.name;

    if (tag === "path" || name === "not-editable") {
      return;
    }

    if (element.tagName === "BUTTON") {
      console.log("BUTTON");
    }

    element.addEventListener("click", (e) => {
      handleElementClick(e, element);
      return false;
    });
    // handleAddDragDropListener(element)

    // element.style.cssText =
    //   element.style.cssText + ' -webkit-user-drag:element;'
  };
  const getElementSection = (node) => {
    let section = null;
    if (node) {
      while (node.parentNode) {
        if (node.tagName == "SECTION") {
          section = node;
          break;
        }
        node = node.parentNode;
      }
    }
    return section;
  };
  const findElementById = (id) => {
    const element = document.getElementById(id);
    return element;
  };

  const findSectionElementById = (id) => {
    const element = document.getElementById(id);
    const section = element.getElementsByTagName("SECTION")[0];
    return section;
  };

  const updateLinks = (element, section) => {
    if (element.src && element.tagName === "IMG") {
      element.src = element.src.replace(
        window.location.origin,
        section.baseURL + "/" + section.section_name
      );
    }

    if (element.href && element.tagName === "LINK") {
      element.href = element.href.replace(
        window.location.origin,
        section.baseURL
      );
    }
  };

  /* ------------------------------------------------------
                State Handlers Functions 
  ------------------------------------------------------- */

  const getAllSections = () => {
    return sections;
  };

  const getSelectedSections = () => {
    return selectedSections;
  };

  const handleSectionOnSelect = async (
    section,
    index,
    target_s3,
    is_template = false
  ) => {
    let section_js;
    let section_css;
    let section_html;
    let unique_uuid = _generate_random_string(10);
    let section_name;

    if (s3baseUrl == target_s3) {
      // SaveActionToStack()

      let section_name = (unique_uuid = _generate_random_string(10));
      section_name = section._id;
      // fetch user html from s3
      section_html = await fetchFileFromURL(
        `${target_s3}/${section_name}/${SelectedPage}.html`
      );

      section_html = section_html.replaceAll("G_U_ID", "id_" + unique_uuid);

      // fetch user css from s3
      section_css =
        SelectedPage == "index"
          ? await fetchFileFromURL(`${target_s3}/${section_name}/css/style.css`)
          : await fetchFileFromURL(
              `${target_s3}/${section_name}/css/${SelectedPage}.css`
            );

      section_css = section_css.replaceAll(
        "../assets",
        `${s3builderSource}/${section_name}/assets`
      );

      // fetch user js from s3
      section_js = await fetchFileFromURL(
        `${target_s3}/${section_name}/js/${SelectedPage}.js`
      );

      section_js = section_js.replaceAll("G_U_ID", "id_" + unique_uuid);
    } else {
      is_template ? "" : SaveActionToStack();
      unique_uuid = _generate_random_string(10);
      section_name = section._id;
      // fetch html from s3
      section_html = await fetchFileFromURL(
        `${target_s3}/${section_name}/index.html`
      );

      // console.log(section_html, "section_html");

      section_html = section_html.replaceAll("G_U_ID", "id_" + unique_uuid);

      // fetch css from s3
      section_css = await fetchFileFromURL(
        `${target_s3}/${section_name}/css/style.css`
      );

      section_css = section_css.replaceAll(
        "../assets",
        `${s3builderSource}/${section_name}/assets`
      );

      // fetch js from s3
      section_js = await fetchFileFromURL(
        `${target_s3}/${section_name}/js/index.js`
      );

      section_js = section_js.replaceAll("G_U_ID", "id_" + unique_uuid);
    }

    // console.log(section_html, "section_html");
    // console.log(section_css, "section_css");
    // console.log(section_js, "section_js");

    let el = document.createElement("div");
    el.innerHTML = section_html;

    const elements = el.getElementsByTagName("SECTION");

    const sections_length = elements.length;
    // If section_html has no sections

    if (sections_length === 0) {
      setIsPreviewLoading(false);
      return;
    }
    if (is_template || s3baseUrl != target_s3) {
      for (let i = 0; i < elements.length; i++) {
        updateNavBarIMG(elements[i]);
      }
    }

    if (sections_length === 1) {
      // if section_html has only one section
      let html_element = document.createElement("div");
      html_element.innerHTML = section_html;

      // const section_start_index = section_html.search("<section>");
      // const section_end_index = section_html.search("</section>") + 10;

      let selected_section = elements[0];

      // assign data first time
      if (s3baseUrl != target_s3) {
        selected_section.dataset._id = section._id;
        selected_section.dataset.section_id = unique_uuid;
        selected_section.dataset.section_title = section.name;
        selected_section.dataset.section_name = section._id;
        selected_section.id = unique_uuid;
      } else {
        section_css = GetSectionCss(
          section_css,
          selected_section.dataset.section_name
        );
      }

      // -------end

      const selected_section_html = selected_section.outerHTML;

      if (!selected_section_html) {
        return;
      }

      section = {
        ...section,
        baseURL: `${target_s3}`,
        section_id: unique_uuid,
        section_html: selected_section_html,
        section_name:
          s3baseUrl == target_s3
            ? selected_section.dataset.section_name
            : section_name,
        section_css,
        section_js,
        section_title: selected_section.dataset.section_title,
      };
      console.log("unique section id : ", unique_uuid);
      console.log("new added section is : ", section);

      if (index !== undefined) {
        const items = Array.from(getSelectedSections());
        items.splice(index, 0, section);
        setSelectedSections(items);
        // scroll to the added section when user drag & drop a section
        setTimeout(() => {
          if (document.getElementById(section.section_id)) {
            document.getElementById(section.section_id).scrollIntoView();
            handleUpdateStateFromDOM(items);
          }
        }, 100);
        return;
      }

      setSelectedSections((c) => [...c, section]);

      // scroll to the added section when user click a section
      setIsPreviewLoading(false);
      setTimeout(() => {
        if (document.getElementById(section.section_id)) {
          document.getElementById(section.section_id).scrollIntoView();

          let sections_list = [...selectedSections, section];

          setSelectedSections([...sections_list]);
        }
      }, 100);
    }

    // if section_html has more then one sections
    if (sections_length > 1) {
      let element_sections = [];

      for (let i = 0; i < sections_length; i++) {
        let selected_section = elements[i];

        if (!selected_section.dataset) {
          const unique_uuid = _generate_random_string(10);
          // assign data first time
          selected_section.dataset._id = section._id;
          selected_section.dataset.section_id = unique_uuid;
          selected_section.dataset.section_title = section.name;
          selected_section.dataset.section_name = section._id;
          selected_section.id = unique_uuid;
          // -------end
        }

        const selected_section_html = selected_section.outerHTML;

        let single_section = {
          section_id: uuidv4(),
          _id: selected_section.dataset._id,
          section_name: selected_section.dataset.section_name,
          section_html: selected_section_html,
          section_css: "",
          section_title: selected_section.dataset.section_title,
        };

        // css
        // single_section.section_css = await fetchFileFromURL(
        //   `${s3builderSource}/${single_section._id}/css/style.css`,
        // )

        // single_section.section_css = single_section.section_css.replaceAll(
        //   '../assets',
        //   `${s3builderSource}/${single_section._id}/assets`,
        // )

        // // js
        // single_section.section_js = await fetchFileFromURL(
        //   `${s3builderSource}/${single_section._id}/js/index.js`,
        // )

        single_section.section_css = GetSectionCss(
          section_css,
          selected_section.dataset.section_name
        );
        if (!single_section.section_css) {
          console.log("No Css now hitting api to get");
          single_section.section_css = await fetchFileFromURL(
            `${s3builderSource}/${single_section._id}/css/style.css`
          );
          single_section.section_css = single_section.section_css.replaceAll(
            "../assets",
            `${s3builderSource}/${single_section._id}/assets`
          );
        }
        single_section.section_css = single_section.section_css.trim();

        // js

        if (section_js && section_js != "/* No JS */") {
          single_section.section_js = GetSectionJS(
            section_js,
            selected_section.dataset.section_name
          );
          if (!single_section.section_js) {
            console.log("No JS now hitting api to get");
            single_section.section_js = await fetchFileFromURL(
              `${s3builderSource}/${single_section._id}/js/index.js`
            );
          }
        } else {
          single_section.section_js = "";
        }
        element_sections.push(single_section);
      }
      // console.log(element_sections, "element_sections");
      setIsPreviewLoading(false);
      setSelectedSections((c) => [...c, ...element_sections]);

      return;
    }
  };
  const handleOpenElementSourceCode = () => {
    setOpenDeveloperOptionMenu(true);
    setDeveloperOptionMenuType("source-code");
    handleCloseAllMenu();
  };
  const handleOpenCustomClassMenu = () => {
    // setOpenCustomClassMenu(true);
    setOpenDeveloperOptionMenu(true);
    setDeveloperOptionMenuType("custom-class");
    handleCloseAllMenu();
  };
  const handleOpenCustomJSMenu = () => {
    setOpenDeveloperOptionMenu(true);
    setDeveloperOptionMenuType("custom-js");
    handleCloseAllMenu();
  };
  const handleCloseAllMenu = () => {
    setRightMenuOpen(false);
    setEditorMenuOpen(false);
    setOpenRowSetting(false);
    setFormSettingsMenuOpen(false);
    setSectionSettingsMenuOpen(false);
    setFormSettingsMenuOpen(false);
    setImageAnchorEl(null);;
  };
  const updateNavBarIMG = async (updatedSection) => {
    let list = [...updatedSection.getElementsByClassName("navbar-brand")];
    for (let i = 0; i < list.length; i++) {
      let img = list[i].getElementsByTagName("img");
      if (img && img.length > 0) {
        let logo = GetProjectLogo();
        if (logo) {
          img[0].src = logo;
          img[0].style.height = "60px";
        }
      }
    }
  };
  const GetProjectLogo = () => {
    let icon = sessionStorage.getItem("project_logo");
    return icon && icon.length > 0 ? icon : "";
  };

  const handleChangeInEditableInputs = (key, val) => {
    console.log("My editor values are here ", val);
    setEditAbleInputs({
      ...editAbleInputs,
      [key]: val,
    });
  };

  const handleRemoveElement = async () => {
    let x = await SaveActionToStack();

    let is_editor = checkIsEditorElement(selectedElement);
    if (is_editor) {
      setEditorMenuOpen(false);
    } else {
      setRightMenuOpen(false);
    }
    if (
      selectedElement.tagName == "INPUT" ||
      selectedElement.tagName == "TEXTAREA" ||
      selectedElement.tagName == "SELECT"
    ) {
      selectedElement.parentNode.remove();
    } else {
      let target = selectedElement;
      while (target.parentNode.children.length == 1) {
        target = target.parentNode;
      }
      target.remove();
    }

    setSelectedSections([...selectedSections]);
  };

  const handleUpdateStateFromDOM = (updatedList) => {
    // console.log(updatedList, "before update");
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = findElementById(sec.section_id).firstChild.innerHTML;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    // console.log(updatedHtml, "updatedHtml");
    setSelectedSections(updatedHtml);
    return updatedHtml;
  };

  const handleRemoveSelection = (id) => {
    SaveActionToStack();
    setUseEditingProject(true);
    const updatedList = selectedSections.filter((sec) => sec.section_id !== id);
    handleUpdateStateFromDOM(updatedList);
  };

  const handleDuplicateSection = async (section) => {
    SaveActionToStack();
    let targetIndex;
    // create copy of section
    let newsection = { ...section };
    const allsections = selectedSections.map((sec, i) => {
      if (sec.section_id === section.section_id) {
        targetIndex = i;
      }
      return sec;
    });

    // generate unique section id
    const unique_uuid = _generate_random_string(10);
    console.log(unique_uuid);

    // get section id and html to replace with update data
    const prev_section_id = newsection.section_id;
    let new_html = newsection.section_html;

    // replace prev section id and html with new
    newsection.section_html = new_html.replaceAll(prev_section_id, unique_uuid);
    newsection.section_id = unique_uuid;

    // console.log(section, "old section with old id");
    // console.log(newsection, "new section with new id");

    // update selection list
    allsections.splice(targetIndex + 1, 0, newsection);
    setSelectedSections(allsections);

    // scroll to the added section when user drag & drop a section
    setTimeout(() => {
      if (document.getElementById(unique_uuid)) {
        document.getElementById(unique_uuid).scrollIntoView();
        handleUpdateStateFromDOM(allsections);
      }
    }, 100);
  };
  const handleUpSelection = (id) => {
    // console.log(id);
    let targetIndex;
    const updatedList = selectedSections.map((sec, i) => {
      if (sec.section_id === id) {
        targetIndex = i;
      }
      return sec;
    });

    if (targetIndex && targetIndex > 0) {
      SaveActionToStack();

      const temp = updatedList[targetIndex - 1];
      updatedList[targetIndex - 1] = updatedList[targetIndex];
      updatedList[targetIndex] = temp;
      handleUpdateStateFromDOM(updatedList);
    }
  };

  const handleDownSelection = (id) => {
    // console.log(id);
    let targetIndex;
    const updatedList = selectedSections.map((sec, i) => {
      if (sec.section_id === id) {
        targetIndex = i;
      }
      return sec;
    });

    if (targetIndex !== null && targetIndex < updatedList.length - 1) {
      SaveActionToStack();

      const temp = updatedList[targetIndex + 1];
      updatedList[targetIndex + 1] = updatedList[targetIndex];
      updatedList[targetIndex] = temp;
      // console.log(updatedList, "updatedList");
      // console.log(targetIndex, "targetIndex");
      handleUpdateStateFromDOM(updatedList);
    }
  };

  const handleUpdateSectionSettings = () => {
    // console.log("update section function");
  };

  const checkIsEditorElement = (targetNode) => {
    const targetElementClasses = targetNode.className;
    let is_editor_index = targetElementClasses.search("wb-editor-block");
    let is_editor = false;
    if (is_editor_index >= 0) {
      is_editor = true;
    }

    return is_editor;
  };

  const checkIsDisableElement = (targetNode) => {
    const targetElementClasses = targetNode.className;
    let is_disable_index = targetElementClasses.search("wb-disable");
    let is_disable = false;
    if (is_disable_index >= 0) {
      is_disable = true;
    }

    return is_disable;
  };

  const checkSPANofButton = (element) => {
    var currentElement = element.parentNode;
    if (currentElement && currentElement.tagName === "BUTTON") {
      return true;
    }
    while (currentElement && currentElement.tagName != "DIV") {
      if (currentElement && currentElement.tagName == "BUTTON") {
        return true;
      } else {
        currentElement = currentElement.parentNode;
      }
    }

    return false;
  };

  const handleUpdateElement = () => {
    SaveActionToStack();
    const tag = selectedElement.tagName;
    let action = "remove";
    // handle A tag element
    if (tag === "A") {
      selectedElement.innerHTML = editAbleInputs.text
        ? editAbleInputs.text
        : "";
      selectedElement.href = editAbleInputs.href ? editAbleInputs.href : "#";
      if (selectedElementAttributes.target) {
        action = "add";
      }
      _dispatch_handle_href_target(selectedElement, action);
      selectedElement.href = selectedElementAttributes.href;
      setRightMenuOpen(false);
      return;
    }

    // for IMG tag element
    if (tag === "IMG") {
      selectedElement.src = editAbleInputs.src;
      setRightMenuOpen(false);
      return;
    }

    // if not match above

    let is_editor = checkIsEditorElement(selectedElement);
    if (is_editor) {
      const value = editorRef.current.getContent();
      selectedElement.innerHTML = value ? value : "-";
    } else {
      selectedElement.innerHTML = editAbleInputs.text
        ? editAbleInputs.text
        : "";
    }
    if (is_editor) {
      setEditorMenuOpen(false);
    } else {
      setRightMenuOpen(false);
    }

    // UndoAction()
  };
  const getEditAbleInputs = (key) => {
    return editAbleInputs[key];
  };

  const getSelectedElementTag = () => {
    return selectedElement.tagName;
  };

  const getEditModeState = () => {
    return editMode;
  };

  const getIsDevState = () => {
    return isDev;
  };

  const handleThemeClick = () => {
    setRightThemeMenuOpen(true);
  };

  const handleOpenSectionSettings = (section_id) => {
    let element = findElementById(section_id);
    if (!element) {
      return;
    }
    let targetElement = element?.children[0]?.children[0];
    if (targetElement) setSelectedElement(targetElement);

    setSelectedSectionSettingId(section_id);
    setSectionSettingsMenuOpen(true);
  };
  const handleOpenFormSetting = (section_id) => {
    setSelectedSectionSettingId(section_id);
    // let element = document.getElementById(section_id)
    // let form = element.getElementsByTagName('form')[0]
    // let anchor = form.getElementsByTagName('a')[0]

    // anchor.scrollIntoView()
    // setTimeout(() => {
    setFormSettingsMenuOpen(true);
    // }, 400)
  };

  // top menus manipulations

  const get_nav_menu_element = () => {
    let is_found = true;
    const selected_section = findElementById(selectedSectionSettingId);

    if (!selected_section) {
      console.error("unable to find section with selectedSectionSettingId");
      is_found = false;
    }
    const nav_sections_list =
      selected_section.getElementsByClassName("navbar-nav");

    if (nav_sections_list.length === 0) {
      console.error("unable to find nav_sections_list with navbar-nav class");
      is_found = false;
    }

    const nav_section = nav_sections_list[0];

    return { nav_section, is_found };
  };

  const getTopMenuNavClasses = ({ class_list }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        class_list,
      };
      const classes = _dispatch_get_class_by_element_in_dom(payloadData);
      return classes;
    } else {
      return "";
    }
  };

  const updateTopMenuNavClasses = ({ value, class_list }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        value,
        class_list,
      };
      const classes = _dispatch_update_classes_by_element_in_dom(payloadData);
      return classes;
    } else {
      return "";
    }
  };

  const getTopMenuNavStyle = ({ property }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        property,
      };
      let children = nav_section.children;
      if (children) {
        children = children[0].children;
        if (children.length > 0) {
          return children[0].style[property];
        }
      }
      const classes = _dispatch_get_styles_by_element_from_dom(payloadData);
      // return classes
    } else {
      return "";
    }
  };

  const getSelectedElementStyleByProperty = ({ property }) => {
    const payloadData = {
      element: selectedElement,
      property,
    };
    const style = _dispatch_get_styles_by_element_from_dom(payloadData);
    return style;
  };

  const updateSelectedElementStyleByProperty = ({ property, value }) => {
    const payloadData = {
      element: selectedElement,
      property,
      value,
    };
    _dispatch_update_styles_by_element_in_dom(payloadData);
  };

  const updateTopMenuNavStyle = ({ property, value }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        property,
        value,
      };
      const classes = _dispatch_update_styles_by_element_in_dom(payloadData);

      for (let i = 0; i < nav_section.childElementCount; i++) {
        const element = nav_section.children[i];

        if (element.childElementCount === 0) {
          const payloadData = {
            element: element,
            property,
            value,
          };
          const classes =
            _dispatch_update_styles_by_element_in_dom(payloadData);
        } else {
          for (let j = 0; j < element.childElementCount; j++) {
            const child_element = element.children[j];
            console.log(child_element, "expected-a");
            if (child_element.childElementCount === 0) {
              const payloadData = {
                element: child_element,
                property,
                value,
              };

              const classes =
                _dispatch_update_styles_by_element_in_dom(payloadData);
            }
          }
        }
      }

      return "";
    } else {
      return "";
    }
  };

  // end top menu manipulations

  const getSelectedSectionLists = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_lists_by_id_and_child(selectedSectionSettingId);
  };

  const getSelectedSectionFrames = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_i_frames_by_id(selectedSectionSettingId);
  };

  const getSelectedSectionForm = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_form_by_id(selectedSectionSettingId);
  };
  const getSelectedSectionFormByID = (id) => {
    if (!id) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_form_by_id(id);
  };

  const updateSelectedSectionStyle = (property, value, child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      property: property,
      value: value,
    };
    _dispatch_update_styles_in_dom(payloadData);
  };

  const getSelectedSectionStyle = (property, child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      property: property,
    };
    const value = _dispatch_get_styles_from_dom(payloadData);
    return value;
  };

  const getSelectedSectionheightwidth = (child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const value = _dispatch_get_heightwidth_from_dom(
      selectedSectionSettingId,
      child
    );
    const val = {
      height: value.offsetHeight,
      width: value.offsetWidth,
    };
    return val;
  };

  const getSelectedSectionClass = ({ child, class_list }) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      class_list,
    };
    const classes = _dispatch_get_classes_from_dom(payloadData);
    return classes;
  };

  const updateSelectedSectionClass = (payload) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const payloadData = {
      id: selectedSectionSettingId,
      child: payload.child,
      class_list: payload.class_list,
      value: payload.value,
    };

    _dispatch_update_classes_in_dom(payloadData);
  };

  const updateSelectedElementClass = (payload) => {
    if (!selectedElement) {
      alert("No element selected!");
      return;
    }
    SaveActionToStack();
    console.log(payload, "paylodclass");
    const payloadData = {
      element: selectedElement,
      ...payload,
    };

    _dispatch_update_classes_by_element_in_dom(payloadData);
  };

  const getSelectedElementClassByType = (payload) => {
    if (!selectedElement) {
      alert("No element selected!");
      return;
    }

    const payloadData = {
      element: selectedElement,
      ...payload,
    };

    const class_name = _dispatch_get_class_by_element_in_dom(payloadData);
    return class_name;
  };
  const getSelectedElement = () => {
    if (selectedElement) {
      return selectedElement;
    }
    console.error("no element selected!");
  };
  const getSelectedElementAttributes = () => {
    if (selectedElement) {
      let type = selectedElement.tagName;
      const { naturalHeight, naturalWidth, height, width } = selectedElement;
      if (
        selectedElement.tagName == "IMG" &&
        (!selectedElement.attributes.imgheight ||
          !selectedElement.attributes.imgwidth)
      ) {
        selectedElement.setAttribute("imgheight", naturalHeight);
        selectedElement.setAttribute("imgwidth", naturalWidth);
      }
      let attributes = Object.values(selectedElement.attributes);
      let data = [];
      attributes.forEach((attr) => {
        data.push({ name: attr.name, value: attr.value });
      });

      return { type: type, attributes: data };
    }
    console.error("no element selected!");
  };

  const updateSelectedElementAttributes = (attribute, value) => {
    if (selectedElement) {
      selectedElement[attribute] = value;
    }
    console.log(selectedElement, "updateSelectedElementAttributes");
    console.error("no element selected!");
  };

  const handleElementClick = (e, element) => {
    e.preventDefault();
    // console.log(element, "element");
    setSelectedElement(element);
    let is_editor = checkIsEditorElement(element);

    if (
      element &&
      element.classList &&
      element.classList.contains("wb-replaceable-element")
    ) {
      handleReplaceableElementClick(element);
      return;
    }
    if (is_editor) {
      // setEditorMenuOpen(true);
      ShowEditor(element);
    } else {
      if (element.tagName == "IMG") {
        setImageAnchorEl(element);
      } else if (element.tagName == "I") {
        setIconAnchorEl(element);

        let winWidth = window.innerWidth;
        let cmWidth = 400;
        let x = winWidth / 2 > e.pageX ? "left" : "right";
        setIconEditorPosition(x);
      } else {
        setRightMenuOpen(true);
      }
    }

    // if we click on anchor tag element
    if (element.tagName === "A") {
      // console.log(element.href);
      setEditAbleInputs({
        ...editAbleInputs,
        text: element.innerText,
        href: element.href
          .trim()
          .replace(window.location.pathname, "")
          .replace(window.location.origin, ""),
      });
      return;
    }

    // if we click on IMG tag element
    if (element.tagName === "IMG") {
      // console.log(element.href);
      setEditAbleInputs({
        ...editAbleInputs,
        src: element.src,
        alt: element.alt,
        height: element.height,
        width: element.width,
      });
      return;
    }
    // else case

    // making editor div for editor

    if (is_editor) {
      setEditAbleInputs({
        ...editAbleInputs,
        text: element.innerHTML,
      });
      return;
    }
    setEditAbleInputs({
      ...editAbleInputs,
      text: element.innerText,
    });
  };
  const checkIframeExistagainstElement = (element) => {
    let element_name = element.getAttribute("name");
    if (element_name) {
      let iframe = document.getElementById(element_name);
      if (iframe) {
        iframe.remove();
      }
    }
  };
  const handleElementRightClick = (e, element) => {
    e.preventDefault();
    setSelectedElement(element);

    let is_editor = _find_is_editor_element(element);
    let is_span_editor = checkIsEditorElement(element);

    setRightMenuAnchorEl(e);

    const Project_Menu_list = [];

    let List = [
      {
        value: "I",
        label: "Icon",
        icon: "simple-icons:iconify",
      },
      {
        value: "A",
        label: "Button",
        icon: "material-symbols:radio-button-checked-outline",
      },
      {
        value: "IMG",
        label: "Image",
        icon: "fluent:image-edit-16-regular",
      },
      {
        value: "BUTTON",
        label: "Button",
        icon: "material-symbols:radio-button-checked-outline",
      },
      {
        value: "INPUT",
        label: "Input",
        icon: "material-symbols:insert-text-outline",
      },
      {
        value: "TEXTAREA",
        label: "Textarea",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "SELECT",
        label: "Option",
        icon: "uil:list-ui-alt",
      },
      {
        value: "FORM",
        label: "Form",
        icon: "ant-design:form-outlined",
      },
    ];

    let span_element = [
      {
        value: "SPAN",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "P",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H1",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H2",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H3",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H4",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H5",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
      {
        value: "H6",
        label: "Text",
        icon: "material-symbols:text-fields-rounded",
      },
    ];

    List.map((list) => {
      if (list.value === element.tagName) {
        Project_Menu_list.push({
          label: `${list.label} Setting`,
          icon: list.icon,
          divider: false,
          handleClick: () => handleElementClick(e, element),
        });
      }
    });

    span_element.map((list) => {
      if (list.value === element.tagName && !is_span_editor && !is_editor) {
        Project_Menu_list.push({
          label: `${list.label} Setting`,
          icon: list.icon,
          divider: false,
          handleClick: () => handleElementClick(e, element),
        });
      }
    });

    if (is_editor) {
      Project_Menu_list.push({
        label: `Editor Setting`,
        icon: "material-symbols:text-fields-rounded",
        divider: false,
        handleClick: () => {
          setTimeout(() => {
            if (!editorMenuOpen) {
              setEditorMenuOpen(true);
            }
          }, 100);
        },
      });
    }

    let rowElement = _find_parent_row_of_element(element);
    if (rowElement) {
      Project_Menu_list.push({
        label: "Row Setting",
        icon: "carbon:row",
        divider: false,
        handleClick: () => {
          setSelectedRow(rowElement);
          setSelectedRowClasses(rowElement.classList);
          setTimeout(() => {
            setOpenRowSetting(true);
          }, 100);
        },
      });
      Project_Menu_list.push({
        label: "Add Element in Row",
        icon: "carbon:row",
        divider: false,
        handleClick: () => {
          setSelectedRow(rowElement);
          setOpenReplaceElementMenu(true);
          setIsReplaceableElement(false);
        },
      });
    }
    let sectionElement = _find_section_of_element(element);
    if (sectionElement) {
      Project_Menu_list.push({
        label: "Section Setting",
        icon: "uil:window-section",
        divider: false,
        handleClick: () => {
          handleOpenSectionSettings(sectionElement.getAttribute("id"));
        },
      });
    }
    List.map((list) => {
      if (list.value === element.tagName) {
        Project_Menu_list.push({
          label: `Remove ${list.label}`,
          icon: "material-symbols:delete-outline-sharp",
          color: "red",
          divider: true,
          handleClick: () => {
            SaveActionToStack();
            let target = element;
            while (target.parentNode.children.length == 1) {
              checkIframeExistagainstElement(target);
              target = target.parentNode;
            }
            checkIframeExistagainstElement(target);
            target.remove();
          },
        });
      }
    });

    span_element.map((list) => {
      if (list.value === element.tagName && !is_span_editor && !is_editor) {
        Project_Menu_list.push({
          label: `Remove ${list.label}`,
          icon: "material-symbols:delete-outline-sharp",
          color: "red",
          divider: true,
          handleClick: () => {
            SaveActionToStack();
            let target = element;
            while (target.parentNode.children.length == 1) {
              checkIframeExistagainstElement(target);
              target = target.parentNode;
            }
            target.remove();
            checkIframeExistagainstElement(target);
          },
        });
      }
    });

    if (is_editor) {
      Project_Menu_list.push({
        label: `Remove Editor`,
        icon: "material-symbols:delete-outline-sharp",
        color: "red",
        divider: true,
        handleClick: () => {
          SaveActionToStack();
          let target = element;
          while (target.parentNode.children.length == 1) {
            target = target.parentNode;
          }
          target.remove();
        },
      });
    }

    setRightMenuList(Project_Menu_list);

    // if we click on anchor tag element
    if (element.tagName === "A") {
    }

    // if we click on IMG tag element
    if (element.tagName === "IMG") {
    }
    // else case

    // making editor div for editor

    if (is_editor) {
      setSelectedElement(is_editor);
      setEditAbleInputs({
        ...editAbleInputs,
        text: is_editor.innerHTML,
      });
      return;
    }
    setEditAbleInputs({
      ...editAbleInputs,
      text: element.innerText,
    });
  };
  const handleReplaceableElementClick = () => {
    setOpenReplaceElementMenu(true);
    setIsReplaceableElement(true);
  };

  const handleReplaceListElements = (selected_element, current_element) => {
    selected_element.replaceWith(current_element);
    ReRenderHtml();
  };

  const fetchFileFromURL = (url) => {
    return new Promise(async (resolve, reject) => {
      const resp = await fetch(url, myInit);
      if (resp.status === 200) {
        const data = await resp.text();
        resolve(data);
      } else {
        console.error(
          "<====================URL resp error====================>",
          resp
        );
        resolve("");
      }
    });
  };

  const RemoveRowBlock = (singleHtml) => {
    const wp_row_block_list = [
      ...singleHtml.getElementsByClassName("wp-row-block"),
      ...singleHtml.getElementsByClassName("wp-box-block"),
    ];
    if (wp_row_block_list.length > 0) {
      for (let x = 0; x < wp_row_block_list.length; x++) {
        wp_row_block_list[x].remove();
      }
      singleHtml = RemoveRowBlock(singleHtml);
      // return singleHtml
    }
    return singleHtml;
  };
  const collectFinalHTML = () => {
    const updatedSectionsList = handleUpdateStateFromDOM(selectedSections);

    let collect_html = "";

    // remove all the elements contain wp-row-block class

    updatedSectionsList.map((section) => {
      let singleHtml = findElementById(section.section_id);
      singleHtml = RemoveRowBlock(singleHtml);
      collect_html += singleHtml.firstChild.innerHTML + "\n\n";
    });

    let Save_Data = true;
    // console.log(collect_html, "collect_html");
    if (collect_html.length == 0 && UseEditingProject == false) {
      Save_Data = false;
      setUseEditingProject(true);
    }
    const css = SelectedPage == "index" ? "style" : SelectedPage;
    // added project  id and page id
    collect_html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>${
          rootValues.meta_title
            ? rootValues.meta_title
            : rootValues.project_name
        }</title>
        <link rel="icon" type="image/x-icon" href="${rootValues.fav_icon}">
        <meta name="description" content="${rootValues.meta_description}" />
        <meta name="keywords" content="${rootValues.meta_keywords}" />
        <!-- Header Script  Start-->
        ${
          rootValues.google_analytics_script
            ? rootValues.google_analytics_script
            : ""
        }
        <!-- Header Script End -->

       
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
        <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />

      <!-- Using Carousel -->
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
        integrity="sha512-sMXtMNL1zRzolHYKEujM2AqCLUR9F2C4/05cdbxjjLSRvMQIciEPCQZo++nk7go3BtSuK9kfa/s+a4f4i5pLkw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
        integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      
      <link
        rel="stylesheet"
        href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/fonts.css"
      />
      <link
        rel="stylesheet"
        href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/main.css"
      />

      
      
      <link href="css/${css}.css" rel="stylesheet">
      <style>
      ${getRootValues()}
      </style>
      </head>
      <body>
      <div name="root-data-div" data-website_id="${rootValues._id}"

      data-page_id="${rootValues.page_id}" data-success_message="${
      rootValues.success_message ? rootValues.success_message : ""
    }" data-failure_message="${
      rootValues.failure_message ? rootValues.failure_message : ""
    }"></div>
        ${collect_html}

        <!-- Body Script Start-->
        ${rootValues.body_script ? rootValues.body_script : ""}
        <!-- Body Script End -->

        <script src="https://code.jquery.com/jquery-3.6.0.js"
      integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
        <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
      
      <!-- font-awesome 6 -->
      <script
        src="https://kit.fontawesome.com/e30259c958.js"
        crossorigin="anonymous"
      ></script>
      
      <!-- Carousel -->
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      ></script>

      <script src="${asserts_base_url}/asserts-db/js/index.js" crossorigin="anonymous"></script>
      <script src="js/${SelectedPage}.js"></script>
      
    </body>
    </html>
    `;
    // console.log(collect_html, "abcdef");

    return { collect_html, Save_Data };
  };

  const collectFinalCSS = () => {
    let collect_css = "";
    selectedSections.forEach((section) => {
      if (section.section_css) {
        collect_css += `/*----[[${section.section_name}]]---*/
        ${section.section_css}
        /*----${section.section_name}----*/
        \n\n`;
      }
    });
    let Save_CSS = true;
    if (collect_css.length == 0 && UseEditingProject == false) {
      Save_CSS = false;
      setUseEditingProject(true);
    } else {
      Save_CSS = true;
    }
    return {
      collect_css: collect_css ? collect_css : `/* No CSS */`,
      Save_CSS,
    };
  };

  const collectFinalJS = () => {
    let collect_js = "";
    selectedSections.forEach((section) => {
      if (section.section_js.trim().length > 0) {
        collect_js += `/*----[[${section.section_name}]]---*/
         ${section.section_js.trim()}
         /*----${section.section_name}----*/
          \n\n`;
      }
    });

    return collect_js ? collect_js : `/* No JS */`;
  };

  const updateUserDesign = async (page_img, exitbuilder) => {
    // change these values

    const ProjectData = {
      project_name: rootValues.project_name,
      image: rootValues.page_list
        ? rootValues.page_list.length == 1
          ? page_img
          : rootValues.page_list[0].image
        : page_img,
      is_approved: rootValues.is_approved,
      is_completed: rootValues.is_completed,
      is_published: rootValues.is_published,
      status: rootValues.status,
      project_pages_count: rootValues.page_list.length,
    };

    const {
      page_name,
      page_slug,
      project,
      theme_heading_color,
      primary_color,
      secondary_color,
      primary_text_color,
      secondary_text_color,
      hover_text_color,
      hover_background_color,
      font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_mode,
      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = rootValues;
    const PageData = {
      page_name,
      page_slug,
      project,
      image: page_img,
      mode: theme_mode ? theme_mode : "light",
      theme_heading_color,
      theme_primary_color: primary_color,
      theme_secondary_color: secondary_color,
      text_primary_color: primary_text_color,
      text_secondary_color: secondary_text_color,
      hover_text_color,
      hover_background_color,
      text_font_family: font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      meta_title: meta_title ? meta_title : "",
      meta_description,
      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };
    // const resp = await _update_user_design_project(rootValues._id, ProjectData)

    // const page_resp = await _update_user_design_page(
    //   rootValues.page_id,
    //   PageData,
    // )

    await Promise.all([
      _update_user_design_project(rootValues._id, ProjectData),
      _update_user_design_page(rootValues.page_id, PageData),
      getHTMLImage("https", "http"),
    ]).then(async (resp) => {
      if (resp[0].code == 200 && resp[1].code == 200) {
        sessionStorage.setItem("project", JSON.stringify(resp[0].project));
        sessionStorage.setItem("page", JSON.stringify(resp[1].page));
        rootValues.page_list.map((page, index) => {
          if (page._id == resp[1].page._id) {
            rootValues.page_list[index] = resp[1].page;
            setRootValues({ ...rootValues });
          }
        });
        if (resp[2]) {
          const page_image_resp = await _s3upload_with_name(
            rootValues._id,
            resp[2],
            rootValues.page_slug
          );
          if (page_image_resp.code == 200) {
            // rootValues.image = page_image_resp.file_name

            setIsSaveLoading(false);
          }
        }

        if (exitbuilder == "projects") {
          navigate(`/projects/${rootValues._id}`, {
            state: {
              Project: {
                _id: rootValues._id,
                project_name: rootValues.project_name,
              },
            },
          });
        } else if (exitbuilder == "login") {
          sessionStorage.clear();
          // navigate("/login");
          GotoLoginPage();
        }
        // return resp
      } else {
        resp[0].code != 200 &&
          enqueueSnackbar(resp[0].message, { variant: "error" });
        resp[1].code != 200 &&
          enqueueSnackbar(resp[1].message, { variant: "error" });
      }
      return resp;
    });
  };

  const GotoLoginPage = () => {
    window.location.href = client_side_url + "/" + "controller/logout";
  };
  const ReRenderHtml = () => {
    if (document.getElementById("wb-button-for-remove-focus")) {
      document.getElementById("wb-button-for-remove-focus").click();
    }
    selectedSections.map((section) => {
      targetElementForAddListener(findElementById(section.section_id), section);
      handleAddDragDropListener(findElementById(section.section_id));
    });
    let element = document.getElementById("preview-div");
    if (!element) return;
    isSaveLoading == false &&
      InitializeTinyEditor(setEditorMenuOpen, editorMenuOpen);

    const contextMenu = document.querySelector(".right-menu-wrapper");
    if (element) {
      element.addEventListener("contextmenu", (e) => {
        e.preventDefault();
        contextMenu.style.visibility = "visible";
        console.log(e, "cndnjdjdjdcjdcjdcjdjjndcjn");
        let x = e.pageX,
          y = e.pageY;

        let winWidth = window.innerWidth;
        let winHeight = window.innerHeight;
        let cmWidth = contextMenu.offsetWidth + 30;
        let cmHeight = contextMenu.offsetHeight;
        x = x > winWidth - cmWidth ? winWidth - cmWidth : x;

        // y = y > winHeight - cmHeight ? winHeight - cmHeight : y;
        contextMenu.style.left = `${x}px`;
        contextMenu.style.top = `${y}px`;
        handleElementRightClick(e, e.target);
        return false;
      });
    }
  };

  const downloadZip = async (name) => {
    const zip_path = `${s3baseUrl}/${name}/${name}.zip`;
    // let blob = await fetch(zip_path, myInit).then((res) => res.blob());

    // saveAs(blob, `${name}.zip`);
    let download_button = document.createElement("a");
    download_button.href = zip_path;
    download_button.click();
    return true;
  };

  const handleDownload = async (Project) => {
    const postData = {
      project_name: `${Project._id}`,
    };
    const resp = await _making_zip_of_project(postData);
    if (resp.code === 200) {
      await downloadZip(`${Project._id}`);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    return resp;
  };
  const setDefaultRootValues = (defaultValues) => {
    SaveActionToStack();
    setRootValues({ ...rootValues, ...defaultValues });
  };
  const handleChangeRootValue = (key, value) => {
    SaveActionToStack();
    // Change in color mood from Quick selection
    if (key === "mode") {
      // If we select light we change background white and text black
      if (value === "light") {
        setRootValues({
          ...rootValues,
          theme_mode: "light",
          primary_color: "#FFFFFF",
          primary_text_color: "#000000",
          secondary_text_color: "#FFFFFF",
        });
        return;
      }

      // If we select light we change background black and text white
      if (value === "dark") {
        setRootValues({
          ...rootValues,
          theme_mode: "dark",
          primary_color: "#000000",
          primary_text_color: "#FFFFFF",
          secondary_text_color: "#FFFFFF",
        });
        return;
      }
      return;
    }

    // Change in theme color from Quick selection
    if (key === "theme") {
      setRootValues({
        ...rootValues,
        primary_color: value.primary_color, //base Color
        secondary_color: value.secondary_color, //Theme Color
        primary_text_color: value.primary_text_color,
        secondary_text_color: value.secondary_text_color,
        hover_background_color: value.secondary_text_color,
        hover_text_color: value.secondary_color,
        theme_heading_color: value.theme_heading_color,
        input_background_color: value.primary_color,
        input_border_color: value.primary_text_color,
        input_text_color: value.primary_text_color,
        input_placeholder_color: value.primary_text_color,
        theme_box_shadow: value.box_shadow_color,
        menu_color: value.secondary_text_color,
        menu_hover_color: value.secondary_color,
      });
      return;
    }

    setRootValues({ ...rootValues, [key]: value });
  };

  const setRootValuesFromProject = (values, options) => {
    const dev = _is_dev() ? true : false;
    const user = _get_user();
    setIsDev(dev);
    // section will be shown to the developer
    if (dev) {
      setEditMode(true);
    } else {
      if (user?.type === 2) {
        setEditMode(false);
      }
    }
    setIsPreviewLoading(true);
    setRootValues(values);
    sessionStorage.setItem("project_logo", values.logo);
    setSelectedSections([]);
    if (options.is_template) {
      handleSectionOnSelect(
        { _id: options.template },
        undefined,
        s3builderSource,
        true
      );
    } else {
      handleSectionOnSelect({ _id: values._id }, undefined, s3baseUrl);
    }
  };

  const getRootValues = () => {
    const root = `
    :root {
      --wb-website-theme-primary-color: ${rootValues.primary_color};
      --wb-website-theme-secondary-color: ${rootValues.secondary_color};
      --wb-website-text-heading_color: ${rootValues.theme_heading_color};
      --wb-website-text-primary-color: ${rootValues.primary_text_color};
      --wb-website-text-secondary-color: ${rootValues.secondary_text_color};
      --wb-website-hover-text-color: ${rootValues.hover_text_color};
      --wb-website-hover-background-color: ${rootValues.hover_background_color};
      --wb-website-text-font-family: ${rootValues.font_family};
      --wb-theme-box-shadow: ${rootValues.theme_box_shadow};
      --wb-theme-h1-font-size:${rootValues.theme_h1_font_size};
      --wb-theme-h2-font-size:${rootValues.theme_h2_font_size};
      --wb-theme-h3-font-size:${rootValues.theme_h3_font_size};
      --wb-theme-h4-font-size:${rootValues.theme_h4_font_size};
      --wb-theme-p-font-size:${rootValues.theme_p_font_size};
      --wb-theme-h1-font-weight:${rootValues.theme_h1_font_weight};
      --wb-theme-h2-font-weight:${rootValues.theme_h2_font_weight};
      --wb-theme-h3-font-weight:${rootValues.theme_h3_font_weight};
      --wb-theme-h4-font-weight:${rootValues.theme_h4_font_weight};
      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};
      --wb-theme-h1-font-family:${rootValues.theme_h1_font_family};
      --wb-theme-h2-font-family:${rootValues.theme_h2_font_family};
      --wb-theme-h3-font-family:${rootValues.theme_h3_font_family};
      --wb-theme-h4-font-family:${rootValues.theme_h4_font_family};


      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};


      --wb-input-placeholder-color:${rootValues.input_placeholder_color};
      --wb-input-text-color :${rootValues.input_text_color};
      --wb-input-background-color:${rootValues.input_background_color};
      --wb-input-border-color:${rootValues.input_border_color};
      --wb-menu-color:${rootValues.menu_color};
      --wb-menu-hover-color:${rootValues.menu_hover_color};
    }
    `;

    console.log(rootValues, "rootValues");
    return root;
  };

  function handleOnDragEnd(result) {
    // console.log(result, "result preview");
    if (!result.destination) return;
    SaveActionToStack();
    if (
      result.destination.droppableId === "preview-sections" &&
      result.source.droppableId === "list-sections"
    ) {
      const section = JSON.parse(result.draggableId);

      if (section) {
        handleSectionOnSelect(
          section,
          result.destination.index,
          s3builderSource
        );
      }
    }
    if (
      result.destination.droppableId === "preview-sections" &&
      result.source.droppableId === "preview-sections"
    ) {
      const items = Array.from(getSelectedSections());
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setSelectedSections(items);
    }
  }
  function getSelectedSectionDataset() {
    let dataset = _dispatch_get_dataset_by_settingId(selectedSectionSettingId);
    return dataset;
  }
  function updateSelectedSectionTitle(newTitle) {
    let dataset = _dispatch_get_dataset_by_settingId(selectedSectionSettingId);
    dataset.section_title = newTitle;
    // console.log("TITLE UPDATED ");
    const updatedSection = selectedSections.map((sec, i) => {
      if (i === selectedSectionsIndex) {
        sec.section_title = newTitle;
        return sec;
      } else {
        return sec;
      }
    });
    setSelectedSections(updatedSection);
  }
  function getSelectedSectionSettingId() {
    if (selectedSectionSettingId) {
      return selectedSectionSettingId;
    }
  }

  /* <===== margins & paddings handling ======> */
  const updateSelectedElementSpacing = (type, property, value) => {
    let element = getSelectedElement();
    if (!element) return;
    if (value != "" && value) {
      element.style.setProperty(
        `${type + "-" + property}`,
        `${value}rem`,
        "important"
      );
    }
    if (!value || value == "") {
      element.style.removeProperty(`${type + "-" + property}`);
    }
  };

  /* <===== parent A tag handling ======> */
  const updateParentLink = (newLink) => {
    // console.log(newLink, "at builder context");
    let parent = selectedElement.parentNode;
    if (parent.tagName === "A") {
      let link = parent.getAttribute("href");
      // console.log(link);
      parent.setAttribute("href", newLink);
    }
  };

  const updateParentLinkTarget = (linkTarget) => {
    let parent = selectedElement.parentNode;
    if (parent.tagName === "A") {
      parent.setAttribute("target", linkTarget);
    }
  };

  const getParentLink = () => {
    let parent = selectedElement.parentNode;
    let href = parent.getAttribute("href");
    let target = parent.getAttribute("target");
    return { href, target };
  };

  const handlePublishProject = async (Project = null) => {
    console.log(Project, "handlePublishProject");

    setIsPublishLoading(true);

    const zipPostData = {
      project_name: `${Project ? Project._id : rootValues._id}`,
    };

    // const zip_resp = await _making_zip_of_project(zipPostData)
    // if (zip_resp.code === 200) {

    if (!Project && rootValues.domain === "") {
      enqueueSnackbar("Please add your domain from your Project settings", {
        variant: "error",
      });
      setIsPublishLoading(false);
      return false;
    }
    if (Project && Project.domain === "") {
      enqueueSnackbar("Please add your domain from your Project settings", {
        variant: "error",
      });
      setIsPublishLoading(false);
      return false;
    }

    // const postData = {
    //   project_id: `${rootValues._id}`,
    // }

    // const unzip_resp = await _make_unzip_file_of_project(postData)
    // if (unzip_resp.code === 200) {
    const postData = {
      project_id: `${Project ? Project._id : rootValues._id}`,
      domain: Project ? Project.domain : rootValues.domain,
    };

    // setTimeout(async () => {
    const publish_resp = await _publish_project_on_domain(postData);
    if (publish_resp.code === 200) {
      enqueueSnackbar("Project Published Successfully", {
        variant: "success",
      });

      setTimeout(() => {
        const domain = Project ? Project.domain : rootValues.domain;
        const URL = `http://${domain}`;
        window.open(URL, "_blank");
      }, 1000);

      setIsPublishLoading(false);
      return true;
    } else {
      setIsPublishLoading(false);
      enqueueSnackbar(publish_resp.message, { variant: "error" });
      return false;
    }
    // }, 3000)
    // } else {
    //   setIsPublishLoading(false)
    //   enqueueSnackbar(unzip_resp.message, { variant: 'error' })
    // }
    // } else {
    //   enqueueSnackbar(zip_resp.message, { variant: 'error' })
    //   setIsPublishLoading(false)
    //   return
    // }
  };
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.download = fileName;

    fakeLink.href = blob;
    fakeLink.click();
    fakeLink.remove();
  };
  const convert_https_to_http = async (html, from, to) => {
    if (html) {
      const image_list = html.getElementsByTagName("img");
      if (image_list.length > 0) {
        for (let index = 0; index < image_list.length; index++) {
          image_list[index].src = image_list[index].src.replace(from, to);
        }
      }
    }

    return true;
  };
  const convert_background_https_to_http = async (html, from, to) => {
    // if (html) {
    //   const section_list = html.getElementsByTagName("section");
    //   if (section_list.length > 0) {
    //     for (let index = 0; index < section_list.length; index++) {
    //       if (section_list[index].style.backgroundImage) {
    //         let result = section_list[index].style.backgroundImage.replace(
    //           from,
    //           to
    //         );
    //         // section_list[index].style.backgroundImage = result
    //       } else {
    //         let result = getComputedStyle(section_list[index])[
    //           "background-image"
    //         ].replace(from, to);
    //         section_list[index].style["background-image"] = result;
    //       }
    //     }
    //     // console.log(getComputedStyle(section_list[0])['background-image'])
    //   }
    // }
    return true;
  };
  function pause(milliseconds) {
    var dt = new Date();
    while (new Date() - dt <= milliseconds) {
      /* Do nothing */
    }
  }
  const CheckModalOpen = async () => {
    setIsSaveLoading(true);
    let delay = 10;
    let list = [...document.getElementsByClassName("video-player-stop-event")];
    console.log(list, "list is here");
    if (list && list.length > 0) {
      list.map((item) => {
        if (
          list[0] &&
          list[0].parentNode &&
          list[0].parentNode.parentNode &&
          list[0].parentNode.parentNode.parentNode &&
          list[0].parentNode.parentNode.parentNode.parentNode &&
          list[0].parentNode.parentNode.parentNode.parentNode.classList &&
          list[0].parentNode.parentNode.parentNode.parentNode.classList.contains(
            "show"
          )
        ) {
          item.click();
          delay = 1500;
        }
      });
    }
    return delay;
  };
  const CheckChangeExsistInHTML = () => {
    RemoveContentEditable();
    const { collect_html, Save_Data } = collectFinalHTML();
    let status = false;
    if (collect_html.trim().length == LastSaveData.trim().length) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  const handleSaveTemplateToServer = async ({
    preview = false,
    exitbuilder = false,
    mobile = false,
    tablet = false,
  }) => {
    setIsSaveLoading(true);
    sessionStorage.setItem("fav_icon", rootValues.fav_icon);

    let delay = CheckModalOpen();
    pause(delay);
    RemoveContentEditable();
    const { collect_html, Save_Data } = collectFinalHTML();

    setLastSaveData(collect_html);
    const final_html = collect_html;

    const { collect_css, Save_CSS } = collectFinalCSS();

    const final_css = collect_css;

    const final_js = collectFinalJS();

    // // console.log(final_css, "final_css");
    // console.log(rootValues, "rootValues");
    // console.log(final_js, "final_js");
    const postData = {
      html: final_html,
      css: final_css,
      javascript: final_js,
      project_id: rootValues._id,
      slug_name: SelectedPage,
    };

    // console.log(postData, "finalPostData");
    if (Save_Data && Save_CSS) {
      setIsSaveLoading(true);
      // const resp = await _save_user_design(postData)
      // const PageUpdateResp = await updateUserDesign(
      //   `${rootValues._id}/asserts/${rootValues.page_slug}`,
      // )
      const resp = await _save_user_design(postData);
      InitializeTinyEditor(setEditorMenuOpen, editorMenuOpen);

      if (mobile) {
        setTimeout(async () => {
          setMobilePreview(true);
          setTabletPreview(false);
        }, 500);
      }

      if (tablet) {
        setTimeout(async () => {
          setTabletPreview(true);
          setMobilePreview(false);
        }, 500);
      }

      if (preview) {
        setTimeout(async () => {
          const URL = `${s3baseUrl}/${rootValues._id}/${SelectedPage}.html`;
          window.open(URL, "_blank");

          // take screenshot
        }, 500);
      }
      let result = await Promise.all([
        updateUserDesign(
          `${rootValues._id}/asserts/${rootValues.page_slug}`,
          exitbuilder
        ),
      ]);
      // = result[0];
      const PageUpdateResp = result[0];
      // const file = result[2]previewURL

      setPreviewURL(`${s3baseUrl}/${rootValues._id}/${SelectedPage}.html`);

      if (resp.code == 200) {
        // const file = await getHTMLImage('https', 'http')
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    } else {
      // console.log("Hello")
      // enqueueSnackbar('Error from server Please reload', { variant: 'error' })
    }
    setIsSaveLoading(false);

    setUseEditingProject(true);
    return true;
  };
  const getHTMLImage = async (from, to, Get_image) => {
    let screenshotTarget = document.querySelector(".wb-preview-div");

    // // convert images url
    // const img_converstion_result = await convert_https_to_http(
    //   screenshotTarget,
    //   from,
    //   to,
    // )

    // convert background image url

    // const background__converstion_result = await convert_background_https_to_http(
    //   screenshotTarget,
    //   from,
    //   to,
    // )

    // if (img_converstion_result && background__converstion_result) {
    let target_image = await html2canvas(screenshotTarget, {
      allowTaint: true,
      useCORS: true,
      height: 600,
      // width: 100,
    })
      .then((canvas) => {
        return canvas.toDataURL("image/png", 1.0);
        // downloadImage(canvas.toDataURL(), 'abc3')
      })
      .catch((err) => {
        console.log(err, "Error in canvas convertion");
      });

    if (target_image) {
      const dataURI = target_image;

      var byteCharacters = atob(dataURI.split(",")[1]);
      var byteArrays = [];
      const contentType = "png";
      const sliceSize = 512;
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var myblob = new Blob(byteArrays, { type: contentType });
      return myblob;
      // downloadImage(target_image, 'abc')
    }
    // }
  };
  useEffect(() => {
    // Automatically Saving project very first time.
    if (!isPreviewLoading) {
      setTimeout(() => {
        if (document.getElementById("wb-save-button")) {
          document.getElementById("wb-save-button").click();
          GetImagesList();
        }
      }, 1000);
    }
  }, [isPreviewLoading]);

  useEffect(() => {
    ReRenderHtml();
    // setnewsection([]);
  }, [selectedSections]);

  useEffect(() => {
    // remove focus from the element
    // by blur method on drawers open and close
    if (selectedElement) {
      selectedElement.blur();
    }
  }, [rightMenuOpen, editorMenuOpen]);
  useEffect(() => {
    CloseAllEditor();
  }, [
    editorMenuOpen,
    rightMenuOpen,
    OpenRowSetting,
    openSelectedBoxDiv,
    sectionSettingsMenuOpen,
    leftMenuOpen,
    useNavigate(),
  ]);

  const handleupdateIMG = (val) => {
    SaveActionToStack();
    editAbleInputs.src = val;
    selectedElement.src = editAbleInputs.src;
  };

  const GetSectionCssAndReplacePath = async () => {
    let section_name = [];
    let PromiseList = [];
    getSelectedSections().map((section) => {
      PromiseList.push(
        fetchFileFromURL(
          `${s3builderSource}/${section.section_name}/css/style.css`
        )
      );
      section_name.push(section.section_name);
    });
    await Promise.all(PromiseList)
      .then((cssList) => {
        section_name.map((singleSectionID, index) => {
          cssList[index] = cssList[index].replaceAll(
            "../assets",
            `${s3builderSource}/${singleSectionID}/assets`
          );
        });
        try {
          getSelectedSections().map((section, index) => {
            selectedSections[index].section_css = cssList[index];
          });

          setSelectedSections([...selectedSections]);
          ReRenderHtml();
          console.log(cssList, "Fetching css of every section resp");
        } catch (error) {
          console.log(error, "Erorr in Updating css");
        }
      })
      .catch((error) => {
        console.log(error, "Error In updating Css");
      });
  };

  const handleUpateCss = async () => {
    // for all sections
    // GetSectionCssAndReplacePath()

    // for single section
    let section_id = findElementById(
      selectedSectionSettingId
    ).getElementsByTagName("section")[0].dataset.section_name;
    let css = await fetchFileFromURL(
      `${s3builderSource}/${section_id}/css/style.css`
    );
    css = css.replaceAll(
      "../assets",
      `${s3builderSource}/${section_id}/assets`
    );
    getSelectedSections().map((section, index) => {
      if (section.section_name == section_id) {
        section.section_css = css;
      }
    });
    setSelectedSections([...selectedSections]);
    // ReRenderHtml();
  };

  // image Editor
  const showItemInLeftDrawer = (menu) => {
    setDrawerWidth(
      menu === "Theme Setting" || menu === "Gallery"
        ? 350
        : menu === "Elements"
        ? 275
        : 315
    );
    setLeftDrawerType("expand");
    setSelectedlistItem(menu);
  };

  const collection = {
    selectedElement,
    setSelectedElement,
    fetchFileFromURL,
    updateSelectedSectionTitle,
    getSelectedSectionDataset,
    getSelectedSectionSettingId,
    getAllSections,
    getSelectedSections,
    setSelectedSections,
    handleOnDragEnd,
    handleSectionOnSelect,
    selectedSections,
    setSelectedSectionSettingId,
    setSelectedSectionsIndex,
    selectedSectionsIndex,
    customerSubscriptionDetail,

    // Drawers
    setLeftMenu,
    leftMenu,
    leftMenuOpen,
    setLeftMenuOpen,
    rightMenuOpen,
    setRightMenuOpen,
    editorMenuOpen,
    setEditorMenuOpen,
    OpenRowSetting,
    setOpenRowSetting,
    SelectedRow,
    setSelectedRow,
    SelectedRowClasses,
    setSelectedRowClasses,
    setTargetSectionId,
    // Mobile or Tablet Preview
    mobilePreview,
    setMobilePreview,
    tabletPreview,
    setTabletPreview,
    changeInHTML,
    setChangeInHTML,
    previewURL,
    // theme menu
    rightThemeMenuOpen,
    setRightThemeMenuOpen,
    // editable inputs
    isSaveLoading,
    setIsSaveLoading,
    handleChangeInEditableInputs,
    getEditAbleInputs,
    handleUpdateElement,
    handleupdateIMG,
    editAbleInputs,
    setEditAbleInputs,
    // element handlings
    handleRemoveElement,
    getSelectedElementTag,
    handleRemoveSelection,
    handleUpSelection,
    handleDownSelection,
    handleSaveTemplateToServer,
    CheckChangeExsistInHTML,
    // loading
    isSaveLoading,
    isPreviewLoading,
    isPublishLoading,
    // download zip
    handleDownload,
    // publish project
    handlePublishProject,
    // root values change function
    rootValues,
    handleChangeRootValue,
    getRootValues,
    setRootValuesFromProject,
    handleThemeClick,
    setDefaultRootValues,

    // Section Settings Functions
    sectionSettingsMenuOpen,
    setSectionSettingsMenuOpen,
    FormSettingsMenuOpen,
    setFormSettingsMenuOpen,
    handleOpenSectionSettings,
    handleOpenFormSetting,
    handleDuplicateSection,
    handleUpdateSectionSettings,
    getSelectedSectionLists,
    // selected section styles
    getSelectedSectionStyle,
    getSelectedSectionheightwidth,
    updateSelectedSectionStyle,
    // selected element style
    getSelectedElementStyleByProperty,
    updateSelectedElementStyleByProperty,
    // classes
    getSelectedSectionClass,
    updateSelectedSectionClass,
    // element update class functions
    updateSelectedElementClass,
    getSelectedElementClassByType,
    // update menu list
    handleReplaceListElements,
    // Get i-frames
    getSelectedSectionFrames,
    //Get selected element
    getSelectedElement,
    // handleIMGHeightWidth,
    checkIsEditorElement,
    getSelectedElementAttributes,
    updateSelectedElementAttributes,
    handleUpdateStateFromDOM,
    //Meta
    metaStates,
    setMetaStates,
    //selected element attribute states
    selectedElementAttributes,
    setSelectedElementAttributes,
    //Modal open/close states
    headerContentModal,
    setHeaderContentModal,
    //Dialog open/close states
    isHeaderDialogOpen,
    setIsHeaderDialogOpen,
    //Edit mode
    getEditModeState,
    getIsDevState,
    setIsDev,
    //
    getSelectedSectionForm,
    getSelectedSectionFormByID,
    //margin & paddings
    updateSelectedElementSpacing,
    //parent A tag handling
    updateParentLink,
    updateParentLinkTarget,
    getParentLink,
    // nav manipulations functions
    getTopMenuNavClasses,
    updateTopMenuNavClasses,
    getTopMenuNavStyle,
    updateTopMenuNavStyle,
    SelectedPage,
    setSelectedPage,
    setUseEditingProject,
    //
    editorRef,
    // rernder html after editing column
    ReRenderHtml,

    // do undo work
    SaveActionToStack,
    UndoAction,
    RedoAction,
    HistoyStack,
    RedoStack,
    setHistoyStack,
    setRedoStack,
    CheckElementIsRow,

    RemoveRowBlock,

    handleUpateCss,

    selectedBoxDiv,
    setSelectedBoxDiv,
    openSelectedBoxDiv,
    setOpenSelectedBoxDiv,

    updateUserAsserts,
    userAssertDetails,
    userImagesList,
    setUserImagesList,
    searchImageList,
    setSearchImageList,
    RemoveUserGalleryClass,
    GetImagesList,
    GotoLoginPage,

    // left menu
    leftDrawerType,
    setLeftDrawerType,
    selectedlistItem,
    setSelectedlistItem,
    drawerWidth,
    setDrawerWidth,
    showItemInLeftDrawer,

    // replaceable element
    openReplaceElementMenu,
    setOpenReplaceElementMenu,
    isReplaceableElement,
    setIsReplaceableElement,

    // imageEditor
    imageAnchorEl,
    setImageAnchorEl,
    // Icon editor
    setIconAnchorEl,
    iconAnchorEl,
    iconEditorPosition,
    //Right Menu
    setRightMenuAnchorEl,
    rightMenuAnchorEl,
    rightMenuList,

    // source code
    handleOpenElementSourceCode,
    handleElementClick,

    // custom class menu
    openDeveloperOptionMenu,
    setOpenDeveloperOptionMenu,
    developerOptionMenuType,
    setDeveloperOptionMenuType,
    handleOpenCustomClassMenu,
    handleOpenCustomJSMenu,
  };

  return (
    <BuilderContext.Provider value={collection}>
      {children}
    </BuilderContext.Provider>
  );
}
