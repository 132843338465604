import React, { useEffect, useState, useRef } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function Croper({
  handleFullSelect,
  onImageLoad,
  handlecrop,
  handleremoveIMG,
  uploadedimgheightwidth,
  Customcrop,
  // states
  Croperheightwidth,
  ImgData,
  PreviewImage,
  imgSrc,
  Checked,
  crop,
  aspect,
  completedCrop,
  previewCanvasRef,
  isLoading,
  imgRef,

  // setstate
  setImgSrc,
  setUplodImgType,
  setChecked,
  setAspect,
  setCrop,
  setCompletedCrop,
  setCustomcrop,
}) {
  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      )
      const img = e.target.files[0]
      setUplodImgType(img.type)

      PreviewImage.show = false
      reader.readAsDataURL(e.target.files[0])
    }
  }
  const handlechangecropaspect = (click) => {
    if (click == 'custom') {
      setChecked('custom')
      setAspect(undefined)
    }
    if (click == 'fix') {
      setAspect(ImgData.width / ImgData.height)
      setChecked('fix')

      // image height width from img tag
      let recomended_height = parseInt(ImgData.height)
      let recomended_width = parseInt(ImgData.width)

      // new uploaded image height width
      let image_height = uploadedimgheightwidth.height
      let image_width = uploadedimgheightwidth.width
      console.log(uploadedimgheightwidth, ImgData, 'compare')
      // check if new image is greater or smaller than the recomended size
      if (
        image_height <= recomended_height ||
        image_width <= recomended_width
      ) {
        if (
          image_width / image_height ==
          recomended_width / recomended_height
        ) {
          // aspect ratio same
          const { height, width } = imgRef.current
          if (width < image_width) {
            image_width = image_width / (uploadedimgheightwidth.width / width)
          }
          if (height < image_height) {
            image_height =
              image_height / (uploadedimgheightwidth.height / height)
          }
          recomended_height = image_height
          recomended_width = image_width
        } else {
          let asp = recomended_width / recomended_height
          if (image_width / image_height > 1) {
            recomended_width =
              recomended_width * image_height * (1 / recomended_height)
            recomended_height = image_height
          } else {
            recomended_height =
              image_width * recomended_height * (1 / recomended_width)
            recomended_width = image_width
          }
          recomended_width =
            recomended_width /
            (uploadedimgheightwidth.width / imgRef.current.width)
          recomended_height =
            recomended_height /
            (uploadedimgheightwidth.height / imgRef.current.height)
        }
      } else {
        const { height, width } = imgRef.current
        recomended_height = recomended_height * (width / image_width)
        recomended_width = recomended_width * (height / image_height)
      }
      setCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: 'px',
      })
      setCompletedCrop({
        x: 0,
        y: 0,
        height: recomended_height,
        width: recomended_width,
        unit: 'px',
      })
    }

    setCustomcrop(!Customcrop)
  }
  return (
    <>
      <div className="col-12 pt-3 mb-1">
        <span className="custom-label">Upload Image:</span>
        {/* react crop image  */}
        <input
          className="pt-2"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>
      {Boolean(imgSrc) && (
        <div className="col-12 mb-1">
          <span className="custom-label">
            Recomended Height : {ImgData.height + 'px'} Width :{' '}
            {ImgData.width + 'px'}
          </span>
        </div>
      )}

      {!PreviewImage.show && (
        <div>
          {Boolean(imgSrc) && (
            <div className="col-12">
              <div>
                <div className="row ">
                  <div className="col-12">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="container"
                        checked={Checked == 'fix' && 'true'}
                        onClick={() => handlechangecropaspect('fix')}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="container"
                      >
                        Recomended Size
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="container-fluid"
                        checked={Checked == 'custom' && 'true'}
                        onClick={() => handlechangecropaspect('custom')}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="container-fluid"
                      >
                        Custom Size
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="full-size"
                        checked={Checked == 'full' && 'true'}
                        onClick={handleFullSelect}
                      />
                      <label
                        className="form-check-label pointer custom-label"
                        htmlFor="full-size"
                      >
                        Full Image
                      </label>
                    </div>
                  </div>
                </div>

                {Croperheightwidth.status && (
                  <div className="row mb-2">
                    <div className="col-12">
                      <span className="custom-label">
                        Image Height:
                        {Croperheightwidth.height +
                          'px' +
                          ' Width:' +
                          Croperheightwidth.width +
                          'px'}
                      </span>
                    </div>
                  </div>
                )}

                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => {
                    setCompletedCrop(c), console.log(c, 'cropcrop')
                  }}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
          )}
        </div>
      )}
      {PreviewImage.show && (
        <div>
          <img alt="Image" src={PreviewImage.src} />
        </div>
      )}
    </>
  )
}

export default Croper
