import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
const ImageTransformBar = ({
  imageTransform,
  handleChangeInClass,
  classesList,
}) => {
  const styleClasses = useStyles();

  const {
    getSelectedElementStyleByProperty,
    updateSelectedElementStyleByProperty,
  } = useBuilder();

  const [borderRadius, setBorderRadius] = useState("0");

  const getValue = () => {
    let border_radius = getSelectedElementStyleByProperty({
      property: "borderRadius",
    });

    if (border_radius) {
      border_radius = border_radius.replace("px", "");
    } else {
      border_radius = 0;
    }

    setBorderRadius(border_radius);
  };

  const handleChangeBorderRadius = (_value) => {
    let value = _value ? _value : 0;
    updateSelectedElementStyleByProperty({
      property: "borderRadius",
      value: value + "px",
    });

    setBorderRadius(_value);
  };

  useEffect(() => {
    getValue();
  }, [imageTransform]);
  return (
    <>
      <div class="mb-2">
        <div className="font-size-xsm mt-2 d-flex">
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={
                imageTransform ? "wb-icons-box" : "wb-icons-box-active"
              }
              onClick={() => {
                handleChangeInClass("imageTransform", null, classesList);
                updateSelectedElementStyleByProperty({
                  property: "borderRadius",
                  value: null,
                });
              }}
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls, i) => (
            <Tooltip key={i} title={cls.label} placement="top" arrow>
              <span
                className={
                  imageTransform === cls.value
                    ? "wb-icons-box-active"
                    : "wb-icons-box"
                }
                onClick={() =>
                  handleChangeInClass("imageTransform", cls.value, classesList)
                }
              >
                <Icon icon={cls.icon_name} />
              </span>
            </Tooltip>
          ))}
          {imageTransform === "img-custom-round-size" && (
            <>
              <input
                className="form-control form-control-sm"
                id="edit-able-width-input"
                size="small"
                label="Width"
                type="number"
                value={borderRadius}
                onChange={(e) => handleChangeBorderRadius(e.target.value)}
                placeholder="custom"
                autoFocus
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageTransformBar;
