import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  _delete_project_subscriber_by_email,
  _project_subscribers_list,
} from "../../DAL/projects/projects";
import Loader from "../../components/Loader/Loader";
import {
  Avatar,
  Button,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import account from "../../_mocks_/account";
import { LoadingButton } from "@mui/lab";
import Label from "../../components/Label";
import {
  _add_support_ticket_comment,
  _delete_support_ticket_comment,
  _support_ticket_details,
} from "../../DAL/support_tickets/support_tickets";
import { s3baseUrl } from "../../config/config";
import { _upload_file_on_s3 } from "../../DAL/s3upload/s3upload";
import moment from "moment";
import { get_local_preview } from "../../utils/utils";
import CustomPopover from "../../components/CustomPopover";
import CustomConfirmation from "../../components/CustomConfirmation";

function SupportTicketDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const ticket_id = params?.ticket_id;

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [ticketData, setTicketData] = useState("");
  const [comments, setComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [inputs, setInputs] = useState({
    message: "",
    image: "",
  });

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickRemove = () => {
    setInputs({
      ...inputs,
      image: "",
    });
  };

  const handleDeleteClick = (row) => {
    setSelectedComment(row);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    const delete_resp = await _delete_support_ticket_comment(
      selectedComment._id
    );
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      fetchDetails();
      setOpenDelete(false);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    // upload image
    if (inputs.image) {
      setIsLoadingSend(true);
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.image);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const postData = {
          support_ticket_id: ticket_id,
          message: inputs.message,
          image: image_resp.file_name,
        };

        const resp = await _add_support_ticket_comment(postData);
        if (resp.code === 200) {
          setIsLoadingSend(false);
          setInputs({
            message: "",
            image: "",
          });
          fetchDetails();
        } else {
          setIsLoadingSend(false);
          enqueueSnackbar(resp.message, { variant: "error" });
        }
      }
    } else {
      const postData = {
        support_ticket_id: ticket_id,
        message: inputs.message,
        image: inputs.image,
      };

      setIsLoadingSend(true);
      const resp = await _add_support_ticket_comment(postData);
      if (resp.code === 200) {
        setIsLoadingSend(false);
        setInputs({
          message: "",
          image: "",
        });
        fetchDetails();
      } else {
        setIsLoadingSend(false);
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
  };
  const fetchDetails = async () => {
    const resp = await _support_ticket_details(ticket_id);
    if (resp.code === 200) {
      setTicketData(resp.support_ticket);
      setComments(resp.support_ticket_comment_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <Typography variant="h4" gutterBottom>
              {ticketData.subject}
            </Typography>
            <div className="mt-1">
              {ticketData.ticket_status === 0 && (
                <Label style={{ width: 70 }} variant="ghost" color="info">
                  OPEN
                </Label>
              )}

              {ticketData.ticket_status === 1 && (
                <Label style={{ width: 70 }} variant="ghost" color="success">
                  CLOSED
                </Label>
              )}
            </div>
          </div>
          <div className="col-12 mb-3">
            {ticketData.image && (
              <img
                width="100%"
                src={s3baseUrl + "/" + ticketData.image}
                alt=""
              />
            )}
          </div>
          <div className="col-12 mb-4">
            <Typography variant="p" gutterBottom>
              {ticketData.description}
            </Typography>
          </div>

          {comments.map((comment) => (
            <div className="col-12 mt-2">
              <div className="support-ticket-wrapper border-top">
                <div className="support-profile me-3 mt-2">
                  <Avatar
                    className="text-capitalize"
                    style={{ width: 50, height: 50 }}
                    src={s3baseUrl + "/" + comment.user?.image}
                    alt={comment.user?.first_name}
                  />
                </div>
                <div className="w-100">
                  <div className="mt-2">
                    <Typography
                      className="mb-0 text-capitalize"
                      variant="subtitle1"
                      gutterBottom
                    >
                      {comment.user?.first_name + " " + comment.user?.last_name}
                    </Typography>
                    {comment.user?.type === "1" && (
                      <div className="float-end">
                        <CustomPopover data={comment} menu={MENU_OPTIONS} />
                      </div>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                      noWrap
                    >
                      {moment(comment.createdAt).fromNow()}
                    </Typography>
                  </div>
                  <div className="mt-1">
                    <Typography
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="p"
                      gutterBottom
                    >
                      {comment.message}
                    </Typography>
                  </div>

                  <div className="mt-1">
                    <a href={s3baseUrl + "/" + comment.image} target="_blank">
                      <img
                        width="60"
                        src={s3baseUrl + "/" + comment.image}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <form onSubmit={handleSubmit}>
            <div className="col-12 mt-4">
              <TextField
                fullWidth
                label="Message"
                placeholder="Please add your details here"
                required
                name="message"
                multiline
                rows={5}
                value={inputs.message}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
              {inputs.image ? (
                <div className="d-flex justify-content-start align-items-center">
                  <img
                    width="60"
                    src={get_local_preview(inputs.image)}
                    alt=""
                  />

                  <Button
                    onClick={handleClickRemove}
                    className="mt-1 ms-3"
                    style={{ color: "Red" }}
                    fullWidth
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="image"
                    onChange={handleChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}

              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isLoadingSend}
              >
                Send
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

export default SupportTicketDetails;
