import { ChildCare } from "@mui/icons-material";
import React, { Children, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import BackgroundImage from "../../../Right-Section-Settings-Menu/components/Background-Image/BackgroundImage";
import {
  colors,
  background_size_classes,
  background_repeat_classes,
} from "../../../../../../ui_libs/Constants/constant";
import Typography from "@mui/material/Typography";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Icon } from "@iconify/react";
import Stack from "@mui/material/Stack";
import ButtonProperty from "../../../Right-Menu/components/Buton-Property/ButtonProperty";
import { TwitterPicker } from "react-color";
import { BackgroundSize } from "../../../Right-Section-Settings-Menu/components";
import { BackgroundRepeat } from "../../../Right-Section-Settings-Menu/components";
import { RowColumnStyleAccordion } from "./RowColumnStyleAccordion";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
export const RowColumnStyle = ({
  // column block styling handlers
  Children,
  ChangeColumnBlockStyle,
  ChangeColumnBackgroundColor,
  getColumnproperty,
  ResetColumnproperty,
  ResetColumnBackgroundColor,
  ChangeColumnBlockClass,
  getColumnBlockClass,
}) => {
  const [ImgData, setImgData] = useState([]);

  const CheckBoxClassAvailable = (target) => {
    let class_result = false;
    const result = target.querySelectorAll(".wb-box");
    console.log(result, "resultresultresultresult");
    if (result.length > 0) {
      class_result = true;
    }

    return class_result;
  };
  useEffect(() => {
    let data = [];
    Children.map((child, index) => {
      const { offsetHeight, offsetWidth } = child;
      child.setAttribute("imgheight", offsetHeight);
      child.setAttribute("imgwidth", offsetWidth);
      data.push({
        height: child.getAttribute("imgheight"),
        width: child.getAttribute("imgwidth"),
      });
      // height=child.getAttribute('imgheight') width=child.getAttribute('imgwidth')
    });
    setImgData([...data]);
  }, []);

  return (
    <div>
      {Children.map((child, index) => (
        <>
          {CheckBoxClassAvailable(child) && (
            <RowColumnStyleAccordion
              child={child}
              OpenColumnStyle={index}
              ChangeColumnBlockStyle={ChangeColumnBlockStyle}
              ChangeColumnBackgroundColor={ChangeColumnBackgroundColor}
              getColumnproperty={getColumnproperty}
              ResetColumnproperty={ResetColumnproperty}
              ResetColumnBackgroundColor={ResetColumnBackgroundColor}
              ChangeColumnBlockClass={ChangeColumnBlockClass}
              getColumnBlockClass={getColumnBlockClass}
              // img properties
              ImgData={ImgData[index]}
            />
          )}
        </>
      ))}
    </div>
  );
};
