import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _dispatch_updateMetaTags } from "../../../../hooks/builder-context/utils";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
  },
}));

export default function HeaderContentModal() {
  const {
    setHeaderContentModal,
    setMetaStates,
    metaStates,
    headerContentModal,
  } = useBuilder();

  const classes = useStyles();
  const handleClose = () => {
    setHeaderContentModal(false);
  };
  const handleChange = (e) => {
    setMetaStates({ ...metaStates, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Modal
        disableEnforceFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={headerContentModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
      >
        <Fade in={headerContentModal}>
          <div className={classes.paper}>
            <div className="row d-flex justify-content-center align-items-center h-100">
              <h6 className="mt-3 text-center mb-3">Header Content</h6>
              <div className="col-md-12 col-lg-8 col-xl-6 ">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-outline mb-3">
                        <label className="form-label " htmlFor="pageTitle">
                          Page Title
                        </label>
                        <input
                          value={metaStates.pageTitle}
                          onChange={handleChange}
                          type="text"
                          id="pageTitle"
                          name="pageTitle"
                          className="form-control form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-outline mb-3">
                        <label
                          className="form-label "
                          htmlFor="pageDescription"
                        >
                          Page Description
                        </label>
                        <input
                          value={metaStates.pageDescription}
                          onChange={handleChange}
                          type="text"
                          name="pageDescription"
                          id="pageDescription"
                          className="form-control form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-outline mb-3">
                        <label className="form-label " htmlFor="keywords">
                          Keywords
                        </label>
                        <input
                          value={metaStates.keywords}
                          onChange={handleChange}
                          type="text"
                          name="keywords"
                          id="keywords"
                          className="form-control form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label " htmlFor="favicon">
                        Favicon URL
                      </label>
                      <div className="d-flex">
                        <input
                          value={metaStates.favicon}
                          onChange={handleChange}
                          required
                          name="favicon"
                          id="favicon"
                          type="text"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-outline mb-3">
                    <label className="form-label " htmlFor="form3Example3">
                      Social Media
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="" className=" ">
                          Title
                        </label>
                        <input
                          value={metaStates.socialTitle}
                          onChange={handleChange}
                          className="form-control form-control-sm"
                          type="text"
                          name="socialTitle"
                          id="socialTitle"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="" className="me-1">
                          Page URL
                        </label>
                        <input
                          value={metaStates.socialPageUrl}
                          onChange={handleChange}
                          className="form-control form-control-sm"
                          type="text"
                          name="socialPageUrl"
                          id="socialUrl"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3 mb-md-0">
                        <label htmlFor="" className="me-1 ">
                          Description
                        </label>
                        <input
                          value={metaStates.socialDescription}
                          onChange={handleChange}
                          className="form-control form-control-sm"
                          type="text"
                          name="socialDescription"
                          id="socialDescription"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3 mb-md-0">
                        <label htmlFor="" className="me-1 ">
                          Image URL
                        </label>
                        <input
                          value={metaStates.socialImageUrl}
                          onChange={handleChange}
                          className="form-control form-control-sm"
                          type="text"
                          name="socialImageUrl"
                          id="socialImageUrl"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-3 row">
                    <div className="col-md-6">
                      <label className="form-label " htmlFor="form3Example3">
                        Robots
                      </label>
                      <div>
                        <input
                          value={metaStates.robots}
                          onChange={handleChange}
                          name="robots"
                          id="robots"
                          type="text"
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label " htmlFor="form3Example3">
                        Theme color
                      </label>
                      <div>
                        <input
                          value={metaStates.themeColor}
                          onChange={handleChange}
                          name="themeColor"
                          id="themeColor"
                          type="text"
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <button
                      type="submit"
                      className="btn main-primary-btn main-btn-text main-btn-small mt-2"
                      style={{ width: "6rem" }}
                    >
                      Update
                    </button>
                    <button
                      onClick={handleClose}
                      type="button"
                      className="btn btn-secondary mt-2 ms-3"
                      style={{ width: "6rem" }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
