import Tooltip from '@mui/material/Tooltip'
import React, { useRef, useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
// import { Close, Done } from "@material-ui/icons";
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'

const InlineInputField = ({ value, handleUpdate, placeholder }) => {
  const [defaultInput, setDefaultInput] = useState(value)
  const [input, setInput] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const inputRef = useRef()
  const [isBtnHover, setIsBtnHover] = useState(false)

  const handleSave = () => {
    handleUpdate(input)
    setDefaultInput(input)
    setIsBtnHover(false)
    setIsDisabled(true)
  }

  const handleClose = () => {
    setInput(defaultInput)
    setIsDisabled(true)
  }
  const handleBlur = () => {
    if (isBtnHover) return
    setInput(defaultInput)
    setIsDisabled(true)
  }
  const handleEdit = async () => {
    await setIsDisabled(false)
    inputRef.current.focus()
  }

  useEffect(() => {
    setInput(value)
    setDefaultInput(value)
  }, [value])

  return (
    <div className="row align-items-center justify-content-center w-100 ms-0">
      <div className="col-12 p-0 position-relative">
        <input
          ref={inputRef}
          placeholder={placeholder}
          onBlur={handleBlur}
          className="form-control form-control-sm"
          name="icon_link"
          id="icon_link"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={isDisabled}
        />
        {isDisabled ? (
          <div className="col-4 p-0 d-flex flex-col  position-absolute top-50 end-0  translate-middle-y">
            <span className="ms-auto ">
              <button onClick={() => handleEdit()} className="btn btn-sm ">
                <Tooltip title="Edit" placement="top" arrow>
                  <EditIcon />
                </Tooltip>
              </button>
            </span>
          </div>
        ) : (
          <div className="col-4 p-0 align-items-center position-absolute top-50 end-0  translate-middle-y">
            <div className="row align-items-center justify-content-end m-0 ">
              <div
                onMouseLeave={(e) => setIsBtnHover(false)}
                onMouseEnter={(e) => setIsBtnHover(true)}
                className="p-0 icon-container fit-content"
              >
                <span className="mx-1 ">
                  <Tooltip title="Save" placement="top" arrow>
                    <DoneIcon htmlColor="green" onClick={() => handleSave()} />
                  </Tooltip>
                </span>
              </div>
              <div className="p-0 icon-container fit-content">
                <span className="mx-1 ">
                  <Tooltip title="Cancel" placement="top" arrow>
                    <CloseIcon htmlColor="red" onClick={() => handleClose()} />
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default InlineInputField
