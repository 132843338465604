import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {
  _save_user_design_html,
  _save_user_design,
  _get_user_design_details,
} from "../../../../DAL/projects/projects";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _create_user_design_page } from "../../../../DAL/projects-pages/projectspages";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});
import { useNavigate } from "react-router-dom";
import { asserts_base_url } from "../../../../config/config";
import LimitationModal from "../../../../components/LimitationModal/LimitationModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function AddNewPage({
  open,
  setOpen,
  Page,
  project_id,
  fetchAvailablePages,
  duplicate = false,
  FinalHTML,
  FinalCSS,
  FinalJS,
}) {
  const navigate = useNavigate();
  const [projectDetail, setProjectDetail] = useState({});
  const [limitModal, setLimitModal] = useState(false);
  const { setUseEditingProject, customerSubscriptionDetail } = useBuilder();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    PageName: "",
    PageSlug: "",
  });
  let MyTargetHTML = " ";
  const GetFavIcon = () => {
    let icon = sessionStorage.getItem("fav_icon");
    return icon ? icon : "";
  };
  const GetTemplateHTML = (page, TargetHTML) => {
    let collect_html = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${page.meta_title ? page.meta_title : page.page_name}</title>
    <link rel="icon" type="image/x-icon" href="${GetFavIcon()}">
    <meta name="description" content="${
      page.meta_description ? page.meta_description : ""
    }" />
    <meta name="keywords" content="${
      page.meta_keywords ? page.meta_keywords : ""
    }" />
    <!-- Header Script  Start-->
    ${page.google_analytics_script ? page.google_analytics_script : ""}
    <!-- Header Script End -->

   
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />

  <!-- Using Carousel -->
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
    integrity="sha512-sMXtMNL1zRzolHYKEujM2AqCLUR9F2C4/05cdbxjjLSRvMQIciEPCQZo++nk7go3BtSuK9kfa/s+a4f4i5pLkw=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
    integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  
  <link
    rel="stylesheet"
    href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/fonts.css"
  />
  <link
    rel="stylesheet"
    href="https://builder-templates-bucket.s3.amazonaws.com/asserts-db/css/main.css"
  />

  
  
  <link href="css/${page.page_slug}.css" rel="stylesheet">
 
  <style>
      ${getRootValues(page)}
      </style>
  </head>
  <body>
  <div name="root-data-div" data-website_id="${
    page.project
  }" data-success_message="${""}" data-failure_message="${""}"></div>
    
${TargetHTML == null ? "" : TargetHTML}
    <!-- Body Script Start-->
    ${page.body_script ? page.body_script : ""}
    <!-- Body Script End -->

    <script src="https://code.jquery.com/jquery-3.6.0.js"
  integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
    <script
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
    crossorigin="anonymous"
  ></script>
  
  <!-- font-awesome 6 -->
  <script
    src="https://kit.fontawesome.com/e30259c958.js"
    crossorigin="anonymous"
  ></script>
  
  <!-- Carousel -->
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
    integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  ></script>

  <script src="${asserts_base_url}/asserts-db/js/index.js" crossorigin="anonymous"></script>
  <script src="js/${page.page_slug}.js"></script>
  
</body>
</html>
`;

    return collect_html;
  };
  const getRootValues = (updatedPage) => {
    const {
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      //
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = updatedPage;

    const rootValues = {
      primary_color: theme_primary_color,
      secondary_color: theme_secondary_color,
      theme_heading_color,
      primary_text_color: text_primary_color,
      secondary_text_color: text_secondary_color,
      hover_text_color: hover_text_color,
      hover_background_color: hover_background_color,
      font_family: text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };
    const root = `
    :root {
      --wb-website-theme-primary-color: ${rootValues.primary_color};
      --wb-website-theme-secondary-color: ${rootValues.secondary_color};
      --wb-website-text-heading_color: ${rootValues.theme_heading_color};
      --wb-website-text-primary-color: ${rootValues.primary_text_color};
      --wb-website-text-secondary-color: ${rootValues.secondary_text_color};
      --wb-website-hover-text-color: ${rootValues.hover_text_color};
      --wb-website-hover-background-color: ${rootValues.hover_background_color};
      --wb-website-text-font-family: ${rootValues.font_family};
      --wb-theme-box-shadow: ${rootValues.theme_box_shadow};
      --wb-theme-h1-font-size:${rootValues.theme_h1_font_size};
      --wb-theme-h2-font-size:${rootValues.theme_h2_font_size};
      --wb-theme-h3-font-size:${rootValues.theme_h3_font_size};
      --wb-theme-h4-font-size:${rootValues.theme_h4_font_size};
      --wb-theme-p-font-size:${rootValues.theme_p_font_size};
      --wb-theme-h1-font-weight:${rootValues.theme_h1_font_weight};
      --wb-theme-h2-font-weight:${rootValues.theme_h2_font_weight};
      --wb-theme-h3-font-weight:${rootValues.theme_h3_font_weight};
      --wb-theme-h4-font-weight:${rootValues.theme_h4_font_weight};
      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};
      --wb-theme-line-height:${rootValues.theme_line_height};
      --wb-theme-h1-font-family:${rootValues.theme_h1_font_family};
      --wb-theme-h2-font-family:${rootValues.theme_h2_font_family};
      --wb-theme-h3-font-family:${rootValues.theme_h3_font_family};
      --wb-theme-h4-font-family:${rootValues.theme_h4_font_family};

      --wb-input-placeholder-color:${rootValues.input_placeholder_color};
      --wb-input-text-color :${rootValues.input_text_color};
      --wb-input-background-color:${rootValues.input_background_color};
      --wb-input-border-color:${rootValues.input_border_color};
      --wb-menu-color:${rootValues.menu_color};
      --wb-menu-hover-color:${rootValues.menu_hover_color};
    }
    `;

    return root;
  };

  const handleCheckAddPage = async (e) => {
    e.preventDefault();
    if (
      customerSubscriptionDetail.plan_id.pages_count >
      projectDetail.project_pages_count
    ) {
      handlePageNameSubmit(e);
    } else {
      // enqueueSnackbar("Your Pages limit reached kindly upgrade your plan", {
      //   variant: "warning",
      // });
      handleClose();
      setLimitModal(true);
      // setLimitationModalTitle("page");
    }
  };

  const handlePageNameSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    let PageSlug = "";
    inputs.PageName = inputs.PageName.trim();
    PageSlug = inputs.PageName.replaceAll(" ", "-");
    PageSlug = PageSlug.toLowerCase();

    const page_resp = await CreateThemeIndexPage(inputs.PageName, PageSlug);
    if (page_resp.code == 200) {
      let template;
      template = duplicate
        ? GetTemplateHTML(page_resp.page, FinalHTML)
        : GetTemplateHTML(page_resp.page, null);

      const postData = {
        html: template,
        css: duplicate ? FinalCSS : "    ",
        javascript: duplicate ? FinalJS : "    ",
        project_id: page_resp.page.project,
        slug_name: page_resp.page.page_slug,
      };
      const temp_resp = await _save_user_design(postData);
      if (temp_resp.code == 200) {
        // setSelectedPage(PageSlug);
        setUseEditingProject(true);
        setTimeout(() => {
          project_id
            ? fetchAvailablePages()
            : navigate("/builder-redirect-controller", {
                state: {
                  project_id: Page.project,
                  page_id: page_resp.page._id,
                },
              });
          setIsLoading(false);
          setOpen(false);
        }, 100);
      } else {
        enqueueSnackbar(temp_resp.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar(page_resp.message, { variant: "error" });
      setIsLoading(false);
      // setOpen(false);
    }
  };
  const CreateThemeIndexPage = async (page_name, page_slug) => {
    const {
      project,
      mode,
      image,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = Page;

    const data = {
      page_name,
      page_slug,
      project,
      image: duplicate == true ? image : "",
      mode,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      text_font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };
    const page_resp = await _create_user_design_page(data);

    return page_resp;
  };
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const GetProjectDomainDetails = async () => {
    console.log(Page, "jnddjndjndjjndjndjndcjndjcjndc");
    const resp = await _get_user_design_details(Page.project);
    if (resp.code == 200) {
      setProjectDetail(resp.Project);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setInputs({});
    open && GetProjectDomainDetails();
  }, [open]);

  return (
    <>
      <LimitationModal
        open={limitModal}
        setOpen={setLimitModal}
        limitationModalTitle={"page"}
      />
      <div>
        <Modal
          disableTypography="true"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleCheckAddPage}>
            <Box sx={style} className="modal-theme">
              <div clo-12 className="text-end modalIcon">
                <CloseIcon onClick={handleClose} className="pointer" />
              </div>
              <Typography
                className="text-center mb-4"
                id="spring-modal-title"
                variant="h6"
                component="h2"
              >
                {duplicate ? "Duplicate Page " : "Add New Page"}
              </Typography>

              <div className="col-12 mt-3">
                <TextField
                  className="text-muted"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  inputProps={{ pattern: "[0-9a-zA-Z-_ ]{1,100}" }}
                  helperText="Special characters not allowed"
                  label="Page Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="PageName"
                  value={inputs.PageName}
                  onChange={handleChange}
                  required={true}
                />
              </div>

              {/* <div className="col-12 mt-3">
              <TextField
                inputProps={{ pattern: "[a-zA-Z-_]{1,100}" }}
                onInvalid={(e) => {
                  e.target.setCustomValidity("Use alhabets only");
                }}
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Page Slug"
                variant="outlined"
                fullWidth
                size="small"
                name="PageSlug"
                value={inputs.PageSlug}
                onChange={handleChange}
                required={true}
              />
            </div> */}
              <div className="col-12 mt-3">
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Add Page
                </LoadingButton>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    </>
  );
}
