import { Divider, IconButton, Menu, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import CloseIcon from "@mui/icons-material/Close";
import { IconSelect } from "../Right-Menu/components";
import { fontAwesomeIcons } from "../../../../ui_libs/Constants/fontAwesomeIcons";
import {
  colors,
  font_sizes_classes,
} from "../../../../ui_libs/Constants/constant";
import ButtonProperty from "../Right-Menu/components/Buton-Property/ButtonProperty";

const IconEditor = () => {
  const previewIconRef = useRef();
  const {
    iconAnchorEl,
    setIconAnchorEl,
    getSelectedElement,
    iconEditorPosition,
    updateSelectedElementClass,
    setRightMenuOpen,
    rootValues,
  } = useBuilder();
  const [textColor, setTextColor] = useState(rootValues.primary_text_color);

  const [classes, setClasses] = useState({
    fontSize: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const getIconClass = () => {
    let element = getSelectedElement();
    let className = element?.getAttribute("class");
    return className;
  };

  const handleClose = () => {
    setIconAnchorEl(null);
    setSelectedTab(0);
  };
  const getproperty = (property) => {
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };
  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };
  const handleChangeTextColor = (value) => {
    let element = getSelectedElement();
    // element.style.setProperty("color", value, "important");
    if (value == rootValues.secondary_color) {
      element.style.setProperty(
        "color",
        `var(--wb-website-theme-secondary-color)`
      );
    } else {
      element.style.setProperty("color", value);
    }
    setTextColor(value);
  };
  const ResetTextColor = () => {
    let element = getSelectedElement();
    element.style.removeProperty("color");

    setTextColor("#000000");
  };
  const handleChangeIcon = (ref, className) => {
    ref.current.setAttribute("class", className);
    //
  };
  const SelectedTab1 = () => {
    return (
      <Stack
        spacing={3}
        sx={{ minHeight: 200, overflowY: "scroll", paddingX: 2 }}
      >
        <div className="col-12">
          <label className="form-label custom-label m-0">Icon Preview:</label>
        </div>
        <div className="col-12 my-2">
          <i ref={previewIconRef} className={getIconClass()}></i>
        </div>
        <div className="col-12 mb-3 mt-0">
          <label className="form-label custom-label">Choose Icon:</label>
          <IconSelect
            label="Icon"
            previewRef={previewIconRef}
            iconClass={getIconClass()}
            data={fontAwesomeIcons}
            handleChange={handleChangeIcon}
          />
        </div>
      </Stack>
    );
  };
  const SelectedTab2 = () => {
    return (
      <Stack
        spacing={3}
        sx={{ minHeight: 200, overflowY: "scroll", paddingX: 2 }}
      >
        <div className="col-12">
          <div className="mb-3">
            <label
              for="edit-able-text-input"
              className="form-label custom-label"
            >
              Icon Size
            </label>

            <select
              className="w-100 form-control custom-icon-select"
              value={classes.fontSize}
              onChange={(e) =>
                handleChangeInClass(
                  "fontSize",
                  e.target.value,
                  font_sizes_classes
                )
              }
            >
              <option value="">Reset</option>
              {font_sizes_classes.map((fs, i) => (
                <option key={i} value={fs.value}>
                  {fs.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12 mb-3">
          <ButtonProperty
            GetProperty={getproperty}
            label={"Icon Color:"}
            ResetProperty={ResetTextColor}
            property={"color"}
            handleChangeProperty={(property, value) =>
              handleChangeTextColor(value)
            }
            colors={colors}
          />
        </div>
      </Stack>
    );
  };

  useEffect(() => {}, [Boolean(iconAnchorEl)]);

  return (
    <>
      <Menu
        anchorEl={iconAnchorEl}
        id="account-menu"
        open={Boolean(iconAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: iconEditorPosition, vertical: "bottom" }}
        anchorOrigin={{
          horizontal: iconEditorPosition === "left" ? "right": "left",
          vertical: "top",
        }}
      >
        <Box sx={{ width: 392 }}>
          <Stack spacing={0.5} direction={"column"}>
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Icon settings</h5>
              </Stack>

              <Tooltip title="Close" arrow placement="top">
                <IconButton
                  onClick={handleClose}
                  size="small"
                  className="mx-2 m-0 p-0"
                >
                  <CloseIcon className="p-1" />
                </IconButton>
              </Tooltip>
            </Stack>
            <div className="d-flex mx-2">
              <div
                className={
                  selectedTab === 0
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(0)}
              >
                <span>Icon</span>
              </div>
              <div
                className={
                  selectedTab === 1
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(1)}
              >
                <span>Style</span>
              </div>
            </div>
            <Divider className="mt-0 mx-3 mb-2" />

            {selectedTab == 0 && SelectedTab1()}
            {selectedTab == 1 && SelectedTab2()}
          </Stack>
        </Box>
      </Menu>
    </>
  );
};

export default IconEditor;
