import { invokeApi } from "../../bl_libs/invokeApi";

export const _support_tickets_listing = async (page, limit) => {
  let requestObj = {
    path: `/api/support_ticket/active_support_ticket`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_active_department = async () => {
  let requestObj = {
    path: `/api/department/list_active_department`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_new_support_ticket = async (data) => {
  let requestObj = {
    path: `/api/support_ticket/add_support_ticket`,
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _close_support_ticket = async (id) => {
  let requestObj = {
    path: `/api/support_ticket/close_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket = async (id) => {
  let requestObj = {
    path: `/api/support_ticket/delete_support_ticket/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _support_ticket_details = async (id) => {
  let requestObj = {
    path: `/api/support_ticket/detail_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_support_ticket_comment = async (data) => {
  let requestObj = {
    path: `/api/support_ticket_comment/add_support_ticket_comment`,
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_comment = async (id) => {
  let requestObj = {
    path: `/api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
