import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Link,
  Link as RouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Loader from "../../../components/Loader/Loader";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/support-tickets";
//
import USERLIST from "../../../_mocks_/user";
import { useSnackbar } from "notistack";
import CustomPopover from "../../../components/CustomPopover";
import CustomConfirmation from "../../../components/CustomConfirmation";
import { _delete_project_subscriber_by_email } from "../../../DAL/projects/projects";
import AddSupportTicket from "./AddSupportTicket";
import {
  _close_support_ticket,
  _delete_support_ticket,
  _support_tickets_listing,
} from "../../../DAL/support_tickets/support_tickets";
import { get_short_string } from "../../../utils/utils";
import moment from "moment";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "subject", label: "Subject", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "department", label: "Department", alignRight: false },
  { id: "comment_badge_count", label: "Messages", alignRight: false },
  { id: "ticket_status", label: "Ticket Status", alignRight: false },
  { id: "last_action_date", label: "Last Activity", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.subject.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const tabs = {
  all: "support_ticket",
  open: "open_tickets_list",
  close: "close_tickets_list",
  trash: "trash_tickets_list",
};

export default function CustomerListing({ fetchProjectSubscribers }) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [ticketsData, setTicketsData] = useState("");
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs.all);

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };

  const handleRefresh = () => {
    get_listing();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteSubscriberByEmail = async (email) => {
    const project_id = params?.project_id;

    const postData = {
      project_id,
      email,
    };

    const resp = await _delete_project_subscriber_by_email(postData);
    if (resp.code === 200) {
      setOpenDelete(false);
      fetchProjectSubscribers();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleDeleteSubscriber = () => {
    deleteSubscriberByEmail(selectedRow.email);
  };

  const handleDeleteConfirmation = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleCompleteClick = (row) => {
    setSelectedRow(row);
    setOpenComplete(true);
  };

  const handleRowClick = (row) => {
    navigate(`/support-tickets/${row._id}`);
  };

  const MENU_OPTIONS = (row) => {
    if (row.ticket_status === 0) {
      return [
        {
          label: "View Details",
          icon: "carbon:view",
          handleClick: handleRowClick,
        },
        {
          label: "Close Ticket",
          icon: "carbon:task-complete",
          handleClick: handleCompleteClick,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleDeleteClick,
        },
      ];
    }

    return [
      {
        label: "View Details",
        icon: "carbon:view",
        handleClick: handleRowClick,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDeleteClick,
      },
    ];
  };

  const handleDelete = async () => {
    const delete_resp = await _delete_support_ticket(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      get_listing(page, rowsPerPage);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleComplete = async () => {
    const delete_resp = await _close_support_ticket(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenComplete(false);
      get_listing(page, rowsPerPage);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const get_listing = async (is_all = false) => {
    const resp = await _support_tickets_listing();
    if (resp.code == 200) {
      // setTotalPages(customer_list_resp.pages);
      setData(resp.support_ticket);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_listing(true);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h4" gutterBottom>
          Support Tickets
        </Typography>
        <Button
          onClick={handleOpenAdd}
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
        >
          Create Support Ticket
        </Button>
      </Stack>

      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-start"
        mb={3}
      >
        <Label variant="ghost" color="info">
          OPEN
        </Label>

        <Label variant="ghost" color="success">
          SOLVED
        </Label>

        <Label variant="ghost" color="warning">
          IN PROCESS
        </Label>
      </Stack> */}

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          data={data}
        />

        <>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      _id,
                      subject,
                      description,
                      ticket_status,
                      response_status,
                      last_action_date,
                      comment_badge_count,
                      status,
                      department_id,
                      createdAt,
                      user_id,
                    } = row;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell
                          className="pointer"
                          onClick={() => handleRowClick(row)}
                          component="th"
                          scope="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="text-capitalize"
                              variant="subtitle2"
                              noWrap
                            >
                              {get_short_string(subject, 30)}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {get_short_string(description)}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {get_short_string(department_id?.title)}
                        </TableCell>

                        <TableCell align="center" className="pl-0">
                          <Label variant="filled" color="warning">
                            {comment_badge_count}
                          </Label>
                        </TableCell>

                        <TableCell className="pl-0" align="center">
                          {ticket_status === 0 && (
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color="info"
                            >
                              OPEN
                            </Label>
                          )}

                          {ticket_status === 1 && (
                            <Label
                              style={{ width: 70 }}
                              variant="ghost"
                              color="success"
                            >
                              CLOSED
                            </Label>
                          )}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          {moment(last_action_date).fromNow()}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {moment(createdAt).format("LLL")}
                        </TableCell>

                        <TableCell align="right">
                          <CustomPopover data={row} menu={MENU_OPTIONS(row)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>

        {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </Card>

      <CustomConfirmation
        open={openComplete}
        setOpen={setOpenComplete}
        title={"Are you sure you want to close this support ticket?"}
        handleAgree={handleComplete}
      />

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
        title={`Are you sure you want to close this support ticket?`}
      />

      <AddSupportTicket
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
