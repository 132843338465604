import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TwitterPicker } from "react-color";
import Select from "react-select";
import { useBuilder } from "../../../../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../../../../DAL/s3upload/s3upload";
import {
  options,
  colors,
} from "../../../../../../../ui_libs/Constants/constant";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding:"0px 5px"
  },
  heading: {},
}));

export default function ThemeSelection() {
  const { rootValues, handleChangeRootValue } = useBuilder();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Accordion className="card"> */}
        {/* <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > */}
          {/* <div style={{lineHeight:"42px"}}>Theme Colors:</div> */}
        {/* </AccordionSummary> */}
        {/* <AccordionDetails> */}
          <div className="col-12">
            {/* Primary Color */}
            <div className="col-12 mt-2 custom-label">Base Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) => handleChangeRootValue("primary_color", e.hex)}
                color={rootValues.primary_color}
              />
            </div>
            {/* Secondary Color */}
            <div className="col-12 mt-3 custom-label">Theme Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("secondary_color", e.hex)
                }
                color={rootValues.secondary_color}
              />
            </div>
            {/* Heading Text Color */}
            <div className="col-12 mt-3 custom-label">Headings Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("theme_heading_color", e.hex)
                }
                color={rootValues.theme_heading_color}
              />
            </div>
            {/* Primary Text Color */}
            <div className="col-12 mt-3 custom-label">Main Text Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("primary_text_color", e.hex)
                }
                color={rootValues.primary_text_color}
              />
            </div>
            {/* Secondary Text Color */}
            <div className="col-12 mt-3 custom-label">Secondary Text Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("secondary_text_color", e.hex)
                }
                color={rootValues.secondary_text_color}
              />
            </div>
            {/* Hover Text Color */}
            <div className="col-12 mt-3 custom-label">Hover Text Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("hover_text_color", e.hex)
                }
                color={rootValues.hover_text_color}
              />
            </div>{" "}
            {/* Hover Background Color */}
            <div className="col-12 mt-3 custom-label">Hover Background Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("hover_background_color", e.hex)
                }
                color={rootValues.hover_background_color}
              />
            </div>
            {/* Box shadow */}
            <div className="col-12 mt-3 custom-label">Box Shadow:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("theme_box_shadow", e.hex)
                }
                color={rootValues.theme_box_shadow}
              />
            </div>
            {/* Input Placeholder Color */}
            <div className="col-12 mt-3 custom-label">Input Placeholder Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("input_placeholder_color", e.hex)
                }
                color={rootValues.input_placeholder_color}
              />
            </div>
            {/* Input Text Color */}
            <div className="col-12 mt-3 custom-label">Input Text Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("input_text_color", e.hex)
                }
                color={rootValues.input_text_color}
              />
            </div>
            {/* Input Background Color */}
            <div className="col-12 mt-3 custom-label">Input Background Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("input_background_color", e.hex)
                }
                color={rootValues.input_background_color}
              />
            </div>
            {/* Input Border Color */}
            <div className="col-12 mt-3 custom-label">Input Border Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("input_border_color", e.hex)
                }
                color={rootValues.input_border_color}
              />
            </div>
            {/* Menu Color */}
            <div className="col-12 mt-3 custom-label">Menu Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) => handleChangeRootValue("menu_color", e.hex)}
                color={rootValues.menu_color}
              />
            </div>
            {/* Menu Hover Color */}
            <div className="col-12 mt-3 custom-label">Menu Hover Color:</div>
            <div className="col-12 mt-2">
              <TwitterPicker
                colors={colors}
                width="100%"
                onChange={(e) =>
                  handleChangeRootValue("menu_hover_color", e.hex)
                }
                color={rootValues.menu_hover_color}
              />
            </div>
          </div>
        {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </div>
  );
}
