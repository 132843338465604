function generate_random_string(characters, length) {
  var randomString = "";
  for (var i = 0; i < length; i++) {
    var index = Math.floor(Math.random() * characters.length);
    var character = characters[index];
    randomString = randomString + character;
  }
  return randomString;
}

function _generate_random_string(length = 10) {
  const string_secret_characters = "abcdefghijklmnopqrstuvwxyz";
  return generate_random_string(string_secret_characters, length);
}

module.exports = { _generate_random_string };
