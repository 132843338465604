import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
const ImageSizeBar = ({
  imageSize,
  handleChangeInClass,
  classesList,
  getEditAbleInputs,
  handleChangeInEditableInputs,
  //   customImageSize,
}) => {
  const styleClasses = useStyles();
  const { getSelectedElement, setEditAbleInputs, editAbleInputs } =
    useBuilder();
  const [oldSize, setOldSize] = useState({ height: "", width: "" });
  const getSizeValues = () => {
    let valuesObj = {};
    let element = getSelectedElement();
    let computedStyles = window.getComputedStyle(element);
    let height = computedStyles.getPropertyValue("height");
    let width = computedStyles.getPropertyValue("width");
    valuesObj = { height: height, width: width };
    return valuesObj;
  };
  const handleResetEditableInputs = (key, value, classesList) => {
    const width = oldSize.width.replace("px", "");
    const height = oldSize.height.replace("px", "");
    setEditAbleInputs({
      ...editAbleInputs,
      ["height"]: height,
      ["width"]: width,
    });
  };

  const handleChangeImageSize = (key, value) => {
    const selectedElement = getSelectedElement();
    selectedElement.style[key] = value + "px";
    handleChangeInEditableInputs(key, value);
  };

  const handleReset = (key, value, classesList) => {
    // let element = getSelectedElement();
    let element = getSelectedElement();

    element.style.removeProperty("height");
    element.style.removeProperty("width");

    handleChangeInClass(key, value, classesList);
  };
  const handleChangeClass = (key, value, classesList, cls) => {
    let isCustomSize = false;
    let element = getSelectedElement();

    if (cls.value === "img-custom-size") {
      isCustomSize = true;
    }
    if (isCustomSize) {
      let computedStyles = window.getComputedStyle(element);
      let height = computedStyles.getPropertyValue("height");
      height = height.replace("px", "");
      height.trim();
      let width = computedStyles.getPropertyValue("width");
      width = width.replace("px", "");
      width.trim();
      handleChangeInEditableInputs("height", height);
      handleChangeInEditableInputs("width", width);
      element.style.height = `${height}px`;
      element.style.width = `${width}px`;
      //   element.style.setProperty("width", `${width}px`, "important");
      //   element.style.setProperty("height", `${width}px`, "important");
      handleChangeInClass(key, value, classesList);
    } else {
      element.style.removeProperty("height");
      element.style.removeProperty("width");
      handleChangeInClass(key, value, classesList);
    }
  };
  const setSize = (size) => {
    const { height, width } = size;
    setOldSize(size);
  };
  useEffect(() => {
    let sizeValues = getSizeValues();
    if (sizeValues) {
      setSize(sizeValues);
      let h = getEditAbleInputs("height");
      let w = getEditAbleInputs("width");
    }
  }, []);
  return (
    <>
      <div>
        <div className="font-size-xsm mt-2 mb-3">
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={imageSize ? "wb-icons-box" : "wb-icons-box-active"}
              onClick={() => handleReset("imageSize", null, classesList)}
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls, i) => (
            <Tooltip key={i} title={cls.label} placement="top" arrow>
              <span
                className={
                  imageSize === cls.value
                    ? "wb-icons-box-active"
                    : "wb-icons-box"
                }
                onClick={() =>
                  handleChangeClass("imageSize", cls.value, classesList, cls)
                }
              >
                <Icon icon={cls.icon_name} />
              </span>
            </Tooltip>
          ))}
        </div>
        {imageSize === "img-custom-size" && (
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="edit-able-width-input" className="form-label">
                    Width
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="edit-able-width-input"
                    size="small"
                    label="Width"
                    type="number"
                    value={getEditAbleInputs("width")}
                    onChange={(e) =>
                      handleChangeImageSize("width", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="edit-able-height-input"
                    className="form-label"
                  >
                    Height
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="edit-able-height-input"
                    size="small"
                    label="Height"
                    type="number"
                    value={getEditAbleInputs("height")}
                    onChange={(e) =>
                      handleChangeImageSize("height", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col d-flex justify-content-end">
                <Tooltip
                  onClick={handleResetEditableInputs}
                  placement="top"
                  title="Reset Height & width"
                >
                  <RotateLeftIcon className={styleClasses.btn} />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageSizeBar;
