import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import { padding_classes } from "../../../../../../ui_libs/Constants/constant";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CustomBorder from "../../../Custom-Border/CustomBorder";
import { Tooltip } from "@mui/material";
function BorderWidth({
  ResetBorder,
  border_classes,
  updateSelectedElementBorderWidth,
  updateSelectedElementBorderWidthClass,
  getSelectedElementBorderWidthClass,
  getSelectedElementBorderWidth,
  handleResetBorder,
}) {
  return (
    <>
      <div className="col-12 mb-2">
        <div className="row">
          <div className="col">
            <label className="form-label custom-label">Border Width</label>
          </div>
          <div className="col d-flex justify-content-end">
            <Tooltip title="Reset Border" arrow placement="top">
              <span>
                <RotateLeftIcon
                  className="pointer"
                  onClick={() => handleResetBorder()}
                />
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="row">
          {/* border overall */}
          <CustomBorder
            ResetValue={ResetBorder}
            myclass={border_classes}
            updateSelectedElementSpacing={updateSelectedElementBorderWidth}
            updateSelectedElementClass={updateSelectedElementBorderWidthClass}
            getSelectedElementClassByType={getSelectedElementBorderWidthClass}
            getSelectedElementStyleByProperty={getSelectedElementBorderWidth}
            spacing_type="border-width"
            dimention="border-width"
            label="border-width"
          />
        </div>
      </div>
    </>
  );
}

export default BorderWidth;
