import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TwitterPicker } from 'react-color'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { colors } from '../../../../../../ui_libs/Constants/constant'
const useStyles = makeStyles({
  previewIcon: {
    height: '180px',
    backgroundColor: '#eeebebf',
  },
  iTag: {
    alignSelf: 'center',
  },
  btn: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
function BackgroundColor({
  styles,
  handleChangeBackground,
  handleResetBackground,
}) {
  const styleClasses = useStyles()

  return (
    <div className="row mt-2">
      <div className="col-12"></div>
      <div className="col">
        <span className="custom-label">Background Color</span>
      </div>
      <div className="col d-flex justify-content-end">
        <Tooltip title="Reset Background Color" arrow placement="top">
          <span>
            <RotateLeftIcon
              className={styleClasses.btn}
              onClick={handleResetBackground}
            />
          </span>
        </Tooltip>
      </div>
      <div className="col-12 mt-2">
        <TwitterPicker
          colors={colors}
          width="100%"
          onChange={handleChangeBackground}
          color={styles.backgroundColor}
        />
      </div>
      {/* <div className="col-12 text-end mt-3">
        <span className="custom-link" onClick={handleResetBackground}>
          Reset Background Color
        </span>
      </div> */}
    </div>
  )
}

export default BackgroundColor
