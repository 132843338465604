import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
const ButtonVariant = ({ handleChangeInClass, classesList }) => {
  const styleClasses = useStyles();

  const { getSelectedElement, getSelectedElementStyleByProperty } =
    useBuilder();

  return (
    <>
      <div class="mb-2">
        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {classesList.map((cls, i) => (
            <Tooltip key={i} title={cls.label} placement="top" arrow>
              <span
                onClick={() => {
                  handleChangeInClass("buttonvariant", cls.value, classesList);
                }}
              >
                <button className={cls.styleclass} >Button</button>
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
};

export default ButtonVariant;
