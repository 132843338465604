import React, { useEffect, useState } from "react";

import {
  Box,
  Menu,
  Typography,
  Link,
  ListItemButton,
  List,
  ListItemText,
  Button,
  Divider,
  Tooltip,
  Stack,
} from "@mui/material";
import { FlashOnTwoTone } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const MenuPopup = () => {
  const { rightMenuAnchorEl, setRightMenuAnchorEl, rightMenuList } =
    useBuilder();
  const handleClose = () => {
    setRightMenuAnchorEl(null);
  };
  const transformOrigin = {
    vertical: "top",
    horizontal: "left",
  };

  const anchorOrigin = {
    vertical: "top",
    horizontal: "left",
  };

  return (
    <>
      {/* <Menu
        id="menu-popup"
        anchorEl={rightMenuAnchorEl?.currentTarget}
        keepMounted
        open={Boolean(rightMenuAnchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            padding: "0px",
            margin: "0px",
            borderRadius: "8px",
            zIndex: (theme) => theme.zIndex.drawer + 10,
            position: "absolute",
            top: "0px",
            left: "0px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      > */}

      <div
        className="right-menu-wrapper"
        style={{
          visibility: rightMenuAnchorEl ? "visible" : "hidden",
        }}
      >
        <Box>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            onClick={handleClose}
            sx={{ padding: "0 0" }}
          >
            {rightMenuList?.map((list, index) => (
              <>
                <div key={index}>
                  {list?.divider == true && <Divider className="my-2" />}

                  <ListItemButton
                    // disabled={list.disable}
                    onClick={list.handleClick}
                    sx={{
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginInline: "8px",
                      padding: "2px 30px 2px 8px",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor:
                          list.backgroundColor && list.backgroundColor,
                      },
                    }}
                  >
                    <Icon
                      icon={list.icon}
                      className="me-3"
                      style={{
                        color: list.color ? list.color : "#023373",
                      }}
                    />
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "14px",
                          color: list.color ? list.color : "#023373",
                        },
                      }}
                      primary={list.label}
                    />
                  </ListItemButton>
                </div>
              </>
            ))}
          </List>
        </Box>
      </div>

      {/* </Menu> */}
    </>
  );
};

export default MenuPopup;
