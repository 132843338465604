import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Button,
  Tooltip,
  Typography,
  TextField,
  ButtonGroup,
  FormControlLabel,
  Switch,
  OutlinedInput,
  FormControl,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import CodeIcon from "@mui/icons-material/Code";
import React, { useEffect, useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadIcon from "@mui/icons-material/Upload";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { Image } from "@mui/icons-material";
import {
  button_alignment_classes,
  image_size_classes,
  object_fit_classes,
} from "../../../../ui_libs/Constants/constant";
import CloseIcon from "@mui/icons-material/Close";
import {
  ImageAlignBar,
  ImageSizeBar,
  ObjectFitBar,
} from "../Right-Menu/components";
import { property } from "lodash";
import { CloseAllEditor } from "../../../../utils/inlineeTinyEditor";
import GalleryDialog from "../Gallery/GalleryDialog";
import { delete_picture } from "../../../../DAL/User-Gallery/user_gallery";
import CustomConfirmation from "../../../../components/CustomConfirmation";
import { Icon } from "@iconify/react";
import { _is_dev } from "../../../../DAL/localStorage/localStorage";

const iconStyle = {
  fontSize: 30,
  cursor: "pointer",
};
const ImageEditor = () => {
  const {
    getEditAbleInputs,
    handleChangeInEditableInputs,
    handleRemoveElement,
    selectedElement,
    imageAnchorEl,
    setImageAnchorEl,
    showItemInLeftDrawer,
    setRightMenuOpen,
    editAbleInputs,
    setEditAbleInputs,
    SaveActionToStack,
    getSelectedElementClassByType,
    updateSelectedElementClass,
    userImagesList,
    setUserImagesList,
    handleOpenElementSourceCode,
    handleOpenCustomClassMenu,
    ReRenderHtml,
  } = useBuilder();

  const [selectedTab, setSelectedTab] = useState(0);
  const [classes, setClasses] = useState([]);
  const [newImageLink, setNewImageLink] = useState("");
  const [enableOverLyProperty, setEnableOverLyProperty] = useState(false);
  const [getWrongImageUrl, setGetWrongImageUrl] = useState(false);
  const [galleryDialog, setGalleryDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [addURL, setAddURL] = useState(false);
  const [addLink, setAddLink] = useState({ status: false, value: "" });
  const [targetImage, setTargetImage] = useState(false);
  const [openDeleteImageDiloge, setOpenDeleteImageDiloge] = useState(false);

  const [objectFitList, setObjectFitList] = useState([
    {
      label: "None",
      value: "",
    },
    {
      label: "Fit",
      value: "fit",
    },
    {
      label: "Cover",
      value: " cover",
    },
    {
      label: "Contain",
      value: "contain",
    },
  ]);
  let initialPoints = {
    x: 0,
    y: 0,
  };
  var menuElement = null;
  const handleRemoveIMG = () => {
    handleClose();
    handleRemoveElement();
  };
  const handleClose = () => {
    setImageAnchorEl(null);
    setAddURL(false);
  };
  const handleOpenGallery = () => {
    // showItemInLeftDrawer("Gallery");
    setGalleryDialog(true);
    handleClose();
  };
  const handleOpenRightMenu = () => {
    handleClose();
    setRightMenuOpen(true);
  };
  const handleChangeImageAltText = (e) => {
    selectedElement.alt = e.target.value;
    editAbleInputs.alt = e.target.value;
    setEditAbleInputs({ ...editAbleInputs });
  };
  const handleChangeImageSrc = (e) => {
    setNewImageLink(e.target.value);
    if (e.target.value.length == 0) {
      setGetWrongImageUrl(false);
      return;
    }
    const image = document.createElement("img");
    image.src = e.target.value;
    image.onload = () => {
      SaveActionToStack();
      editAbleInputs.src = e.target.value;
      setEditAbleInputs({ ...editAbleInputs });
      selectedElement.src = e.target.value;
      setGetWrongImageUrl(false);
    };
    image.onerror = () => {
      setGetWrongImageUrl(true);
    };
  };
  const handleChangeInClass = (key, value, class_list) => {
    setClasses({ ...classes, [key]: value });
    const payload = {
      class_list,
      value,
    };
    updateSelectedElementClass(payload);
  };
  const handleChangeStyle = (property, value) => {
    setClasses({ ...classes, [property]: value });
    selectedElement.style[property] = value;
  };

  const getproperty = (property) => {
    let computedStyles = window.getComputedStyle(selectedElement);
    let value = computedStyles.getPropertyValue(property);
    return value;
  };
  const getImageData = () => {
    if (!selectedElement) return;
    const imageAlign = getSelectedElementClassByType({
      class_list: button_alignment_classes,
    });

    const objectFit = getSelectedElementClassByType({
      class_list: object_fit_classes,
    });
    const imageSize = getSelectedElementClassByType({
      class_list: image_size_classes,
    });

    setClasses({ ...classes, imageAlign, objectFit, imageSize });
  };
  const handleEnableOverly = () => {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // background-color: blue;
    // opacity: 0.2;

    if (!enableOverLyProperty) {
    }
    setEnableOverLyProperty(!enableOverLyProperty);
  };

  const DeleteImageConfirmation = (image) => {
    setTargetImage(image);
    setOpenDeleteImageDiloge(true);
  };
  const handleDeleteImage = async () => {
    const resp = await delete_picture({ image_object: targetImage });
    if (resp.code == 200) {
      let list = userImagesList.filter((img) => {
        if (img != targetImage) return img;
      });
      setOpenDeleteImageDiloge(false);
      setUserImagesList([...list]);
    }
  };
  const handleEnableDisableLink = (e) => {
    setAddLink({ ...addLink, status: e.target.checked, value: "" });
  };
  const handleCheckLinkAndCloseEditor = () => {
    if (addLink.status) {
      if (selectedElement.parentNode.tagName == "A") {
        selectedElement.parentNode.href =
          addLink.value.trim().length > 0 ? addLink.value.trim() : "#";
      } else {
        let a = document.createElement("a");
        a.href = addLink.value.trim().length > 0 ? addLink.value.trim() : "#";
        a.prepend(selectedElement.cloneNode(true));
        selectedElement.parentNode.insertBefore(a, selectedElement);
        selectedElement.remove();
        ReRenderHtml();
      }
    } else {
      if (selectedElement.parentNode.tagName == "A") {
        selectedElement.parentNode.parentNode.insertBefore(
          selectedElement.cloneNode(true),
          selectedElement.parentNode
        );
        selectedElement.parentNode.remove();
      }
    }
    handleClose();
  };

  const SelectedTab1 = () => {
    return (
      <Stack spacing={3} sx={{ height: 400, overflowY: "scroll", paddingX: 2 }}>
        {/* image */}
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ backgroundColor: "#ebedf0", padding: 1, borderRadius: 1 }}
        >
          <img
            src={editAbleInputs?.src}
            style={{ width: "100%", background: "#fff" }}
          />

          <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
            <Grid>
              <Tooltip title="Choose From Gallery" arrow placement="top">
                <IconButton onClick={handleOpenGallery}>
                  <AddPhotoAlternateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Upload Image" arrow placement="bottom">
                <IconButton onClick={handleOpenRightMenu}>
                  <UploadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Image Url" arrow placement="top">
                <IconButton onClick={() => setAddURL(!addURL)}>
                  <LinkRoundedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Stack>
              <Tooltip title="Remove Image" arrow placement="bottom">
                <IconButton onClick={handleRemoveIMG}>
                  <DeleteOutlineIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>

        {/* text inputs */}
        <Stack direction={"column"} spacing={2} sx={{ paddingBottom: 3 }}>
          {addURL && (
            <TextField
              autoFocus
              error={getWrongImageUrl}
              label="Image Link"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  style: { fontSize: 12, color: "grey" },
                  inputStyle: { fontSize: "12px" },
                },
                placeholder: "Add link of an Image",
              }}
              variant="outlined"
              value={newImageLink ? newImageLink : editAbleInputs.src}
              onChange={handleChangeImageSrc}
              FormHelperTextProps={{ color: "red" }}
              helperText={
                getWrongImageUrl
                  ? "Incorrect url. Please give valid image url to update your image"
                  : ""
              }
            />
          )}

          <TextField
            label="Image alt text"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                style: { fontSize: 12, color: "grey" },
                inputStyle: { fontSize: "12px" },
              },
              placeholder: "Add text to improve accessibility and SEO.",
            }}
            variant="outlined"
            value={editAbleInputs.alt}
            onChange={handleChangeImageAltText}
          />
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ alignItems: "center", color: "red" }}
            >
              <Typography variant="subtitle2" color={"GrayText"}>
                Add link to image
              </Typography>

              <Tooltip title="Link Image" arrow placement="top">
                <Switch
                  size="small"
                  checked={addLink.status}
                  onChange={handleEnableDisableLink}
                ></Switch>
              </Tooltip>
            </Stack>
            {addLink.status && (
              <TextField
                autoFocus
                label="Add Link To Image"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    style: { fontSize: 12, color: "grey" },
                    inputStyle: { fontSize: "12px" },
                  },
                  placeholder: "https://www.example.com",
                }}
                value={addLink.value}
                onChange={(e) =>
                  setAddLink({ ...addLink, value: e.target.value })
                }
                variant="outlined"
                FormHelperTextProps={{ color: "red" }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  };
  const SelectedTab2 = () => {
    return (
      <Stack spacing={1} sx={{ height: 400, overflowY: "scroll" }}>
        {/* image size */}
        <Stack direction={"column"} spacing={0.5} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Image Alignment</Typography>
          <Stack direction={"row"}>
            <ImageAlignBar
              imageAlign={classes.imageAlign}
              handleChangeInClass={handleChangeInClass}
              classesList={button_alignment_classes}
            />
          </Stack>
        </Stack>

        {/* image fit */}
        <Divider />
        <Stack direction={"column"} spacing={0.5} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Image Fit</Typography>
          <Stack direction={"row"}>
            <ObjectFitBar
              objectFit={classes.objectFit}
              handleChangeInClass={handleChangeInClass}
              classesList={object_fit_classes}
            />
          </Stack>
        </Stack>

        {/* image size bar  */}
        <Divider />
        <Stack direction={"column"} spacing={0.5} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Image Size</Typography>

          <ImageSizeBar
            getEditAbleInputs={getEditAbleInputs}
            handleChangeInEditableInputs={handleChangeInEditableInputs}
            imageSize={classes.imageSize}
            // customImageSize={classes.customImageSize}
            handleChangeInClass={handleChangeInClass}
            classesList={image_size_classes}
          />
        </Stack>
        {/* overlay */}
        {/* <Divider />
        <Stack sx={{ paddingX: 2 }}>
          <FormControlLabel
            onChange={handleEnableOverly}
            label="Overlay"
            control={<Switch checked={enableOverLyProperty} />}
          />
        </Stack> */}
      </Stack>
    );
  };
  const handleMouseDown = (e) => {
    let element = e.target;

    menuElement = document.getElementById("account-menu");
    if (menuElement)
      initialPoints = {
        ...initialPoints,
        menuX: parseInt(
          menuElement.children[2].style["left"].replace("px", "")
        ),
        menuY: parseInt(menuElement.children[2].style["top"].replace("px", "")),
        mouseX: e.clientX,
        mouseY: e.clientY,
      };
    element.addEventListener("mousemove", handleMouseMove);
  };
  const handleMouseUp = (e) => {
    let new_element = handleReplaceElement(e);
    new_element.addEventListener("mousedown", handleMouseDown);
    new_element.addEventListener("mouseup", handleMouseUp);
  };
  const handleMouseMove = (e) => {
    if (menuElement) {
      let top = initialPoints.menuY + (e.y - initialPoints.mouseY);
      let left = initialPoints.menuX + (e.x - initialPoints.mouseX);
      menuElement.children[2].style["top"] = `${top}px`;
      menuElement.children[2].style["left"] = `${left}px`;
    }
  };
  const handleReplaceElement = (e) => {
    let old_element = e.target;
    let new_element = old_element.cloneNode(true);
    old_element.parentNode.replaceChild(new_element, old_element);
    return new_element;
  };
  const setDefaultValues = () => {
    setTimeout(() => {
      setSelectedTab(0);
      setNewImageLink("");
      setGetWrongImageUrl(false);
      setEnableOverLyProperty(false);
      setAddLink({ ...addLink, status: false, value: "" });
    }, 100);
  };
  const handleCheckLink = () => {
    if (!selectedElement) return;
    //  https://www.google.com
    let status = false;
    let value = "";
    if (
      selectedElement &&
      selectedElement.parentNode &&
      selectedElement.parentNode.tagName == "A"
    ) {
      status = true;
      if (selectedElement.parentNode.href.includes("/builder")) {
        value = "";
      } else {
        value = selectedElement.parentNode.href;
      }
    }
    setAddLink({
      ...addLink,
      status: status,
      value: value,
    });
  };

  useEffect(() => {
    if (Boolean(imageAnchorEl) == false) {
      setDefaultValues();
    } else {
      getImageData();
      CloseAllEditor();
      handleCheckLink();
    }
  }, [Boolean(imageAnchorEl)]);

  return (
    <>
      <Menu
        anchorEl={imageAnchorEl}
        id="account-menu"
        open={Boolean(imageAnchorEl)}
        onClose={handleCheckLinkAndCloseEditor}
        // onClick={handleClose}
        keepMounted={false}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            // "&:before": {
            //   content: '""',
            //   display: "block",
            //   position: "absolute",
            //   top: 0,
            //   right: 14,
            //   width: 10,
            //   height: 10,
            //   bgcolor: "background.paper",
            //   transform: "translateY(-50%) rotate(45deg)",
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Box sx={{ width: 300 }}>
          <Stack spacing={0.5} direction={"column"}>
            {/* header */}
            {/* <Stack
              sx={{ backgroundColor: "red", height: 30, cursor: "pointer" }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              // onMouseLeave={handleMouseUp}
            ></Stack> */}
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Stack direction={"row"} sx={{ paddingX: 2 }}>
                <h5 className="mb-0">Image settings</h5>
                {/* <ButtonGroup
                  onClick={(e) => {
                    setSelectedTab(e.target.value);
                  }}
                  variant="text"
                >
                  <Button
                    variant={selectedTab == 0 ? "contained" : ""}
                    value={0}
                  >
                    Image
                  </Button>
                  <Button
                    variant={selectedTab == 1 ? "contained" : ""}
                    value={1}
                  >
                    Style
                  </Button>
                </ButtonGroup> */}
              </Stack>

              <Tooltip title="Close" arrow placement="top">
                <IconButton
                  onClick={handleClose}
                  size="small"
                  className="mx-2 m-0 p-0"
                >
                  <CloseIcon className="p-1" />
                </IconButton>
              </Tooltip>
            </Stack>
            <div className="d-flex mx-2">
              <div
                className={
                  selectedTab === 0
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(0)}
              >
                <span>Image</span>
              </div>
              <div
                className={
                  selectedTab === 1
                    ? "image-editor-button image-editor-button-active"
                    : "image-editor-button"
                }
                onClick={() => setSelectedTab(1)}
              >
                <span>Style</span>
              </div>
            </div>
            <Divider className="mt-0 mx-3 mb-2" />

            {/* selected Tab 1 body */}
            {selectedTab == 0 && SelectedTab1()}
            {selectedTab == 1 && SelectedTab2()}
          </Stack>
        </Box>
      </Menu>

      <GalleryDialog
        galleryDialog={galleryDialog}
        setGalleryDialog={setGalleryDialog}
        uploadDialog={uploadDialog}
        setUploadDialog={setUploadDialog}
        DeleteImageConfirmation={DeleteImageConfirmation}
        UpdateImageSrc={true}
      />
      <CustomConfirmation
        open={openDeleteImageDiloge}
        setOpen={setOpenDeleteImageDiloge}
        handleAgree={handleDeleteImage}
        IMG={targetImage}
        title="Are you sure you want to delete this image?"
      />
    </>
  );
};

export default ImageEditor;
