import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { _get_user_profile } from "../../../../DAL/login/login";
import Typography from "@mui/material/Typography";
import { Button, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import ExitBuilderConfirmation from "../../../../components/ExitBuilderConfirmation";
import CustomConfirmation from "../../../../components/CustomConfirmation";
import AddNewPage from "../../../../layouts/MainEditorLayout/components/AddNewPage/AddNewPage";
import { Icon } from "@iconify/react";
import DescriptionIcon from "@mui/icons-material/Description";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Menu from "@mui/material/Menu";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Settings, SettingsRemote } from "@mui/icons-material";
import { Close, Done } from "@mui/icons-material";
import { _get_user_design_details } from "../../../../DAL/projects/projects";
import { useSnackbar } from "notistack";
import LimitationModal from "../../../../components/LimitationModal/LimitationModal";

function PageMenu(props) {
  const { leaveHoverDrawer, selectedlistItem } = props;

  const {
    getSelectedSections,
    handleSaveTemplateToServer,
    CheckChangeExsistInHTML,
    selectedSections,
    handleOpenSectionSettings,
    handleRemoveSelection,
    handleUpSelection,
    handleDuplicateSection,
    updateSelectedSectionTitle,
    getSelectedSectionDataset,
    setSelectedSectionSettingId,
    getSelectedSectionFormByID,
    handleOpenFormSetting,
    handleDownSelection,
    rootValues,
    handleUpdateStateFromDOM,
    SelectedPage,
    setSelectedSectionsIndex,
    selectedSectionsIndex,
    customerSubscriptionDetail,
  } = useBuilder();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState(getSelectedSections());
  const [projectDetail, setProjectDetail] = useState({});

  const [OpenNewPageDailogue, setOpenNewPageDailogue] = useState(false);
  const [OpenPageDuplicateDailogue, setOpenPageDuplicateDailogue] =
    useState(false);

  const [FinalHTML, setFinalHTML] = useState("");
  const [FinalCSS, setFinalCSS] = useState("");
  const [FinalJS, setFinalJS] = useState("");
  const [PageValue, setPageValue] = useState("");
  const [ChangePageAlert, setChangePageAlert] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(false);
  const [iconIndex, setIconIndex] = useState("");
  const [TargetSlug, setTargetSlug] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [sectionToChange, setSectionToChange] = useState(null);
  const [optionMenuOpen, setOptionMenuOpen] = useState(false);
  const [delmsg, setDelmsg] = useState(false);

  const [title, setTitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputFocus, setInputFocus] = useState(false);
  const [limitModal, setLimitModal] = useState(false);

  const handleSelectedSectionDataset = () => {
    setIsDisabled(false);
    setTimeout(() => {
      setInputFocus(true);
    }, 0);
  };

  const handleUpdate = () => {
    updateSelectedSectionTitle(
      title === "" ? sectionToChange.section.section_title : title
    );
    setIsDisabled(true);
    setInputFocus(false);
  };

  const handleMenuOptions = (e, section, i) => {
    setSectionToChange({ ...sectionToChange, section });
    setIconIndex(i);
    setSelectedSectionsIndex(i);
    setOptionMenuOpen(true);
    setVisibleIcon(true);
    setAnchorEl(e.currentTarget);
    setTitle(section.section_title);
  };
  const handleClose = () => {
    setAnchorEl("");
    setOptionMenuOpen(false);
    setVisibleIcon(false);
  };
  const handleDragStart = (e) => {
    document.getElementById(
      selectedSections[e.source.index].section_id
    ).style.outline = "2px solid red";
  };
  const handleDragEnd = ({ source, destination, ...rest }) => {
    document.getElementById(
      selectedSections[source.index].section_id
    ).style.outline = "";
    if (source && destination && source.index != destination.index) {
      // let newList = [...list];

      const newList = selectedSections.map((sec) => {
        return sec;
      });
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      setList({ ...list, newList });
      handleUpdateStateFromDOM(newList);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const handledeletesection = () => {
    handleRemoveSelection(sectionToChange.section.section_id);
    setDelmsg(false);
  };
  const handleCheckAddPage = async (e) => {
    e.preventDefault();
    if (
      customerSubscriptionDetail.plan_id.pages_count >
      projectDetail.project_pages_count
    ) {
      handleDuplicatePage();
    } else {
      setLimitModal(true);
      // handleClose();
    }
  };

  const handleDuplicatePage = () => {
    let html = collectTargetHTML();
    let css = collectTargetCSS();
    let js = collectTargetJS();
    setFinalHTML(html);
    setFinalCSS(css);
    setFinalJS(js);
    rootValues.page_list.map((page) => {
      if (page.page_slug == SelectedPage) {
        setPageValue({ ...page });
      }
    });
    setOpenPageDuplicateDailogue(true);
  };

  const handleSavePageandChangePage = async () => {
    let result = await handleSaveTemplateToServer({ preview: false });
    if (result) {
      handleChangePage();
    }
  };
  const getFormData = (id) => {
    let { form, status } = getSelectedSectionFormByID(id);
    if (form && form.length > 0) {
      let form_seting_value = form[0].getAttribute("enable_form_setting");

      if (form_seting_value == false || form_seting_value == "false") {
        status = false;
      }
    }
    return { form, status };
  };
  const handleChangePage = (value = null) => {
    value = value ? value : TargetSlug;
    rootValues.page_list.map((page) => {
      if (page.page_slug == value) {
        navigate("/builder-redirect-controller", {
          state: {
            project_id: rootValues._id,
            page_id: page._id,
          },
        });
      }
    });
  };
  const handlechangepage = (value) => {
    if (value == "") {
      rootValues.page_list.map((page) => {
        if (page.page_slug == SelectedPage) {
          setPageValue({ ...page });
        }
      });
      setOpenNewPageDailogue(true);
    } else if (value == "button") {
      return;
    } else {
      rootValues.page_list.map((page) => {
        if (page.page_slug == value) {
          setTargetSlug(value);
          if (CheckChangeExsistInHTML()) {
            setChangePageAlert(true);
          } else {
            handleChangePage(value);
          }

          // navigate('/builder-redirect-controller', {
          //   state: {
          //     project_id: rootValues._id,
          //     page_id: page._id,
          //   },
          // })
        }
      });
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }

  const collectTargetHTML = () => {
    let collect_html = "";
    getSelectedSections().forEach((section) => {
      collect_html += section.section_html + "\n\n";
    });

    return collect_html ? collect_html : `/* No HTML */`;
  };
  const collectTargetCSS = () => {
    let collect_css = "";
    getSelectedSections().forEach((section) => {
      collect_css += section.section_css + "\n\n";
    });

    return collect_css ? collect_css : `/* No CSS */`;
  };

  const collectTargetJS = () => {
    let collect_js = "";
    getSelectedSections().forEach((section) => {
      collect_js += section.section_js;
    });
    ///asdasd
    return collect_js ? collect_js : `/* No JS */`;
  };

  const GetProjectDomainDetails = async () => {
    const resp = await _get_user_design_details(rootValues.project);
    if (resp.code == 200) {
      setProjectDetail(resp.Project);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (ChangePageAlert) {
      GetProjectDomainDetails();
    }
  }, [ChangePageAlert]);
  // useEffect(() => {
  //   handleSelectedSectionDataset();
  // }, []);

  return (
    <>
      {/* add page dailogue */}
      <AddNewPage
        open={OpenNewPageDailogue}
        setOpen={setOpenNewPageDailogue}
        Page={PageValue}
        CheckChanges={true}
      />

      {/* duplicate Page */}
      <AddNewPage
        duplicate={true}
        open={OpenPageDuplicateDailogue}
        Page={PageValue}
        setOpen={setOpenPageDuplicateDailogue}
        FinalHTML={FinalHTML}
        FinalCSS={FinalCSS}
        FinalJS={FinalJS}
        CheckChanges={true}
      />
      {/* page cahnge */}
      <ExitBuilderConfirmation
        open={ChangePageAlert}
        setOpen={setChangePageAlert}
        handleAgree={() => handleChangePage(TargetSlug)}
        handleSavePageAndExit={handleSavePageandChangePage}
        // title="You might have unsaved changes please save otherwise you might lose your page data."
        title="Leaving page without saving data"
        option1={"Continue"}
        option2={"Save and Continue"}
      />

      <LimitationModal
        open={limitModal}
        setOpen={setLimitModal}
        limitationModalTitle={"page"}
      />
      <CustomConfirmation
        open={delmsg}
        setOpen={setDelmsg}
        handleAgree={handledeletesection}
        title="Are you sure you want to remove this Section?"
      />

      {selectedlistItem === "Pages and Sections" && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              overflow: "hidden",
            }}
            className="transition"
          >
            <div
              className="border-bottom"
              style={{
                lineHeight: "41px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <div>{selectedlistItem}</div>
              <div className="icon" onClick={leaveHoverDrawer}>
                <i class="fa fa-xmark"></i>
              </div>
            </div>
            <div
              className="border-bottom"
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 8px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  sx={{
                    width: 150,
                    fontSize: 12,
                    color: "black",
                    "&:hover": {
                      "&& fieldset": {
                        color: "#fa2356",
                        backgroundColor: "initial",
                        backgroundColor: "#fa23560d",
                        border: "1px",
                      },
                    },
                    "&:not(:hover)": {
                      "&& fieldset": {
                        border: "1px",
                      },
                    },
                  }}
                  className="border"
                  value={rootValues.page_slug ? rootValues.page_slug : "index"}
                  onChange={(e) => {
                    handlechangepage(e.target.value);
                  }}
                  label="page"
                >
                  {!rootValues?.page_list && (
                    <MenuItem
                      sx={{
                        fontSize: 12,
                        width: 120,
                      }}
                      className="text-dark"
                      value="index"
                    >
                      Index
                    </MenuItem>
                  )}
                  {rootValues?.page_list &&
                    rootValues.page_list.map((page) => (
                      <MenuItem
                        noWrap
                        sx={{ fontSize: 12 }}
                        className="text-dark"
                        value={page.page_slug}
                      >
                        <Typography
                          sx={{ width: 100, m: "0" }}
                          variant="body2"
                          noWrap
                        >
                          {page.page_name}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0px 0px 0px 30px",
                }}
              >
                <Tooltip title="Add New Page" arrow placement="top">
                  <div
                    className=""
                    onClick={(e) => {
                      handlechangepage("");
                    }}
                  >
                    <div className="icon">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                  </div>
                </Tooltip>
                <div>
                  <Tooltip title="Duplicate Page" arrow placement="top">
                    <div className="" onClick={handleCheckAddPage}>
                      <div className="icon">
                        <i class="fa fa-clone" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <span
              className="custom-label"
              style={{
                lineHeight: "42px",
                fontWeight: "500",
                padding: "0px 8px",
              }}
            >
              Sections in Current Page:
            </span>
            <div className="pb-5 overflow-auto">
              {rootValues?.page_list &&
                rootValues.page_list.map((page) => (
                  <div>
                    {page.page_name === rootValues.page_name && (
                      <DragDropContext
                        onDragStart={handleDragStart}
                        onDragEnd={(props) => handleDragEnd(props)}
                      >
                        <Droppable droppableId="doprable1">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {selectedSections.map((section, i) => {
                                return (
                                  <Draggable
                                    key={i}
                                    draggableId={`dragable-${i}`}
                                    index={i}
                                  >
                                    {(provided, snapshot) => (
                                      <Tooltip
                                        title={section.section_title}
                                        arrow
                                        placement="right"
                                      >
                                        <div
                                          className={`${
                                            snapshot.isDragging
                                              ? "shadow"
                                              : null
                                          } wb-menu-icon-page-section`}
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            background: snapshot.isDragging
                                              ? "white"
                                              : null,
                                          }}
                                          onClick={() => {
                                            document
                                              .getElementById(
                                                section.section_id
                                              )
                                              .scrollIntoView();
                                          }}
                                        >
                                          {inputFocus && (
                                            <div
                                              className="border"
                                              style={{
                                                display:
                                                  !isDisabled && iconIndex === i
                                                    ? "block"
                                                    : "none",
                                                verticalAlign: "baseline",
                                                background: "white",
                                                height: "42px",
                                                width: "100%",
                                                borderRadius: "4px",
                                                transition: "0.3s ease-in-out",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  visibility:
                                                    !isDisabled &&
                                                    iconIndex === i
                                                      ? "visible"
                                                      : "hidden",
                                                  alignItems: "center",
                                                  height: "100%",
                                                  width: "100%",
                                                  transition:
                                                    "0.3s ease-in-out",
                                                }}
                                              >
                                                <input
                                                  className="form-control form-control-sm"
                                                  name="section_title"
                                                  id="section_title"
                                                  type="text"
                                                  autoFocus
                                                  // transition = "0.3s ease-in-out"
                                                  value={title}
                                                  onChange={(e) =>
                                                    setTitle(e.target.value)
                                                  }
                                                  disabled={
                                                    isDisabled ? true : false
                                                  }
                                                  style={{
                                                    border: "none",
                                                    padding: "0 8px",
                                                    margin: "0 5px",
                                                    fontSize: "12px",
                                                    transition:
                                                      "ease-in-out 0.3s",
                                                    // width: "163px",
                                                  }}
                                                />

                                                <div className="px-1 align-items-center">
                                                  <div
                                                    className="align-items-center justify-content-center m-0 d-flex"
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <div className="p-0 icon-container fit-content">
                                                      <span className="mx-1 ">
                                                        <Tooltip
                                                          title="Save"
                                                          placement="top"
                                                          arrow
                                                        >
                                                          <Done
                                                            htmlColor="green"
                                                            onClick={() =>
                                                              handleUpdate()
                                                            }
                                                          />
                                                        </Tooltip>
                                                      </span>
                                                    </div>
                                                    <div className="p-0 icon-container fit-content">
                                                      <span className="mx-1 ">
                                                        <Tooltip
                                                          title="Cancel"
                                                          placement="top"
                                                          arrow
                                                        >
                                                          <Close
                                                            htmlColor="red"
                                                            onClick={() => {
                                                              setIsDisabled(
                                                                true
                                                              ),
                                                                setInputFocus(
                                                                  false
                                                                );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div
                                            style={{
                                              display:
                                                inputFocus && iconIndex === i
                                                  ? "none"
                                                  : "flex",
                                              alignItems: "center",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              height: "100%",
                                              padding: "0 0.6rem 0 0.8rem",
                                            }}
                                          >
                                            <div
                                              className="cut-text"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {section.section_title}
                                            </div>
                                            <div>
                                              <Icon
                                                icon="mdi:dots-horizontal"
                                                width="20"
                                                height="20"
                                                className="section-option-icon"
                                                onClick={(e) => {
                                                  handleMenuOptions(
                                                    e,
                                                    section,
                                                    i
                                                  );
                                                  setSelectedSectionSettingId(
                                                    section.section_id
                                                  );
                                                  setIsDisabled(true);
                                                  setInputFocus(false);
                                                }}
                                                style={
                                                  visibleIcon &&
                                                  iconIndex == i && {
                                                    visibility: "visible",
                                                    backgroundColor: "#3485fe",
                                                    color: "#fff",
                                                  }
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Tooltip>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </>
      )}

      {/* Menu for sections list */}
      {sectionToChange &&
        !getFormData(sectionToChange.section.section_id).status && (
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={optionMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                ml: 1,
                borderRadius: "2px",

                "& .MuiMenuItem-root": {
                  // width: 18,
                  height: 30,
                  "&:hover": {
                    backgroundColor: "#d6e6fe",
                  },
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: -1.5,
                  width: 10,
                  height: 25,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <>
              <MenuItem
                onClick={() =>
                  handleUpSelection(sectionToChange.section.section_id)
                }
              >
                <ArrowUpwardIcon fontSize="small" />
                <span className="ms-2 font-style">Move Up</span>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleDownSelection(sectionToChange.section.section_id)
                }
              >
                <ArrowDownwardIcon fontSize="small" />
                <span className="ms-2 font-style">Move Down</span>
              </MenuItem>
              <MenuItem
                onClick={() => handleDuplicateSection(sectionToChange.section)}
              >
                <ContentCopyIcon fontSize="small" />
                <span className="ms-2 font-style">Duplicate</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectedSectionDataset();
                }}
              >
                <EditIcon fontSize="small" />
                <span className="ms-2 font-style">Rename</span>
              </MenuItem>
              <MenuItem onClick={() => setDelmsg(true)}>
                <HighlightOffIcon fontSize="small" />
                <span className="ms-2 font-style">Delete</span>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleOpenSectionSettings(sectionToChange.section.section_id)
                }
              >
                <Settings fontSize="small" />
                <span className="ms-2 font-style">Settings</span>
              </MenuItem>
            </>
          </Menu>
        )}
      {sectionToChange &&
        getFormData(sectionToChange.section.section_id).status && (
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={optionMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                ml: 1,
                borderRadius: "2px",

                "& .MuiMenuItem-root": {
                  // width: 18,
                  height: 30,
                  "&:hover": {
                    backgroundColor: "#d6e6fe",
                  },
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: -1.5,
                  width: 10,
                  height: 25,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <>
              <MenuItem
                onClick={() =>
                  handleUpSelection(sectionToChange.section.section_id)
                }
              >
                <ArrowUpwardIcon fontSize="small" />
                <span className="ms-2 font-style">Move Up</span>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleDownSelection(sectionToChange.section.section_id)
                }
              >
                <ArrowDownwardIcon fontSize="small" />
                <span className="ms-2 font-style">Move Down</span>
              </MenuItem>
              <MenuItem
                onClick={() => handleDuplicateSection(sectionToChange.section)}
              >
                <ContentCopyIcon fontSize="small" />
                <span className="ms-2 font-style">Duplicate</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectedSectionDataset();
                }}
              >
                <EditIcon fontSize="small" />
                <span className="ms-2 font-style">Rename</span>
              </MenuItem>
              <MenuItem onClick={() => setDelmsg(true)}>
                <HighlightOffIcon fontSize="small" />
                <span className="ms-2 font-style">Delete</span>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleOpenFormSetting(sectionToChange.section.section_id)
                }
              >
                <DescriptionIcon fontSize="small" />
                <span className="ms-2 font-style">Form Settings</span>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleOpenSectionSettings(sectionToChange.section.section_id)
                }
              >
                <Settings fontSize="small" />
                <span className="ms-2 font-style">Settings</span>
              </MenuItem>
            </>
          </Menu>
        )}
    </>
  );
}

export default PageMenu;
