import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";

import AccordionDetails from "@mui/material/AccordionDetails";
const GenerateGradient = ({
  handleAddGradient,
  getColumnproperty,
  removeColumnproperty,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [colors, setColors] = useState(getColumnproperty("background"));
  const [gradientType, setGradientType] = useState("linear");

  const [gradientDirection, setGradientDirection] = useState("");
  const handleChange = (e, index) => {
    setColors(e.target.value);
  };
  const AddGradient = () => {
    if (colors.length <= 0) {
      enqueueSnackbar("Please paste gradient string", { variant: "error" });
      return;
    }
    // handleAddGradient(
    //   "background",
    //   `${gradientType}-gradient( ${
    //     gradientDirection ? gradientDirection + "," : ""
    //   }${GetColorList()})`
    // );
    handleAddGradient("background", colors);
  };
  const RemoveGradient = () => {
    setColors("");

    removeColumnproperty("background");
  };
  const GetColorList = () => {
    let value_list = "";
    colors.map((color, index) => {
      value_list += color.value;
      if (index < colors.length - 1) {
        value_list += ",";
      }
    });
    return value_list;
  };
  return (
    <div>
      <Accordion className="card mb-2">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Generate Gradient
        </AccordionSummary>
        <AccordionDetails>
          <div className="row border mb-2 p-2 mx-1 rounded form-label-checkbox-color formsetting-label-parent-div">
            <div className=" col-12 d-flex align-items-center">
              <div className=" col-3 d-flex align-items-center">
                <div>
                  <label className="custom-label d-flex align-items-start">
                    Gradient
                  </label>
                </div>
              </div>
              <div className="col-9">
                <input
                placeholder="Paste gradient string"
                  className="form-control form-control-sm"
                  value={colors}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 mt-2 d-flex justify-content-end">
              <button className="btn btn-success " onClick={AddGradient}>
                Add
              </button>
              <button className="btn btn-danger mx-1 " onClick={RemoveGradient}>
                Remove
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GenerateGradient;
