import React from 'react'
import { Icon } from '@iconify/react'
import { Tooltip } from '@mui/material'

function LabelAlignBar({ textAlign, handleChangeInClass, classesList }) {
  return (
    <>
      <div class="mb-2">
        {/* <label for="edit-able-text-input" class="form-label">
          Text Align
        </label> */}

        {/* <select
          className="w-100 form-control custom-icon-select"
          value={textAlign}
          onChange={(e) =>
            handleChangeInClass("textAlign", e.target.value, classesList)
          }
        >
          {classesList.map((fs) => (
            <option value={fs.value}>{fs.label}</option>
          ))}
        </select> */}

        {/* icons toolbar */}
        <div className="font-size-xsm mt-2">
          <Tooltip title={'Reset'} placement="top" arrow>
            <span
              className={textAlign ? 'wb-icons-box' : 'wb-icons-box-active'}
              onClick={() =>
                handleChangeInClass('labelAlign', null, classesList)
              }
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls) => (
            <Tooltip title={cls.label} placement="top" arrow>
              <span
                className={
                  textAlign === cls.value
                    ? 'wb-icons-box-active'
                    : 'wb-icons-box'
                }
                onClick={() =>
                  handleChangeInClass('labelAlign', cls.value, classesList)
                }
              >
                <Icon icon={cls.icon_name} />
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  )
}

export default LabelAlignBar
