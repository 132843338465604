import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import VSEditor from "../../pages/Builder/components/VSEditor/VSEditor";
const CustomClassMenu = () => {
  const {
    openDeveloperOptionMenu,
    setOpenDeveloperOptionMenu,
    developerOptionMenuType,
    setDeveloperOptionMenuType,
    setSelectedElement,
    selectedElement,
    getSelectedSections,

    setSelectedSections,
    ReRenderHtml,
  } = useBuilder();

  const [isLoading, setIsLoading] = useState(false);
  const [targetElement, setTargetElement] = useState("");
  const [customClass, setCustomClass] = useState("");
  const [targetSectionIndex, setTargetSectionIndex] = useState("");
  const [allSectionList, setAllSectionList] = useState([]);
  const [addCustomClassName, setAddCustomClassName] = useState(false);
  const [customClassName, setCustomClassName] = useState("");
  const handleClose = () => {
    setOpenDeveloperOptionMenu(false);
    setSelectedElement(null);
  };

  const handleChange = (value) => {
    setCustomClass(value);
  };
  const getSectionCss = () => {
    if (selectedElement) {
      let sectionList = getSelectedSections();
      let element = selectedElement;
      let section_id = null;
      let found_section = false;
      while (element && element.parentNode) {
        if (element.tagName == "SECTION") {
          found_section = true;
          break;
        }
        element = element.parentNode;
      }
      if (!found_section) return;
      section_id = element.parentNode.parentNode.getAttribute("id");

      sectionList.map((section, index) => {
        if (section.section_id == section_id) {
          console.log("Found Section", "sectionsectionsection");

          setTargetSectionIndex(index);
        } else {
          console.log("No Section", "sectionsectionsection");
        }
      });
      setAllSectionList(sectionList);
    }
  };

  const getSubstring = (str, start, end) => {
    let char1 = str.indexOf(start) + 1;
    let char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  };
  const handleAddCustomClass = () => {
    setIsLoading(true);
    selectedElement.classList = targetElement.classList;
    let className = getSubstring(customClass, ".", "{");
    if (className) {
      selectedElement.classList.add(className);

      if (allSectionList) {
        allSectionList[targetSectionIndex].section_css =
          allSectionList[targetSectionIndex].section_css + customClass;

        setSelectedSections([...allSectionList]);
      }

      ReRenderHtml();
    }
    handleClose();
  };
  const handleAddCustomClassFromTextFeild = (e) => {
    e.preventDefault();
    selectedElement.classList.add(customClassName);
    targetElement.classList.add(customClassName);
    setCustomClassName("");
    // setAddCustomClassName(false);
  };
  const CloseCustomClassInput = (e) => {
    e.preventDefault();
    setCustomClassName("");
    setAddCustomClassName(false);
  };
  const GetListOfClasses = () => {
    if (!selectedElement) return;
    let list = [...selectedElement.classList];
    return list.map((cls) => (
      <Chip
        size="small"
        sx={{ cursor: "pointer" }}
        color="secondary"
        variant={
          targetElement?.classList?.contains(cls) ? "filled" : "outlined"
        }
        label={cls}
        onClick={() => {
          targetElement.classList.toggle(cls);
          setTargetElement(targetElement.cloneNode(true));
        }}
      />
    ));
  };
  useEffect(() => {
    getSectionCss();
    setCustomClass("");
    setTargetElement(selectedElement?.cloneNode(true));

    setIsLoading(false);
  }, [selectedElement, openDeveloperOptionMenu]);

  return (
    <Stack sx={{ padding: 2 }}>
      <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">Add Custom Class</Typography>

        <Stack direction={"row"} spacing={2}>
          <LoadingButton
            size="small"
            variant="contained"
            // disabled={!isChange}
            loading={isLoading}
            onClick={handleAddCustomClass}
            autoFocus
          >
            Update
          </LoadingButton>
          <Button size="small" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>

      <Stack sx={{ paddingBottom: 2 }}>
        <Typography variant="subtitle1">Active Classes</Typography>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ flexWrap: "wrap", alignItems: "center" }}
        >
          {GetListOfClasses()}
          <Tooltip title="Add class" arrow placement="top">
            <IconButton
              color="secondary"
              onClick={() => {
                setAddCustomClassName(true);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {addCustomClassName && (
          <form onSubmit={handleAddCustomClassFromTextFeild}>
            <Stack direction={"row"} spacing={1}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                label="Custom Class"
                value={customClassName}
                onChange={(e) => {
                  setCustomClassName(e.target.value);
                }}
              ></TextField>

              <Button
                onClick={CloseCustomClassInput}
                color="error"
                variant="contained"
                size="small"
              >
                Close
              </Button>
              <Button
                type="submit"
                onClick={handleAddCustomClassFromTextFeild}
                color="info"
                variant="contained"
                size="small"
              >
                Add
              </Button>
            </Stack>
          </form>
        )}
      </Stack>

      <VSEditor
        defaultValue={customClass}
        onChange={handleChange}
        defaultLanguage="css"
      />
    </Stack>
  );
};

export default CustomClassMenu;
