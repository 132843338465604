import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { margin_classes } from "../../../../../../ui_libs/Constants/constant";
import CustomSpacing from "../../../Custom-Spacing/CustomSpacing";
function Margins({ handleGetMarginCustom, handleUpdateMarginCustom, handleGetMarginClass, handleupdateMarginClass }) {
  //done custom
  const handleGetCustomSpacing = (property) => {
    const value = handleGetMarginCustom(property.property)
    return value ? value / 16 + "rem" : '';

  }

  const handleUpdateCustomSpacing = (...property) => {
    handleUpdateMarginCustom(property[0], property[2] * 16)
  }



  const handleGetClassSpacing = (myproperty) => {
    const value = handleGetMarginClass(myproperty)
    return value ? value : 0

  }

  const handleUpdateClassSpacing = (property) => {
    handleupdateMarginClass(property)
  }

  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Margin</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">
          <CustomSpacing
            myclass={margin_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='marginTop' dimention='marginTop' label='top' />


          <CustomSpacing
            myclass={margin_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='marginBottom' dimention='marginBottom' label='bottom' />

          <CustomSpacing
            myclass={margin_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='marginLeft' dimention='marginLeft' label='left' />

          <CustomSpacing
            myclass={margin_classes}
            updateSelectedElementSpacing={handleUpdateCustomSpacing}
            updateSelectedElementClass={handleUpdateClassSpacing}
            getSelectedElementClassByType={handleGetClassSpacing}
            getSelectedElementStyleByProperty={handleGetCustomSpacing}
            spacing_type='marginRight' dimention='marginRight' label='right' />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default Margins;
