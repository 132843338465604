export const mode_options = [
  { value: "light", label: "Light" },
  { value: "dark", label: "Dark" },
];
export const defaultFontWeights = {
  theme_h1_font_weight: "500",
  theme_h2_font_weight: "500",
  theme_h3_font_weight: "500",
  theme_h4_font_weight: "500",
  theme_p_font_weight: "500",
};
export const defaultFontSizes = {
  theme_h1_font_size: "2.500rem",
  theme_h2_font_size: "2.000rem",
  theme_h3_font_size: "1.750rem",
  theme_h4_font_size: "1.500rem",
  theme_p_font_size: "1.000rem",
  theme_line_height: "1.5",
};
export const defaultColors = {
  primary_color: "#FFFFFF",
  secondary_color: "#EB144C",
  primary_text_color: "#000000",
  secondary_text_color: "#FFFFFF",
  hover_text_color: "#FFFFFF",
  hover_background_color: "#EB144C",
  box_shadow: "#ABB8C3",
};
export const options = [
  { value: "'Roboto', sans-serif", label: "Roboto" },
  { value: '"Montserrat", sans-serif', label: "Montserrat" },
];

export const basicColors = ["#000000", "#FFFFFF", "#808080"];

export const colors = [
  "#000000",
  "#FFFFFF",
  "#808080",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#EB9694",
  "#cbbc7d",
  "#28634e",
  "#8E214B",
  "#ab9849",
];

export const website_types = [
  { value: "Business", label: "Business" },
  { value: "Blog", label: "Blog" },
  { value: "Portfolio", label: "Portfolio" },
  { value: "Fitness", label: "Fitness" },
  { value: "Food", label: "Food" },
  { value: "Events", label: "Events" },
  { value: "Other", label: "Other" },
];

export const text_color_classes = [
  {
    color: "#000000",
    label: "#000000",
    value: "color_text_000000",
  },
  {
    color: "#FFFFFF",
    label: "#FFFFFF",
    value: "color_text_FFFFFF",
  },
  {
    color: "#808080",
    label: "#808080",
    value: "color_text_808080",
  },
  {
    color: "#FF6900",
    label: "#FF6900",
    value: "color_text_FF6900",
  },
  {
    color: "#FCB900",
    label: "#FCB900",
    value: "color_text_FCB900",
  },
  {
    color: "#7BDCB5",
    label: "#7BDCB5",
    value: "color_text_7BDCB5",
  },
  {
    color: "#00D084",
    label: "#00D084",
    value: "color_text_00D084",
  },
  {
    color: "#8ED1FC",
    label: "#8ED1FC",
    value: "color_text_8ED1FC",
  },
  {
    color: "#0693E3",
    label: "#0693E3",
    value: "color_text_0693E3",
  },
  {
    color: "#ABB8C3",
    label: "#ABB8C3",
    value: "color_text_ABB8C3",
  },
  {
    color: "#EB144C",
    label: "#EB144C",
    value: "color_text_EB144C",
  },
  {
    color: "#F78DA7",
    label: "#F78DA7",
    value: "color_text_F78DA7",
  },
  {
    color: "#DB3E00",
    label: "#DB3E00",
    value: "color_text_DB3E00",
  },
  {
    color: "#FCCB00",
    label: "#FCCB00",
    value: "color_text_FCCB00",
  },
  {
    color: "#008B02",
    label: "#008B02",
    value: "color_text_008B02",
  },
  {
    color: "#006B76",
    label: "#006B76",
    value: "color_text_006B76",
  },
  {
    color: "#1273DE",
    label: "#1273DE",
    value: "color_text_1273DE",
  },
  {
    color: "#004DCF",
    label: "#004DCF",
    value: "color_text_004DCF",
  },
  {
    color: "#5300EB",
    label: "#5300EB",
    value: "color_text_5300EB",
  },
  {
    color: "#EB9694",
    label: "#EB9694",
    value: "color_text_EB9694",
  },
  {
    color: "#CBBC7D",
    label: "#CBBC7D",
    value: "color_text_CBBC7D",
  },
  {
    color: "#28634E",
    label: "#28634E",
    value: "color_text_28634E",
  },
  {
    color: "#8E214B",
    label: "#8E214B",
    value: "color_text_8E214B",
  },
  {
    color: "#AB9849",
    label: "#AB9849",
    value: "color_text_AB9849",
  },
];

export const bg_color_classes = [
  {
    color: "#000000",
    label: "#000000",
    value: "color_bg_000000",
  },
  {
    color: "#FFFFFF",
    label: "#FFFFFF",
    value: "color_bg_FFFFFF",
  },
  {
    color: "#808080",
    label: "#808080",
    value: "color_bg_808080",
  },
  {
    color: "#FF6900",
    label: "#FF6900",
    value: "color_bg_FF6900",
  },
  {
    color: "#FCB900",
    label: "#FCB900",
    value: "color_bg_FCB900",
  },
  {
    color: "#7BDCB5",
    label: "#7BDCB5",
    value: "color_bg_7BDCB5",
  },
  {
    color: "#00D084",
    label: "#00D084",
    value: "color_bg_00D084",
  },
  {
    color: "#8ED1FC",
    label: "#8ED1FC",
    value: "color_bg_8ED1FC",
  },
  {
    color: "#0693E3",
    label: "#0693E3",
    value: "color_bg_0693E3",
  },
  {
    color: "#ABB8C3",
    label: "#ABB8C3",
    value: "color_bg_ABB8C3",
  },
  {
    color: "#EB144C",
    label: "#EB144C",
    value: "color_bg_EB144C",
  },
  {
    color: "#F78DA7",
    label: "#F78DA7",
    value: "color_bg_F78DA7",
  },
  {
    color: "#DB3E00",
    label: "#DB3E00",
    value: "color_bg_DB3E00",
  },
  {
    color: "#FCCB00",
    label: "#FCCB00",
    value: "color_bg_FCCB00",
  },
  {
    color: "#008B02",
    label: "#008B02",
    value: "color_bg_008B02",
  },
  {
    color: "#006B76",
    label: "#006B76",
    value: "color_bg_006B76",
  },
  {
    color: "#1273DE",
    label: "#1273DE",
    value: "color_bg_1273DE",
  },
  {
    color: "#004DCF",
    label: "#004DCF",
    value: "color_bg_004DCF",
  },
  {
    color: "#5300EB",
    label: "#5300EB",
    value: "color_bg_5300EB",
  },
  {
    color: "#EB9694",
    label: "#EB9694",
    value: "color_bg_EB9694",
  },

  {
    color: "#cbbc7d",
    label: "#cbbc7d",
    value: "color_bg_CBBC7D",
  },
  {
    color: "#28634E",
    label: "#28634E",
    value: "color_bg_28634E",
  },
  {
    color: "#8E214B",
    label: "#8E214B",
    value: "color_bg_8E214B",
  },
  {
    color: "#AB9849",
    label: "#AB9849",
    value: "color_bg_AB9849",
  },
];

export const container_classes = [
  { value: "container", label: "Container" },
  { value: "container-fluid", label: "Full Width" },
];

export const font_sizes_classes = [
  { value: "font-size-xxs", label: "12px" },
  { value: "font-size-xs", label: "14px" },
  { value: "font-size-sm", label: "16px" },
  { value: "font-size-md", label: "18px" },
  { value: "font-size-lg", label: "20px" },
  { value: "font-size-xlg", label: "24px" },
  { value: "font-size-xxlg", label: "28px" },
  { value: "font-size-32", label: "32px" },
  { value: "font-size-42", label: "42px" },
  { value: "font-size-52", label: "52px" },
];

// export const font_weight_classes = [
//   { value: "fw-bold", label: "Bold" },
//   { value: "fw-bolder", label: "Bolder" },
//   { value: "fw-normal", label: "Normal" },
//   { value: "fw-light", label: "Light" },
// ];

export const menu_bar_position_classes = [
  { value: "wb-navbar-sticky", label: "Yes" },
  { value: "wb-navbar-scroll", label: "No" },
];
export const font_weight_classes = [
  { value: "wb-fw-100", label: "Thin 100" },
  { value: "wb-fw-200", label: "Extra Light 200" },
  { value: "wb-fw-300", label: "Light 300" },
  { value: "wb-fw-400", label: "Regular 400" },
  { value: "wb-fw-500", label: "Medium 500" },
  { value: "wb-fw-600", label: "Semi Bold 600" },
  { value: "wb-fw-700", label: "Bold 700" },
  { value: "wb-fw-800", label: "Extra Bold 800" },
  { value: "wb-fw-900", label: "Black 900" },
];

export const line_height_classes = [
  { value: "lh-1", label: "Normal" },
  { value: "lh-sm", label: "Small" },
  { value: "lh-base", label: "Base" },
  { value: "lh-lg", label: "Large" },
];

export const text_transform_classes = [
  { value: "text-uppercase", label: "Uppercase" },
  { value: "text-capitalize", label: "Capitalize" },
  { value: "text-lowercase", label: "Lowercase" },
];

export const text_decoration_classes = [
  { value: "text-decoration-none", label: "Normal" },
  { value: "text-decoration-underline", label: "Underline" },
  { value: "text-decoration-line-through", label: "Line Through" },
];

export const text_alignment_classes = [
  {
    value: "text-start",
    label: "Start",
    icon_name: "akar-icons:text-align-left",
  },
  {
    value: "text-center",
    label: "Center",
    icon_name: "akar-icons:text-align-center",
  },
  { value: "text-end", label: "End", icon_name: "akar-icons:text-align-right" },
];
export const label_alignment_classes = [
  {
    value: "justify-content-start",
    label: "Move to start",
    icon_name: "akar-icons:text-align-left",
  },
  {
    value: "justify-content-center",
    label: "Move to center",
    icon_name: "akar-icons:text-align-center",
  },
  {
    value: "justify-content-end",
    label: "Move to end",
    icon_name: "akar-icons:text-align-right",
  },
];
export const button_alignment_classes = [
  {
    value: "justify-content-start",
    label: "Move to start",
    icon_name: "akar-icons:text-align-left",
  },
  {
    value: "justify-content-center",
    label: "Move to center",
    icon_name: "akar-icons:text-align-center",
  },
  {
    value: "justify-content-end",
    label: "Move to end",
    icon_name: "akar-icons:text-align-right",
  },
];
export const button_variant_classes = [
  {
    value: "wb-btn-contained",
    styleclass: "btn-contained-drawer",
    label: "Contained",
  },
  {
    value: "wb-btn-outlined",
    styleclass: "btn-outlined-drawer",
    label: "Outlined",
  },
  {
    value: "wb-btn-text",
    styleclass: "btn-text-drawer",
    label: "Text",
  },
];
export const button_radius_classes = [
  { value: "rounded-3", label: "Rounded Edges", icon_name: "akar-icons:box" },
  {
    value: "rounded-pill",
    label: "Pill",
    icon_name: "icons8:rounded-rectangle",
  },
  {
    value: "img-custom-round-size",
    label: "Custom Border Radius",
    icon_name: "gridicons:customize",
  },
];

export const DESIGNER_MADE_PALETTE = [
  {
    label: "Palette#1",
    primary_color: "#F2F2F2",
    secondary_color: "#F2B705",
    secondary_text_color: "#000",
    primary_text_color: "#261C0A",
    theme_heading_color: "#BF7E04",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#2",
    primary_color: "#d5ccc3",
    secondary_color: "#8C3E3E",
    secondary_text_color: "#FFF",
    primary_text_color: "#0D0D0D",
    theme_heading_color: "#FFF",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#3",
    primary_color: "#8C8668",
    secondary_color: "#BF964B",
    secondary_text_color: "#EBDEC6",
    primary_text_color: "#59291E",
    theme_heading_color: "#F2F2F2",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#4",
    primary_color: "#D9946C",
    secondary_color: "#A6654E",
    secondary_text_color: "#FFF",
    primary_text_color: "#FFF",
    theme_heading_color: "#260101",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#5",
    primary_color: "#0D0D0D",
    secondary_color: "#F2B705",
    secondary_text_color: "#FFF",
    primary_text_color: "#fff",
    theme_heading_color: "#F8C536",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#6",
    primary_color: "#242626",
    secondary_color: "#F2ECE4",
    secondary_text_color: "#636969",
    primary_text_color: "#A68C76",
    theme_heading_color: "#EBE5E0",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#7",
    primary_color: "#011532",
    secondary_color: "#4080BF",
    secondary_text_color: "#FFF",
    primary_text_color: "#bfbfbf",
    theme_heading_color: "#fff",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#8",
    primary_color: "#fff",
    secondary_color: "#014235",
    secondary_text_color: "#B2BEBF",
    primary_text_color: "#a45f53",
    theme_heading_color: "#97aded",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#9",
    primary_color: "#F2F2F2",
    secondary_color: "#D9D9D9",
    secondary_text_color: "#0D0D0D",
    primary_text_color: "#262626",
    theme_heading_color: "#737373",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#10",
    primary_color: "#0D0D0D",
    secondary_color: "#636AF2",
    secondary_text_color: "#fff",
    primary_text_color: "#E9E9F2",
    theme_heading_color: "#9B9FF2",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#11",
    primary_color: "#40352C",
    secondary_color: "#BF702A",
    secondary_text_color: "#D9D8D2",
    primary_text_color: "#F2CEAE",
    theme_heading_color: "#F2E30C",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#12",
    primary_color: "#F2F2F2",
    secondary_color: "#C0D99C",
    secondary_text_color: "#1B4001",
    primary_text_color: "#1B4001",
    theme_heading_color: "#7C96A6",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#13",
    primary_color: "#202426",
    secondary_color: "#F2F2F2",
    secondary_text_color: "#6C733D",
    primary_text_color: "#F2F2F2",
    theme_heading_color: "#9DA65D",
    box_shadow_color: "#808080",
  },
  {
    label: "Palette#14",
    primary_color: "#253C59",
    secondary_color: "#DFE7F2",
    secondary_text_color: "#D98A29",
    primary_text_color: "#fff",
    theme_heading_color: "#BF8D30",
    box_shadow_color: "#808080",
  },
];
export const image_alignment_classes = [
  {
    value: "float-start",
    label: "Move to start",
    icon_name: "akar-icons:text-align-left",
  },
  {
    value: "align-img-center",
    label: "Move to center",
    icon_name: "akar-icons:text-align-center",
  },
  {
    value: "float-end",
    label: "Move to end",
    icon_name: "akar-icons:text-align-right",
  },
];
export const object_fit_classes = [
  {
    value: "object-fit-fill",
    label: "Fill",
    icon_name: "mdi:stretch-to-page-outline",
  },
  {
    value: "object-fit-cover",
    label: "Cover",
    icon_name: "iconoir:enlarge-round-arrow",
  },
  {
    value: "object-fit-contain",
    label: "Contain",
    icon_name: "ant-design:arrows-alt-outlined",
  },
  {
    value: "object-fit-scale",
    label: "Scale Down",
    icon_name: "system-uicons:scale-contract",
  },
];
export const image_transform_classes = [
  { value: "rounded-3", label: "Rounded Edges", icon_name: "akar-icons:box" },
  {
    value: "rounded-pill",
    label: "Pill",
    icon_name: "icons8:rounded-rectangle",
  },
  { value: "rounded-circle", label: "Circle", icon_name: "akar-icons:circle" },
  {
    value: "img-custom-round-size",
    label: "Custom Border Radius",
    icon_name: "gridicons:customize",
  },
];
export const form_inputs_transform_classes = [
  { value: "rounded-3", label: "Rounded Edges", icon_name: "akar-icons:box" },
  {
    value: "rounded-pill",
    label: "Pill",
    icon_name: "icons8:rounded-rectangle",
  },
  {
    value: "img-custom-round-size",
    label: "Custom Border Radius",
    icon_name: "gridicons:customize",
  },
];
export const image_size_classes = [
  {
    value: "img-fluid",
    label: "Responsive",
    icon_name: "fluent:slide-size-24-regular",
  },
  {
    value: "img-width-fluid",
    label: "Width 100%",
    icon_name: "carbon:fit-to-width",
  },
  {
    value: "img-height-fluid",
    label: "Height 100%",
    icon_name: "carbon:fit-to-height",
  },
  {
    value: "img-custom-size",
    label: "Custom size",
    icon_name: "gridicons:customize",
  },
];

export const background_size_classes = [
  { value: "background-size-auto", label: "Default" },
  { value: "background-size-contain", label: "Contain" },
  { value: "background-size-cover", label: "Cover" },
];
export const background_position_classes = [
  { label: "Top Left", value: "wp-bp-tl" },
  { label: "Top Center", value: "wp-bp-tc" },
  { label: "Top Right", value: "wp-bp-tr" },
  { label: "Center Left", value: "wp-bp-cl" },
  { label: "Center Center", value: "wp-bp-cc" },
  { label: "Center Right", value: "wp-bp-cr" },
  { label: "Bottom Left", value: "wp-bp-bl" },
  { label: "Bottom Center", value: "wp-bp-bc" },
  { label: "Bottom Right", value: "wp-bp-br" },
];
// justify content class

export const justify_content_classes = [
  {
    value: "justify-content-start",
    label: "Left",
    icon_name: "akar-icons:text-align-left",
  },
  {
    value: "justify-content-center",
    label: "Center",
    icon_name: "akar-icons:text-align-center",
  },
  {
    value: "justify-content-end",
    label: "Right",
    icon_name: "akar-icons:text-align-right",
  },
  {
    value: "justify-content-between",
    label: "Space Between",
    icon_name: "akar-icons:text-align-right",
  },
  {
    value: "justify-content-around",
    label: "Space Around",
    icon_name: "akar-icons:text-align-right",
  },
];
// align items class
export const align_items_classes = [
  { value: "align-items-start", label: "Top" },
  { value: "align-items-center", label: "Center" },
  { value: "align-items-end", label: "Bottom" },
];

export const background_repeat_classes = [
  { value: "background-repeat-repeat", label: "Repeat" },
  { value: "background-repeat-no-repeat", label: "No Repeat" },
];

export const font_family_classes = [
  { value: "font-roboto-class", label: "Roboto" },
  { value: "font-Montserrat-class", label: "Montserrat" },
];

export const available_spacing_list = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 28, 32, 40,
  45, 50, 55, 60, 65, 70, 75, 80,
];
export const available_border_spacing = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const border_classes = (direction = "") => {
  if (direction === "left") {
    const arr = available_border_spacing.map((space) => {
      return { name: "border-left-width", value: space, label: space };
    });
    return arr;
  }

  if (direction === "right") {
    const arr = available_border_spacing.map((space) => {
      return { name: "border-right-width", value: space, label: space };
    });
    return arr;
  }

  if (direction === "top") {
    const arr = available_border_spacing.map((space) => {
      return { name: "border-top-width", value: space, label: space };
    });
    return arr;
  }

  if (direction === "bottom") {
    const arr = available_border_spacing.map((space) => {
      return { name: "border-bottom-width", value: space, label: space };
    });
    return arr;
  }

  const arr = available_border_spacing.map((space) => {
    return { name: "border-width", value: space, label: space };
  });
  return arr;
};

export const button_width_classes = () => {
  const arr = available_border_spacing.map((space) => {
    return { value: "wp-border-" + space, label: space };
  });
  return arr;
};

export const margin_classes = (direction) => {
  if (direction === "left") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-ml-" + space, label: space };
    });
    return arr;
  }

  if (direction === "right") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-mr-" + space, label: space };
    });
    return arr;
  }

  if (direction === "top") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-mt-" + space, label: space };
    });
    return arr;
  }

  if (direction === "bottom") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-mb-" + space, label: space };
    });
    return arr;
  }
};

export const padding_classes = (direction) => {
  if (direction === "left") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-pl-" + space, label: space };
    });
    return arr;
  }

  if (direction === "right") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-pr-" + space, label: space };
    });
    return arr;
  }

  if (direction === "top") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-pt-" + space, label: space };
    });
    return arr;
  }

  if (direction === "bottom") {
    const arr = available_spacing_list.map((space) => {
      return { value: "wp-pb-" + space, label: space };
    });
    return arr;
  }
};

export const form_spacing_classes = (direction) => {
  if (direction === "left") {
    const arr = available_border_spacing.map((space) => {
      return { value: "wp-pl-" + space, label: space };
    });
    return arr;
  }

  if (direction === "right") {
    const arr = available_border_spacing.map((space) => {
      return { value: "wp-pr-" + space, label: space };
    });
    return arr;
  }

  if (direction === "top") {
    const arr = available_border_spacing.map((space) => {
      return { value: "wp-mt-" + space, label: space };
    });
    return arr;
  }

  if (direction === "bottom") {
    const arr = available_border_spacing.map((space) => {
      return { value: "wp-mb-" + space, label: space };
    });
    return arr;
  }
};

export const ElementList = [
  {
    title: "Text",
    icon: "fa-solid fa-paragraph",
    code: `<div id='drag'>
            <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
             or a typeface without relying on meaningful content</p>
          </div>`,
  },
  {
    title: "Heading",
    icon: "fa-sharp fa-solid fa-heading",
    code: `<div id='drag' >
            <h4>Lorem ipsum is a placeholder text commonly used</h4>
          </div>`,
  },
  {
    title: "Editor",
    icon: "fa-solid fa-file-contract",
    code: `<div id='drag' >
    <div class="wb-editor-block wp-pt-16 heading-style">
    <h4>Lorem ipsum</h4>
    <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
    or a typeface without relying on meaningful content
    </p>
</div>
    </div>
        `,
  },
  {
    title: "Button",
    icon: "fa-sharp fa-solid fa-toggle-off",
    code: `<div id='drag' >
    <button class="register-btn rounded border-0 w-100" type="submit" 
    style="background-color: var(--wb-website-theme-secondary-color) !important;">Button</button>       
    </div>`,
  },

  {
    title: "Image",
    icon: "fa fa-picture-o",
    code: `<div  id='drag' class="col-md-3 col-12" block-name="Block-1">
        <img width='70'   src="https://archive.org/download/placeholder-image/placeholder-image.jpg" 
        class="img-fluid" alt="" data-width="20" data-height="40">
        </div>
        `,
  },
  {
    title: "I-Frame",
    icon: "fa-solid fa-film",
    code: `<div id='drag' >
    <div class="wb-iframe">
    <img  width="560" height="315" src="https://cdn.icon-icons.com/icons2/2248/PNG/512/iframe_icon_136494.png" 
    class="img-fluid" alt="" data-width="20" data-height="40">
                  </div>  
    </div>
        `,
    alternate_code: true,
  },

  {
    title: "Layout(1x2)",
    icon: "fa-sharp fa-solid fa-table-columns",
    code: `<div id='drag' class="row wb-layout"  >
      <div class="col-6 d-flex justify-content-center align-items-center" >
        <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
      </div>
      <div class="col-6 d-flex justify-content-center align-items-center" >
        <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
      </div>
    </div>
        `,
  },
  {
    title: "Layout(1x3)",
    icon: "fa-sharp fa-solid fa-table-columns",
    code: `<div id='drag' class="row wb-layout"  >
      <div class="col-4 d-flex justify-content-center align-items-center" >
        <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center" >
        <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center" >
      <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
    </div>
    </div>
        `,
  },
  {
    title: "Layout(1x4)",
    icon: "fa-sharp fa-solid fa-table-columns",
    code: `<div id='drag' class="row wb-layout"  >
    <div class="col-3 d-flex justify-content-center align-items-center" >
    <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center" >
    <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center" >
    <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center" >
    <img class="wb-replaceable-element" style="width:'auto'" src='https://w7.pngwing.com/pngs/972/334/png-transparent-computer-icons-add-logo-desktop-wallpaper-add-thumbnail.png'/>
  </div>
    </div>
        `,
  },
];

export const google_font_family_classes = [
  {
    css_rule: "'Roboto', sans-serif",
    label: "Default",
    value: "",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Mukta', sans-serif",
    label: "Muktra",
    value: "font_muktra",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Sora', sans-serif",
    label: "Sora",
    value: "font_sora",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Hahmlet', serif",
    label: "Hahmlet",
    value: "font_Hahmlet",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Andada Pro', serif",
    label: "Andada Pro",
    value: "font_andada-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Work Sans', sans-serif",
    label: "Work Sans",
    value: "font_work-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Raleway', sans-serif",
    label: "Raleway",
    value: "font_raleway",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Spectral', serif",
    label: "Spectral",
    value: "font_spectral",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Cormorant', serif",
    label: "Cormorant",
    value: "font_cormorant",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Archivo', sans-serif",
    label: "Archivo",
    value: "font_archivo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Playfair Display', serif",
    label: "Playfair",
    value: "font_playfair",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'BioRhyme', serif",
    label: "BioRhyme",
    value: "font_biorhyme",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap')",
  },
  {
    css_rule: "'Lora', serif",
    label: "Lora",
    value: "font_lora",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Manrope', sans-serif",
    label: "Manrope",
    value: "font_manrope",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')",
  },
  {
    css_rule: "'Encode Sans', sans-serif",
    label: "Encode Sans",
    value: "font_encode-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Inter', sans-serif",
    label: "Inter",
    value: "font_inter",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Epilogue', sans-serif",
    label: "Epilogue",
    value: "font_epilogue",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Open Sans', sans-serif",
    label: "Open Sans",
    value: "font_open-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Oxygen', sans-serif",
    label: "Oxygen",
    value: "font_oxygen",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap')",
  },
  {
    css_rule: "'Source Sans Pro', sans-serif",
    label: "Source Sans Pro",
    value: "font_source-sans-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Nunito', sans-serif",
    label: "Nunito",
    value: "font_nunito",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Poppins', sans-serif",
    label: "Poppins",
    value: "font_poppins",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Montserrat', sans-serif",
    label: "Montserrat",
    value: "font_montserrat",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Oswald', sans-serif",
    label: "Oswald",
    value: "font_oswald",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap')",
  },
  {
    css_rule: "'Old Standard TT', serif",
    label: "Old Standard TT",
    value: "font_old-standard-tt",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap')",
  },
  {
    css_rule: "'Anton', sans-serif",
    label: "Anton",
    value: "font_anton",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap')",
  },
  {
    css_rule: "'Lato', sans-serif",
    label: "Lato",
    value: "font_lato",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Philosopher', sans-serif",
    label: "Philosopher",
    value: "font_philosopher",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Sanchez', serif",
    label: "Sanchez",
    value: "font_sanchez",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap')",
  },
  {
    css_rule: "'Rubik', sans-serif",
    label: "Rubik",
    value: "font_rubik",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Rokkitt', serif",
    label: "Rokkitt",
    value: "font_rokkitt",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap')",
  },
  {
    css_rule: "'Rambla', sans-serif",
    label: "Rambla",
    value: "font_rambla",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Noto Sans', sans-serif",
    label: "Noto Sans",
    value: "font_noto-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')",
  },
  {
    css_rule: "'Mulish', sans-serif",
    label: "Mulish",
    value: "font_mulish",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Merriweather', sans-serif",
    label: "Merriweather",
    value: "font_merriweather",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap')",
  },
  {
    css_rule: "'Karla', sans-serif",
    label: "Karla",
    value: "font_karla",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')",
  },
  {
    css_rule: "'Josefin Sans', sans-serif",
    label: "Josefin Sans",
    value: "font_josefin-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Fira Sans', sans-serif",
    label: "Fira Sans",
    value: "font_fira-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: "'Fjalla One', sans-serif",
    label: "Fjalla One",
    value: "font_fjalla-one",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap')",
  },
  {
    css_rule: "'DM Sans', sans-serif",
    label: "DM Sans",
    value: "font_dm-sans",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap')",
  },
  {
    css_rule: "'Concert One', cursive",
    label: "Concert One",
    value: "font_concert-one",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap')",
  },
  {
    css_rule: "'Cardo', serif",
    label: "Cardo",
    value: "font_cardo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap')",
  },
  {
    css_rule: "'Cabin', sans-serif",
    label: "Cabin",
    value: "font_cabin",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Bree Serif', serif",
    label: "Bree Serif",
    value: "font_bree-sarif",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap')",
  },
  {
    css_rule: "'Arimo', sans-serif",
    label: "Arimo",
    value: "font_arimo",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')",
  },
  {
    css_rule: "'Abril Fatface', cursive",
    label: "Abril Fatface",
    value: "font_abril-fatface",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap')",
  },
  {
    css_rule: "'Readex Pro', sans-serif",
    label: "Readex Pro",
    value: "font_readex-pro",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap')",
  },
  {
    css_rule: "'Ubuntu', sans-serif",
    label: "Ubuntu",
    value: "font_ubuntu",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap')",
  },
  {
    css_rule: "'Courgette', cursive",
    label: "Courgette",
    value: "font_courgette",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap')",
  },
  {
    css_rule: "'Saira', sans-serif",
    label: "Saira",
    value: "font_saira",
    import_link:
      "@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')",
  },
  {
    css_rule: `"Lobster Two", cursive`,
    label: "Lobster Two",
    value: "font_lobster_two",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');`,
  },
  {
    css_rule: `"Roboto", sans-serif`,
    label: "Roboto",
    value: "font-roboto",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');`,
  },
  {
    css_rule: `"Heebo", sans-serif`,
    label: "Heebo",
    value: "font-heebo",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');`,
  },

  {
    css_rule: `"Teko", sans-serif`,
    label: "Teko",
    value: "font_teko",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');`,
  },

  {
    css_rule: `"Bebas Neue"`,
    label: "Bebas Neue",
    value: "font_bebas_Neue",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');`,
  },

  {
    css_rule: `"Manrope"`,
    label: "Manrope",
    value: "font_manrope",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');`,
  },

  {
    css_rule: `"Nova Square"`,
    label: "Nova Square",
    value: "font_nova_square",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Nova+Square&display=swap');`,
  },

  {
    css_rule: `"Palette Mosaic"`,
    label: "Palette Mosaic",
    value: "font_palette_mosaic",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Palette+Mosaic&display=swap');`,
  },

  {
    css_rule: `"Hurricane"`,
    label: "Hurricane",
    value: "font_hurricane",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');`,
  },

  {
    css_rule: `Red Hat Mono`,
    label: "Red Hat Mono",
    value: "font_red_hat_mono",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');`,
  },
  {
    css_rule: `Dancing Script`,
    label: "Dancing Script",
    value: "font_dancing_script",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');`,
  },
  {
    css_rule: `Kaushan Script`,
    label: "kaushan Script",
    value: "font_kaushan_script",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');`,
  },
  {
    css_rule: `Square Peg`,
    label: "Square Peg",
    value: "font_square_peg",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');`,
  },
  {
    css_rule: `Libre Bodoni`,
    label: "Libre Bodoni",
    value: "font_libre_bodoni",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');`,
  },
  {
    css_rule: "'Black Han Sans', sans-serif",
    label: "Black Han Sans",
    value: "font_black_han_sans",
    import_link: `@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');`,
  },
];

export const italic_classes = [
  // { value: "fst-normal", label: "Normal", icon_name: "healthicons:i" },
  {
    value: "fst-italic",
    label: "Italic",
    icon_name: "ant-design:italic-outlined",
  },
];
