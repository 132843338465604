import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { _s3upload } from "../../../../../../DAL/s3upload/s3upload";
import { s3baseUrl } from "../../../../../../config/config";
import BackgroundImage from "../../../Right-Section-Settings-Menu/components/Background-Image/BackgroundImage";
import {
  background_size_classes,
  background_repeat_classes,
  colors,
  background_position_classes,
} from "../../../../../../ui_libs/Constants/constant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BackgorundPosition,
  BackgroundRepeat,
} from "../../../Right-Section-Settings-Menu/components";
import { BackgroundSize } from "../../../Right-Section-Settings-Menu/components";
import ButtonProperty from "../../../Right-Menu/components/Buton-Property/ButtonProperty";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../../../hooks/builder-context/BuilderContext";

const useStyles = makeStyles({
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
export const RowColumnStyleAccordion = ({
  child,
  OpenColumnStyle,
  ChangeColumnBlockStyle,
  ChangeColumnBackgroundColor,
  getColumnproperty,
  ResetColumnproperty,
  ResetColumnBackgroundColor,
  ChangeColumnBlockClass,
  getColumnBlockClass,
  ImgData,
}) => {
  const { rootValues } = useBuilder();
  const styleClasses = useStyles();
  const [isLoadingBackgroundImage, setisLoadingBackgroundImage] =
    useState(false);
  const handleGetColumnProperty = (property) => {
    return getColumnproperty(property, OpenColumnStyle);
  };
  const handleResetColumnproperty = (property) => {
    ResetColumnproperty(property, OpenColumnStyle);
  };
  const handleChangeColumnBlockStyle = (property, value) => {
    ChangeColumnBlockStyle(property, value, OpenColumnStyle);
  };
  const handleChangeColumnBackgroundColor = (property, value) => {
    ChangeColumnBackgroundColor(property, value, OpenColumnStyle);
  };
  const handleResetColumnBackgroundColor = (property) => {
    ResetColumnBackgroundColor(property, OpenColumnStyle);
  };

  const handleChangeColumnBlockClass = (property, value, classlist) => {
    ChangeColumnBlockClass(value, classlist, OpenColumnStyle);
  };
  const handleGetColumnBlockBackgroundSize = () => {
    let value = getColumnBlockClass(background_size_classes, OpenColumnStyle);
    return value;
  };
  const handleGetColumnBlockBackgroundPosition = () => {
    let value = getColumnBlockClass(
      background_position_classes,
      OpenColumnStyle
    );
    return value;
  };
  const handleGetColumnBlockBackgroundRepeat = () => {
    let value = getColumnBlockClass(background_repeat_classes, OpenColumnStyle);
    return value;
  };
  const handleImageSelection = async (e, showupdatedimg) => {
    setisLoadingBackgroundImage(true);
    const resp = await _s3upload(`${rootValues._id}`, e);
    if (resp.code === 200) {
      const image_url = resp.file_name.trim();
      const img = s3baseUrl + "/" + image_url.replace(" ", "%20");
      handleChangeColumnBlockStyle("background-image", `url("${img}")`);

      setisLoadingBackgroundImage(false);
      showupdatedimg();
    } else {
      setisLoadingBackgroundImage(false);
    }
  };
  const CheckBoxClassAvailable = (child) => {
    let result = false;
    if (child) {
      let classlist = child.classList;
      if (classlist) {
        classlist.forEach((cl) => {
          console.log(cl, "classlistclasslistclasslistclasslist");

          if (cl == "wb-box") {
            result = true;
          }
        });
      }
    }
    return result;
  };
  return (
    <Accordion className="card mb-2">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {child && child.getAttribute("block-name")
          ? child.getAttribute("block-name")
          : "Box Style"}
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <BackgroundImage
            ImgData={ImgData}
            backgroundImage={getColumnproperty(
              "background-image",
              OpenColumnStyle
            )}
            handleRemoveBackgroundImage={() =>
              handleChangeColumnBlockStyle("background-image", "")
            }
            handleImageSelection={handleImageSelection}
            isLoadingBackgroundImage={isLoadingBackgroundImage}
          />

          <BackgroundSize
            value={handleGetColumnBlockBackgroundSize()}
            handleChangeInClass={(property, value, classlist) => {
              handleChangeColumnBlockClass(
                property,
                "",
                background_position_classes
              );
              handleChangeColumnBlockClass(property, value, classlist);
            }}
            classesList={background_size_classes}
            OpenColumnStyle={OpenColumnStyle}
          />
          <BackgroundRepeat
            value={handleGetColumnBlockBackgroundRepeat()}
            handleChangeInClass={handleChangeColumnBlockClass}
            classesList={background_repeat_classes}
            OpenColumnStyle={OpenColumnStyle}
          />
          <BackgorundPosition
            backgroundPosition={handleGetColumnBlockBackgroundPosition()}
            handleChangeInClass={(property, value, classlist) => {
              handleChangeColumnBlockClass(
                property,
                "background-size-auto",
                background_size_classes
              );
              handleChangeColumnBlockClass(
                property,
                "background-repeat-no-repeat",
                background_repeat_classes
              );
              handleChangeColumnBlockClass(property, value, classlist);
            }}
            classesList={background_position_classes}
          />

          <ButtonProperty
            GetProperty={handleGetColumnProperty}
            label={"Background Color"}
            ResetProperty={handleResetColumnBackgroundColor}
            property={"background-color"}
            handleChangeProperty={handleChangeColumnBackgroundColor}
            colors={colors}
            child={child}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
