import React, { useEffect } from 'react'
import { website_placeholder_img } from '../../assets'
import { app_type, s3baseUrl, s3builderSource } from '../../config/config'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import CustomPopover from '../../components/CustomPopover'
import {
  _create_user_design_project,
  _delete_user_design_project,
  _get_user_created_designs,
  _update_user_design_project,
} from '../../DAL/projects/projects'
import {
  _create_user_design_page,
  _update_user_design_page,
} from '../../DAL/projects-pages/projectspages'

import AddNewProjectPopup from '../../layouts/MainEditorLayout/components/AddNewProjectPopup/AddNewProjectPopup'
import { useNavigate, useLocation } from 'react-router-dom'
import { _get_user, _is_dev } from '../../DAL/localStorage/localStorage'
import { useBuilder } from '../../hooks/builder-context/BuilderContext'
import Loader from '../../components/Loader/Loader'
import CustomConfirmation from '../../components/CustomConfirmation'
import AddProjectVersion from './component/AddProjectVersion'
import EditProjectName from '../../layouts/MainEditorLayout/components/EditProjectName/EditProjectName'
import { Backdrop } from '@mui/material'
import EditProjectThumbnail from '../../layouts/MainEditorLayout/components/EditProjectThumbnail/EditProjectThumbnail'
import { styled } from '@mui/material/styles'
import Page from '../../components/Page'
function ProjectList(props) {
  const {
    setSelectedSections,
    handlePublishProject,
    handleDownload,
  } = useBuilder()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const navigate = useNavigate()
  const [Projects, setProjects] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [CreateNewProject, setCreateNewProject] = useState(false)

  const [TargetProjectID, setTargetProjectID] = useState('')
  const [RemoveProjectDailogue, setRemoveProjectDailogue] = useState(false)

  const [OpenEditProjectPopup, setOpenEditProjectPopup] = useState(false)
  const [OpenEditProjectThumbnailPopup, setOpenEditProjectThumbnailPopup] = useState(false)
  const [TargetProject, setTargetProject] = useState('')
  // project version save dailogue state
  const [OpenSaveVersionDailogue, setOpenSaveVersionDailogue] = useState(false)
  const [isPublishLoading, setisPublishLoading] = useState({
    value: false,
    label: 'Please wait! Publishing your Project...',
  })
  const [OpenGallery, setOpenGallery] = useState(false)

  const RootStyle = styled(Page)(({ theme }) => ({
  }));

  const fetchAvailableProjects = async () => {
    const user = _get_user()
    const postData = {
      app_type: `${user?.type}`,
    }
    const resp = await _get_user_created_designs()
    console.log(resp.Project, 'project_list_resp')
    if (resp.code === 200) {
      if (resp.Project.length > 0) {
        setProjects(resp.Project)
        setisLoading(false)
        // // get response and navigate to pages if project available other-wise navigate to choose templates
        // if (resp.Project.length == 0) {
        //   navigate(`/choose-template`)
        // } else {
        //   handleSelectProjectPages(resp.Project[0])
        // }
      } else {
        navigate('/choose-template')
      }
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }

  const handleSelectProjectPages = (Project) => {
    navigate(`/projects/${Project._id}`, {
      state: {
        Project,
      },
    })
  }
  const handleSelectSubscribers = (Project) => {
    navigate(`/subscribers/${Project._id}`, {
      state: {
        Project,
      },
    })
  }
  const openWebSettings = (Project) => {
    navigate(`/website-settings/${Project._id}`)
  }
  const handleCreateNewProject = async () => {
    navigate('/add-project')
  }

  const handleDeleteProject = async (Project) => {
    setTargetProjectID(Project._id)
    setRemoveProjectDailogue(true)
  }
  const handleConfirmRemoveProject = async (_multiple_hit) => {
    if (_multiple_hit) {
      const resp = await _delete_user_design_project(TargetProjectID)
      if (resp.code == 200) {
        setRemoveProjectDailogue(false)
        setisLoading(true)
        fetchAvailableProjects()
      } else {
        enqueueSnackbar(resp.message, { variant: 'error' })
      }
    }
  }
  const handeOpenEditProjectName = async (Project) => {
    setTargetProject(Project)
    setOpenEditProjectPopup(true)
  }
  const handeOpenEditProjectThumbnailPopup = async (Project) => {
    setTargetProject(Project)
    setOpenEditProjectThumbnailPopup(true)
  }

  const handleEditProjectName = async (name) => {
    const PostData = {
      project_name: name,
      image: TargetProject.image,
      is_approved: TargetProject.is_approved,
      is_completed: TargetProject.is_completed,
      is_published: TargetProject.is_published,
      status: TargetProject.status,
      project_pages_count:TargetProject.project_pages_count
    }
    const resp = await _update_user_design_project(TargetProject._id, PostData)
    if (resp.code == 200) {
      setOpenEditProjectPopup(false)
      setisLoading(true)
      fetchAvailableProjects()
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }
  const handleEditProjectThumbnail = async (image) => {
    const PostData = {
      project_name: TargetProject.project_name,
      image: image,
      is_approved: TargetProject.is_approved,
      is_completed: TargetProject.is_completed,
      is_published: TargetProject.is_published,
      status: TargetProject.status,
      project_pages_count: TargetProject.project_pages_count,
    }
    const resp = await _update_user_design_project(TargetProject._id, PostData)
    if (resp.code == 200) {
      setOpenEditProjectThumbnailPopup(false)
      setisLoading(true)
      fetchAvailableProjects()
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }
  const handleSaveVersion = async (project) => {
    setTargetProject(project)

    setOpenSaveVersionDailogue(true)
  }
  // to view version list
  const handleViewVersions = (project) => {
    navigate(`/projects/${project._id}/version`, {
      state: {
        project,
      },
    })
  }

  const PublishProjecthandler = async (Project) => {
    setisPublishLoading({
      ...isPublishLoading,
      value: true,
      label: 'Please wait! Publishing your Project...',
    })
    let result = await handlePublishProject(Project)
    if (result) {
      fetchAvailableProjects()
      setisPublishLoading({ ...isPublishLoading, value: false })
    } else {
      setisPublishLoading({ ...isPublishLoading, value: false })
    }
  }
  const handleDownloadProject = async (Project) => {
    setisPublishLoading({
      ...isPublishLoading,
      value: true,
      label: 'Please wait! Downloading your Project...',
    })

    let result = await handleDownload(Project)
    if (result) {
      setisPublishLoading({
        ...isPublishLoading,
        value: false,
      })
    }
  }

  const MENU_OPTIONS = (row) => {
    return [
      {
        label: 'Publish Project',
        icon: 'material-symbols:published-with-changes-rounded',
        // disable: row.domain.length > 0 ? false : true,
        handleClick: PublishProjecthandler,
      },
      {
        label: 'Subscribers',
        icon: 'clarity:users-solid',
        handleClick: handleSelectSubscribers,
      },
      {
        label: 'Edit Project Name',
        icon: 'ant-design:edit-twotone',
        handleClick: handeOpenEditProjectName,
      },
      {
        label: 'Edit Thumbnail',
        icon: 'clarity:picture-line',
        handleClick: handeOpenEditProjectThumbnailPopup,
      },
      {
        label: 'Save Project Version',
        icon: 'material-symbols:save',
        handleClick: handleSaveVersion,
      },
      {
        label: 'View Project Versions',
        icon: 'ic:outline-format-list-numbered',
        disable: row.version_count > 0 ? false : true,
        handleClick: handleViewVersions,
      },
      {
        label: 'Project Setting',
        icon: 'ant-design:setting-twotone',
        handleClick: openWebSettings,
      },
      {
        label: 'Download Project',
        icon: 'material-symbols:download',
        disable: _is_dev() ? false : true,
        handleClick: handleDownloadProject,
      },
      {
        label: 'Delete Project',
        icon: 'ant-design:delete-twotone',
        handleClick: handleDeleteProject,
      },
    ]
  }

  useEffect(() => {
    setSelectedSections([])
    if (location?.state?.NavigateToPageList) {
      const { project_id, project_name } = location.state

      navigate(`/projects/${project_id}`, {
        state: {
          Project: {
            _id: project_id,
            project_name: project_name,
          },
        },
      })
    }
    fetchAvailableProjects()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (isPublishLoading.value) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        // onClick={handleClose}
      >
        <Loader />
        <span className="mt-5 pt-5">{isPublishLoading.label}</span>
      </Backdrop>
    )
  }

  return (
    <>
    <RootStyle title = "Wezily Builder">
      {OpenSaveVersionDailogue && (
        <AddProjectVersion
          reload={fetchAvailableProjects}
          open={OpenSaveVersionDailogue}
          setOpen={setOpenSaveVersionDailogue}
          Project_id={TargetProject._id}
        />
      )}
      {/* {OpenGallery && (
        <UserGallery open={OpenGallery} setOpen={setOpenGallery} />
      )} */}
      <AddNewProjectPopup
        open={CreateNewProject}
        setOpen={setCreateNewProject}
      />
      <EditProjectName
        open={OpenEditProjectPopup}
        setOpen={setOpenEditProjectPopup}
        EditProjectName={handleEditProjectName}
        TargetProject={TargetProject}
      />
      <EditProjectThumbnail
        open={OpenEditProjectThumbnailPopup}
        setOpen={setOpenEditProjectThumbnailPopup}
        EditProjectThumbnail={handleEditProjectThumbnail}
        TargetProject={TargetProject}
      />
      <CustomConfirmation
        open={RemoveProjectDailogue}
        setOpen={setRemoveProjectDailogue}
        handleAgree={handleConfirmRemoveProject}
        title="Are sure you want to remove this Project?"
      />
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 text-center mt-3">
            <h5>Your Project List</h5>
          </div>
          {_is_dev() ? (
            <div className="d-flex justify-content-end">
              <button
                onClick={() => setCreateNewProject(true)}
                className="main-dashboard-website-action-btn"
              >
                Add New Project
              </button>
            </div>
          ) : (
            ''
          )}

          <div className="col-12 mt-2 mb-5">
            <div className="row">
              {Projects.map((Project) => (
                <div
                  key={Project._id}
                  className="col-12 col-sm-6 col-lg-4 mt-4"
                >
                  <div className="border position-relative">
                    <div className="page_menu_option">
                      <CustomPopover
                        color={'#ffff'}
                        data={Project}
                        menu={MENU_OPTIONS(Project)}
                      />{' '}
                    </div>
                    <div>
                      <img
                        className="main-dashboard-website-placeholder"
                        onError={(e) => {
                          e.target.src = website_placeholder_img
                        }}
                        src={
                          Project.image
                            ? s3baseUrl + '/' + Project.image
                            : website_placeholder_img
                        }
                        // src={website_placeholder_img}
                        width="100%"
                        height="140%"
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                        }}
                        alt=""
                      />

                      <div className="main-dashboard-website-action rounded-card">
                        <button
                          onClick={() => handleSelectProjectPages(Project)}
                          className="main-dashboard-website-action-btn visible-parent-hover mb-2"
                        >
                          Open Project Pages List
                        </button>
                        {/* <a
                          href={`${s3baseUrl}/${Project._id}/index.html`}
                          target="_blank"
                        >
                          <button className="main-dashboard-website-action-btn visible-parent-hover">
                            Preview Project
                          </button>
                        </a> */}
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="main-dashboard-website-name text-capitalize">
                        {Project.project_name}
                        <span className="float-end"></span>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </RootStyle>
    </>
  )
}

export default ProjectList
