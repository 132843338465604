import React from "react";
import { RowDivManipulation } from "../Row-Div-Manipulation/RowDivManipulation";
export const RowBlockClasses = ({
  // justify content
  justify_content,
  justify_value,
  // align item
  align_items,
  align_items_value,
  // row and row reverse classes
  row,
  row_value,
  // handler
  handleChangeRowClass,
}) => {
  return (
    <div className="row ">
      {/* classes of row and row reverse */}
      <RowDivManipulation
        label="Row"
        classlist={row}
        value={row_value ? row_value : "row"}
        handleChangeRowClass={handleChangeRowClass}
      />
      {/* classes of justify content */}
      <RowDivManipulation
        label="Horizontal Alignment"
        classlist={justify_content}
        value={justify_value ? justify_value : "justify-content-start"}
        handleChangeRowClass={handleChangeRowClass}
      />

      {/* classes of align items */}
      <RowDivManipulation
        label="Vertical Alignment"
        classlist={align_items}
        value={align_items_value ? align_items_value : "align-items-start"}
        handleChangeRowClass={handleChangeRowClass}
      />
    </div>
  );
};
