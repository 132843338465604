import React from "react";
import { Icon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";

function FontWeight({ fontWeight, handleChangeInClass, classesList }) {
  return (
    <>
      <div class="mb-2">
        <div
          className="font-size-xsm mt-2"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={fontWeight ? "wb-icons-box" : "wb-icons-box-active"}
              onClick={() =>
                handleChangeInClass("fontWeight", null, classesList)
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls) => (
            <Tooltip title={cls.label} placement="top" arrow>
              <span
                className={
                  fontWeight === cls.value
                    ? "wb-icons-box-active " + cls.value
                    : "wb-icons-box " + cls.value
                }
                onClick={() =>
                  handleChangeInClass("fontWeight", cls.value, classesList)
                }
              >
                B
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
}

export default FontWeight;
