import React, { useEffect, useState } from 'react'
import { useBuilder } from '../../../../hooks/builder-context/BuilderContext'
import {
  padding_classes,
  margin_classes,
} from '../../../../ui_libs/Constants/constant'
function CustomSpacing({
  updateSelectedElementSpacing,
  updateSelectedElementClass,
  getSelectedElementClassByType,
  getSelectedElementStyleByProperty,
  spacing_type,
  label,
  dimention,
  myclass,
  ResetValue,
  label_,
}) {
  // reset
  // classes
  const [ClassValue, setClassValue] = useState('')
  // custom  vlaues
  const [CustomValue, setCustomValue] = useState('')
  // check display custom values or  classes
  const [ShowCustomValue, setShowCustomValue] = useState(false)

  const handleChangeInClass = (key, value, class_list) => {
    // if user select  custom
    if (value == 'custom' || value == 'select_custom') {
      setShowCustomValue(true)
      setClassValue('0')
      value = ''
      const payload = {
        class_list,
        value,
      }

      updateSelectedElementClass(payload)
    }
    // if select class value
    else {
      setCustomValue(0)
      updateSelectedElementSpacing(spacing_type, [key], '')
      setClassValue(value)
      const payload = {
        class_list,
        value,
      }
      console.log('customspacing', payload)
      updateSelectedElementClass(payload)
    }
  }

  const handleCustomChange = (e) => {
    setCustomValue(e.target.value)
    const px_to_rem = e.target.value / 16
    updateSelectedElementSpacing(spacing_type, e.target.name, px_to_rem)
  }

  useEffect(() => {
    // console.log("type=================", spacing_type)
    // initialize classes value

    let classval = getSelectedElementClassByType({
      class_list: myclass(label),
    })

    setClassValue(classval)

    // initialize custom values
    const customval = getSelectedElementStyleByProperty({
      property: `${dimention}`,
    })
    let convertion_to_px =
      customval == undefined || customval == ''
        ? '0'
        : customval.replace('rem', '') * 16

    // push pixel values to state
    // console.log("====================>", convertion_to_px)
    setCustomValue(convertion_to_px)
  }, [ResetValue])

  return (
    <>
      {!ShowCustomValue && (
        <div className="col mb-3">
          <label
            for="edit-able-text-input"
            className="text-capitalize form-label custom-label"
          >
            {label_ ? label_ : label}:
          </label>

          <select
            className="w-100 form-control custom-icon-select"
            value={parseInt(CustomValue) > 0 ? 'select_custom' : ClassValue}
            onChange={(e) =>
              handleChangeInClass(label, e.target.value, myclass(label))
            }
          >
            <option value="">Reset</option>
            {myclass(label).map((fs, i) => (
              <option key={i} value={fs.value}>
                {fs.label}
              </option>
            ))}

            {parseInt(CustomValue) > 0 && (
              <option value="select_custom" selected>
                {CustomValue}
              </option>
            )}
            <option value="custom">Custom</option>
          </select>
        </div>
      )}

      {ShowCustomValue && (
        <div className="text-capitalize pt-1 col pe-1">
          <label htmlFor="" className="custom-label">
            {label}:
          </label>
          <input
            onBlur={() => setShowCustomValue(false)}
            type="number"
            onChange={handleCustomChange}
            autoFocus={ShowCustomValue}
            name={label}
            value={CustomValue}
            className="form-control form-control-sm "
          />
        </div>
      )}
    </>
  )
}
export default CustomSpacing
