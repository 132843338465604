import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CountrySelect } from "./index";
import { _register } from "../../../DAL/login/login";
import { _set_token, _set_user } from "../../../DAL/localStorage/localStorage";
import { _get_active_categories } from "../../../DAL/categories/categories";

// ----------------------------------------------------------------------

export default function RegisterForm({
  onHandleRegister,
  isLoading,
  handleLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [template_category_list, set_template_category_list] = useState([]);
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    password: "",
    confirm_password: "",
    post_code: "",
    business_country: {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    business_name: "",
  });
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangePhone = (e) => {
    let value = e.target.value;
    if (
      inputs.business_country &&
      inputs.business_country.phone &&
      inputs.business_country.phone != ""
    ) {
      value = value.replace(inputs.business_country.phone, "");
    }

    setInputs({ ...inputs, [e.target.name]: value });
  };
  const handleChangeCountry = (newVal) => {
    setInputs({
      ...inputs,
      ["contact_number"]: "",
      ["business_country"]: newVal,
    });
  };

  // const RegisterSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('First name required'),
  //   lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required')
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     password: ''
  //   },
  //   validationSchema: RegisterSchema,
  //   onSubmit: () => {
  //     navigate('/dashboard', { replace: true });
  //   }
  // });
  const get_active_category = async () => {
    let res = await _get_active_categories();
    if (res.code == 200) {
      let { category } = res;
      set_template_category_list(category);
    }
  };
  const validate_inputs = (values_object) => {
    let values = Object.values(values_object);
    let invalid_input = false;
    values.forEach((val) => {
      if (val == "" || undefined) {
        invalid_input = true;
      }
    });
    if (invalid_input) {
      handleLoading(false);
    }
    return invalid_input;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLoading(true);
    if (inputs.password !== inputs.confirm_password) {
      enqueueSnackbar("Password and confirm password does not match", {
        variant: "error",
      });
      handleLoading(false);
      return;
    }
    if (inputs.contact_number.length == 0) {
      enqueueSnackbar("Phone Number is required !", {
        variant: "error",
      });
      handleLoading(false);
      return;
    }
    // if (!/^[0-9]{11,}$/.test(inputs.contact_number)) {
    //   enqueueSnackbar("Invalid Number!", { variant: "error" });
    //   handleLoading(false);
    //   return;
    // }
    if (!validate_inputs(inputs)) {
      let formData = new FormData();

      formData.append("first_name", inputs.first_name);
      formData.append("last_name", inputs.last_name);
      formData.append("email", inputs.email);
      formData.append(
        "contact_number",
        inputs.business_country.phone + inputs.contact_number
      );
      formData.append("business_name", inputs.business_name);
      formData.append("business_country", inputs.business_country.label);
      formData.append("password", inputs.password);
      formData.append("post_code", inputs.post_code);
      formData.append("type", "1");

      const postData = {
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        email: inputs.email,
        contact_number: inputs.business_country.phone + inputs.contact_number,
        business_name: inputs.business_name,
        business_country: inputs.business_country,
        password: inputs.password,
        post_code: inputs.post_code,
      };
      const result = await _register(formData);
      if (result.code === 200) {
        _set_token(result.customer.token);
        _set_user(result.customer);
        navigate("/choose-template", { replace: true });
        // navigate("/choose-template", { replace: true });
        handleLoading(false);
      } else {
        handleLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
    }
    // onHandleRegister(formData);
  };
  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  useEffect(() => {
    get_active_category();
  }, []);
  return (
    // <FormikProvider value={formik}>
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <p className="mb-0">Personal Information</p>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="First name"
            name="first_name"
            required
            value={inputs.first_name}
            onChange={handleChange}
            // {...getFieldProps('firstName')}
            // error={Boolean(touched.firstName && errors.firstName)}
            // helperText={touched.firstName && errors.firstName}
          />

          <TextField
            fullWidth
            label="Last name"
            required
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
            // {...getFieldProps('lastName')}
            // error={Boolean(touched.lastName && errors.lastName)}
            // helperText={touched.lastName && errors.lastName}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Email"
            required
            name="email"
            value={inputs.email}
            onChange={handleChange}
            // {...getFieldProps('firstName')}
            // error={Boolean(touched.firstName && errors.firstName)}
            // helperText={touched.firstName && errors.firstName}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            required
            name="password"
            value={inputs.password}
            onChange={handleChange}
            // {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // error={Boolean(touched.password && errors.password)}
            // helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            required
            name="confirm_password"
            value={inputs.confirm_password}
            onChange={handleChange}
            // {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                  >
                    <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // error={Boolean(touched.password && errors.password)}
            // helperText={touched.password && errors.password}
          />
        </Stack>
        <p className="mb-0">Business Information</p>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Business Name"
            required
            name="business_name"
            value={inputs.business_name}
            onChange={handleChange}
            // value={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
            // {...getFieldProps('firstName')}
            // error={Boolean(touched.firstName && errors.firstName)}
            // helperText={touched.firstName && errors.firstName}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Post Code"
            required
            name="post_code"
            value={inputs.post_code}
            onChange={handleChange}
          />

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
            <Select
              name="business_type"
              value={inputs.business_type}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value="Australia">Australia</MenuItem>
              <MenuItem value="America">America</MenuItem>
              <MenuItem value="Brazil">Brazil</MenuItem>
            </Select>
          </FormControl> */}
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <CountrySelect handleChangeCountry={handleChangeCountry} />

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
            <Select
              name="business_type"
              value={inputs.business_type}
              label="Business Type"
              onChange={handleChange}
              required
              variant="outlined"
            >
              {template_category_list &&
                template_category_list.map((list, index) => {
                  return (
                    <MenuItem key={index} value={list._id}>
                      {list.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl> */}
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            type="number"
            label="Phone Number"
            required
            name="contact_number"
            value={
              inputs.business_country
                ? inputs.business_country.phone
                  ? inputs.business_country.phone + inputs.contact_number
                  : inputs.contact_number
                : inputs.contact_number
            }
            onChange={handleChangePhone}
            // {...getFieldProps('lastName')}
            // error={Boolean(touched.lastName && errors.lastName)}
            // helperText={touched.lastName && errors.lastName}
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Register
        </LoadingButton>
      </Stack>
    </form>
    // </FormikProvider>
  );
}
