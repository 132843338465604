import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopupMenu from "../PopupMenu/PopupMenu";
import Edit from "@mui/icons-material/Edit";

import { Tooltip } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
function SectionTitle({ dataset, UpdateDatasetTitle }) {
  const [title, setTitle] = useState(dataset.section_title);
  const [isDisabled, setIsDisabled] = useState(true);
  const handleUpdate = () => {
    UpdateDatasetTitle(title);
    setIsDisabled(true);
  };
  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Section Title</Typography>
      </AccordionSummary>
      <AccordionDetails className="d-flex ">
        {/* <PopupMenu dataset={dataset} UpdateDatasetTitle={UpdateDatasetTitle} /> */}
        <div className="row align-items-center justify-content-center w-100 px-2">
          <div className="col-8 px-1">
            <input
              className="form-control form-control-sm"
              name="section_title"
              id="section_title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={isDisabled ? true : false}
            />
          </div>
          {isDisabled ? (
            <div className="col-4 px-1 d-flex flex-col ">
              <span className="ms-auto ">
                <button
                  onClick={() => setIsDisabled(false)}
                  className="btn btn-sm"
                >
                  <Tooltip title="Edit" placement="top" arrow>
                    <Edit />
                  </Tooltip>
                </button>
              </span>
            </div>
          ) : (
            <div className="col-4 px-1 align-items-center">
              <div className="row align-items-center justify-content-center  m-0 d-flex">
                <div className="p-0 icon-container fit-content">
                  <span className="mx-1 ">
                    <Tooltip title="Save" placement="top" arrow>
                      <Done htmlColor="green" onClick={() => handleUpdate()} />
                    </Tooltip>
                  </span>
                </div>
                <div className="p-0 icon-container fit-content">
                  <span className="mx-1 ">
                    <Tooltip title="Cancel" placement="top" arrow>
                      <Close
                        htmlColor="red"
                        onClick={() => setIsDisabled(true)}
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default SectionTitle;
