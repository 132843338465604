import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
import {
  ButtonGroup,
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Editor from "@monaco-editor/react";
import VSEditor from "../../pages/Builder/components/VSEditor/VSEditor";

const ElementSourceCode = () => {
  const {
    selectedElement,
    setSelectedElement,
    openDeveloperOptionMenu,
    setOpenDeveloperOptionMenu,
    developerOptionMenuType,
    setDeveloperOptionMenuType,
    ReRenderHtml,
    SaveActionToStack,
  } = useBuilder();
  const [targetElement, setTargetElement] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const handleClose = () => {
    setOpenDeveloperOptionMenu(false);
    setSelectedElement(null);
  };
  const handleChangeCode = (value) => {
    setTargetElement(value);
  };
  const handleUpdateSourceCode = (e) => {
    setIsLoading(true);
    SaveActionToStack();
    setTimeout(() => {
      selectedElement.outerHTML = targetElement.toString();
      setSelectedElement(selectedElement);

      handleClose();
      ReRenderHtml();
    }, 10);
  };
  useEffect(() => {
    setTargetElement(selectedElement?.outerHTML);
    setIsLoading(false);
  }, [selectedElement]);

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ padding: 2, overflow: "hidden" }}
      >
        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Source Code</Typography>

          <Stack direction={"row-reverse"} spacing={2}>
            <Button disabled={isLoading} size="small" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              size="small"
              variant="contained"
              // disabled={!isChange}
              loading={isLoading}
              onClick={handleUpdateSourceCode}
              autoFocus
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
        <VSEditor
          defaultValue={targetElement}
          onChange={handleChangeCode}
          defaultLanguage={"html"}
        />
      </Stack>
    </>
  );
};

export default ElementSourceCode;
