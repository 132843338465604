import React from "react";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
const ImageTransformBar = ({ objectFit, handleChangeInClass, classesList }) => {
  return (
    <>
      <div class="mb-2">
        <div className="font-size-xsm mt-2">
          <Tooltip title={"Reset"} placement="top" arrow>
            <span
              className={objectFit ? "wb-icons-box" : "wb-icons-box-active"}
              onClick={() =>
                handleChangeInClass("objectFit", null, classesList)
              }
            >
              <Icon icon="ant-design:stop-outlined" />
            </span>
          </Tooltip>

          {classesList.map((cls, i) => (
            <Tooltip key={i} title={cls.label} placement="top" arrow>
              <span
                className={
                  objectFit === cls.value
                    ? "wb-icons-box-active"
                    : "wb-icons-box"
                }
                onClick={() =>
                  handleChangeInClass("objectFit", cls.value, classesList)
                }
              >
                <Icon icon={cls.icon_name} />
              </span>
            </Tooltip>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageTransformBar;
