import { invokeApi } from "../../bl_libs/invokeApi";
import { source_base_uri } from "../../config/config";

export const _get_active_helping_categories = () => {
  let requestObj = {
    path: "/api/helping_article_category/list_helping_article_category_active",
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_helping_articles = (id) => {
  let requestObj = {
    path: "/api/helping_article/list_helping_article_by_category/" + id,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
