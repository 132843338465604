import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import { Close, Done } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  typography: {
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(0.5),
    position: "relative",
  },
}));

export default function PopupMenu({
  value,
  isOpen,
  onChange,
  targetRef,
  togglePopup,
  children,
}) {
  const classes = useStyles();
  const [element, setElement] = useState({ clientX: 0, clientY: 0 });
  const handleClose = () => {
    togglePopup();
  };
  const handleWindowResize = () => {
    if (targetRef.current) {
      getCoordinates();
    }
  };
  window.onresize = () => {
    handleWindowResize();
  };
  const getCoordinates = () => {
    let rects = targetRef.current.getClientRects();
    let height = targetRef.current.clientHeight;
    height = parseInt(height);
    let x = rects[0].x;
    x = parseInt(x);
    let y = rects[0].y + height + 14;
    y = parseInt(y);
    setElement({ clientX: x, clientY: y });
    return { clientX: x, clientY: y };
  };
  useEffect(() => {
    getCoordinates();
  }, [isOpen]);

  return (
    <div className="d-flex  align-items-center">
      <Popover
        open={isOpen ? true : false}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: element.clientY, left: element.clientX }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography component="div" className={classes.typography}>
          {children}
        </Typography>
      </Popover>
    </div>
  );
}
// PopupMenu.propTypes = {
//   onChange: propTypes.func.isRequired,
//   togglePopup: propTypes.func.isRequired,
//   value: propTypes.any.isRequired,
//   targetRef: propTypes.any.isRequired,
//   isOpen: propTypes.bool.isRequired,
//   children: propTypes.any.isRequired,
// };
