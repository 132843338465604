import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { s3baseUrl } from "../config/config";
function CustomConfirmation({
  open,
  setOpen,
  handleAgree,
  title,
  CancelAction = null,
  IMG = null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
    if (CancelAction) {
      CancelAction(false);
    }
  };
  useEffect(() => {
    setIsLoading(false);
  }, [open]);

  let _multiple_hit = true;
  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle sx={{paddingBottom:0}}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {title ? title : "Are you sure you want to take this action?"}
            {IMG && (
              <img
                style={{
                  marginTop:5,
                  borderRadius: 10,
                  width: 130,
                  height: 130,
                  objectFit: "cover",
                }}
                src={s3baseUrl + "/" + IMG.file_path}
              />
            )}
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button  onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            autoFocus
            disableRipple
            onClick={() => {
              // setTimeout(() => {
              setIsLoading(true);
              handleAgree(_multiple_hit);
              if (_multiple_hit) {
                _multiple_hit = null;
              }
              // }, 1)
            }}
          >
            Yes, Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
