import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Icon } from "@iconify/react";

function MenuList({
  getSelectedSectionLists,
  handleReplaceListElements,
  handleClose,
}) {
  const [list, setList] = useState(getSelectedSectionLists());
  const [SectionsDataset, setSectionsDataset] = useState([]);

  const handleChange = (e, i) => {
    if (e.target.value == "custom") {
    }
    let data = list.data;
    data[i][e.target.name] = e.target.value;
    data[i]["target"] = "";
    setList({ ...list, data });
  };

  const handleChangeTarget = (value, i) => {
    let data = list.data;
    data[i]["target"] = value;
    setList({ ...list, data });
  };

  const findLinks = (link) => {
    let is_match = false;
    SectionsDataset.forEach((section) => {
      if (section.section_id === link) {
        is_match = true;
      }
    });
    return is_match;
  };
  const handleRemove = (i) => {
    let data = list.data.map((l) => l);
    data.splice(i, 1);
    setList({ ...list, data });
  };
  const get_preview_div_sections = () => {
    let datasets = [];
    const preview_div_sections = document.getElementById("preview-div");
    let sections = preview_div_sections.getElementsByTagName("section");
    for (let index = 0; index < sections.length; index++) {
      let section = sections.item(index);

      datasets.push({
        ...section.dataset,
        section_id: "#" + section.dataset.section_id,
        section_title: section.dataset.section_title
          ? section.dataset.section_title !== "undefined"
            ? section.dataset.section_title
            : index + 1
          : index + 1,
      });
    }
    setSectionsDataset(datasets);
  };
  const handleAddNew = () => {
    setList({ ...list, data: [...list.data, { text: "", link: "" }] });
  };
  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...list.data];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);

      setList({ ...list, data: newList });
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const create_class_list = (element, class_list = []) => {
    class_list.push({
      class_name: element[0].class_name,
      tag_name: element[0].main_element.tagName,
    });
    if (element[0].sub_elements.length > 0) {
      create_class_list(element[0].sub_elements, class_list);
    }
    return class_list;
  };

  const handleSave = () => {
    const main = create_class_list(list.list);

    let created_dom_array = [];

    let ul;
    let li;
    let a;

    const elements = list.data.map((data, index) => {
      //
      const element = main.map((el_info, i) => {
        if (i === 0 && index === 0) {
          ul = document.createElement(el_info.tag_name);
          ul.className = el_info.class_name;
        }

        if (i === 1) {
          li = document.createElement(el_info.tag_name);
          li.className = el_info.class_name;
          ul.appendChild(li);
        }

        if (i === 2) {
          a = document.createElement(el_info.tag_name);
          a.className = el_info.class_name.replace("active", "");
          a.href = data.link;
          a.target = data.target;
          a.innerText = data.text;
          li.appendChild(a);
        }
      });

      handleClose();
      return element;
    });

    const current_element = document.getElementsByClassName(
      main[0].class_name
    )[0];
    handleReplaceListElements(current_element, ul);
  };
  useEffect(() => {
    get_preview_div_sections();
  }, []);
  return (
    <Accordion className="card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Menu</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="dragdrop">
          <DragDropContext onDragEnd={(props) => handleDragEnd(props)}>
            <Droppable droppableId="doprable1">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {list.data.map((li, i) => {
                    return (
                      <Draggable
                        key={i}
                        draggableId={`dragable-${i}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`${
                              snapshot.isDragging ? "shadow" : null
                            } row border m-1 mt-2 pb-3 p-1 align-items-center`}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? "white" : null,
                            }}
                          >
                            <div className="col-12 mt-1">
                              <div className="row justify-content-end">
                                <span
                                  className="ms-auto d-inline-block"
                                  style={{ maxWidth: "2.5rem" }}
                                >
                                  <button
                                    type="button"
                                    className="btn  extra-small-btn p-1"
                                    onClick={() => handleRemove(i)}
                                  >
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Remove"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="red"
                                        className="bi bi-x-circle"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </Tooltip>
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <span>Title:</span>
                              <input
                                value={li.text}
                                onChange={(e) => handleChange(e, i)}
                                name="text"
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col-6 ">
                              <label htmlFor="selectLink">Choose Link:</label>
                              <select
                                onChange={(e) => handleChange(e, i)}
                                value={li.link}
                                name="link"
                                id="selectLink"
                                className=" form-control custom-icon-select form-control-sm"
                              >
                                <option value="">Custom Link</option>
                                {SectionsDataset &&
                                  SectionsDataset.map((dataset, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={dataset.section_id}
                                      >
                                        {dataset.section_title}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            {!findLinks(li.link) && (
                              <>
                                <div className="col-6">
                                  <div>
                                    <span>
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title="Link"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-link"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                        </svg>
                                      </Tooltip>
                                    </span>
                                    <div className="custom">
                                      <input
                                        value={li.link}
                                        onChange={(e) => handleChange(e, i)}
                                        name="link"
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 mt-1 pt-3">
                                  <Tooltip
                                    title={"Reset"}
                                    placement="top"
                                    arrow
                                  >
                                    <span
                                      className={
                                        li.target === ""
                                          ? "wb-icons-box-active"
                                          : "wb-icons-box"
                                      }
                                      onClick={(e) => handleChangeTarget("", i)}
                                    >
                                      <Icon icon="ant-design:stop-outlined" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title={"Open in new tab"}
                                    placement="top"
                                    arrow
                                  >
                                    <span
                                      onClick={(e) =>
                                        handleChangeTarget("_blank", i)
                                      }
                                      className={
                                        li.target === "_blank"
                                          ? "wb-icons-box-active"
                                          : "wb-icons-box"
                                      }
                                    >
                                      <Icon icon="ic:baseline-open-in-new" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="row">
            <div className="col-12 mt-2 text-center">
              {list.data.length > 0 && (
                <button
                  type="button"
                  className="btn btn-success me-3"
                  onClick={handleSave}
                >
                  Update
                </button>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddNew}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default MenuList;
