import React from 'react'
import { CircularProgress } from '@mui/material'

function Loader({ labe_value = null }) {
  return (
    <div className="centered">
      {/* <CircularProgress sx={{ marginLeft: 2 }} /> */}
      {/* <div class="loader"></div> */}
      <div class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
      <div className="col-12">{labe_value && <label>{labe_value}</label>}</div>
    </div>
  )
}

export default Loader
