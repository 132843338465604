import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { _s3upload } from "../../../../DAL/s3upload/s3upload";

import { TwitterPicker } from "react-color";
import CodeIcon from "@mui/icons-material/Code";

import Select from "react-select";
import {
  options,
  colors,
  color_classes,
  container_classes,
  bg_color_classes,
  text_color_classes,
  background_size_classes,
  background_repeat_classes,
  background_position_classes,
} from "../../../../ui_libs/Constants/constant";
import { s3baseUrl } from "../../../../config/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BackgroundColor,
  BackgroundImage,
  Layout,
  Margins,
  MenuList,
  Paddings,
  TextColor,
  VideoIframe,
  SectionTitle,
  FormMenu,
  MenuStyles,
  BackgroundSize,
  BackgroundRepeat,
  BackgorundPosition,
} from "./components";
import { Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

const container_layout_action_child = 1;
const background_image_action_child = 0;
const background_color_action_child = 0;
const text_color_action_child = 0;
const paddings_action_child = 0;
const margins_action_child = 0;

function RightSectionSettingsMenu(props) {
  const classes = useStyles();
  const {
    handleRemoveElement,
    rootValues,
    handleUpdateSectionSettings,
    getSelectedSectionStyle,
    getSelectedSectionheightwidth,
    updateSelectedSectionStyle,
    getSelectedSectionClass,
    updateSelectedSectionClass,
    getSelectedSectionLists,
    handleReplaceListElements,
    getSelectedSectionFrames,
    getSelectedSections,
    getSelectedSectionSettingId,
    getSelectedSectionDataset,
    updateSelectedSectionTitle,
    getSelectedSectionForm,
    updateSelectedElementClass,

    isSaveLoading,
    handleUpateCss,
    getIsDevState,
    handleOpenElementSourceCode,
  } = useBuilder();

  const [selectedSectionDataset, setSelectedSectionDataset] = useState();

  const { setSectionSettingsMenuOpen } = props;

  const [isLoadingBackgroundImage, setIsLoadingBackgroundImage] =
    useState(false);
  const [styles, setStyles] = useState({
    layout: "",
    backgroundColor: "",
    backgroundImage: "",
  });

  const [activeClasses, setActiveClasses] = useState({
    backgroundSize: "",
    BackgroudPosition: "",
  });

  const [controls, setControls] = useState({
    is_shown_background_color: false,
    is_shown_text_color: false,
  });

  const handleCancel = () => {
    setSectionSettingsMenuOpen(false);
  };

  const handleSave = () => {
    handleUpdateSectionSettings();
  };
  const handleRemove = () => {
    handleRemoveElement();
  };
  const [ImgData, setImgData] = useState({
    height: "",
    width: "",
  });
  const handleChangeInClass = (key, value, class_list) => {
    setActiveClasses({ ...activeClasses, [key]: value });
    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
  };
  const handleChangeInBackgroundSizeClass = (key, value, class_list) => {

    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
    setTimeout(() => {
      const val = "";
      const payload2 = {
        class_list: background_position_classes,
        value: val,
        child: 0,
      };
      updateSelectedSectionClass(payload2);
    }, 100);
    setActiveClasses({
      ...activeClasses,
      [key]: value,
      ["BackgroudPosition"]: "",
    });
  };

  const handleChangeInBackgroundPositionClass = (key, value, class_list) => {

    const payload = {
      class_list,
      value,
      child: 0,
    };
    updateSelectedSectionClass(payload);
    setTimeout(() => {
      const val = "background-size-auto";
      const payload2 = {
        class_list: background_size_classes,
        value: val,
        child: 0,
      };
      updateSelectedSectionClass(payload2);
    }, 100);
    setActiveClasses({
      ...activeClasses,
      [key]: value,
      ["backgroundSize"]: "background-size-auto",
    });
  };

  const handleRemoveBackgroundImage = (value) => {
    const child = background_image_action_child;
    handleUpdateStyleProperty("backgroundImage", value, child);
    setStyles({ ...styles, backgroundImage: "" });
  };

  const handleImageSelection = async (e, showupdatedimg) => {
    setIsLoadingBackgroundImage(true);
    const resp = await _s3upload(`${rootValues._id}`, e);
    if (resp.code === 200) {
      const image_url = resp.file_name.trim();
      const img = s3baseUrl + "/" + image_url.replace(" ", "%20");
      const child = background_image_action_child;
      handleUpdateStyleProperty("backgroundImage", `url("${img}")`, child);
      // handleUpdateStyleProperty("backgroundRepeat", `no-repeat`, child);
      // handleUpdateStyleProperty("backgroundSize", `cover`, child);
      // setStyles({ ...styles, backgroundImage: img });
      showupdatedimg();
      setIsLoadingBackgroundImage(false);
    }
  };

  // General handlers for setting and getting styles

  const handleUpdateStyleProperty = (property, value, child) => {
    setStyles({ ...styles, [property]: value });
    updateSelectedSectionStyle(property, value, child);
  };

  const handleGetStyleProperty = (property) => {
    const value = getSelectedSectionStyle(property, paddings_action_child);
    return value;
  };

  // Padding handlers for setting and getting styles

  const handleGetSpacingCustom = (property) => {
    const value =
      getSelectedSectionStyle(property, paddings_action_child).split("rem")[0] *
      16;
    return value ? value : 0;
  };
  const handleGetSpacingClass = (myproperty) => {
    const payload = {
      child: paddings_action_child,
      class_list: myproperty.class_list,
    };

    return getSelectedSectionClass(payload);
  };
  const handlegetbackgroundpositionclass = () => {
    const payload = {
      child: paddings_action_child,
      class_list: background_position_classes,
    };

    activeClasses.BackgroudPosition = getSelectedSectionClass(payload);
    return getSelectedSectionClass(payload);
  };

  const handleupdateSpacingClass = (property) => {
    const payload = {
      child: paddings_action_child,
      class_list: property.class_list,
      value: property.value,
    };
    updateSelectedSectionClass(payload);
  };

  const handleUpdateSpacingCustom = (property, val) => {
    const value = val ? val / 16 + "rem" : "0rem";
    updateSelectedSectionStyle(property, value, paddings_action_child);
  };

  const handleChangeLayout = (e) => {
    setStyles({ ...styles, layout: e.target.id });

    updateSelectedSectionClass({
      value: e.target.id,
      child: container_layout_action_child,
      class_list: container_classes,
    });
  };

  const handleChangeBackground = (e) => {
    setStyles({ ...styles, backgroundColor: e.hex });

    const color_class = bg_color_classes.find(
      (clr) => clr.color.toUpperCase() === e.hex.toUpperCase()
    );

    if (color_class) {
      const payload = {
        value: color_class.value,
        child: background_color_action_child,
        class_list: bg_color_classes,
      };
      updateSelectedSectionClass(payload);
    } else {
      // remove all important classes
      const payload = {
        value: "",
        child: background_color_action_child,
        class_list: bg_color_classes,
      };
      updateSelectedSectionClass(payload);
      // apply style inline
      handleUpdateStyleProperty(
        "backgroundColor",
        e.hex,
        background_color_action_child
      );
    }
  };

  const handleResetBackground = (e) => {
    // remove all important classes
    const payload = {
      value: "",
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty(
      "backgroundColor",
      "",
      background_color_action_child
    );
  };

  const handleChangeTextColor = (e) => {
    setStyles({ ...styles, textColor: e.hex });

    const color_class = text_color_classes.find(
      (clr) => clr.color.toUpperCase() === e.hex.toUpperCase()
    );

    if (color_class) {
      const payload = {
        value: color_class.value,
        child: text_color_action_child,
        class_list: text_color_classes,
      };
      updateSelectedSectionClass(payload);
    } else {
      // remove all important classes
      const payload = {
        value: "",
        child: text_color_action_child,
        class_list: text_color_classes,
      };
      updateSelectedSectionClass(payload);
      // apply style inline
      handleUpdateStyleProperty("color", e.hex, text_color_action_child);
    }
  };

  const handleResetTextColor = (e) => {
    // remove all important classes
    const payload = {
      value: "",
      child: text_color_action_child,
      class_list: text_color_classes,
    };
    updateSelectedSectionClass(payload);
    // apply style inline
    handleUpdateStyleProperty("textColor", "", text_color_action_child);
  };

  const handleSetLayout = () => {
    const layout = getSelectedSectionClass({
      child: container_layout_action_child,
      class_list: container_classes,
    });
    // const backgroundColor = handleGetStyleProperty(
    //   'backgroundColor',
    //   background_color_action_child,
    // )
    let payload = {
      // value: color_class.value,
      child: background_color_action_child,
      class_list: bg_color_classes,
    };
    let backgroundColor = getSelectedSectionClass(payload);

    if (backgroundColor) {
      bg_color_classes.forEach((color) => {
        if (backgroundColor == color.value) {
          backgroundColor = color.color;
        }
      });
    }
    payload = {
      // value: color_class.value,
      child: background_color_action_child,
      class_list: text_color_classes,
    };
    let textColor = getSelectedSectionClass(payload);

    if (textColor) {
      text_color_classes.forEach((color) => {
        if (textColor == color.value) {
          textColor = color.color;
        }
      });
    }

    const backgroundImage = handleGetStyleProperty(
      "backgroundImage",
      background_color_action_child
    );

    let getStyles = {};

    getStyles.layout = layout;

    if (backgroundColor) {
      getStyles.backgroundColor = backgroundColor;
    }
    if (textColor) {
      getStyles.textColor = textColor;
    }

    if (backgroundImage) {
      getStyles.backgroundImage = backgroundImage;
    }
    setStyles((c) => {
      return {
        ...c,
        ...getStyles,
      };
    });
  };
  const handleUpdateDatasetTitle = (newTitle) => {
    updateSelectedSectionTitle(newTitle);
  };
  const handleSelectedSectionDataset = () => {
    let dataset = getSelectedSectionDataset();
    setSelectedSectionDataset(dataset);
  };

  const getFormData = () => {
    const { form, status } = getSelectedSectionForm();
    return { form, status };
  };

  const handleGetClassByType = () => {
    const classes_data = {
      backgroundSize: getSelectedSectionClass({
        class_list: background_size_classes,
        child: 0,
      }),

      backgroundRepeat: getSelectedSectionClass({
        class_list: background_repeat_classes,
        child: 0,
      }),
      BackgroudPosition: handlegetbackgroundpositionclass(),
    };

    setActiveClasses(classes_data);
  };

  useEffect(() => {
    const value = getSelectedSectionheightwidth(paddings_action_child);
    ImgData.height = value.height;
    ImgData.width = value.width;
    handleSetLayout();
    handleSelectedSectionDataset();
    handleGetClassByType();
  }, []);

  return (
    <div className="p-3 pt-0" role="presentation">
      <div
        className="col-12 pt-2 mb-2 pb-2 border-bottom"
        style={{
          position: "sticky",
          zIndex: "100",
          background: "white",
          top: "0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h6 className="mb-0">Section Settings</h6>
        <div className="d-flex">
          <ElementSourceCodeEditorBar showJSIcon={true} />
          <Tooltip title="Close" position="bottom" arrow>
            <div className="icon me-2" onClick={handleCancel}>
              <i class="fa fa-xmark"></i>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="col-12 mb-2">
        {selectedSectionDataset && (
          <SectionTitle
            UpdateDatasetTitle={handleUpdateDatasetTitle}
            dataset={selectedSectionDataset}
          />
        )}
      </div>
      {getSelectedSectionLists().show_menu && (
        <div className="col-12 mb-2">
          <MenuList
            handleReplaceListElements={handleReplaceListElements}
            getSelectedSectionLists={getSelectedSectionLists}
            handleClose={handleCancel}
          />
        </div>
      )}

      {getSelectedSectionLists().show_menu && (
        <div className="col-12 mb-2">
          <MenuStyles />
        </div>
      )}
      {getSelectedSectionFrames().show_menu && (
        <div className="col-12 mb-2">
          <VideoIframe
            getSelectedSectionFrames={getSelectedSectionFrames}
            handleChangeLayout={handleChangeLayout}
          />
        </div>
      )}
      <div className="col-12 mb-2">
        <Layout styles={styles} handleChangeLayout={handleChangeLayout} />
      </div>

      <div className="col-12 mb-2">
        <Accordion className="card">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Section Background</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BackgroundImage
              ImgData={ImgData}
              backgroundImage={styles.backgroundImage}
              isLoadingBackgroundImage={isLoadingBackgroundImage}
              handleImageSelection={handleImageSelection}
              handleRemoveBackgroundImage={handleRemoveBackgroundImage}
            />
            <BackgroundSize
              value={
                activeClasses.backgroundSize
                  ? activeClasses.backgroundSize
                  : "background-size-auto"
              }
              handleChangeInClass={handleChangeInBackgroundSizeClass}
              // handleChangeInClass={handleChangeInClass}
              classesList={background_size_classes}
            />
            <BackgroundRepeat
              value={
                activeClasses.backgroundRepeat
                  ? activeClasses.backgroundRepeat
                  : "background-repeat-no-repeat"
              }
              handleChangeInClass={handleChangeInClass}
              classesList={background_repeat_classes}
            />
            <BackgorundPosition
              backgroundPosition={activeClasses.BackgroudPosition}
              handleChangeInClass={handleChangeInBackgroundPositionClass}
              classesList={background_position_classes}
            />
            <BackgroundColor
              styles={styles}
              handleChangeBackground={handleChangeBackground}
              handleResetBackground={handleResetBackground}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="col-12 mb-2">
        <TextColor
          styles={styles}
          handleChangeTextColor={handleChangeTextColor}
          handleResetTextColor={handleResetTextColor}
        />
      </div>

      <div className="col-12 mb-2">
        <Paddings
          handleGetPaddingClass={handleGetSpacingClass}
          handleupdatePaddingClass={handleupdateSpacingClass}
          handleGetPaddingCustom={handleGetSpacingCustom}
          handleUpdatePaddingCustom={handleUpdateSpacingCustom}
        />
      </div>

      <div className="col-12 mb-2">
        <Margins
          handleGetMarginClass={handleGetSpacingClass}
          handleupdateMarginClass={handleupdateSpacingClass}
          handleGetMarginCustom={handleGetSpacingCustom}
          handleUpdateMarginCustom={handleUpdateSpacingCustom}
        />
      </div>

      {getIsDevState() && (
        <div className="col-12">
          <div className="col-12 mb-2">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advance Setting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title="Update CSS" arrow placement="top">
                  <span
                    onClick={handleUpateCss}
                    className="wb-header-preview border-start"
                  >
                    <SyncIcon className="me-1" />
                    Update Css
                  </span>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}

      {/* {getFormData().status && (
        <div className="col-12 mb-2">
          <FormMenu
            getFormData={getFormData}
            handleChangeLayout={handleChangeLayout}
          />
        </div>
      )} */}

      {/* <div className="col-12 m-4 ">
        <button
          type="button"
          class="btn btn-secondary me-1"
          onClick={handleCancel}
        >
          Close
        </button>
      </div> */}
      <div className="col-12 text-right mt-5"></div>
    </div>
  );
}

export default RightSectionSettingsMenu;
