import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { s3baseUrl } from "../../../../config/config";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import SearchIcon from "@mui/icons-material/Search";
import MediaUploadDialog from "./MediaUploadDialog";

function GalleryDialog({
  galleryDialog,
  setGalleryDialog,
  uploadDialog,
  setUploadDialog,
  DeleteImageConfirmation,
  UpdateImageSrc = false,
}) {
  const {
    userImagesList,
    setUserImagesList,
    handleupdateIMG,
    setRightMenuOpen,
    selectedElement,
    SaveActionToStack,
    setSearchImageList,
    searchImageList,
  } = useBuilder();
  const { enqueueSnackbar } = useSnackbar();
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((p) => p.concat(imagesArray));
  };

  const handleClose = () => {
    setGalleryDialog(false);
    handleChangeSearch();
  };
  const handleDialogOpen = () => {
    setUploadDialog(true);
  };
  const handleClick = async (e) => {
    if (UpdateImageSrc) {
      await SaveActionToStack();
      selectedElement.src = e.target.src;
      setRightMenuOpen(false);
      handleClose();
    }
  };

  const handleChangeSearch = (e) => {
    if (e) {
      let value = e.target.value;
      let list = userImagesList;
      if (value.trim().length > 0) {
        list = [];
        userImagesList.map((image) => {
          if (image.file_name.toLowerCase().includes(value.toLowerCase())) {
            list.push(image);
          }
        });
      }
      setSearchImageList([...list]);
    } else {
      let list = userImagesList;
      setSearchImageList([...list]);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={galleryDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderTop: "5px solid #023373",
            },
          },
        }}
      >
        <div
          className="border-bottom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <DialogTitle>
            <Stack>Media Files</Stack>
          </DialogTitle>
          {UpdateImageSrc && (
            <DialogTitle>
              <Stack>Click image to select</Stack>
            </DialogTitle>
          )}
          <div
            className="icon"
            style={{ margin: "16px 24px" }}
            onClick={handleClose}
          >
            <i class="fa fa-xmark"></i>
          </div>
        </div>
        <div
          style={{
            height: "85vh",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#f0f4f7",
              width: "20%",
              overflow: "hidden",
            }}
          >
            <div style={{ padding: "30px" }}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#3899ec",
                  borderRadius: "30px",
                  padding: "3px 23px",
                  "&:hover": {
                    backgroundColor: "#4eb7f5",
                  },
                }}
                onClick={handleDialogOpen}
              >
                {/* <input
                  hidden
                  multiple
                  accept="image/*"
                  type="file"
                  onChange={onSelectFile}
                /> */}
                <i class="fa-solid fa-plus" style={{ fontSize: "8px" }}></i>
                <span
                  style={{
                    marginLeft: "0.5rem",
                    fontSize: "16px",
                    fontWeight: "lighter",
                  }}
                >
                  Upload Media
                </span>
              </Button>
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{ padding: "10px 24px 20px", width: "80%" }}
          >
            <div className="d-flex justify-content-between align-items-center pb-2">
              <h5 className="m-0">Images</h5>
              <div className="">
                <TextField
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      backgroundColor: "white",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "#bebebe" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  label={null}
                  variant="standard"
                  id="outlined-required"
                  placeholder="Search..."
                  // value={searchTemplates}
                  onChange={handleChangeSearch}
                />
              </div>
            </div>
            <div className="wb-image-block">
              {userImagesList &&
                searchImageList.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="wb-image col-4"
                      style={{
                        width: "161px",
                        height: "161px",
                        margin: "10px",
                      }}
                    >
                      {UpdateImageSrc && (
                        <Tooltip
                          title="Click on image to select"
                          arrow
                          placement="top"
                        >
                          <img
                            onClick={handleClick}
                            src={s3baseUrl + "/" + image.file_path}
                            style={{
                              width: "161px",
                              height: "161px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            alt="upload"
                          />
                        </Tooltip>
                      )}

                      {!UpdateImageSrc && (
                        <img
                          onClick={handleClick}
                          src={s3baseUrl + "/" + image.file_path}
                          style={{
                            width: "161px",
                            height: "161px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          alt="upload"
                        />
                      )}

                      {!UpdateImageSrc && (
                        <Tooltip title="Remove Image" placement="top" arrow>
                          <div
                            className="image-icon icon-delete"
                            style={{ top: "2%" }}
                            onClick={() => DeleteImageConfirmation(image)}
                          >
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <MediaUploadDialog
          uploadDialog={uploadDialog}
          setUploadDialog={setUploadDialog}
          userImagesList={userImagesList}
          setUserImagesList={setUserImagesList}
        />
      </Dialog>
    </>
  );
}

export default GalleryDialog;
