import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import {
  _get_user_design_details,
  _save_user_active_campaign,
} from '../../DAL/projects/projects'

import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Loader from '../../components/Loader/Loader'
import { _get_user, _set_user } from '../../DAL/localStorage/localStorage'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ActiveCampaign, CustomDomain, ProjectSettings } from './components'
import { useBuilder } from '../../hooks/builder-context/BuilderContext'

function WebsiteSettings() {
  const params = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [project, setProject] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [instanceIP, setInstanceIP] = useState('')

  const fetchProjectInfo = async () => {
    const project_id = params?.project_id
    const resp = await _get_user_design_details(project_id)
    if (resp.code === 200) {
      const project = resp.Project

      setProject(project)
      setInstanceIP(resp.instance_ip)
      // setTimeout(() => {
      setIsLoading(false)
      // }, 200);
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchProjectInfo()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1)
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>

          {/* <div className="col-12">
            <ActiveCampaign project={project} />
          </div> */}

          <div className="col-12">
            <CustomDomain project={project} instanceIP={instanceIP} />
          </div>
        </div>
      </div>
    </>
  )
}

export default WebsiteSettings
