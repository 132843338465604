import { Icon } from "@iconify/react";
import { Stack, Tooltip } from "@mui/material";
import React from "react";
import { _is_dev } from "../../DAL/localStorage/localStorage";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";

const ElementSourceCodeEditorBar = ({
  showHTMLIcon = true,
  showCSSIcon = true,
  showJSIcon = false,
}) => {
  const {
    handleOpenElementSourceCode,
    handleOpenCustomClassMenu,
    handleOpenCustomJSMenu,
  } = useBuilder();
  return (
    <>
      {_is_dev() && (
        <div className="d-flex">
          {showHTMLIcon && (
            <Tooltip title="Customize HTML" placement="bottom" arrow>
              <div
                className="icon icon-delete me-2"
                onClick={handleOpenElementSourceCode}
              >
                <Icon
                  style={{ fontSize: 23, fontWeight: "bold" }}
                  icon="material-symbols:html"
                />
              </div>
            </Tooltip>
          )}
          {showCSSIcon && (
            <Tooltip title="Customize CSS" placement="bottom" arrow>
              <div
                className="icon icon-delete me-2"
                onClick={handleOpenCustomClassMenu}
              >
                <Icon
                  style={{ fontSize: 23, fontWeight: "bold" }}
                  icon="ic:sharp-css"
                />
              </div>
            </Tooltip>
          )}
          {showJSIcon && (
            <Tooltip title="Customize JS" placement="bottom" arrow>
              <div
                className="icon icon-delete me-2"
                onClick={handleOpenCustomJSMenu}
              >
                <Icon
                  style={{ fontSize: 23, fontWeight: "bold" }}
                  icon="ic:sharp-javascript"
                />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

export default ElementSourceCodeEditorBar;
