import React, { useEffect, useState } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import {
  padding_classes,
  margin_classes,
} from "../../../../ui_libs/Constants/constant";
function CustomBorder({
  updateSelectedElementSpacing,
  updateSelectedElementClass,
  getSelectedElementClassByType,
  getSelectedElementStyleByProperty,
  spacing_type,
  label,
  dimention,
  myclass,
  ResetValue,
}) {
  // reset
  // classes
  const [ClassValue, setClassValue] = useState("");
  // custom  vlaues
  const [CustomValue, setCustomValue] = useState({
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  });
  // check display custom values or  classes
  const [ShowCustomValue, setShowCustomValue] = useState(false);
  const [Spacing_Type, setSpacing_Type] = useState("");

  const handleChangeInClass = (key, value, class_list) => {
    // if user select  custom
    if (value == "custom" || value == "select_custom") {
      CustomValue.top = 0;
      CustomValue.left = 0;
      CustomValue.right = 0;
      CustomValue.bottom = 0;
      setShowCustomValue(true);
      setClassValue("custom");
      value = "";
      const payload = {
        class_list,
        value,
      };
      updateSelectedElementClass(payload);
    }
    // if select class value
    else {
      setShowCustomValue(false);

      handleCustomChange("border-top-width", "top", "");
      handleCustomChange("border-bottom-width", "top", "");
      handleCustomChange("border-left-width", "top", "");
      handleCustomChange("border-right-width", "top", "");
      setClassValue(value);
      const payload = {
        class_list,
        value,
      };
      updateSelectedElementClass(payload);
    }
  };

  const handleCustomChange = (label, name, value) => {
    setCustomValue({ ...CustomValue, [name]: value });
    const px_to_rem = value / 16;
    updateSelectedElementSpacing(label, name, px_to_rem);
  };

  useEffect(() => {
    let classval = getSelectedElementClassByType({
      class_list: myclass(label),
    });
    setClassValue(classval);
    // initialize custom values
    // top
    let customval = getSelectedElementStyleByProperty({
      property: "border-top-width",
    });
    // let convertion_to_px =
    //   customval == undefined || customval == ""
    //     ? "0"
    //     : customval.replace("rem", "") * 16;

    CustomValue.top = customval;

    //   bottom
    customval = getSelectedElementStyleByProperty({
      property: "border-bottom-width",
    });
    CustomValue.bottom = customval;

    //   left
    customval = getSelectedElementStyleByProperty({
      property: "border-left-width",
    });
    CustomValue.left = customval;

    //   right
    customval = getSelectedElementStyleByProperty({
      property: "border-right-width",
    });
    CustomValue.right = customval;
    console.log("valuevalue", classval, CustomValue);
    if (
      !classval &&
      (CustomValue.top != 0 ||
        CustomValue.bottom != 0 ||
        CustomValue.left != 0 ||
        CustomValue.right != 0)
    ) {
      setShowCustomValue(true);
      setClassValue("custom");
    } else {
      setShowCustomValue(false);
    }
  }, [ResetValue]);

  return (
    <>
      {/* border top */}
      <div className="col mt-1 mb-2">
        <select
          className="w-100 form-control custom-icon-select"
          value={parseInt(CustomValue) > 0 ? "select_custom" : ClassValue}
          onChange={(e) =>
            handleChangeInClass(label, e.target.value, myclass())
          }
        >
          <option value="">Reset</option>
          {myclass(label).map((fs, i) => (
            <option key={i} value={fs.value}>
              {fs.label}
            </option>
          ))}

          <option value="custom">Custom</option>
        </select>
      </div>

      {ShowCustomValue && (
        <div className="row">
          {/* border top */}
          <div className="col">
            <label htmlFor="" className="custom-label">
              Top:
            </label>
            <input
              type="number"
              onChange={(e) =>
                handleCustomChange(
                  "border-top-width",
                  e.target.name,
                  e.target.value
                )
              }
              name="top"
              value={CustomValue.top}
              className="form-control form-control-sm "
            />
          </div>

          {/* border bottom */}
          <div className="col">
            <label htmlFor="" className="custom-label">
              Bottom:
            </label>
            <input
              type="number"
              onChange={(e) =>
                handleCustomChange(
                  "border-bottom-width",
                  e.target.name,
                  e.target.value
                )
              }
              name="bottom"
              value={CustomValue.bottom}
              className="form-control form-control-sm "
            />
          </div>

          {/* border left */}
          <div className="col">
            <label htmlFor="" className="custom-label">
              Left:
            </label>
            <input
              type="number"
              onChange={(e) =>
                handleCustomChange(
                  "border-left-width",
                  e.target.name,
                  e.target.value
                )
              }
              name="left"
              value={CustomValue.left}
              className="form-control form-control-sm "
            />
          </div>
          {/* border right */}
          <div className="col">
            <label htmlFor="" className="custom-label">
              Right:
            </label>
            <input
              type="number"
              onChange={(e) =>
                handleCustomChange(
                  "border-right-width",
                  e.target.name,
                  e.target.value
                )
              }
              name="right"
              value={CustomValue.right}
              className="form-control form-control-sm "
            />
          </div>
        </div>
      )}
    </>
  );
}
export default CustomBorder;
