import React, { useEffect, useState } from 'react'
import { useBuilder } from '../../../../../../hooks/builder-context/BuilderContext'
import { makeStyles } from '@mui/styles'
import { TwitterPicker } from 'react-color'

import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Tooltip } from '@mui/material'
const useStyles = makeStyles({
  previewIcon: {
    height: '180px',
    backgroundColor: '#eeebebf',
  },
  iTag: {
    alignSelf: 'center',
  },
  btn: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
function ButtonProperty({
  colors,
  handleChangeProperty,
  property,
  ResetProperty,
  GetProperty,
  label,
}) {
  const { rootValues } = useBuilder()
  const styleClasses = useStyles()
  const [ColorList, setColorList] = useState(colors)

  useEffect(() => {
    let value = GetProperty(property)
    setvalue(value)
    AppendThemeValues()
  }, [])
  const AppendThemeValues = () => {
    if (rootValues && rootValues.secondary_color) {
      let myset = new Set()
      let color = [
        // rootValues.primary_color,
        rootValues.secondary_color,
        ...colors,
      ]
      color.forEach((val) => {
        myset.add(val)
      })

      setColorList([...myset])
    }
  }
  const [TooltipValue, setTooltipValue] = useState('')
  // pre-defined classes values
  const [value, setvalue] = useState('')
  return (
    <div className="col-12 mb-3">
      <div className="row">
        <div className="col">
          <label className="form-label custom-label">{label} :</label>
        </div>
        <div className="col d-flex justify-content-end">
          <Tooltip title={`Reset ${label}`} arrow placement="top">
            <span>
              <RotateLeftIcon
                className={styleClasses.btn}
                onClick={() => {
                  setvalue(ResetProperty(property))
                }}
              />
            </span>
          </Tooltip>
        </div>
      </div>

      <TwitterPicker
        colors={ColorList}
        width="100%"
        onChange={(e) => {
          handleChangeProperty(property, e.hex)
          setvalue(e.hex)
        }}
        color={value}
      />
    </div>
  )
}

export default ButtonProperty
