/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/lab/Autocomplete";
import { Autocomplete, Tooltip } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  selected: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#0d85e734",
      // backgroundColor: "#ff0b4424",
      color: "black",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#0d85e7",
      // backgroundColor: "#fa2356",
      color: "white",
    },
    fontSize: ".875rem",
    "& > span": {
      marginRight: 50,
      fontSize: "0.875rem",
    },
  },
});
export default function MuiSelectClasses({ data, handleChange, value, label }) {
  const [prevFamily, setPrevFamily] = useState("");
  const [isFamilyEmpty, setIsFamilyEmpty] = useState(true);
  const [AutocompleteValue, setAutocompleteValue] = React.useState(data[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const curFamily = useRef();
  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };
  const handleOnChange = (event, newValue) => {
    if (newValue === null || undefined) return;
    curFamily.current = newValue;
    handleChange("fontFamily", newValue.value, data);
    setAutocompleteValue(newValue);
    setPrevFamily(newValue.value);
  };
  const handleHighlightChange = (e, newValue) => {
    if (!newValue || newValue == "" || newValue == undefined) return;
    if (newValue.value !== prevFamily) {
      handleChange("fontFamily", newValue.value, data);
    }
  };
  const isInvalid = () => {
    let invalid = false;
    if (curFamily.current === undefined || !curFamily.current) {
      invalid = true;
    }
    return invalid;
  };
  const handleClose = () => {
    if (!isInvalid()) {
      handleChange("fontFamily", curFamily.current.value, data);
    }
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleBlur = (e) => {
    if (!isInvalid()) {
      handleChange("fontFamily", curFamily.current.value, data);
    }

    setOpen(false);
  };
  const handleMouseEnter = (value) => {
    // handleChange("fontFamily", value, data);
  };
  const handleMouseLeave = () => {
    // handleChange("fontFamily", prevFamily, data);
  };
  const setSelectedFontFamily = () => {
    data.map((family, i) => {
      if (family.value == value) {
        setAutocompleteValue(data[i]);
        curFamily.current = data[i];
      }
    });
  };
  useEffect(() => {
    if (value != "") {
      setIsFamilyEmpty(false);
    }
  }, [value]);
  useEffect(() => {
    if (!isFamilyEmpty) {
      setSelectedFontFamily();
      setPrevFamily(value);
    }
  }, [isFamilyEmpty]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <label className="form-label custom-label">Font Family</label>
        <Tooltip title="Reset font family" arrow placement="top">
          <span>
            <RotateLeftIcon
              className="pointer"
              onClick={() => {
                handleChange("fontFamily", "", data);
                setAutocompleteValue(data[0]);
              }}
            />
          </span>
        </Tooltip>
      </div>
      <Autocomplete
        autoComplete="off"
        blurOnSelect
        open={open}
        onOpen={handleOpen}
        onBlur={handleBlur}
        onClose={handleClose}
        inputValue={inputValue}
        value={AutocompleteValue}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue);
        }}
        onHighlightChange={(e, newValue) => handleHighlightChange(e, newValue)}
        onInputChange={(event, newValue) => handleInputChange(event, newValue)}
        id="family-select"
        color="secondary"
        size="small"
        options={data}
        classes={{
          option: classes.selected,
        }}
        className="familySelect"
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              value={option.value}
              onMouseEnter={(e) =>
                handleMouseEnter(e.target.getAttribute("value"))
              }
              onMouseLeave={() => handleMouseLeave()}
              className={`${option.value} ${props.className} auto-complete-option`}
            >
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            //   onHighlightChange={(e) => console.log(e)}
            className="familySelect"
            size="small"
            color="secondary"
            classes={{ root: classes.selected }}
            {...params}
            label={label ? label : "untitled"}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
}
