import { source_base_uri } from "../../config/config";
import { invokeApi } from "../../bl_libs/invokeApi";

export const _section_type = () => {
  let requestObj = {
    path: "/api/section_type/list_active_section_type",
    base_url: source_base_uri,
    method: "GET",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _sections_list_against_type = (postData) => {
  let requestObj = {
    path: "/api/section/list_section_by_category",
    base_url: source_base_uri,
    method: "POST",
    headers: {
      "x-sh-auth": sessionStorage.getItem("token"),
    },
    postData,
  };

  return invokeApi(requestObj);
};
