import React, { useEffect, useState, useLayoutEffect } from "react";
import { useBuilder } from "../../../../hooks/builder-context/BuilderContext";
import { Close, Done } from "@mui/icons-material";
import { RowBlockClasses } from "./components/Row-Block-Classes/RowBlockClasses";
import { RowChildBlockClasses } from "./components/Row-Child-Block-Classes/RowChildBlockClasses";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CodeIcon from "@mui/icons-material/Code";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import InlineInputField from "../Right-Menu/components/Inline-Input-Field/InlineInputField";
import CustomSpacing from "../Custom-Spacing/CustomSpacing";
import { RowColumnStyle } from "./components/Row-Column-Style/RowColumnStyle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { RowDivManipulation } from "./components/Row-Div-Manipulation/RowDivManipulation";
import RowColumnPadding from "./components/Row-Column-Padding/RowColumnPadding";
import {
  justify_content_classes,
  align_items_classes,
} from "../../../../ui_libs/Constants/constant";
import GenerateGradient from "../../../../components/Generate-Gradient/GenerateGradient";
import ElementSourceCodeEditorBar from "../../../../components/Element-Source-Code-Editor-Bar/ElementSourceCodeEditorBar";
const useStyles = makeStyles({
  previewIcon: {
    height: "180px",
    backgroundColor: "#eeebebf",
  },
  iTag: {
    alignSelf: "center",
  },
  btn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});
function RowSettingMenu() {
  const styleClasses = useStyles();
  const {
    SelectedRow,
    setSelectedRow,
    SelectedRowClasses,
    OpenRowSetting,
    setOpenRowSetting,
    ReRenderHtml,
    getIsDevState,
    handleOpenElementSourceCode,
  } = useBuilder();

  const [column_classes_list, setcolumn_classes_list] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [show, setshow] = useState(false);
  const [ClassChange, setClassChange] = useState(false);
  const [EditColumnName, setEditColumnName] = useState("");
  const [EditColumnIndex, setEditColumnIndex] = useState(null);

  const [justify_value, setjustify_value] = useState("");
  const [align_items_value, setalign_items_value] = useState("");
  const [Form, setForm] = useState("");
  const [ShowForm, setShowForm] = useState(false);
  const [FormChild, setFormChild] = useState([]);
  // row ad row reverse class
  const [Row, setRow] = useState([
    { value: "row", label: "Default" },
    { value: "flex-row-reverse", label: "Reverse" },
    {
      value: "flex-column-reverse",
      label: "Reverse on Mobile",
      extra_class: "flex-sm-row-reverse",
    },
  ]);
  const [row_value, setrow_value] = useState("");

  // children of selected row
  const [Children, setChildren] = useState([]);
  const [Column_classes, setColumn_classes] = useState([]);

  // column media quries list

  const handleRemoveRow = () => {
    SelectedRow.remove();
    setOpenRowSetting(false);
  };
  const Check_Class_Available = (row_class_list, searching_list) => {
    let classname = "";
    searching_list.forEach((cl) => {
      row_class_list.forEach((c) => {
        if (c === cl.value || c === cl?.extra_class) {
          classname = c;
        }
      });
    });
    return classname;
  };
  const getColumnproperty = (name, index) => {
    let property = Children[index].children[0].style[name];
    return property ? property : "";
  };
  const getRowProperty = (name) => {
    let property = SelectedRow.style[name];
    return property ? property : "";
  };
  const removeRowProperty = (name) => {
    SelectedRow.style.removeProperty(name);
    setClassChange(!ClassChange);
  };
  const handleChnageRowStyle = (property, value) => {
    SelectedRow.style.setProperty(property, value);
    setClassChange(!ClassChange);
  };

  const ResetColumnproperty = (name, index) => {
    Children[index].children[0].style.removeProperty(name);
  };
  const ResetColumnBackgroundColor = (name, index) => {
    Children[index].children[0].style.removeProperty(name);
  };

  const handleChangeRowClass = (ClassName, classList) => {
    // removing relevent classes
    SelectedRow.classList.remove("flex-row-reverse");
    SelectedRow.classList.remove("flex-sm-row-reverse");
    classList.map((cl) => {
      SelectedRow.classList.remove(cl.value);
    });

    if (
      ClassName === "flex-row-reverse" ||
      ClassName === "flex-column-reverse"
    ) {
      SelectedRow.classList.add("row");
      if (ClassName === "flex-column-reverse") {
        SelectedRow.classList.add("flex-md-row");
        CheckColumnValueonMobile(ClassName);
        return;
      }
    }
    SelectedRow.classList.add(ClassName);
    setClassChange(!ClassChange);
  };
  const CheckColumnValueonMobile = (ClassName) => {
    if (
      Column_classes &&
      Column_classes.length > 0 &&
      (Column_classes[0].sm === "col-12" ||
        Column_classes[0].sm === "col-sm-12")
    ) {
      SelectedRow.classList.add(ClassName);
    } else {
      SelectedRow.classList.add("flex-sm-row-reverse");
    }
    setClassChange(!ClassChange);
  };

  const Get_column_Class = (classlist, name) => {
    let classname = "";
    for (let a = 0; a < classlist.length; a++) {
      let list = classlist[a].split("-");
      if (list[0] == "col" && list[1] == name) {
        classname = classlist[a];
      }
    }

    return classname;
  };

  // get and column spacing  handlers
  const handleGetRowCustomSpace = (property) => {
    let space = "";
    if (Children && Children[0] && Children[0].style) {
      space = Children[0].style[property.property];
    }

    return space ? space : "";
  };
  const handleGetRowSpaceClass = (property) => {
    let class_list = property.class_list;
    let answer = "";
    if (Children && Children[0] && Children[0].classList) {
      answer = Check_Class_Available(Children[0].classList, class_list);
    }
    return answer ? answer : "";
  };

  const handleUpdateRowCustomSpace = (property, dimention, value) => {
    Children.forEach((column) => {
      if (value > 0) {
        column.style.setProperty(
          `${property + "-" + dimention}`,
          `${value}rem`,
          "important"
        );
      } else {
        column.style.removeProperty(`${property + "-" + dimention}`);
      }
    });
    setClassChange(!ClassChange);
  };
  const handleChangeColumnBlockStyle = (property, value, index) => {
    Children[index].children[0].style.setProperty(property, value);
    setClassChange(!ClassChange);
  };
  const handleChangeColumnBackgroundColor = (property, value, index) => {
    Children[index].children[0].style.setProperty(property, value);
    setClassChange(!ClassChange);
  };

  const handleUpdateRowSpaceClass = (property) => {
    let class_list = property.class_list;

    Children.forEach((column) => {
      if (property.value == "") {
        let answer = Check_Class_Available(column.classList, class_list);
        if (answer) {
          column.classList.remove(answer);
        }
      } else {
        let answer = Check_Class_Available(column.classList, class_list);
        if (answer != "") {
          column.classList.remove(answer);
        }
        column.classList.add(property.value);
      }
    });
    setClassChange(!ClassChange);
  };

  const handleChangeColumnClass = (value, index, prev_class) => {
    let columns = SelectedRow.children;
    if (prev_class == "") {
      columns[index].classList.add(value);
    } else if (value == "") {
      columns[index].classList.remove(prev_class);
    } else {
      columns[index].classList.remove(prev_class);
      columns[index].classList.add(value)
    }
    setClassChange(!ClassChange);
  };
  const handleRemoveColumn = (index) => {
    let column_list = SelectedRow.children;
    column_list[index].remove();
    setClassChange(!ClassChange);
  };

  const handleMoveup = (source) => {
    let destination = source - 1;
    if (source > 0) {
      let newList = [...Children];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setClassChange(!ClassChange);
    }
  };
  const handleMovedown = (source) => {
    let destination = source + 1;
    if (source < Children.length) {
      let newList = [...Children];
      newList.splice(destination, 0, newList.splice(source, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setClassChange(!ClassChange);
    }
  };

  const handleDuplicate = (source) => {
    let destination = source + 1;
    let newList = [...Children];
    newList.splice(destination, 0, newList[source].cloneNode(true));
    newList[destination].setAttribute("block-name", `Block-${newList.length}`);
    let newdata = "";
    for (let i = 0; i < newList.length; i++) {
      newdata += newList[i].outerHTML;
    }
    SelectedRow.innerHTML = newdata;
    ReRenderHtml();
    setClassChange(!ClassChange);
  };

  const handleEditBlockName = (value, index) => {
    setEditColumnName(value);
    setEditColumnIndex(index);
  };

  const handleDoneEditColumnName = (index) => {
    Children[index].setAttribute("block-name", EditColumnName);
    setEditColumnIndex(null);
    setClassChange(!ClassChange);
  };

  const handleDragEnd = ({ source, destination, ...rest }) => {
    if (source && destination && source.index != destination.index) {
      let newList = [...Children];
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      let newdata = "";
      for (let i = 0; i < newList.length; i++) {
        newdata += newList[i].outerHTML;
      }
      SelectedRow.innerHTML = newdata;
      ReRenderHtml();
      setClassChange(!ClassChange);
    } else {
      console.log("ERROR", source, destination);
    }
  };
  const handleAddNewColumn = () => {
    let list = SelectedRow.children;
    let newcol = list[0].outerHTML;

    SelectedRow.innerHTML += newcol;
    setClassChange(!ClassChange);
  };
  const margin_classes = (direction) => {
    if (direction === "bottom") {
      const arr = column_classes_list.map((space) => {
        return { value: "wp-mb-" + space, label: space };
      });
      return arr;
    }
  };
  const Get_Column_Block_Classes = () => {
    let child = SelectedRow.children;
    let class_detail = [];
    for (let a = 0; a < child.length; a++) {
      let sm = "";
      let md = "";
      let lg = "";
      let temp = "";
      child[a].getAttribute("block-name")
        ? child[a].getAttribute("block-name")
        : child[a].setAttribute("block-name", `Block-${a + 1}`);
      let count = -1;
      let val = "col" + a;
      let list = child[a].classList;
      for (let index = 0; index < list.length; index++) {
        if (list[index].split("-")[1]) {
          if (
            list[index].split("-")[0] == "col" &&
            list[index].split("-")[1] > 0 &&
            list[index].split("-")[1] <= 12
          ) {
            sm = list[index];
          }
        }
      }
      if (sm == "") {
        child[a].classList.add("col-12");
        sm = "col-12";
        temp = "12";
      } else {
        temp = sm.split("-")[2] ? sm.split("-")[2] : sm.split("-")[1];
      }

      count = child[a].className.search("col-md");
      if (count >= 0) {
        md = Get_column_Class(child[a].classList, "md");
        temp = md.split("-")[2];
      } else {
        md = "col-md-" + temp;
      }

      count = child[a].className.search("col-lg");
      if (count >= 0) {
        lg = Get_column_Class(child[a].classList, "lg");
      } else {
        lg = "col-lg-" + temp;
      }

      class_detail.push({ sm, md, lg });
    }
    setColumn_classes([...class_detail]);
  };

  const Get_Row_Block_Classes = () => {
    // get justify content classes available in selected row or not
    let answer = Check_Class_Available(
      SelectedRow.classList,
      justify_content_classes
    );
    setjustify_value(answer);

    // get align item classes available in selected row or not
    let answer2 = Check_Class_Available(
      SelectedRow.classList,
      align_items_classes
    );
    setalign_items_value(answer2);

    // get row classes available in selected row or not
    let answer3 = Check_Class_Available(SelectedRow.classList, Row);
    setrow_value(answer3);
  };

  const getproperty = (property) => {
    let computedStyles = window.getComputedStyle(Children[index]);
    let color = computedStyles.getPropertyValue(property);
    return color;
  };
  const handleChangeColumnBlockClass = (value, class_list, index) => {
    // let column_class_list = Children[index].classList;

    class_list.forEach((c) => {
      Children[index].children[0].classList.remove(c.value);
    });
    if (value) {
      Children[index].children[0].classList.add(value);
    }

    setClassChange(!ClassChange);
  };
  const handlegetColumnBlockClass = (class_list, index) => {
    let classname = "";

    class_list.forEach((c) => {
      if (Children[index].children[0].classList.contains(c.value)) {
        classname = c.value;
      }
    });

    return classname;
  };
  const getRowForm = () => {
    let form = SelectedRow.getElementsByTagName("form");
    if (form.length === 0) {
      setShowForm(false);
    }
    if (SelectedRow.getElementsByTagName("input").length > 0) {
      const data = SelectedRow.getElementsByTagName("input");
      const textarea = SelectedRow.getElementsByTagName("textarea");
      setForm(form[0]);
      setFormChild([...data, ...textarea]);
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  };
  const handleChangeInputProperty = (item, property, val) => {
    if (property == "name") {
      if (val == "email") {
        item.type = "email";
      } else if (val == "phone") {
        item.type = "number";
      } else {
        {
          item.type = "text";
        }
      }
    }
    item[property] = val;
    getRowForm();
  };

  const CheckBoxClassAvailable = () => {
    let class_result = false;
    const result = SelectedRow.querySelectorAll(".wb-box");
    console.log(result, "resultresultresultresult");
    if (result.length) {
      class_result = true;
    }

    return class_result;
  };
  const RemoveRowBlock = () => {
    if (
      SelectedRow &&
      SelectedRow.children &&
      SelectedRow.children.length > 0 &&
      SelectedRow.children[0].classList &&
      SelectedRow.children[0].classList.contains("wp-row-block")
    ) {
      SelectedRow.children[0].remove();
    }
  };
  useEffect(() => {
    getRowForm();
    RemoveRowBlock();
    setChildren([...SelectedRow.children]);

    // get column-block classses
    Get_Column_Block_Classes();

    // get row-block-classes
    Get_Row_Block_Classes();

    setshow(true);
  }, [ClassChange]);

  useLayoutEffect(() => {
    // removing block contain row text and setting icon
    if (SelectedRow.firstChild.className === "wp-row-block") {
      SelectedRow.firstChild.remove();
    }
  }, []);

  return (
    <div className="p-3 mb-5 pt-0" role="presentation">
      {show && (
        <div>
          <div
            className="col-12 pt-2 mb-2 pb-2 border-bottom"
            style={{
              position: "sticky",
              zIndex: "100",
              background: "white",
              top: "0%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mb-0">Row Settings</h6>
            <div style={{ display: "flex" }}>
              <Tooltip title="Remove Row" placement="bottom" arrow>
                <div
                  className="icon icon-delete me-2"
                  onClick={handleRemoveRow}
                >
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </Tooltip>
              <ElementSourceCodeEditorBar />
              <Tooltip title="Close" placement="bottom" arrow>
                <div
                  className="icon me-2"
                  onClick={() => setOpenRowSetting(false)}
                >
                  <i class="fa fa-xmark"></i>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="col-12">
            <div className="col-12 mb-2">
              <Accordion className="card">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Row-Block</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RowBlockClasses
                    // justify content
                    justify_content={justify_content_classes}
                    justify_value={justify_value}
                    // align items
                    align_items={align_items_classes}
                    align_items_value={align_items_value}
                    // row and row reverse classes
                    row={Row}
                    row_value={row_value}
                    // handler
                    handleChangeRowClass={handleChangeRowClass}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="col-12">
            <div className="col-12 mb-2">
              <Accordion className="card">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Row-Column</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RowChildBlockClasses
                    Children={Children}
                    EditColumnIndex={EditColumnIndex}
                    setEditColumnIndex={setEditColumnIndex}
                    EditColumnName={EditColumnName}
                    setEditColumnName={setEditColumnName}
                    Column_classes={Column_classes}
                    column_classes_list={column_classes_list}
                    handleChangeColumnClass={handleChangeColumnClass}
                    handleDragEnd={handleDragEnd}
                    handleMoveup={handleMoveup}
                    handleMovedown={handleMovedown}
                    handleDuplicate={handleDuplicate}
                    handleRemoveColumn={handleRemoveColumn}
                    handleDoneEditColumnName={handleDoneEditColumnName}
                    handleEditBlockName={handleEditBlockName}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          {CheckBoxClassAvailable() && (
            <div className="col-12">
              <div className="col-12 mb-2">
                <Accordion className="card">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Column-Style</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RowColumnStyle
                      Children={Children}
                      ChangeColumnBlockStyle={handleChangeColumnBlockStyle}
                      ChangeColumnBackgroundColor={
                        handleChangeColumnBackgroundColor
                      }
                      getColumnproperty={getColumnproperty}
                      ResetColumnproperty={ResetColumnproperty}
                      ResetColumnBackgroundColor={ResetColumnBackgroundColor}
                      ChangeColumnBlockClass={handleChangeColumnBlockClass}
                      getColumnBlockClass={handlegetColumnBlockClass}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          )}

          {/* margin bottom in column */}
          <div className="col-12">
            <div className="col-12 mb-2">
              <Accordion className="card">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Column Spacing</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RowColumnPadding
                    ResetValue={"an"}
                    padding_classes={margin_classes}
                    updateSelectedElementSpacing={handleUpdateRowCustomSpace}
                    updateSelectedElementClass={handleUpdateRowSpaceClass}
                    getSelectedElementClassByType={handleGetRowSpaceClass}
                    getSelectedElementStyleByProperty={handleGetRowCustomSpace}
                  />

                  {/* margin bottom  */}
                  <div className="col">
                    <Typography variant="body2">Margin</Typography>
                  </div>
                  <CustomSpacing
                    ResetValue={"an"}
                    myclass={margin_classes}
                    updateSelectedElementSpacing={handleUpdateRowCustomSpace}
                    updateSelectedElementClass={handleUpdateRowSpaceClass}
                    getSelectedElementClassByType={handleGetRowSpaceClass}
                    getSelectedElementStyleByProperty={handleGetRowCustomSpace}
                    spacing_type="margin"
                    dimention="margin-bottom"
                    label="bottom"
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {getIsDevState() && (
            <GenerateGradient
              handleAddGradient={handleChnageRowStyle}
              getColumnproperty={getRowProperty}
              removeColumnproperty={removeRowProperty}
            />
          )}

          {/*Form Menu */}
          {/* {ShowForm && (
            <div className="col-12">
              <div className="col-12 mb-2">
                <Accordion className="card">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Form Setting</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {FormChild.map((item, index) => (
                      <div className="mb-2">
                        <Accordion className="card">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{`${item.tagName} ${
                              index + 1
                            }`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="col-12" key={index}>
                              <div className="col-12 mb-3">
                                <label className="mb-1" htmlFor="">
                                  Placeholder:
                                </label>

                                <InlineInputField
                                  placeholder="Field Placeholder"
                                  value={item.placeholder}
                                  handleUpdate={(val) =>
                                    handleChangeInputProperty(
                                      item,
                                      "placeholder",
                                      val
                                    )
                                  }
                                />
                              </div>
                              <div className="col-12 ">
                                <label className="mb-1" htmlFor="">
                                  Input For:
                                </label>
                                <select
                                  className=" form-control custom-icon-select"
                                  name="name"
                                  id="name"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleChangeInputProperty(
                                      item,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Choose Name</option>
                                  <option value="first_name">First Name</option>
                                  <option value="last_name">Last Name</option>
                                  <option value="full_name">Full Name</option>
                                  <option value="phone">Phone</option>
                                  <option value="email">Email</option>
                                  <option value="message">Message</option>
                                  <option value="checkbox">Checkbox</option>
                                </select>
                              </div>
                              <div className="col-12 mt-2 d-flex  align-items-center">
                                <div>
                                  <label>Requried : </label>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <div>
                                  <input
                                    type="checkbox"
                                    onClick={(e) =>
                                      handleChangeInputProperty(
                                        item,
                                        "required",
                                        e.target.checked
                                      )
                                    }
                                    checked={item.required}
                                  />
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          )} */}

          {/* <div
            className="col-12 pb-3 pt-2"
            style={{
              position: "fixed",
              background: "white",
              bottom: "0%",
              zIndex: 100,
            }}
          >
            <button
              onClick={() => setOpenRowSetting(false)}
              type="button"
              className="btn btn-secondary  me-1"
            >
              Close
            </button>
            <button
              onClick={handleRemoveRow}
              type="button"
              className="btn btn-danger float-right"
            >
              Remove
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default RowSettingMenu;
